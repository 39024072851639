import { Component, Provider, ElementRef, OnInit, Input, Compiler } from '@angular/core';
import { Router } from "@angular/router";
import { EhsHeroConfig, config } from '../../environments/environment';

@Component({
  selector: 'managementtier-splash',
  templateUrl: './managementtier-splash.component.html',
  styleUrls: ['./managementtier-splash.component.css']
})

export class ManagementTierSplashComponent {

    redirectUrl: string = "";
  constructor(private router: Router) { }

  ngOnInit() {
      this.redirectUrl = EhsHeroConfig.basicsafeURL;
      let self = this;
      window.setTimeout(function(){
          //console.log("aaaand redirected to " + self.redirectUrl);
          window.location.href = self.redirectUrl;
      },5000);
  }

}
