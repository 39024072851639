<ng-container *ngIf="available">
  <ng-container *ngIf="widget != null && widget.Header != '' && widget.Header != null && widget.Header != 'null'" >
    <div class='widget-header' [innerHtml]="widget.Header">
    </div>
    <!--<div class='widget-header-hidden' [innerHtml]="&nbsp;">
      &nbsp;
    </div>-->
    <div [ngClass]="bagClass" [id]="widget.Id">
      <span *ngIf="!useParent" class='close' (click)='hideComponent(widget.Id)'><i class='fas fa-times'></i></span>
      <div [innerHtml]="widget.Body | safeHtml" *ngIf="widget != null && widget.Body != '' && widget.Body != null && widget.Body != 'null'"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="widget != null && (widget.Header == '' || widget.Header == null || widget.Header == 'null')">
    
    <div [ngClass]="bagClass" [id]="widget.Id">
      <!--<div class='widget-header-hidden' [innerHtml]="&nbsp;">
      &nbsp;
      </div>-->
      <span *ngIf="!useParent" class='close' (click)='hideComponent(widget.Id)'><i class='fas fa-times'></i></span>
      <div [innerHtml]="widget.Body | safeHtml" *ngIf="widget != null && widget.Body != '' && widget.Body != null && widget.Body != 'null'"></div>
    </div>
  </ng-container>
</ng-container>
