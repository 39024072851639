import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'
import { Router } from '@angular/router';

@Component({
    selector: 'safety101',
    styles: ['>>> .modal-xl { width: 1000px; }'],
    templateUrl: './safety101.html'
})

export class Safety101WidgetComponent {
    isLimitedAccess: boolean;

    constructor(private _mbService: MembershipDataService, private router: Router) { }

    ngOnInit() {
    }

    navigate() {
        this.router.navigate(["/resources/guidance-documents/safety-administration/safety-general/Safety-101-A-Guide-for-New-Safety-Professionals/1592084"]);
    }
}

