import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

import { SafeHtmlPipe } from "../../shared/pipes/safe-html";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SearchModel } from "../../shared/types/search.type";
import { Information } from "../../shared/types/information.type";
import { EhsResourcesService } from '../../shared/services/ehs-resources.service';
import { ChallengeModalComponent } from '../../shared/components/challenge-modal.component';
import { AllResourcesFilterOptions } from '../../shared/types/allresources-filter-options.type';
import { PagedResourcesModel } from '../../shared/types/resources-paged';
import { IFilterTag } from '../../shared/types/filter-tag.type';
import { Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'all-resouces',
  templateUrl: './all-resources.component.html',
  styleUrls: ['./all-resources.component.css'],
  providers: [  SafeHtmlPipe]
})

export class AllResources {

  draw : number;
  defaultPageSize:number = 15;
  resourceItems: PagedResourcesModel = new PagedResourcesModel();
  loading: boolean = false;
  hasEnviro: boolean = false;
  hasSafety: boolean = false;
  informationSelected: Information = null;
  tags: Array<IFilterTag> = [];
  isLimitedAccess: boolean = true;
  @ViewChild('challengeModal')
  modal: ChallengeModalComponent;
  filterParameters: AllResourcesFilterOptions = {
    DatabaseIds: "",
    PageNumber: 1,
    PageSize: 15,
    SelectedSources: "",
    SelectedStates: "",
    SelectedCategories: "",
    SelectedTopics: "",
    SelectedDocTypes: "",
    SortBy: "title",
    Acsending: true
  };
  originalSources:any =null;
  originalJurisdictions:any =null;
  originalCategories:any=null;
  originalTopics:any=null;
  originalDoc:any=null;

  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: EhsResourcesService,
    private _mbService: MembershipDataService,


  ) { }
  
 
  ngOnInit() {
   
    if (!this._mbService.hasTool("AllResources") || !this._mbService.customPermissions.GeneralPermissions.allresources.Visible)
      this.router.navigate(["error/403"]);

    this.route.params.subscribe(params => {
      this.isLimitedAccess = this._mbService.isLimitedAccess();
      if (params['id'] != null) {
        let id = params['id'];
        this.loading = true;
        this._heroService.getResourceDetails(id).then(data => {

          if (data != null) {
            this.informationSelected = data;
            this.loading = false;
          }
          else
            this.router.navigate(["error/404"]);
        });
      }
      else {        
        this.loading = true;
        let accessLevels = this._mbService.dataAccessLevels();
        this.hasSafety = accessLevels.includes("3");
        this.hasEnviro = accessLevels.includes("4");
        this.filterParameters.DatabaseIds = accessLevels;     
        
        this.popFiltersOnInit();       
        
        this.dtOptions = { 
          pagingType: 'full_numbers',
          pageLength: this.defaultPageSize,
          searching: false,
          ordering: true,
          order:[[2, 'asc']],
          info: false,
          language : {
            lengthMenu: '_MENU_',
            paginate: {
                  first: '<<',
                  last: '>>',
                  next: '>',
                  previous: '<'
              }
            },
          serverSide: true,
          processing: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.loading ==true;
            this.draw = dataTablesParameters.draw;
            var take = this.filterParameters.PageSize = dataTablesParameters.length;
            var skip = dataTablesParameters.start;
            this.filterParameters.PageNumber = skip == 0 ? 1 : skip / take + 1;
    
            if (dataTablesParameters.order != null)
            {
              this.filterParameters.SortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
              this.filterParameters.Acsending = dataTablesParameters.order[0].dir.toLowerCase() == "asc";
            }
           
              this._heroService.getAllResourcesPagedPost(this.filterParameters).subscribe((resp) => {
              if(resp!=null)
              {
                this.resourceItems = resp;
                if(this.draw==1)
                {           
                  
                  this.tags = [];
                  if (this.hasEnviro && this.hasSafety)
                  this.resourceItems.Sources.filter(f => f.Checked).forEach(item => {
                    this.tags.push({ Id: item.DatabaseId, Filter: "Source", Name: this.getSource(item.DatabaseId) })
                  });
      
                  this.resourceItems.Jurisdictions.filter(f => f.Checked).forEach(item => {
                    this.tags.push({ Id: item.JurisdictionId, Filter: "Jurisdiction", Name: item.JurisdictionName })
                  });
      
                  this.resourceItems.Categories.filter(f => f.Checked).forEach(item => {
                    this.tags.push({ Id: item.CategoryId, Filter: "Category", Name: item.CategoryName })
                  });
      
                  this.resourceItems.Topics.filter(f => f.Checked).forEach(item => {
                    this.tags.push({ Id: item.TopicId, Filter: "Topic", Name: item.TopicName })
                  });
      
                  this.resourceItems.InformationTypes.filter(f => f.Checked).forEach(item => {
                    this.tags.push({ Id: item.InformationTypeId, Filter: "Document", Name: item.InformationTypeName })
                  }); 
                  // this.originalSources = this.resourceItems.Sources; 
                  // this.originalJurisdictions = this.resourceItems.Jurisdictions;
                  // this.originalCategories = this.resourceItems.Categories;
                  // this.originalTopics = this.resourceItems.Topics;
                  // this.originalDoc = this.resourceItems.InformationTypes;            
                } 
                else
                {
                         
                  if (this.originalSources!=null && (this.originalSources?.length > this.resourceItems.Sources.length))
                  {
                    this.resourceItems.Sources = this.originalSources;
                    this.originalSources= null;
                  } 
                  else if (this.originalJurisdictions!=null && (this.originalJurisdictions.length > this.resourceItems.Jurisdictions.length))
                  {
                    this.resourceItems.Jurisdictions = this.originalJurisdictions;
                    this.originalJurisdictions=null;
                  }
                  else if (this.originalCategories!=null && (this.originalCategories.length > this.resourceItems.Categories.length))
                  {
                    this.resourceItems.Categories = this.originalCategories;
                    this.originalCategories =null;
                  }
                  else if (this.originalTopics !=null && (this.originalTopics.length > this.resourceItems.Topics.length))
                  {      
                    this.resourceItems.Topics = this.originalTopics;
                    this.originalTopics = null;
                  }
                  else if (this.originalDoc!=null && (this.originalDoc.length > this.resourceItems.InformationTypes.length))
                  {
                    this.resourceItems.InformationTypes = this.originalDoc;
                    this.originalDoc = null;
                  }
                  
                }    
              this.loading = false;
              
                  callback({
                    recordsTotal: resp.Total,                
                    recordsFiltered: resp.Total,
                    data: []
                  });
              }
              });   
                
              
          },
          drawCallback : (settings:any)=>{ 

            if (this.dtElement && this.resourceItems.Information.length == 0) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                if ($('.dataTables_paginate').length > 0) {
                  $('.dataTables_paginate').hide(); 
                }
                if ($('.dataTables_length').length > 0) {
                  $('.dataTables_length').hide(); 
                }
              });
            }
            else if (this.dtElement && this.resourceItems.Information.length > 0)
            {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                if ($('.dataTables_paginate').length > 0) {
                  $('.dataTables_paginate').show(); 
                }
                if ($('.dataTables_length').length > 0) {
                  $('.dataTables_length').show(); 
                }
                if ($('.dataTables_empty').length > 0) {
                  $('.dataTables_empty').hide(); 
                }
              });
            }
          },
          columns: this.hasSafety && this.hasEnviro ? [{ data: 'type' }, { data: 'topic' }, { data: 'title' }, { data: 'state' }, { data: 'source' }] : [{ data: 'type' }, { data: 'topic' }, { data: 'title' }, { data: 'state' }],
          lengthMenu : [15,25, 50],
          dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
        };      
      
      }
    });
  } 

  popFiltersOnInit() {
    
    //We either set the filters based on url or based on saved filters

    let sources = this.route.snapshot.queryParams['sources'];
    let categories = this.route.snapshot.queryParams['categories'];
    let states = this.route.snapshot.queryParams['states'];
    let docTypes = this.route.snapshot.queryParams['docTypes'];
    let topics = this.route.snapshot.queryParams['topics'];
    if(categories!=null || sources!=null || states!=null || docTypes!=null || topics!=null)
    {
      if (sources != null) {
        this.filterParameters.SelectedSources=sources;
      }
      if (categories != null) {
        this.filterParameters.SelectedCategories=categories;
      }
      if (states != null) {
        this.filterParameters.SelectedStates=states;
      }
      if (docTypes!= null) {

        this.filterParameters.SelectedDocTypes=docTypes;
      }
      if (topics != null) {

        this.filterParameters.SelectedTopics=topics;
      }

      this.saveFilters();
    }
    else
    {      
      let savedFilters = this._heroService.popSavedFilters("all-resources-filters");
      if (savedFilters != null) {
        this.filterParameters.SelectedSources = savedFilters.Sources.reduce((a, b) => { if (b.Checked) return a + b.DatabaseId + ","; else return a; }, "").slice(0, -1);
        this.filterParameters.SelectedCategories = savedFilters.Categories.reduce((a, b) => { if (b.Checked) return a + b.CategoryId + ","; else return a; }, "").slice(0, -1);
        this.filterParameters.SelectedStates = savedFilters.Jurisdictions.reduce((a, b) => { if (b.Checked) return a + b.JurisdictionId + ","; else return a; }, "").slice(0, -1);
        this.filterParameters.SelectedDocTypes = savedFilters.InformationTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);
        this.filterParameters.SelectedTopics = savedFilters.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1);
        
        this.setAllUrl(this.filterParameters.SelectedSources,this.filterParameters.SelectedCategories,this.filterParameters.SelectedStates,this.filterParameters.SelectedDocTypes,this.filterParameters.SelectedTopics);
      }
    }
  }

setAllUrl(sources,categories,states,docTypes,topics)
{
   
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: { sources: sources?sources:null ,categories: categories?categories:null, states:states?states:null, docTypes:docTypes?docTypes:null, topics:topics?topics:null},
      queryParamsHandling: 'merge'
    });
}

setSourcesUrl(sources)
{ 
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {sources: sources?sources:null},
      queryParamsHandling: 'merge'
    });
}
setTopicsUrl(topics)
{
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {topics: topics?topics:null},
      queryParamsHandling: 'merge'
    });
}
setDocTypesUrl(docTypes)
{    
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {docTypes: docTypes?docTypes:null},
      queryParamsHandling: 'merge'
    });
}

setStateUrl(states)
{
 
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {states: states?states:null},
      queryParamsHandling: 'merge'
    });
}

setCategoriesUrl(categories)
{
    
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {categories: categories?categories:null},
      queryParamsHandling: 'merge'
    });
}


  getSource(databaseId: number) {
    switch (databaseId) {
      case 3: return "Safety";
      case 4: return "Enviro"
    }
  }

  getTopic(topicId: number) {
    return this.resourceItems.Topics.find(f => f.TopicId == topicId);
  }
  getType(typeId: number) {
    return this.resourceItems.InformationTypes.find(f => f.InformationTypeId == typeId);
  }
  getCategory(categoryId: number) {
    return this.resourceItems.Categories.find(f => f.CategoryId == categoryId);
  }
  getState(stateId: number) {
    return this.resourceItems.Jurisdictions.find(f => f.JurisdictionId == stateId);
  }

  clearAll() {
    this.tags = [];
    this.filterParameters.PageSize = 15;
    this.filterParameters.PageNumber = 1;
    this.filterParameters.SelectedSources= "";
    this.filterParameters.SelectedStates= "";
    this.filterParameters.SelectedCategories= "";
    this.filterParameters.SelectedTopics = "";
    this.filterParameters.SelectedDocTypes = "";
    this.filterParameters.SortBy = "title";
    this.filterParameters.Acsending = true;
    this.loading = true;
    this._heroService.clearSavedFilters("all-resources-filters");
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.order([[2, 'asc']]);
      dtInstance.page.len(this.defaultPageSize);
     });
     this.setAllUrl(null,null,null,null,null);
    this.reload();    
  }

  clearTag(tag: IFilterTag) {
    switch (tag.Filter) {
      case "Source": this.filterSources(tag.Id, false, tag.Name); break;
      case "Jurisdiction": this.filterJurisdiction(tag.Id, false, tag.Name); break;
      case "Category": this.filterCategory(tag.Id, false, tag.Name); break;
      case "Topic": this.filterTopic(tag.Id, false, tag.Name); break;
      case "Document": this.filterDocument(tag.Id, false, tag.Name); break;
    }
  }

  filterSources(databaseId: number, checked: boolean, name: string) {
    this.resourceItems.Sources.filter(f => f.DatabaseId == databaseId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedSources = this.resourceItems.Sources.reduce((a, b) => { if (b.Checked) return a + b.DatabaseId + ","; else return a; }, "").slice(0, -1);
    
    if (checked) this.tags.push({ Id: databaseId, Filter: "Source", Name: this.getSource(databaseId) }); else this.tags = this.tags.filter(item => !(item.Id === databaseId && item.Filter === "Source"));
    this.loading = true;
    this.originalSources = this.resourceItems.Sources;
    this.setSourcesUrl(this.filterParameters.SelectedSources);
    this.reload();    
    this.saveFilters();
                    

  }

  filterJurisdiction(jurisdictionId: number, checked: boolean, name: string) {

    this.resourceItems.Jurisdictions.filter(f => f.JurisdictionId == jurisdictionId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedStates = this.resourceItems.Jurisdictions.reduce((a, b) => { if (b.Checked) return a + b.JurisdictionId + ","; else return a; }, "").slice(0, -1);
      if (checked) this.tags.push({ Id: jurisdictionId, Filter: "Jurisdiction", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === jurisdictionId && item.Filter === "Jurisdiction"));
    
    this.loading = true;
    this.originalJurisdictions = this.resourceItems.Jurisdictions;
    this.setStateUrl(this.filterParameters.SelectedStates);
    this.reload();
    this.saveFilters();
  }

  filterCategory(categoryId: number, checked: boolean, name: string) {
    this.resourceItems.Categories.filter(f => f.CategoryId == categoryId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedCategories = this.resourceItems.Categories.reduce((a, b) => { if (b.Checked) return a + b.CategoryId + ","; else return a; }, "").slice(0, -1);

    if (checked) this.tags.push({ Id: categoryId, Filter: "Category", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === categoryId && item.Filter === "Category"));
    
    this.loading = true; 
    this.originalCategories = this.resourceItems.Categories;  
    
    this.setCategoriesUrl(this.filterParameters.SelectedCategories);

    this.reload();    
    this.saveFilters();
  }


  filterTopic(topicId: number, checked: boolean, name: string) {
    this.resourceItems.Topics.filter(f => f.TopicId == topicId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedTopics = this.resourceItems.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1);
    if (checked) this.tags.push({ Id: topicId, Filter: "Topic", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === topicId && item.Filter === "Topic"));
  
    this.loading = true;   
    this.originalTopics = this.resourceItems.Topics; 

    this.setTopicsUrl(this.filterParameters.SelectedTopics);
    
    this.reload();
    this.saveFilters();
  }

  filterDocument(documentId, checked: boolean, name: string) {
    this.resourceItems.InformationTypes.filter(f => f.InformationTypeId == documentId || documentId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    
    this.filterParameters.SelectedDocTypes = this.resourceItems.InformationTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);

    if (documentId !== "all")
      if (checked) this.tags.push({ Id: documentId, Filter: "Document", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === documentId && item.Filter === "Document"));
    
    this.loading = true;
    this.originalDoc = this.resourceItems.InformationTypes;
    this.setDocTypesUrl(this.filterParameters.SelectedDocTypes);
    this.reload();
    this.saveFilters();
  }

  linkType(informationFilePath: string) {

    if (informationFilePath == null || informationFilePath == '') 
      return 1;
    else if (informationFilePath.includes(':')) 
      return 2   
    else
      return 3;
  }

  openDetails(information: Information) {
    this.saveFilters();
    this.router.navigate([this.getType(information.InformationTypeId).Url + "/" + this.getCategory(information.CategoryId).Url + "/" + this.getTopic(information.TopicId).Url + "/"+ information.Url +"/" + information.InformationId], { relativeTo: this.route });
  }

  saveFilters() {
    var filters = new SearchModel();
    filters.Jurisdictions = this.resourceItems.Jurisdictions.filter(f => f.Checked);
    filters.Categories = this.resourceItems.Categories.filter(f => f.Checked);
    filters.Topics = this.resourceItems.Topics.filter(f => f.Checked);
    filters.InformationTypes = this.resourceItems.InformationTypes.filter(f => f.Checked);
    filters.Sources = this.resourceItems.Sources.filter(f => f.Checked);
    this._heroService.saveResouceFilters("all-resources-filters", filters);
  }
  reload()
  {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload(function(callback){
        //this.saveFilters();
        
      });
    });
  }
  ngOnDestroy(): void {
    
  }
}
