import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild, ViewEncapsulation } from '@angular/core';

import { SafeHtmlPipe } from "../../shared/pipes/safe-html";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Information } from "../../shared/types/information.type";
import { EhsResourcesService } from '../../shared/services/ehs-resources.service';
import { RegulatoryActivityModel } from '../../shared/types/reg-activity.type';
import { ChallengeModalComponent } from '../../shared/components/challenge-modal.component';
import { RegActivityFilterOptions } from '../../shared/types/reg-activity-filter-options.type';
import { IFilterTag } from '../../shared/types/filter-tag.type';

import { Subject, Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'regulatory-activity-q',
  templateUrl: './regulatory-activity.component.html',
  styleUrls: ['./regulatory-activity.component.css'],
  providers: [SafeHtmlPipe]
})

export class RegulatoryActivity {
 
  draw : number;
  activityItems: RegulatoryActivityModel = new RegulatoryActivityModel;
  loading: boolean = false;
  selectedDateRange: string = "";
  itemNew: any = { Checked: true };
  fromDate: Date = null;
  toDate: Date = null;
  hasEnviro: boolean = false;
  hasSafety: boolean = false;
  details: Information;
  tags: Array<IFilterTag> = [];
  showBody: boolean = true;
  isLimitedAccess: boolean = true;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  defaultPageSize : number = 15;

  @ViewChild('challengeModal')
  challengeModal: ChallengeModalComponent;
  filterParameters: RegActivityFilterOptions = {
    DatabaseIds: "",
    PageNumber: 1,
    PageSize:   this.defaultPageSize,
    SelectedSources: "",
    SelectedStates: "",
    SelectedCategories: "",
    SelectedTopics: "",
    SelectedDocTypes: "",
    SortBy: "pubdate",
    Acsending: false,
    UserId: this._mbService.user.UserId,
    PubDateRange: null
  };

  originalSources:any =null;
  originalJurisdictions:any =null;
  originalCategories:any=null;
  originalTopics:any=null;
  originalTypes :any=null;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: EhsResourcesService,
    private _mbService: MembershipDataService,

    
  ) { }

  ngOnInit() {
    
    this.isLimitedAccess = this._mbService.isLimitedAccess();
    if (!this._mbService.hasTool("RegActivity") || !this._mbService.customPermissions.GeneralPermissions.regActivity.Visible)
      this.router.navigate(["error/403"]);
    this.route.params.subscribe(params => {
      
      if (params['id'] != null) {
        let id = params['id'];

        this.loading = true;
        
        this._heroService.getRegulatoryActivityDetails(id).then(data => {

          if (data != null) {
            this.details = data;
            this.loading = false;
          }
          else
            this.router.navigate(["error/404"]);
            this.loading = false;
            
        });  
      }
      else {
        this.loading = true;
        let accessLevels = this._mbService.dataAccessLevels();
        this.hasSafety = accessLevels.includes("3");
        this.hasEnviro = accessLevels.includes("4");
        this.filterParameters.DatabaseIds = accessLevels;
        
        this.popFiltersOnInit();
        this.dtOptions = { 
          pagingType: 'full_numbers',
          pageLength: 15,
          searching: false,
          ordering: true,
          order:[[3, 'desc']],
          info: false,
          language : {
            lengthMenu: '_MENU_',
            paginate: {
                  first: '<<',
                  last: '>>',
                  next: '>',
                  previous: '<'
              }
            },
          serverSide: true,
          processing: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.loading ==true;
            this.draw = dataTablesParameters.draw;
            var take = this.filterParameters.PageSize = dataTablesParameters.length;
            var skip = dataTablesParameters.start;
            this.filterParameters.PageNumber = skip == 0 ? 1 : skip / take + 1;
    
            if (dataTablesParameters.order != null)
            {
              this.filterParameters.SortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
              this.filterParameters.Acsending = dataTablesParameters.order[0].dir.toLowerCase() == "asc";
            }
           
              this._heroService.getRegulatoryActivityPagedPost(this.filterParameters).subscribe((resp) => {
              this.activityItems = resp;

            if(this.draw==1)
              { 
                

                if (this.hasEnviro && this.hasSafety)
                  this.activityItems.Sources.filter(f => f.Checked).forEach(item => {
                    this.tags.push({ Id: item.DatabaseId, Filter: "Source", Name: this.getSource(item.DatabaseId) })
                });

                this.activityItems.Jurisdictions.filter(f => f.Checked).forEach(item => {
                  this.tags.push({ Id: item.JurisdictionId, Filter: "Jurisdiction", Name: item.JurisdictionName })
                });

                this.activityItems.Categories.filter(f => f.Checked).forEach(item => {
                  this.tags.push({ Id: item.CategoryId, Filter: "Category", Name: item.CategoryName })
                });

                this.activityItems.Topics.filter(f => f.Checked).forEach(item => {
                  this.tags.push({ Id: item.TopicId, Filter: "Topic", Name: item.TopicName })
                });

                this.activityItems.InformationTypes.filter(f => f.Checked).forEach(item => {
                  this.tags.push({ Id: item.InformationTypeId, Filter: "Document", Name: item.InformationTypeName })
                });  
                           
          
          }
          else{
            if (this.originalSources!=null && (this.originalSources?.length > this.activityItems.Sources.length))
            {
              this.activityItems.Sources = this.originalSources;
              this.originalSources= null;
            } 
            else if (this.originalJurisdictions!=null && (this.originalJurisdictions.length > this.activityItems.Jurisdictions.length))
            {
              this.activityItems.Jurisdictions = this.originalJurisdictions;
              this.originalJurisdictions=null;
            }
            else if (this.originalCategories!=null && (this.originalCategories.length > this.activityItems.Categories.length))
            {
              this.activityItems.Categories = this.originalCategories;
              this.originalCategories =null;
            }
            else if (this.originalTopics !=null && (this.originalTopics.length > this.activityItems.Topics.length))
            {      
              this.activityItems.Topics = this.originalTopics;
              this.originalTopics = null;
            }
            else if (this.originalTypes!=null && (this.originalTypes.length > this.activityItems.InformationTypes.length))
            {
              this.activityItems.InformationTypes = this.originalTypes;
              this.originalTypes = null;
            }
            

          }
          this.loading = false;
          callback({
            recordsTotal: resp.AllTimeTotal,                
            recordsFiltered: resp.Total,
            data: []
          });
              });  
        
        },
        drawCallback : (settings:any)=>{
          if (this.dtElement && this.activityItems.Information?.length == 0) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              if ($('.dataTables_paginate').length > 0) {
                $('.dataTables_paginate').hide(); 
              }
              if ($('.dataTables_length').length > 0) {
                $('.dataTables_length').hide(); 
              }
              
            });
          }
          else if (this.dtElement && this.activityItems.Information?.length > 0)
          {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              if ($('.dataTables_paginate').length > 0) {
                $('.dataTables_paginate').show(); 
              }
              if ($('.dataTables_length').length > 0) {
                $('.dataTables_length').show(); 
              }
              if ($('.dataTables_empty').length > 0) {
                $('.dataTables_empty').hide(); 
              }
            });
          }
        },
        // columnDefs: [
        //   {targets: [3], type: 'date'}, ],
        columns: this.hasSafety && this.hasEnviro ? [{ data: 'type' }, { data: 'topic' }, { data: 'title' },{data:'pubdate', type:'date'} ,{ data: 'state' }, { data: 'source' }] : [{ data: 'type' }, { data: 'topic' }, { data: 'title' },{data:'pubdate', type:'date'} , { data: 'state' }],
        lengthMenu : [15,25, 50],
        dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
      }; 
      }
    });

  }
  // getPage(page: number) {
  //   this.loading = true;
  //   this.filterParameters.PageNumber = page;
  //   this.loading = true;
  //   this._heroService.getRegulatoryActivityPagedPost(this.filterParameters).then(data => {
  //     if (data != null) {
  //       this.activityItems.Information = data.Information;
  //       this.activityItems.PageNumber = data.PageNumber;
  //       this.loading = false;
  //       this.dtTrigger.next();
  //     }
  //   });
  // }

  popFiltersOnInit() {
    let savedFilters = this._heroService.popSavedFilters("reg-activity-filters");
    if (savedFilters == null || savedFilters.New) {
      let newDate = new Date();
      newDate.setDate(newDate.getDate() - 14);
      this.filterParameters.PubDateRange = { From: newDate, To: new Date() };
      this.itemNew.Checked = true;
    }

    if (savedFilters != null) {
      this.filterParameters.SelectedSources = savedFilters.Sources.reduce((a, b) => { if (b.Checked) return a + b.DatabaseId + ","; else return a; }, "").slice(0, -1);
      this.filterParameters.SelectedCategories = savedFilters.Categories.reduce((a, b) => { if (b.Checked) return a + b.CategoryId + ","; else return a; }, "").slice(0, -1);
      this.filterParameters.SelectedStates = savedFilters.Jurisdictions.reduce((a, b) => { if (b.Checked) return a + b.JurisdictionId + ","; else return a; }, "").slice(0, -1);
      this.filterParameters.SelectedDocTypes = savedFilters.InformationTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);
      this.filterParameters.SelectedTopics = savedFilters.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1);

      if (!savedFilters.New) {
        this.toDate = savedFilters.To==null ? savedFilters.To : new Date(savedFilters.To);
        this.fromDate = savedFilters.From==null ? savedFilters.From :new Date(savedFilters.From);
        this.itemNew.Checked= false;
        this.filterParameters.PubDateRange = {
          To: this.toDate,
          From: this.fromDate
        };
      }
    }
  }

 

  getSource(databaseId: number) {
    switch (databaseId) {
      case 3: return "Safety";
      case 4: return "Enviro"
    }
  }

  getTopic(topicId: number) {
    return this.activityItems.Topics.find(f => f.TopicId == topicId);
  }
  getType(typeId: number) {
    return this.activityItems.InformationTypes.find(f => f.InformationTypeId == typeId);
  }
  getState(stateId: number) {
    return this.activityItems.Jurisdictions.find(f => f.JurisdictionId == stateId);
  }

  getCategory(categoryId: number) {
    return this.activityItems.Categories.find(f => f.CategoryId == categoryId);
  }

  clearAll() {
    this.tags = [];
    this.itemNew.Checked = true;
    let newDate = new Date();
    newDate.setDate(newDate.getDate() - 14);
    this.filterParameters.PubDateRange = { From: newDate, To: new Date() };
    
    this.fromDate = null;
    this.toDate = null;
    this.filterParameters.PageSize = 15;
    this.filterParameters.PageNumber = 1;
    this.filterParameters.SelectedSources = "";
    this.filterParameters.SelectedStates = "";
    this.filterParameters.SelectedCategories = "";
    this.filterParameters.SelectedTopics = "";
    this.filterParameters.SelectedDocTypes = "";
    this.filterParameters.SortBy = "pubdate";
    this.filterParameters.Acsending = false;
    this._heroService.clearSavedFilters("reg-activity-filters");
    this.loading = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
     dtInstance.order([[3, 'desc']]);
     dtInstance.page.len(this.defaultPageSize);     
    });
    this.reload();
  }

  clearTag(tag: IFilterTag) {
    switch (tag.Filter) {
      case "Source": this.filterSources(tag.Id, false, tag.Name); break;
      case "Jurisdiction": this.filterJurisdiction(tag.Id, false, tag.Name); break;
      case "Category": this.filterCategory(tag.Id, false, tag.Name); break;
      case "Topic": this.filterTopic(tag.Id, false, tag.Name); break;
      case "Document": this.filterDocument(tag.Id, false, tag.Name); break;
    }
  }

  filterByFromDate(date: Date, input) {
      this.fromDate = date;
  }

  filterByDate(from: Date, to:Date) {
    this.filterParameters.PubDateRange = {
      From: from,
      To: to
    }
    this.loading = true;
    this.saveFilters();
    this.reload();
  }

  filterByToDate(date, input) {
      this.toDate = date;
  }

  filterNew(isNew: string, checked) {
    if (isNew === "new") {
      this.itemNew.Checked = true;
      this.fromDate = null;
      this.toDate = null;
      let fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 14);
      this.filterByDate(fromDate, new Date());
    }
    else {
      this.itemNew.Checked = !this.itemNew.Checked;
      this.filterParameters.PubDateRange = null;
      this.saveFilters();
      this.loading = true;
      this.reload();
    }
  }

  filterSources(databaseId: number, checked: boolean, name: string) {
    this.activityItems.Sources.filter(f => f.DatabaseId == databaseId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedSources = this.activityItems.Sources.reduce((a, b) => { if (b.Checked) return a + b.DatabaseId + ","; else return a; }, "").slice(0, -1);

    if (checked) this.tags.push({ Id: databaseId, Filter: "Source", Name: this.getSource(databaseId) }); else this.tags = this.tags.filter(item => !(item.Id === databaseId && item.Filter === "Source"));
    this.loading = true;
    this.originalSources = this.activityItems.Sources;
    this.saveFilters();
    this.reload();
  }

  filterJurisdiction(jurisdictionId: number, checked: boolean, name: string) {

    this.activityItems.Jurisdictions.filter(f => f.JurisdictionId == jurisdictionId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedStates = this.activityItems.Jurisdictions.reduce((a, b) => { if (b.Checked) return a + b.JurisdictionId + ","; else return a; }, "").slice(0, -1);
    if (checked) this.tags.push({ Id: jurisdictionId, Filter: "Jurisdiction", Name: name}); else this.tags = this.tags.filter(item => !(item.Id === jurisdictionId && item.Filter === "Jurisdiction"));
    this.loading = true;
    this.originalJurisdictions = this.activityItems.Jurisdictions;
    this.saveFilters();
    this.reload();
  }

  filterCategory(categoryId: number, checked: boolean, name: string) {
    this.activityItems.Categories.filter(f => f.CategoryId == categoryId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedCategories = this.activityItems.Categories.reduce((a, b) => { if (b.Checked) return a + b.CategoryId + ","; else return a; }, "").slice(0, -1);
    if (checked) this.tags.push({ Id: categoryId, Filter: "Category", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === categoryId && item.Filter === "Category"));
    this.loading = true;
    this.originalCategories = this.activityItems.Categories;
    this.saveFilters();
    this.reload();
  }


  filterTopic(topicId: number, checked: boolean, name: string) {
    this.activityItems.Topics.filter(f => f.TopicId == topicId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedTopics = this.activityItems.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1);

    if (checked) this.tags.push({ Id: topicId, Filter: "Topic", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === topicId && item.Filter === "Topic"));
    this.loading = true;
    this.originalTopics = this.activityItems.Topics;
    this.saveFilters();
    this.reload();
  }

  filterDocument(documentId: number, checked: boolean, name: string) {
    this.activityItems.InformationTypes.filter(f => f.InformationTypeId == documentId).forEach((item) => {
      item.Checked = checked;
    });
    this.filterParameters.SelectedDocTypes = this.activityItems.InformationTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);
    this.loading = true;
    if (checked) this.tags.push({ Id: documentId, Filter: "Document", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === documentId && item.Filter === "Document"));
    this.originalTypes = this.activityItems.InformationTypes;
    this.saveFilters();
    this.reload();
  }

  saveFilters() {
    var filters = new RegulatoryActivityModel();
    filters.Jurisdictions = this.activityItems.Jurisdictions.filter(f => f.Checked);
    filters.Categories = this.activityItems.Categories.filter(f => f.Checked);
    filters.Topics = this.activityItems.Topics.filter(f => f.Checked);
    filters.InformationTypes = this.activityItems.InformationTypes.filter(f => f.Checked);
    filters.Sources = this.activityItems.Sources.filter(f => f.Checked);
    filters.New = this.itemNew.Checked;
    filters.From = this.fromDate;
    filters.To = this.toDate;
    this._heroService.saveResouceFilters("reg-activity-filters", filters);
  }  

ngOnDestroy(): void {
  
}


reload()
  {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
}
