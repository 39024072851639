<h1>Regupdate Administration</h1>

<h2>Resend Regupdate
</h2>
<input type="email" class="form-control" [(ngModel)]="email" placeholder="Enter email" />
<button type="button" class="btn btn-default regupdate-send-button" (click)="sendEmail()">Send</button>


<div id="lockAlert" class="alert alert-success lockAlert">
  <strong>Sending Email</strong>
</div>