import { Topic } from '../../types/topic.type'
import { InformationType } from '../../types/information-type.type'
import { TrainingType } from '../../types/Training/training-type'
import { Language } from '../../types/language.type'
import { TrainingInformation } from '../../types/Training/training-information'
import { Source } from '../../types/source.type'
import { TrainingAllFilterOptions } from '../../types/Training/training-all-filter-options-type';


export class TrainingAllModel {
  DocumentTypes: InformationType[]=[];
  TrainingTypes: InformationType[]=[];
  Topics: Topic[]=[];
  Information: TrainingInformation[]=[];
  InformationMaster: TrainingInformation[]=[];
  Languages: Language[]=[];
  Sources: Source[]=[];
  DatabaseIds: number;
  PageNumber: number;
  PageSize: number;
  TotalCount: number;
  FilterParameters: TrainingAllFilterOptions;
}
