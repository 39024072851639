import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'error',
  templateUrl: './error.html',
  styleUrls:['./error.css']
})

export class Error {

  type: string;
  errorMessage403: string = "Access Denied";

  constructor(
    public _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {

      if (params["type"] != null) {

        this.type = params["type"];

      }

      if (params["page"] != null) {

        switch (params["page"]) {
          case "sds":
            this.errorMessage403 = "Full access to the Safety Data Sheets search is reserved for EHS Hero subscribers.";
            break;
        }

      }

    });

  }
  
}
