<div *ngIf="!loading">
  <h1>EHS Training Materials</h1>
    <div>Browse through the EHS Hero training library and select the training materials that meet your needs.</div>
    <div style="text-align: right;">
      <a href="/ehs/resources/guidance-documents/safety-administration/Employee-Manager-Training/Training-Resource-Guide/1801107" role ="button" target="_blank" class="btn btn-white" title="Learn how to develop, deliver, assess, and support a comprehensive OSHA-compliant training program."><i class="fas fa-book"></i>  Training Guide</a>
    </div>
  <br />
</div>

<div *ngIf="loading" class="row" style="text-align:center;">
  <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
</div>

<div class="row" [class.is-hidden]="loading">
  <div  style="margin-top:20px;" class="row">
    <div class="col-lg-3" style="margin-bottom:20px;" *ngIf="trainingItems != null">
      <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
      <div class="outer-filter-panel">
        <div class="filter-panel body-content-label" data-target="#trainingType">
          Training Types
          <div class="dropdown">
            <a class="droplink nav-sub-link"><img src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/question-icon.png" /></a>

            <div class="dropdown-content">

              <div class="body-content-label" *ngIf="filterParameters.HasSafety">7-Minute Safety Trainer</div>
              <div class="body-content" style="font-weight:normal; padding-bottom:5px;" *ngIf="filterParameters.HasSafety">Shorter instructor-led written training sessions with supporting handout and quiz.</div>

              <div class="body-content-label" >Audiovisual Presentation</div>

              <div class="body-content" style="font-weight:normal; padding-bottom:5px;">Complete automated slideshow including voice narration for classroom or self-paced presentation; associated exercises, handouts, and quizzes provided.</div>
              <div class="body-content-label" >PowerPoints</div>
              <div class="body-content" style="font-weight:normal; padding-bottom:5px;">Usually used as an initial in-depth instructor led training (20-30 minutes each session). Includes complete and customizable instructor-led slideshow presentations with associated exercises, handouts, and quizzes.</div>
              <div class="body-content-label" >Toolbox Talks</div>
              <div class="body-content" style="font-weight:normal; padding-bottom:5px;">5 - Minute talks on specific tasks and hazards intended for use on a job site.</div>
              
            </div>
          </div>

          <span data-toggle="collapse" (click)="ttCollapsed = !ttCollapsed" data-target="#trainingType" style="float: right;cursor: pointer;">{{ ttCollapsed? '+': '&ndash;' }}</span>
        </div>
        <div id="trainingType" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table"  *ngIf="resourceItems!=null">
          <tbody>
            <tr *ngFor="let item of resourceItems.TrainingTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTrainingTypes(item.InformationTypeId, $event.target.checked, item.InformationTypeName)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <div class="filter-panel  body-content-label" data-target="#language">
          Languages<span data-toggle="collapse" (click)="langCollapsed = !langCollapsed" data-target="#language" style="float: right;cursor: pointer;">{{ langCollapsed? '+': '&ndash;' }}</span>
        </div>
        <div id="language" class="collapse in">
          <table class="table"  *ngIf="resourceItems!=null">
          <tbody>
            <tr *ngFor="let item of resourceItems.Languages">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterLanguages(item.Language, $event.target.checked, item.Language)" [checked]="item.Checked">{{item.Language}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <div class="filter-panel  body-content-label" data-target="#topic">
          Topics<span data-toggle="collapse" (click)="topicsCollapsed = !topicsCollapsed" data-target="#topic" style="float: right;cursor: pointer;">{{ topicsCollapsed? '+': '&ndash;' }}</span>
        </div>
        <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table"  *ngIf="resourceItems!=null">
          <tbody>
            <tr *ngFor="let item of resourceItems.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <div class="filter-panel  body-content-label" data-target="#doc">
          Document Types<span data-toggle="collapse" (click)="dtCollapsed = !dtCollapsed" data-target="#doc" style="float: right;cursor: pointer;">{{ dtCollapsed? '+': '&ndash;' }}</span>
        </div>
        <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table"  *ngIf="resourceItems!=null">
          <tbody>
            <tr *ngFor="let item of resourceItems.DocumentTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterDocumentTypes(item.InformationTypeId, $event.target.checked, item.InformationTypeName)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
        <div class="filter-panel  body-content-label" data-target="#source" *ngIf="filterParameters.HasEnviro && filterParameters.HasSafety">
          Source<span data-toggle="collapse" (click)="sourceCollapsed = !sourceCollapsed" data-target="#source" style="float: right;cursor: pointer;">{{ sourceCollapsed? '+': '&ndash;' }}</span>
        </div>
        <div id="source" class="collapse in" *ngIf="filterParameters.HasEnviro && filterParameters.HasSafety">
          <table class="table" *ngIf="resourceItems!=null">
          <tbody>
            <tr *ngFor="let item of resourceItems.Sources">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterSources(item.DatabaseId, $event.target.checked, item.Source)" [checked]="item.Checked">{{item.Source}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

      </div>
    </div>
    <div class="col-lg-9">

      <div class="ar-tab-container">
        <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
          <div class="inline-text">{{tag.Name}}</div>
          <div class="delete-button" style="height: 100%">
            <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
              <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
            </svg>
          </div>
        </div>
      </div>

      <div class="white-well">
        <table class="table table-striped alltraining-activity-table" datatable [dtOptions]="dtOptions">
          <thead class="thead">
            <tr>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Training Type &nbsp; <span></span> </a>                
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Topic &nbsp; <span></span> </a> 
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Title &nbsp; <span></span> </a> 
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Document Type &nbsp; <span></span> </a> 
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Language &nbsp; <span></span> </a> 
              </th>
              <th *ngIf="filterParameters.HasEnviro && filterParameters.HasSafety">
                <a class="text-nowrap" style="cursor:pointer">Source &nbsp; <span></span> </a> 
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of trainingItems">
              <td>{{item.TrainingTypeName}}</td>
              <td>{{item.TopicName}}</td>
              <td><a style="cursor:pointer;" [routerLink]="openDetails(item)">{{item.Title}}</a></td>
              <td>{{item.DocumentTypeName}}</td>
              <td>{{item.Language}}</td>
              <td *ngIf="filterParameters.HasEnviro && filterParameters.HasSafety">{{item.Source}}</td>
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

