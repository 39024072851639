import { Injectable, Provider, Component } from "@angular/core";
import { HttpClient, HttpHeaders  } from '@angular/common/http'
import { EhsHeroSvc } from "./ehs-hero.service";
import { Router } from "@angular/router";
import { EhsHeroConfig } from '../../../environments/environment'
import { MembershipDataService } from "./membership.data.service";
import { Information } from '../types/information.type'

import { TrainingAllModel } from "../../shared/types/training/training-all";
import { TrainingAllFilterOptions } from "../types/Training/training-all-filter-options-type";

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

export class EhsTrainingService {


  constructor(private http: HttpClient, private ehsService: EhsHeroSvc, private router: Router, private mbDataService: MembershipDataService) {
    
  }

  getAllTraining(sourceDatabaseIds)  {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }
    let options =  {
      headers: headers
   }

    return this.http.get<TrainingAllModel>(EhsHeroConfig.endpoint + '/Information/AllTraining?sourceDatabaseIds=' + sourceDatabaseIds, options);
  }

  
  getAllPagedTraining(filters: TrainingAllFilterOptions)  {

    filters.UserId = this.mbDataService.user.UserId;

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.post<TrainingAllModel>(EhsHeroConfig.endpoint + '/Information/AllTrainingPaged',filters, options).toPromise();
  }


  getResourceDetails(informationId) {
    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }
    let options =  {
      headers: headers
   }
    return this.http.get(EhsHeroConfig.endpoint + '/Information/ResourceDetails/' + informationId, options)
    .toPromise();
  }

  getTrainingDetails(infoId) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information/AllTrainingDetails?infoId=' + infoId, options)
      .toPromise();
  }

  getTrainingSupportingDocuments(infoId) {

    let headers: any = {
      //'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/AllTrainingSupportingDocuments?infoId=' + infoId, options)
       .toPromise();
  }

  getInformationByType(infoId, type) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information?id=' + infoId + '&type=' + type, options)
      .toPromise();
  }

  saveResouceFilters(key, filters) {
    localStorage.setItem(key, JSON.stringify(filters));
  }

  popSavedFilters(key) {
    let filters = JSON.parse(localStorage.getItem(key));
    localStorage.removeItem('filters');
    return filters;
  }
  
  removedSaveFilter(key){
    localStorage.removeItem(key);
  }

}
