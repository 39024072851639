
<div id="zenDesk"></div>

<div class="options">

  <div class="section group row">
    <div class="col col1o3 col-md-4"><img src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/hrhotline/HRHotline_emailicon.png" alt="" /><br /><strong>Email</strong></div>
    <div class="col col2o3 col-md-4">Submit your question through email, and we will get back to you within one (1) business day on average.* Questions are guaranteed to be answered within three (3) business days.</div>
    <div class="col col3o3 col-md-4" *ngIf="!isLimitedAccess"><a class="btn-orange" (click)="openEmail();">Email Now</a></div>
    <div class="col col3o3 col-md-4" *ngIf="isLimitedAccess"><a class="btn-orange" (click)="openModal(modal);"><i class="fas fa-lock"></i>&nbsp;&nbsp;Email Now</a></div>
  </div>
</div>

<div class="standardText" style="text-align: center; padding-top:10px;">
  A "single" question pertains to one subject and does not require a multipart answer. Questions are answered within one (1) business day on average* and are guaranteed to be answered within three (3) business days. For more information on the Terms and Conditions governing BLR’s EHS Hotline service, see <a href="http://www.blr.com/About/Legal-Terms" target="_blank" style="white-space: nowrap">http://www.blr.com/About/Legal-Terms</a>.
</div>
<div class="standardText" style="text-align: center; padding-top:10px; padding-bottom:20px;">
  *based on typical EHS Hero subscriber response time for submitted EHS Hotline questions.
</div>

   
    <ng-template #modal>
      <div class="modal-header modal-header-green">
        Limited Access
      </div>
      <div class="modal-body">
        <div class="body-content">

          <h1>
            The resource you are trying to access is reserved for EHS Hero subscribers.
          </h1>

          <div>
            <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">Request a demo</a> to access time-saving resources, workflow tools, and much more.
          </div>

        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
      </div>

    </ng-template>
