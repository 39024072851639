import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'safeHtmlNews' })
export class SafeHtmlNewsPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    value = value.replace(/\.container/, ".container-innerhtml");
    value = value.replace(/class="container"/, "class=\"container-innerhtml\"");
    value = value.replace(/body{[\r\n]*?.*?[\r\n]*?}/, "");
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
