import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EhsHeroConfig } from '../../environments/environment';

        @Component({
          selector: 'app-footer',
          templateUrl: 'footer.component.html',
          styleUrls: ['footer.component.css']
            
        })
        export class FooterComponent {
          year = Date.now();
          version: string;

          @Input() footerImage: string;
          @Input() footerAddress: string;
          @Input() footerPhone: string;
          constructor(
            private router: Router
          ) {
           
          }


          gotToTop() {
            window.scrollTo(0, 0);
          }

        }
