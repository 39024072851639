import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

import { EhsHeroConfig, config } from '../../../environments/environment';
import { EhsHeroSvc } from "../../shared/services/ehs-hero.service";
import { SafeHtmlPipe } from "../../shared/pipes/safe-html";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EhsResourcesService } from '../../shared/services/ehs-resources.service';
import { SDSSearch } from "../../shared/types/sds-search.type";
import  'jquery'; 
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'sds-search-component',
  templateUrl: './sds-search.component.html',
  styleUrls: ['./sds-search.component.css'],
  providers: [ SafeHtmlPipe]
})

export class SDSSearchComponent {

  sdsSearchItems: SDSSearch[] = null;
  updatedAPI: boolean = false; 
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: EhsHeroSvc,
    private _mbService: MembershipDataService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: true,
      info: false,
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };

    this.updatedAPI = EhsHeroConfig.newSDSSearch;
    if (this._mbService.user.SubscriptionType == "EHS Free Trial")
      this.router.navigate(["error/403/sds"]);

    if (!this._mbService.hasTool("SDS") || !this._mbService.customPermissions.GeneralPermissions.sdsSearch.Visible)
      this.router.navigate(["error/403"]);

    $('.imgloader').hide();
    let _this = this;

    $('#txtProduct, #txtManufacturer, #txtCAS').on('keypress', function (e) {
      var code = e.keyCode || e.which;
      if (code == 13) {
        _this.search($('#txtProduct').val(), $('#txtManufacturer').val(), $('#txtCAS').val())
      }
    });
  }
  openPDF(name,libraryDocumentId){
    this._heroService.getSdsPdf(name,libraryDocumentId).subscribe(
      (blob) => {
        let winNav: any;

        winNav = window.navigator;
          if (winNav && winNav.msSaveOrOpenBlob) {
            winNav.msSaveOrOpenBlob(blob);
            return;
          }else{
            let url = URL.createObjectURL(blob);
            window.open(url,"_blank");
          }
        });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  
  search(product, manufacturer, cas) {

    $('.imgloader').css('visibility', 'visible');
    $('.imgloader').show();

    if(EhsHeroConfig.newSDSSearch){
      this._heroService.getNewSDSSearch(product, manufacturer, cas).then(result => {

        if (result.length > 0) {
          for(let i = 0; i < result.length; i++){
            result[i].name = result[i].productName;
            result[i].CAS = result[i].productCas;
          }
          this.sdsSearchItems = result;
          
          $('.imgloader').hide();
        } else {
          this.sdsSearchItems = null;
          $('.imgloader').hide();
        }
        this.rerender();
      });
    }else{
      this._heroService.getSDSSearch(product, manufacturer, cas).then(result => {

        if (result.length > 0) {
          this.sdsSearchItems = result;
          
          $('.imgloader').hide();
        } else {
          this.sdsSearchItems = null;
          $('.imgloader').hide();
        }
        this.rerender();
      });
    }
  }
  rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });  
      
    }  
    else if (this.dtElement)
    {      
         this.dtTrigger.next();     
    } 
  }

}
