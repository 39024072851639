import { Component, Provider, TemplateRef, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';

//types
import { BookmarkFolder } from '../../shared/types/bookmark-folder.type'

//services
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'

@Component({
  selector:"bookmarks",
  templateUrl: './bookmarks.component.html'
})

export class BookmarkComponent{

  @ViewChild('bookmarkModal')
  private template: TemplateRef<any>;

  modal: BsModalRef;
  
  @Output() bookmarkSaved = new EventEmitter();

  bookmarkFolders: BookmarkFolder[];
  selectedBookmarkFolder: BookmarkFolder;
  errorMessage: string = "";

  @Input() informationId: number = 0;
  @Input() parentInformationTypeId: number = 0;
  @Input() informationTypeId: number = 0;
  @Input() categoryId: number = 0;
  @Input() topicId: number = 0;
  @Input() bookmarkFolderId: number = 0;
  @Input() bookmarkTitle: string;
  @Input() bookmarkId: number;

  constructor(private _heroService: EhsHeroSvc, private _mbDataService: MembershipDataService,public modalService: BsModalService) { }

  ngOnInit() { this.getBookmarkFolders(); }

  getBookmarkFolders() {
    this._heroService.getBookmarkFolders(this._mbDataService.dataAccessLevels()).then(result => {
      if (result != null) {
        this.bookmarkFolders = result;

        if (this.bookmarkFolders.filter(f => f.BookmarkFolderId == this.bookmarkFolderId).length > 0) {
          this.selectedBookmarkFolder = this.bookmarkFolders.filter(f => f.BookmarkFolderId == this.bookmarkFolderId)[0];
        }
        else {
          this.selectedBookmarkFolder = this.bookmarkFolders.find(f => f.Name == "Bookmarks");
        }
        
      }
    });
  }

  saveBookmark() {

    this._heroService.saveBookmark(

        this.informationId,
        this.bookmarkTitle,
        this._mbDataService.user.UserId,
        (this.selectedBookmarkFolder == null) ? 0 : this.selectedBookmarkFolder.BookmarkFolderId,
        this.informationTypeId,
        this.topicId,
        this.bookmarkId,
        this.categoryId,
        this.parentInformationTypeId

    ).subscribe((data) => {

      if (data == "success") {

          this.bookmarkSaved.emit('close');
          this.modal.hide();
          //this.clearSelections();

        }
        else
        {
          this.errorMessage =data.toString();
        }

    });
  }

  deleteBookmark(bookmarkId) {
    if (confirm("Are you sure you want to delete this bookmark?")) {
      this._heroService.deleteMyBookmark(bookmarkId).subscribe((data) => {

        if (data == "success") {
          this.bookmarkSaved.emit('close');
          this.modal.hide();
        } else {
          this.errorMessage = data.toString();
        }

      });
    }
  }

  openBookmarkModal() {
    this.getBookmarkFolders();
    this.modal = this.modalService.show(this.template); 
    this.bookmarkSaved.emit('open');
  }

  dismissBookmarkModal() {
    this.modal.hide();
    this.errorMessage = "";
    this.bookmarkSaved.emit('close');
  }

  clearSelections() {
    this.errorMessage = "";
    this.bookmarkTitle = "";
    this.bookmarkFolderId = 0;
    this.selectedBookmarkFolder = null;
  }
}
