import { Component, TemplateRef } from '@angular/core';

import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'
import { EhsHeroConfig } from '../../../../environments/environment'

@Component({
  selector: 'plan-builder',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './plan-builder.html'
})

export class PlanBuilderWidgetComponent {
  //isLimitedAccess: boolean;

  modalRef: BsModalRef;
  constructor(private _mbService: MembershipDataService,
    public modalService: BsModalService) { }

  ngOnInit() {
    //this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {

    window.open(EhsHeroConfig.planBuilderURL, "_blank")

  } 
  openModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
 }
}

