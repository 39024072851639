import { Component, ViewChild, Input, TemplateRef} from '@angular/core';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: "challenge-modal",
  templateUrl: './challenge-modal.component.html'
})

export class ChallengeModalComponent {
  modal: BsModalRef;
  @ViewChild('template') private templateRef: TemplateRef<any>;

  @Input() title: string = "The resource you are trying to access is reserved for EHS Hero subscribers.";

  constructor(private modalService: BsModalService) { }

  open()
  {
     this.modal = this.modalService.show(this.templateRef);
  }
 
   hide() {
    this.modal.hide();
   } 
 }
