import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

//services
import { MembershipDataService } from '../app/shared/services/membership.data.service'

//types
import { UserProfile } from '../app/shared/types/user-profile.type'

import  'jquery';
import { EhsHeroConfig, config } from '../environments/environment';
import { PermissionTier } from './shared/types/permission-tier.type';

import { Subscription, timer } from 'rxjs';
import { take, map, first } from 'rxjs/operators';
import { Account } from './shared/types/account.type';

declare let ga: Function;

@Component({
  selector: 'ehs-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  customWidgetsVisible: boolean;
  showWidgets: boolean;
  customPermissions: PermissionTier = new PermissionTier();
  footerPhoneDefault: string;
  footerImageDefault: string;
  footerAddressDefault: string;
  headerImageDefault: string;
  title = 'app';
  currentRoute: string;
  currentSubRoute: string;
  searchTerm: string = null;
  isAuthenticated: boolean = false;
  userName: string = null;
  userProfile: UserProfile = new UserProfile;
  headerVisible: boolean = false;
  authenticated: boolean = false;
  trainingTodayUrl: string;
  //hidetopNavCustomerIds: Array<number>;

  topNavVisible: boolean = true;
  videoInclusion:boolean =false;
  trainingInclusion: boolean = false;
  allResourcesInclusion: boolean = false;
  sdsInclusion: boolean = false;
  sttInclusion: boolean = false;
  regulationInclusion: boolean = false;
  newsInclusion: boolean = false;
  hotlineInclusion: boolean = false;
  regActivityInclusion: boolean = false;
  reportSpillInclusion: boolean = false;
  complianceCalendarInclusion: boolean = false;
  chartBuilderInclusion: boolean = false;
  planBuilderInclusion: boolean = false;
  graybarInclusion : boolean = false;
  industriesInclusion : boolean = false;

  customSettingsVisible: boolean = false;
  userManagementVisible: boolean = false;
  showNotificationBanner:boolean = false;

  headerImage: string;
  footerImage: string;
  footerAddress: string;
  footerPhone: string;
  hasSafety: boolean=false;
  snBranded: boolean = false;
  isAdmin: boolean = false;

  inquiryTimeout: number;
  readonly warningTime: number=600;//10 min in seconds
  warningTimeRemaining:number;

  userLoggedIn:boolean = false;
 
  ActiveXObject: (type: string) => void;

  @ViewChild('mobileClick') mobileClick: ElementRef;
  @ViewChild('appClick') appClick: ElementRef;

  warningTimerSubscribe:Subscription;
  timedOutTimerSubscribe:Subscription;

  constructor(private _router: Router, private _mbService: MembershipDataService) {

    if (this._router.url.indexOf("staging.") <= -1) {
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.snBranded = event.url.indexOf("snehs") > -1;
          if(EhsHeroConfig.usesInquiry){
            this.acquireAndSendInquiryInformation();
          }
        }
      });
    }

  }

  toggleToolHeader(visible) {
    this.headerVisible = !visible;
  }
  
  ngOnInit() {

    this.trainingTodayUrl = EhsHeroConfig.trainingTodayUrl;

    //this.suggestions = Observable
    //  .create((observer: any) => {
    //    // Runs on every search
    //    observer.next(this.searchTerm);
    //  })
    //  .mergeMap((token: string) => this._searchService.getSuggestions(this.searchTerm, this._mbService.dataAccessLevels()));
    
    this._mbService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.userLoggedIn = userLoggedIn;
        if(this.warningTimerSubscribe) 
        {
          this.warningTimerSubscribe.unsubscribe();
        }
    
        if(this.timedOutTimerSubscribe)
        {
          this.timedOutTimerSubscribe.unsubscribe();
        } 
    
        const tokenExpiry = this._mbService.user.TokenExpiry*60000;//millisconds
        const warning = (this.warningTime*1000);//milliseconds      
        const warningTimer = timer(Math.abs(tokenExpiry-warning)); 
       //const warningTimer = timer(60000);        
       this.warningTimerSubscribe = warningTimer.subscribe(n => this.showWarningTimeout());        
      }
    })
  }
  ngOnDestroy(): void {
    if(this.warningTimerSubscribe) 
        {
          this.warningTimerSubscribe.unsubscribe();
        }
    
        if(this.timedOutTimerSubscribe)
        {
          this.timedOutTimerSubscribe.unsubscribe();
        } 
  }
  
  closeMobileDropDownAndLogOut() {
    this.mobileClick.nativeElement.click();
    this._mbService.logout();
  }

  closeMobileDropDown() {
    this.mobileClick.nativeElement.click();
  }

  ngDoCheck() {


    let currentUrl = this._router.url;

    this.currentRoute = "";
    this.currentSubRoute = "";


    if (currentUrl.indexOf("/account-settings") > -1) {
      this.currentRoute = "account-settings";
      this.currentSubRoute = "account-settings";
    }
    else if (currentUrl.indexOf("/edit-widget") > -1) {
      this.currentRoute = "edit-widget";
      this.currentSubRoute = "edit-widget";
    }
    else if (currentUrl.indexOf("/custom-settings") > -1) {
      this.currentRoute = "custom-settings";
      this.currentSubRoute = "custom-settings";
    } else if (currentUrl.indexOf("/preferences") > -1) {
      this.currentRoute = "preferences";
      this.currentSubRoute = "preferences";
    } else if (currentUrl.indexOf("/user-management") > -1) {
      this.currentRoute = "user-management";
      this.currentSubRoute = "user-management";
    } else if (currentUrl.indexOf("/regupdate-admin") > -1) {
      this.currentRoute = "regupdate-admin";
      this.currentSubRoute = "regupdate-admin";            
    } 
    else if (currentUrl.indexOf("/regupdate") > -1) {
      this.currentRoute = "regupdate";
      this.currentSubRoute = "regupdate";     
    } else if (currentUrl.indexOf("/custom-site-settings") > -1) {
      this.currentRoute = "custom-site-settings";
      this.currentSubRoute = "custom-site-settings";
    }

    if (currentUrl.indexOf("/industry-collections") > -1) {
      this.currentRoute = "industry-collections";
      this.currentSubRoute = "";
    } else if (currentUrl.indexOf("/tools") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "";
    } else if (currentUrl.indexOf("/job-description-manager") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "external";
    } else if (currentUrl.indexOf("/chart-builder") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "external";
    } else if (currentUrl.indexOf("/spill-reporting") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "spill-reporting";
    }
    else if (currentUrl.indexOf("/compliance-calendar") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "compliance-calendar";
    }    
    else if (currentUrl.indexOf("/training") > -1) {
      //$('#allTraining').focus();
      if (currentUrl.indexOf("/new") > -1) {
        this.currentSubRoute = "new";
      } else if (currentUrl.indexOf("/all") > -1) {
        this.currentSubRoute = "all";
      } else if (currentUrl.indexOf("/trainers-toolbox") > -1) {
        this.currentSubRoute = "trainers-toolbox";
      } else if (currentUrl.indexOf("/safety-video") > -1) {
        this.currentSubRoute = "safety-video";
      } 
      else {
        this.currentSubRoute = "all";
      }

      this.currentRoute = "training";

    } else if (currentUrl.indexOf("/resources") > -1) {
      //$('#allTraining').focus();
      this.currentRoute = "resources";
      if (currentUrl.indexOf("/final-regulations") > -1 || currentUrl.indexOf("/notices") > -1 || currentUrl.indexOf("/proposed-regulations") > -1)
        this.currentSubRoute = "regulatory-activity";
      else
        this.currentSubRoute = "all-resources";
    } else if (currentUrl.indexOf("/news") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "news";
    } else if (currentUrl.indexOf("/regulatory-activity") > -1) {

      this.currentRoute = "resources";
      this.currentSubRoute = "regulatory-activity";
    } else if (currentUrl.indexOf("/regulations") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "regulations";
    } else if (currentUrl.indexOf("/all-resources") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "all-resources";
    } else if (currentUrl.indexOf("/checklists-forms") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "checklists-forms";
    } else if (currentUrl.indexOf("/calculators") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "calculators";
    } else if (currentUrl.indexOf("/myBookmarks") > -1) {
      this.currentRoute = "myBookmarks";
    } else if (currentUrl.indexOf("/search") > -1) {
      this.currentRoute = "search";
    } else if (currentUrl.indexOf("/dashboard") > -1) {
      this.currentRoute = "dashboard";
    } else if (currentUrl.indexOf("/login") > -1) {
      this.currentRoute = "login";
    } else if (currentUrl.indexOf("/snehs-login") > -1) {
      this.currentRoute = "snehs-login";
    } else if (currentUrl.indexOf("/hotline") > -1) {
      this.currentRoute = "hotline";
    } else if (currentUrl.indexOf("/sds-search") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "sds-search";
    }
    else if (currentUrl.indexOf("/sso-login") > -1) {
      this.currentRoute = "sso-login";
    }
    if (this._mbService.user != null) {

      this.isAdmin = this._mbService.user.IsEHSAdmin;  

      if (this._mbService.hasTool("Training"))
        this.trainingInclusion = true;

      if (this._mbService.hasTool("CHB"))
        this.chartBuilderInclusion = true;

      if (this._mbService.hasTool("PB"))
        this.planBuilderInclusion = true;

      if (this._mbService.hasTool("RegActivity"))
      {
        if (this._mbService.user)
        {
          this.regActivityInclusion = this._mbService.customPermissions.GeneralPermissions.regActivity.Visible;
        }
      }

      if (this._mbService.hasTool("SDS"))
        this.sdsInclusion = true;

      if (this._mbService.hasTool("STT"))
        this.sttInclusion = true;

      if (this._mbService.hasTool("AllResources"))
        this.allResourcesInclusion = true;

      if (this._mbService.hasTool("Hotline"))
        this.hotlineInclusion = true;

      if (this._mbService.hasTool("News"))
        this.newsInclusion = true;

      if (this._mbService.hasTool("Regulations"))
        this.regulationInclusion = true;

      if (this._mbService.hasTool("ReportSpill"))
        this.reportSpillInclusion = true;

      if (this._mbService.hasTool("ComplianceCalendar"))
        this.complianceCalendarInclusion = true;

      if (this._mbService.hasTool("Graybar"))
      {
        this.graybarInclusion = true;
      }

      if (this._mbService.hasTool("Industries"))
      {
        this.industriesInclusion = true;
      }
      if (this._mbService.hasTool("VIDEO"))
      {
        this.videoInclusion = true;
      }
      if (!this.graybarInclusion){
        this.topNavVisible = false;
      }

      let accessLevels = this._mbService.dataAccessLevels();

      if (accessLevels != null)
        this.hasSafety = accessLevels.includes("3");

      this.userName = this._mbService.user.UserName;

    }

    if (this._mbService.userProfile != null && this._mbService.userProfile.InheritableProperties != null) {
      this.setHeaderAndFooter();
      this.customPermissions = this._mbService.customPermissions;
      if (this._mbService.user) {

        var widgets = (this._mbService.user.Widgets != null) ? this._mbService.user.Widgets.filter(f => f.IsCustom && f.Active && f.ActiveOnTools).length : 0;
        this.showWidgets = ((this._mbService.customPermissions.GeneralPermissions.toolsWidgets.Visible && widgets > 0)
          || this._mbService.customPermissions.GeneralPermissions.training.Visible
          || this._mbService.customPermissions.GeneralPermissions.regActivity.Visible
          || this._mbService.customPermissions.GeneralPermissions.planBuilterTool.Visible
          || this._mbService.customPermissions.GeneralPermissions.chartBuilderTool.Visible
          || this._mbService.customPermissions.SafetyPermissions.safetyToolboxtrainer.Visible
          || this._mbService.customPermissions.EnviroPermissions.spillReporting.Visible
          || this._mbService.customPermissions.GeneralPermissions.ComplianceCalendar.Visible
          || this._mbService.customPermissions.GeneralPermissions.sdsSearch.Visible);
      }

      this.customSettingsVisible = this._mbService.userProfileTypeId === 1;
      this.userManagementVisible =(this._mbService.userProfileTypeId === 1 || (this._mbService.userProfile.AdminPreferences != null && this._mbService.getVisibility('User Management'))) && this._mbService.AutherizationType==1;
      this.customWidgetsVisible = this._mbService.userProfileTypeId === 1 || (this._mbService.userProfile.AdminPreferences != null && this._mbService.getVisibility('Custom Widgets'));
    }
  }

  setHeaderAndFooter() {
    this.headerImageDefault = this._mbService.defaultUserProfile.InheritableProperties.EHSHeaderLogo.Value;
    this.footerImageDefault = this._mbService.defaultUserProfile.InheritableProperties.EHSFooterLogo.Value;
    this.footerAddressDefault = this._mbService.defaultUserProfile.InheritableProperties.EHSFooterAddress.Value;
    this.footerPhoneDefault = this._mbService.defaultUserProfile.InheritableProperties.EHSFooterPhone.Value;
    var userProfile = this._mbService.userProfile;
    var hasParent = !!this._mbService.parentUserId;
    let userProperties = userProfile.InheritableProperties;
    if (hasParent) {
      let parentProperties = this._mbService.parentUserProfile.InheritableProperties;
      this.headerImage = (parentProperties.EHSHeaderLogo && parentProperties.EHSHeaderLogo.TakeChild == "false") ? (parentProperties.EHSHeaderLogo.Value || this.headerImageDefault) : ((userProperties.EHSHeaderLogo) ? userProperties.EHSHeaderLogo.Value || this.headerImageDefault : this.headerImageDefault);
      this.footerImage = (parentProperties.EHSFooterLogo && parentProperties.EHSFooterLogo.TakeChild == "false") ? (parentProperties.EHSFooterLogo.Value || this.footerImageDefault) : ((userProperties.EHSFooterLogo) ? userProperties.EHSFooterLogo.Value || this.footerImageDefault : this.footerImageDefault);
      this.footerAddress = (parentProperties.EHSFooterAddress && parentProperties.EHSFooterAddress.TakeChild == "false") ? (parentProperties.EHSFooterAddress.Value || this.footerAddressDefault) : ((userProperties.EHSFooterAddress) ? userProperties.EHSFooterAddress.Value || this.footerAddressDefault : this.footerAddressDefault);
      this.footerPhone = (parentProperties.EHSFooterPhone && parentProperties.EHSFooterPhone.TakeChild == "false") ? (parentProperties.EHSFooterPhone.Value || this.footerPhoneDefault) : ((userProperties.EHSFooterPhone) ? userProperties.EHSFooterPhone.Value || this.footerPhoneDefault : this.footerPhoneDefault);
    }
    else {
      this.headerImage = (userProperties.EHSHeaderLogo && userProperties.EHSHeaderLogo.TakeChild == "false") ? (userProperties.EHSHeaderLogo.Value || this.headerImageDefault) : this.headerImageDefault;
      this.footerImage = (userProperties.EHSFooterLogo && userProperties.EHSFooterLogo.TakeChild == "false") ? (userProperties.EHSFooterLogo.Value || this.footerImageDefault) : this.footerImageDefault;
      this.footerAddress = (userProperties.EHSFooterAddress && userProperties.EHSFooterAddress.TakeChild == "false") ? (userProperties.EHSFooterAddress.Value || this.footerAddressDefault) : this.footerAddressDefault;
      this.footerPhone = (userProperties.EHSFooterPhone && userProperties.EHSFooterPhone.TakeChild == "false") ? (userProperties.EHSFooterPhone.Value || this.footerPhoneDefault) : this.footerPhoneDefault;
    }

    if (this.headerImage.startsWith('//')) {
      this.headerImage = this.headerImage.replace("//", "/");
    }
    if (this.footerImage.startsWith('//')) {
      this.footerImage = this.footerImage.replace("//", "/");
    }
  }

  search(searchTerm) {
    //this.searchTermValue = "";
    if (searchTerm)
      this._router.navigate(["/search/" + searchTerm]);
    else
      this._router.navigate(["/search"]);
  }

  navigate(rte, close) {

    if (close == 'close') {
      this.closeMobileDropDown();
    }

    switch (rte) {
      case 'account-settings':
        this.customSettingsVisible = this._mbService.getVisibility('Custom Widgets');
        this.userManagementVisible = this._mbService.getVisibility('User Management') && this._mbService.AutherizationType==1;

        this._router.navigate(["/account-settings"]);
        break;
        case 'administration':
          if (this.isAdmin)
          {
            this._router.navigate(["/regupdate-admin"]);
          }
          break;        
      case 'login':
        location.href = EhsHeroConfig.marketingSite;
        break;
    }
  }
  litmosLogin(){
    window.open(this._mbService.litmosLink,"_blank");
  }

  acquireAndSendInquiryInformation() {
    if (this._mbService.user == null) {
      return; //no logged in user to track
    }

    this.simplify_inquiry_request(
      this._mbService.user.FirstName, this._mbService.user.LastName, this._mbService.user.EmailAddress, this._mbService.user.Company,
      this._mbService.user.SubscriptionType, EhsHeroConfig.origin, "", this._mbService.user.LastLoginDate, this._mbService.user.CustomerId, this._mbService.user.ParentUserId, this._mbService.user.UserName);
  }

  simplify_inquiry_maintain(id) {
    let ActiveXObject: (type: string) => void;
    var xmlhttp; var send;
    if ((<any>window).XMLHttpRequest) {
      xmlhttp = new XMLHttpRequest();
    } else {
      xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    let ths = this;
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        ths.inquiryTimeout = window.setTimeout(function () {
          ths.simplify_inquiry_maintain(xmlhttp.responseText)
        }, 10000);
      }
    }
    xmlhttp.open("POST", ("https://d1v834xrsg9kw8.cloudfront.net/api/observer.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&id=" + id);
  }

  simplify_inquiry_request(user_first_name, user_last_name, user_email, user_company, user_subscription_type, request_top_level, request_mid_level, request_low_level, user_id, user_parent_id, user_username) {
    var xmlhttp; var send;
    let ActiveXObject: (type: string) => void;
    if ((<any>window).XMLHttpRequest) {
      xmlhttp = new XMLHttpRequest();
    } else {
      xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }
    let ths = this;
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        window.clearTimeout(ths.inquiryTimeout);
        ths.inquiryTimeout = window.setTimeout(function () {
          ths.simplify_inquiry_maintain(xmlhttp.responseText)
        }, 10000);
      }
    }
    xmlhttp.open("POST", ("https://d1v834xrsg9kw8.cloudfront.net/api/request.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&uf="
      + user_first_name + "&ul=" + user_last_name + "&ue="
      + user_email + "&uc=" + user_company + "&us="
      + user_subscription_type + "&r1=" + request_top_level +
      "&r2=" + request_mid_level + "&r3=" +
      request_low_level + "&uldap=" + user_username +
      "&uccid=" + user_id + "&uccpid=" +
      user_parent_id + "&xu=" + window.location.href + "&xw="
      + screen.width + "&xh=" + screen.height + "&xn="
      + navigator.userAgent + "&xc=" +
      navigator.cookieEnabled + "&xl=" + navigator.language +
      "&xp=" + navigator.platform + "&xx=15");
  }

  hasTool(name) {
    return this._mbService.hasTool(name);
  }

  logout() {
    this.showNotificationBanner = false;
    if(this.warningTimerSubscribe) 
    {
      this.warningTimerSubscribe.unsubscribe();
    }

    if(this.timedOutTimerSubscribe)
    {
      this.timedOutTimerSubscribe.unsubscribe();
    } 

    this._mbService.logout();
  }

  showWarningTimeout():void{
    this.showNotificationBanner = true;
    this.warningTimeRemaining=this.warningTime;
    const timedOutTimer  =  timer(0,1000).pipe(
      take(this.warningTime),
      map(() => --this.warningTimeRemaining)
    );
    this.timedOutTimerSubscribe = timedOutTimer.subscribe(val =>{if(this.warningTimeRemaining<=0){this.logout();}});
    
  }
  trainingVisibility()
  {
    return this.customPermissions.GeneralPermissions.training.Visible && this.trainingInclusion;
  }
  resourcesVisibility()
  {
    return (this.customPermissions.GeneralPermissions.news.Visible && this.newsInclusion) || (this.customPermissions.GeneralPermissions.regActivity.Visible && this.regActivityInclusion) || (this.customPermissions.GeneralPermissions.regulations.Visible && this.regulationInclusion) || (this.customPermissions.GeneralPermissions.allresources.Visible && this.allResourcesInclusion) || (this.customPermissions.GeneralPermissions.sdsSearch.Visible && this.sdsInclusion);
  }
  industriesVisibility()
  {
    return this.industriesInclusion && ((this.customPermissions.GeneralPermissions.training.Visible && this.trainingInclusion) || (this.customPermissions.GeneralPermissions.allresources.Visible && this.allResourcesInclusion)) && this.hasSafety;
  }
  helpVisibility()
    {
      //return  this._mbService.user.Widgets.filter(f=>f.InclusionCode=="HELP").length > 0;   
      return false;   
    }
  stay():void{   
    this.showNotificationBanner = false;
    let _ths = this; 

      this._mbService.autoLogin().subscribe((account: Account) => {

        //authentication failed
        if (account == null || account.Authorized == false) {
          this._router.navigate(['login']);
          return false;
        }

        _ths._mbService.setUpAccount(account);
        this.isAdmin = account.IsEHSAdmin;

        return true;

      });
      

  }

}
