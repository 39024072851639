import { Component } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { UserProfile } from '../../shared/types/user-profile.type'
import { UserProfileService } from '../../shared/services/user-profile.service';
import { Router } from '@angular/router';

//services
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service';

import  'jquery';
import { UserManagementService } from '../../shared/services/user-management.service';

@Component({
  selector: 'regupdate-admin',
  templateUrl: './regupdate-admin.component.html',
  styleUrls: ['./regupdate-admin.component.css']
})

export class RegUpdateAdminComponent {

  userProfile: UserProfile = new UserProfile;
  loading = false;
  
  email: string = '';

  constructor(
    private _mbService: MembershipDataService,
    private _router: Router,
    private _userProfileService: UserProfileService,
    private _userManagementService: UserManagementService,
    private _heroService: EhsHeroSvc
  ) {
    this.userProfile = this._mbService.userProfile;
  }

async ngOnInit() {
    if (!this._mbService.user.IsEHSAdmin)
    {
      this._router.navigate(["error/404"]);
    }
    this.loading = true;
    $(".lockAlert").hide();

    if (!this._mbService.hasTool("RegActivity"))
    {
      this._router.navigate(["error/404"]);
    }

    this._heroService.getRegUpdateSettings().subscribe((result) => {
      
      this.loading = false;
    });
}

  sendEmail()
  {
    //  this._heroService.saveRegUpdateSettings(this.regupdateSetting);
     $(".lockAlert").show();
     var sendMail = this.email;
     this._heroService.sendRegUpdateEmail(sendMail);
     this.email = '';
    setTimeout(function () { 
        $(".lockAlert").hide();
     }, 1500);
  }
}
