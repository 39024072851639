import { Component, Provider, ElementRef, OnInit, Input, ViewChild, Compiler } from '@angular/core';

//services
import { EhsHeroSvc } from '../shared/services/ehs-hero.service';

//types
import { Bookmark } from '../shared/types/bookmark.type';
import { BookmarkFolder } from '../shared/types/bookmark-folder.type'
import { Information } from '../shared/types/information.type';

import { MembershipDataService } from '../shared/services/membership.data.service'

@Component({
  selector: 'reportable-incident',
  templateUrl: './reportable-incident.component.html',
  styleUrls: ['./reportable-incident.component.css']
})

export class ReportableIncidentComponent {

  pnl: string = "loggedOut";

  constructor(private _heroService: EhsHeroSvc, private _mbService: MembershipDataService) { }

  ngOnInit() {
    
  }

}
