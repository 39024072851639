import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

import { Router } from '@angular/router';


@Component({
  selector: 'covid-19',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './covid-19.html'
})

export class Covid19WidgetComponent {

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  navigate() {
    this._router.navigateByUrl("/search/covid-19");

  }
}

