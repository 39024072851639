import { Component, TemplateRef,ViewChild } from '@angular/core';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';
import { Subject, Subscription} from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

//services
import { EhsHeroSvc } from '../shared/services/ehs-hero.service';

//types
import { Bookmark } from '../shared/types/bookmark.type';
import { BookmarkFolder } from '../shared/types/bookmark-folder.type'

import { MembershipDataService } from '../shared/services/membership.data.service'

@Component({
  selector: 'myBookmarks',
  templateUrl: './myBookmarks.component.html',
  styleUrls: ['./myBookmarks.component.css']
})

export class MyBookmarksComponent {

  @ViewChild('bookmarkFolderModal')
  private bookmarkFolderTemplate: TemplateRef<any>;
  bookmarkFolderModal: BsModalRef;

  bookmarks: Bookmark[]=[];
  bookmarkFolders: BookmarkFolder[]=[];
  bookmarkFolderName: string = null;
  bookmarkFolderLabel: string = "All";
  loading : boolean = false;
  selectedBookmark: Bookmark;
  selectedBookamrkFolderId: number = null;
  errorMessage: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  subscription :  Subscription;

  constructor(private _heroService: EhsHeroSvc, private _mbService: MembershipDataService,public modalService: BsModalService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: false,
      info: false,
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
        lengthMenu : [5, 10,25],
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };

    this.getBookmarkFolders();
    
    this.getBookmarks();
  }

  getBookmarks() {
    this.loading=true;
    this.subscription = this._heroService.getBookmarks("user", null).subscribe((result) => {
      this.bookmarks = result;
        this.loading =false;  
        this.rerender();
    });
  }

  getBookmarksByBookmarkFolder(bookmarkFolderId, bookmarkFolderName) {
    this.loading=true;
    this.bookmarkFolderLabel = bookmarkFolderName;
    //Cancel the first call since we clicked on a folder
    if(this.subscription)
    {
      this.subscription.unsubscribe();
    }

    this._heroService.getBookmarks("folder", bookmarkFolderId).subscribe((result) => {
      this.bookmarks = result;
      this.loading =false;  
      this.rerender();
      
    });
  }

  // saveBookmark(bookmark: Bookmark) {
  //   this._heroService.updateMyBookmark(bookmark);
  //   this.selectedBookamrkFolderId = null;
  //   this.modal.hide();
  // }

  // deleteBookmark(bookmarkId: number) {

  //   if (confirm("Are you sure you want to delete this bookmark?")) {
  //     this._heroService.deleteMyBookmark(bookmarkId);

  //     this.modal.hide();
  //   }
  // }

  bookmarkSaved(event) {

    if (event != 'open') {
      this.getBookmarkFolders();

      this.getBookmarks();
    }
  }

  getBookmarkFolders() {

    this._heroService.getBookmarkFolders(this._mbService.dataAccessLevels()).then(result => {

      if (result.length > 0) {
        this.bookmarkFolders = result;
      } else {
        this.bookmarkFolders = null;
        this.bookmarkFolderName = "Bookmarks";
        this.addBookmarkFolder();
      }
    });
  }

  addBookmarkFolder() {

    this._heroService.addBookmarkFolder(this.bookmarkFolderName, this.selectedBookamrkFolderId).subscribe((data) => {
      if (data == "success") {
        this.getBookmarkFolders();
        this.bookmarkFolderName = "";
        this.bookmarkFolderModal.hide();
        this.selectedBookamrkFolderId = null;
      } else {
        this.errorMessage = data.toString();
      }
    });
  }

  editBookmarkFolder(folder: BookmarkFolder) {

    this.selectedBookamrkFolderId = folder.BookmarkFolderId;
    this.bookmarkFolderName = folder.Name;
    this.openBookmarkFolderModal();
  }

  deleteBookmarkFolder() {

    if (confirm("Are you sure you want to delete this folder?")) {
      this._heroService.deleteBookmarkFolder(this.selectedBookamrkFolderId).subscribe((data) => {
        if (data == "success") {
          this.getBookmarkFolders();

          this.getBookmarks();

          this.bookmarkFolderName = "";
          this.bookmarkFolderLabel = "All";

          this.bookmarkFolderModal.hide();

        } else {
          this.errorMessage = data.toString();
        }
      });
    }
  }

  openBookmarkFolderModal() {
    this.bookmarkFolderModal = this.modalService.show(this.bookmarkFolderTemplate); 
  }

  closeBookmarkFolderModal() {
    this.bookmarkFolderModal.hide();
    this.bookmarkFolderName = "";
    this.errorMessage = "";
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
}

  ngOnDestroy(): void {
    if(this.subscription)
    {
      this.subscription.unsubscribe();
    }
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });    
  }

}
