
<div class="row">
  <div class="col-12">
    <div *ngIf="hasAccess">
      <h1>User Management</h1>
      <div *ngIf="isOwner" style="float:right">
        <a [routerLink]="['permission-tiers']">Manage Permission Tiers</a>
      </div>
      
      <div *ngIf="dataAvailable == 1">
        <div *ngIf="(ccCustomers == null || ccCustomers?.length == 0) && !currentlyFiltering">
          <p>No users were added to the account yet </p>
        </div>
      </div>
      <div *ngIf="dataAvailable == 0" class="row" style="text-align:center;">
        <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
      </div>

      <div [hidden]="dataAvailable != 1" style="margin-top:25px; clear: both;">
        <div class="panel panel-default">
          <table class="table table-bordered table-hover">
            <thead class="thead">
              <tr>
                <th colspan="4" style="text-align:center;">Membership Summary</th>
                <th colspan="3" style="text-align:center;">Additional Users</th>
              </tr>
              <tr>
                <th>Membership Id</th>
                <th>Membership</th>
                <th>Market</th>
                <th>Expiration Date</th>
                <th>Purchased</th>
                <th>Used</th>
                <th>Available</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let membership of parentMemberships">
                <td>{{membership.id ? membership.id : ''}}
                <td>{{membership.plan ? membership.plan.name : ''}}</td>
                <td>{{getMarket(membership.id)}}</td>
                <td>{{membership.expiration_date ? membership.expiration_date : getExpirationDate(membership) | date: 'yyyy-MM-dd'}}</td>
                <td>{{membership.child_accounts}}</td>
                <td>{{membership.usedAccounts}}</td>
                <td>{{membership.remaining_child_accounts}} </td>
              </tr>
            </tbody>
          </table>
        </div>
            
          <h3 style="margin-left: 5px;">Manage Users</h3>
          <table datatable class="table table-bordered table-hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" style="table-layout:fixed;">
              <thead class="thead">
                <tr>
                  <th>
                    <a class="text-nowrap" style="cursor:pointer">First Name <span></span> </a></th>
                  <th>
                    <a class="text-nowrap" style="cursor:pointer">Last Name <span></span> </a>
                  </th>
                  <th>
                    <a class="text-nowrap" style="cursor:pointer">Email <span></span> </a>
                  </th>
                  <th> 
                    <a class="text-nowrap" style="cursor:pointer">Username <span></span> </a>
                  </th>
                  <th>
                    <a class="text-nowrap" style="cursor:pointer">Company <span></span> </a>
                  </th>
                  <th><a class="text-nowrap" style="cursor:pointer">Market <span></span> </a></th>
                  <th>Permission Tier</th>
                  <th style="max-width: 70px;" >Manage</th>
                  <th style="max-width: 55px;" >Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ccCustomers">              
                    <td style="word-wrap: break-word">{{item.FirstName}}</td>
                    <td style="word-wrap: break-word">{{item.LastName}}</td>
                    <td style="word-wrap: break-word">{{item.Email}}</td>
                    <td style="word-wrap: break-word">{{item.UserName}}</td>
                    <td style="word-wrap: break-word">{{item.Company}}</td>
                    <td>{{item.Markets}}</td>                
                    <td>
                      <tier-selector [assignedTierId]="item.PermissionTierId" [permissionTiers]="getApplicableTiers(item)" save="true" [userId]="item.CustomerId" (onSelectionComplete)="item.PermissionTierId = $event"></tier-selector>
                    </td>
                    <td><a [routerLink]="['edit-user', item.CustomerId]">Edit</a></td>
                    <td><input type="checkbox" [(ngModel)]="item.IsSelected" (change)="setSelectedFlag($event)" /> </td>
                </tr>

              </tbody>
            </table>
            <div class="btn-toolbar">
              <a class="btn btn-success" [routerLink]="['edit-user',0]">ADD NEW USER</a>
              <button class="btn" (click)="removeUsers()" [disabled]="selectedCount<=0" style="background-color: #FB9133; border-color: #FB9133; color:#fff;">REMOVE USER(S) </button>
            </div>
        </div>
    </div>

    <div *ngIf="!hasAccess">
      <h2>You do not have access to this view.</h2>
    </div>
  </div>
</div>