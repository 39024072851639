import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';

//types
import { User } from '../../shared/types/membershipbase-user'
import { UserProfile } from '../../shared/types/user-profile.type';
import { CCUser } from '../../shared/types/cc-user';
//services
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service';
import { UserProfileService } from '../../shared/services/user-profile.service';

import { MembershipDataService } from '../../shared/services/membership.data.service'
import { UserManagementService } from '../../shared/services/user-management.service';
import { CCCustomer } from '../../shared/types/cc-customer';
import { AdminPreferences } from '../../shared/types/AdminPreferences.type';
import { ConfirmDeleteComponent } from '../../shared/components/confirm-delete.component';
import { PermissionTierApi } from '../../shared/types/permission-tier-api.type';

import { CustomTag } from '../../shared/types/CustomTag';

@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})

export class EditUserComponent {

  userProfile: UserProfile = null;
  userId: number;
  ccCustomer: CCCustomer;
  assignedTierId: number = 0;
  hasAccess: boolean = false;
  disablePermissions: boolean = false;
  User: CCUser;
  customerId: number;
  initialCustomerId: number;
  parentMemberships: any;
  membershipsAvailable: boolean = false;
  emailAvailable: boolean = true;
  emailLinkable: boolean = false;
  usernameAvailable: boolean = true;
  browserIsIE: boolean = (window.navigator.userAgent.indexOf('MSIE ') > 0) || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  markets: any;
  saveInProgress: boolean = false;
  adminIsChild: boolean = false;
  savingNewUser: boolean = false;
  @ViewChild('linkingModal')
  private linkingModalTemplate: TemplateRef<any>;
  modalRef: BsModalRef;
  customTags: CustomTag[];

  adminPreferences: AdminPreferences[] = [
    { Id: 1, Name: "Custom Widgets", Visible: false },
    { Id: 3, Name: "User Management", Visible: false },
    { Id: 4, Name: "Safety Toolbox Trainer", Visible: true },
    { Id: 5, Name: "Regulatory Activity", Visible: true },
    { Id: 6, Name: "Training Materials", Visible: true },
    { Id: 7, Name: "Regulatory Analysis Chart Builder", Visible: true },
    { Id: 8, Name: "Plan Builder", Visible: true },
    { Id: 9, Name: "SDS Search", Visible: true },
    { Id: 12, Name: "Report a Spill", Visible: true },
    { Id: 13, Name: "Show Custom widgets on Home page", Visible: true },
    { Id: 14, Name: "Show Custom widgets on Tools page", Visible: true },
    { Id: 15, Name: "Hotline", Visible: true },
    { Id: 16, Name: "Regulations", Visible: true },
    { Id: 17, Name: "News", Visible: true },
    { Id: 18, Name: "All Resources", Visible: true },
    { Id: 19, Name: "Help Resources", Visible: true },
    { Id: 20, Name: "Coronavirus Resources", Visible: true },
    { Id: 21, Name: "Management Tier", Visible: true },
    { Id: 22, Name: "Safety 101", Visible: true }
  ];

  permissionTiers: PermissionTierApi[];

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _up: UserProfileService,
    private _mb: MembershipDataService,
    private _userManagementService: UserManagementService,
    public modalService: BsModalService,
    private _heroService: EhsHeroSvc
  ) {

    this.hasAccess = this._mb.getVisibility('User Management') && this._mb.AutherizationType==1;

    this.User = new CCUser();
    this.ccCustomer = new CCCustomer();
    this.adminIsChild = this._mb.userProfileTypeId == 2;

    this._route.params.subscribe(async params => {

        if (params["id"] != null) { 
            //I don't know why there is a userId and customerId but on the surface they both appear to be using the cc customer Id, maybe one day we can get rid of one of them 
          this.userId = params["id"];
          this.customerId = params["id"];
          this.initialCustomerId = this.customerId;

          if (this.customerId!=0) {

              this.ccCustomer = await this.getUser(params["id"]);

              this.User.id = this.ccCustomer.UserId;
              this.User.username = this.ccCustomer.UserName;
              this.User.Email = this.ccCustomer.Email;
              this.User.FirstName = this.ccCustomer.FirstName;
              this.User.LastName = this.ccCustomer.LastName;
              this.User.Company = this.ccCustomer.Company;
              this.User.Markets = this.ccCustomer.Markets;
              this.User.MembershipId = this.ccCustomer.MembershipIds[0] ? this.ccCustomer.MembershipIds[0] : 0;
              this.User.ProfileAddressId = this.ccCustomer.ProfileAddressId ? this.ccCustomer.ProfileAddressId : 0;

              let inclusions = this.ccCustomer.Inclusions;
              this.disablePermissions = (inclusions.length == 2 && this._mb.hasTool("CHB") && this._mb.hasTool("PB")) || (inclusions.length == 1 && (this._mb.hasTool("CHB") || this._mb.hasTool("PB")))
              await this.getPermissionTiers();
              this.userProfile = await this._userManagementService.getUserProfile(this.userId, 2, this.ccCustomer.Markets);
              this.assignedTierId = this.ccCustomer.PermissionTierId;

          }
          else {     
              this.markets = this._mb.getAllPlans().filter(f => !((f.Inclusions.length == 2 && this._mb.hasTool("CHB", f.Inclusions) && this._mb.hasTool("PB", f.Inclusions)) || (f.Inclusions.length == 1 && (this._mb.hasTool("CHB", f.Inclusions) || this._mb.hasTool("PB", f.Inclusions)))));
               let inclusions = this._mb.user.Inclusions;
               this.disablePermissions = (inclusions.length == 2 && this._mb.hasTool("CHB") && this._mb.hasTool("PB")) || (inclusions.length == 1 && (this._mb.hasTool("CHB") || this._mb.hasTool("PB")))
               await this.getPermissionTiers();
               this.userProfile = await this._userManagementService.getUserProfile(this.userId, 2, this._mb.user.Market);
               this.assignedTierId = 0;
          }
      }
    });
  }

  ngOnInit() {
    if(this.adminIsChild && this.initialCustomerId != 0){
        this.getMemberships(this.customerId);
    }else{
        this.getMemberships(this._mb.user.CustomerId);
    }
      
  }

  async getUser(userId: number) {
    if (this._mb.allUsers != null || this._mb.allUsers != undefined) {
      let cust = this._mb.allUsers.find(f => f.CustomerId == userId);
      if (cust != null)
        return cust;
    }
    else
      return await this._userManagementService.getCustomerCentralUser(userId);
  }

  async getPermissionTiers() {  
      this.permissionTiers = await this._userManagementService.getPermissionTiers();

      if (this.ccCustomer.MembershipIds) {
          this.permissionTiers = this.permissionTiers.filter(f => this.ccCustomer.MembershipIds.includes(f.MembershipId));
      }
  }

  getInternalAdminPreferences(adminPreferences: AdminPreferences[]) {
    
        return adminPreferences.filter(f => {
        return (f.Name == "Custom Widgets" ||
            f.Name == "User Management")
        });
    
  }

  getCustomWidgets(adminPreferences: AdminPreferences[]) {
        return adminPreferences.filter(f => {
        return (f.Name == "Show Custom widgets on Home page" ||
            f.Name == "Show Custom widgets on Tools page")
        });
   
  }

  getClientMemberships(adminPreferences: AdminPreferences[]) {
    
        return adminPreferences.filter(f => {
        return (f.Name != "Custom Widgets" &&
            f.Name != "User Management" &&
            f.Name != "Show Custom widgets on Home page" &&
            f.Name != "Show Custom widgets on Tools page")
        });    
  }

  isIncluded(setting) {
    let inclusions = this.ccCustomer.Inclusions;
    let isIncluded = this._userManagementService.checkTierInclusions(setting, inclusions, this._mb.dataAccessLevels(this.ccCustomer.Markets));
    if (!isIncluded)
      setting.Visible = false;
    return isIncluded;
  }

  getMemberships(customerId: number) {
    //   if(this.adminIsChild && this.initialCustomerId != 0){
    //       this.parentMemberships = [];
    //       return;
    //   }
      this._userManagementService.getMemberships(customerId).then(result => {
          this.parentMemberships = result;
        if(this.adminIsChild && this.initialCustomerId != 0)
        {
            for (var i = 0; i < this.parentMemberships.length; i++) {
                 this.parentMemberships[i].market = this.User.Markets;
            }
            
        }
        else
        {
            for (var i = 0; i < this.parentMemberships.length; i++) {
                var market = this._mb.user.AllMarkets.filter(f => f.MembershipId == this.parentMemberships[i].id).map(x => x.Market);
                this.parentMemberships[i].market = market[0];
            }
        }
          if(this.User.MembershipId==null)
          {
               this.User.MembershipId = this.parentMemberships[0].id;    
          }
          this.getCustomTags(this.User.MembershipId);
          this.membershipsAvailable = true;
          if(this.initialCustomerId == 0){
            let idvaluetoset = this.parentMemberships[0].id;
            let membershipdropdown = document.getElementsByName("MembershipId")[0] as HTMLSelectElement;
            let linkingdropdown = document.getElementsByName("linkingMembershipId")[0] as HTMLSelectElement;
            let thisuser = this.User;
            window.setTimeout(function(){
                membershipdropdown.value = "0: " + idvaluetoset;
                if(linkingdropdown)
                {
                    linkingdropdown.value = "0: " + idvaluetoset;
                }
                thisuser.MembershipId = idvaluetoset;
            },1000);
          }
      });
  }

  saveProfile() {
    if (this.assignedTierId != 0)
      this._userManagementService.assignTier(this.assignedTierId, this.userId);
    else
      this._userManagementService.deleteAssignment(this.userId);

    if (this._mb.allUsers != null && this.ccCustomer.CustomerId>0) {
        this._mb.allUsers.find(f => f.CustomerId == this.ccCustomer.CustomerId).PermissionTierId = this.assignedTierId;
    }
      
    this._up.saveUserProfile(this.userId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, 2, this._mb.user.UserName).then((data) => {
        
        
        this._router.navigate(['/user-management']);
    });
  }

  validateEmail(email) {
      this.User.username = email;
      this._userManagementService.validateEmail(email).then(result => {
          if(result == "TAKEN"){
            this.emailAvailable = false;
            this.emailLinkable = false;
          }else if(result == "LINKABLE"){
            this.emailAvailable = false;
            this.emailLinkable = true;
          }else if(result == "AVAILABLE"){
            this.emailAvailable = true;
          }else{ //error
            this.emailAvailable = false;
            this.emailLinkable = false;
            console.log("Bad response from email validation: " + result);
          }
      });
  }

  validateUsername(username, UsernameValid) {
    if (UsernameValid) {
        this._userManagementService.isUsernameAvailable(username).then(resultUsername => {
            this.usernameAvailable = resultUsername;
        });
    }
    else {
        this.usernameAvailable = false;
    }
}

  async membershipChanged() {
      await this.updateMarket();
      let defaultProfile = await this._userManagementService.getDefaultUserProfileForMarket(this.User.Markets);

      this.userProfile.AdminPreferences = defaultProfile.AdminPreferences;
  }

  updateMarket() {
      this.User.Markets = this._mb.user.AllMarkets.filter(f => f.MembershipId == this.User.MembershipId).map(x => x.Market).toString();
  }

  saveUser() {
      this.saveInProgress = true;
      if (this.customerId == 0) {
         
          this.savingNewUser = true;
          this.User.username = this.User.Email;
          this._userManagementService.createChildAccount(this.User).then(result => {
              if (result) {
                  this.customerId = result.id;
                  this.customTags.forEach(element => element.UserId=result.id);
                  this.saveCustomTags();
                  this._userManagementService.copyWidgetsToChild(this.customerId);
                  this._userManagementService.getCustomerCentralUser(this.customerId).then(res => {
                      if (res && res.CustomerId>0) {
                          this.ccCustomer = res;
                          this.userId = this.ccCustomer.CustomerId;
                          this._mb.allUsers.push(this.ccCustomer);
                          this.saveProfile();
                          this._heroService.getRegUpdateSettings(this.customerId).subscribe((result) => {
                            result.Active = false;
                            this.userProfile.AdminPreferences.filter(function(item){
                                if (item['Name'] == 'Regulatory Activity')
                                {
                                    result.Active = (item['Visible']);
                                }
                            });
                            this._heroService.saveRegUpdateSettings(result);
                            
                          });
                          
                      }
                  })
              }
          });
      }
      else {
        let index = this._mb.allUsers.indexOf(this.ccCustomer);
        if (this.ccCustomer.FirstName != this.User.FirstName || this.ccCustomer.LastName != this.User.LastName ||
            this.ccCustomer.Company != this.User.Company) {

            let profileAddress: any = {
                "id": this.User.ProfileAddressId,
                "first_name": this.User.FirstName,
                "last_name": this.User.LastName,
                "company": this.User.Company,
            }
            this._userManagementService.updateUser(this.customerId, profileAddress).then(result => {
                
                this.ccCustomer.FirstName = this.User.FirstName;
                this.ccCustomer.LastName = this.User.LastName,
                this.ccCustomer.Company = this.User.Company;

                this._mb.allUsers[index] = this.ccCustomer;
                this.saveProfile();
            });

        }
        
        if (this.ccCustomer.Email != this.User.Email) {
            this._userManagementService.updateCustomerEmail(this.customerId, this.User.Email).then(result => {

                this.ccCustomer.Email = this.User.Email;
                this._mb.allUsers[index] = this.ccCustomer;
                this.saveProfile();
            });
        }
        if (this.ccCustomer.UserName != this.User.username) {
            this._userManagementService.updateUsername(this.User.id, this.User.username).then(result => {
                this.ccCustomer.UserName = this.User.username;
                this._mb.allUsers[index] = this.ccCustomer;
                this.saveProfile();
            });
        }

        if (this.ccCustomer.MembershipIds[0] != this.User.MembershipId && !(this.adminIsChild)) { 

            this._userManagementService.updateMembership(this.customerId, this.ccCustomer.MembershipIds[0], this.User.MembershipId)
                .then(result => {
                this.ccCustomer.MembershipIds[0] = this.User.MembershipId;
                this.ccCustomer.Markets = this.User.Markets;
                this._mb.allUsers[index] = this.ccCustomer;
                this.saveProfile();
            });
        }

        if (this.ccCustomer.FirstName == this.User.FirstName && this.ccCustomer.LastName == this.User.LastName &&
            this.ccCustomer.Company == this.User.Company && this.ccCustomer.Email == this.User.Email &&
            this.ccCustomer.UserName == this.User.username && this.ccCustomer.MembershipIds[0] == this.User.MembershipId
        ) {
            this.saveProfile();
        }

        this._heroService.getRegUpdateSettings(this.customerId).subscribe((result) => {
                this.userProfile.AdminPreferences.filter(function(item){
                    if (item['Name'] == 'Regulatory Activity') 
                    {
                        result.Active = (item['Visible']);
                    }
                });
                this._heroService.saveRegUpdateSettings(result);
          });
          
        this.saveCustomTags();
        }
    }
    linkUserByEmail(email,membershipId){
        if(!membershipId || membershipId == 0){
            //do nothing
            return;
        }
        this.saveInProgress = true;

        this._userManagementService.linkCustomerToMembershipByEmail(email,membershipId).then(result => {
            if(result){
                this._userManagementService.getCustomerCentralUser(result).then(res => {
                    this._userManagementService.copyWidgetsToChild(res.CustomerId);
                    if (res && res.CustomerId>0) {
                        this.ccCustomer = res;
                        this.userId = this.ccCustomer.CustomerId;
                        let userlist = this._mb.allUsers;
                        if(userlist != undefined){
                            userlist.push(this.ccCustomer);
                        }else{
                            console.log("allUsers was undefined");
                        }
                        this._up.saveUserProfile(this.userId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, 2, this._mb.user.UserName).then((data) => {
                            this.closeLinkingModal();
                            this._router.navigate(['/user-management']);
                        });
                    }
                }).catch(error => {
                    this.closeLinkingModal();
                    this._router.navigate(['/user-management']);
                });
                //this._router.navigate(['/user-management']);
            }else{
                this.saveInProgress = false;
            }
        });
    }
    openLinkingModal() {
        this.modalRef = this.modalService.show(this.linkingModalTemplate); 
      }

      closeLinkingModal()  {
        this.modalRef.hide();
      }

      getCustomTags(membershipId){  
          if(membershipId)
          {          
            this._userManagementService.getCustomTags( membershipId, this.customerId).then(result => {
                this.customTags = result;
            });    
        }      
   }

   displayName(widget) {
    switch (widget.Name) {
        case "ManagementTier": return "Management Tier";
        case "Safety101": return "Safety 101";
        default: return widget.Name;
    }
  }


  saveCustomTags() {
    if (this.customTags) {
      if (this.customTags != null || this.customTags?.length > 0) {
        this._userManagementService.saveCustomTags(this.customTags);
      }
    }
  }
}
