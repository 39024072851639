export class CCUser {
    id: number;
    username: string;
    memberbase_Id: number;
    Email: string;
    FirstName: string;
    LastName: string;
    Company: string;
    Password: string;
    ProfileAddressId: number;
    MembershipId: number;
    Markets: string;
}