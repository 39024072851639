import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EhsHeroSvc } from '../shared/services/ehs-hero.service'
import { ChallengeModalComponent } from '../shared/components/challenge-modal.component';
import { MembershipDataService } from '../shared/services/membership.data.service';
import { Information } from '../shared/types/information.type';
import { SearchModel } from '../shared/types/search.type';
import { IndustryCollectionDetails, CollectionInfo } from '../shared/types/industry-collection-details.type';
import { IFilterTag } from '../shared/types/filter-tag.type';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  templateUrl: './industry-collections-details.component.html',
  styleUrls: ['./industry-collections-details.component.css']
})

export class IndustryCollectionsDetailsComponent {

  isLimitedAccess: any;

  @ViewChild('challengeModal')
  modal: ChallengeModalComponent;

  pageName: string;
  isCollection: boolean = false;
  collectionData: CollectionInfo;
  informationMaster: Information[];
  title: string;
  body: string;
  loading: boolean = false;
  docLink: string = null;
  tags: Array<IFilterTag> = [];
  storageKey: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: EhsHeroSvc,
    private _mbService: MembershipDataService,
    public route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      searching: false,
      ordering: true,
      order:[[1, 'asc']],
      info: false,
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
        lengthMenu : [15,25, 50],
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };
    
    let accessLevels = this._mbService.dataAccessLevels();
    var hasSafety = accessLevels.includes("3");
    if ((!this._mbService.hasTool("Industries")) || !hasSafety  || ((!this._mbService.hasTool("AllResources") || !this._mbService.customPermissions.GeneralPermissions.allresources.Visible) && (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible)))
    {
      this._router.navigate(["error/403"]);
    }
    this._route.params.subscribe(async params => {
      this.isLimitedAccess = this._mbService.isLimitedAccess();
      if (params["category"] != null) {
        this.loading = true;
        var exclusions = null;
        var data = null;

        if ((!this._mbService.hasTool("AllResources") || !this._mbService.customPermissions.GeneralPermissions.allresources.Visible) && (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible))
        {
          exclusions="1,2";
        }
        else if (!this._mbService.hasTool("AllResources") || !this._mbService.customPermissions.GeneralPermissions.allresources.Visible)
        {
          exclusions="1";
        }
        else if (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible)
        {
          exclusions="2";
        }
        
        if(exclusions)
        {
          data = await this._heroService.getIndustryCollectionDetailsWithExclusions(params["category"],exclusions);
          
        }
        else
        {
          data = await this._heroService.getIndustryCollectionDetails(params["category"]);
        }
        
        
        this.storageKey = "filters-" + params["category"];
        this.collectionData = data.CollectionInfo;
        this.informationMaster = this.collectionData.Information;
        
        this.title = data.Title;
        this.body = data.Body;
        this.docLink = data.DocLink;

        this.rerender();

        let savedFilters = this.popSavedFilters(this.storageKey);
        if (savedFilters!=null) {

          this.collectionData?.Topics?.forEach(f => f.Checked = savedFilters.Topics?.some(s => s.TopicId == f.TopicId));
          this.collectionData?.InformationTypes?.forEach(f => f.Checked = savedFilters.InformationTypes?.some(s => s.InformationTypeId == f.InformationTypeId));
          this.collectionData?.Jurisdictions?.forEach(f => f.Checked = savedFilters.Jurisdictions?.some(s => s.JurisdictionId == f.JurisdictionId));
          this.collectionData?.Categories?.forEach(f => f.Checked = savedFilters.Categories?.some(s => s.CategoryId == f.CategoryId));

          this.collectionData?.Jurisdictions?.forEach(f => {
            if (savedFilters.Jurisdictions?.some(s => s.JurisdictionId == f.JurisdictionId)) {
              this.tags.push({ Id: f.JurisdictionId, Filter: "Jurisdiction", Name: f.JurisdictionName })
            }
          });

          this.collectionData?.Topics?.forEach(f => {
            if (savedFilters?.Topics?.some(s => s.TopicId == f.TopicId)) {
              this.tags.push({ Id: f.TopicId, Filter: "Topic", Name: f.TopicName })
            }
          });

          this.collectionData?.InformationTypes?.forEach(f => {
            if (savedFilters?.InformationTypes?.some(s => s.InformationTypeId == f.InformationTypeId)) {
              this.tags.push({ Id: f.InformationTypeId, Filter: "DocumentType", Name: f.InformationTypeName })
            }
          });

          this.collectionData?.Categories?.forEach(f => {
            if (savedFilters?.Categories?.some(s => s.CategoryId == f.CategoryId)) {
              this.tags.push({ Id: f.CategoryId, Filter: "Category", Name: f.CategoryName })
            }
          });

          this.filter(7);
        }
        this.filterSelections(7);
        this.loading = false;
        }
    }
    );

  }
  clearAll() {
    this.tags = [];
    this.collectionData.Jurisdictions.forEach((item) => {
      item.Checked = false;
    });
    this.collectionData.Categories.forEach((item) => {
      item.Checked = false;
    });
    this.collectionData.Topics.forEach((item) => {
      item.Checked = false;
    });
    this.collectionData.InformationTypes.forEach((item) => {
      item.Checked = false;
    });
    //this._heroService.clearSavedFilters("search-filters");
    
    this.filter(7);
    this.filterSelections(7);
    this.popSavedFilters(this.storageKey);
    // this.pagination.resetPagination();
  }

  filterDocument(informationTypeId, checked, name) {
    if (checked) this.tags.push({ Id: informationTypeId, Filter: "Document", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === informationTypeId && item.Filter === "Document"));
    this.collectionData.InformationTypes.filter(f => f.InformationTypeId == informationTypeId || informationTypeId == 'all').forEach((item) => {
      item.Checked = checked;
    });
    //this.saveFilters();
    this.filter(2);
    this.filterSelections(2);
  }
  filterJurisdiction(jurisdictionId, checked, name) {
    if (checked) this.tags.push({ Id: jurisdictionId, Filter: "Jurisdiction", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === jurisdictionId && item.Filter === "Jurisdiction"));
    this.collectionData.Jurisdictions.filter(f => f.JurisdictionId == jurisdictionId || jurisdictionId == 'all').forEach((item) => {
      item.Checked = checked;
    });
   // this.saveFilters();
    this.filter(4);
    this.filterSelections(4);
  }

  filterCategory(categoryId, checked, name:string) {
    if (checked) this.tags.push({ Id: categoryId, Filter: "Category", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === categoryId && item.Filter === "Category"));
    this.collectionData.Categories.filter(f => f.CategoryId == categoryId || categoryId == 'all').forEach((item) => {
      item.Checked = checked;
    });
    //this.saveFilters();
    this.filter(5);
    this.filterSelections(5);
  }

  filterTopic(topicId, checked, name) {
    if (checked) this.tags.push({ Id: topicId, Filter: "Topic", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === topicId && item.Filter === "Topic"));
    this.collectionData.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => {
      item.Checked = checked;
    });
   // this.saveFilters();
    this.filter(6);
    this.filterSelections(6);
  }

  clearTag(tag: IFilterTag) {
    switch (tag.Filter) {
      case "Jurisdiction": this.filterJurisdiction(tag.Id, false, tag.Name); break;
      case "Category": this.filterCategory(tag.Id, false, tag.Name); break;
      case "Topic": this.filterTopic(tag.Id, false, tag.Name); break;
      case "Document": this.filterDocument(tag.Id, false, tag.Name); break;
    }
  }

  filter(selector) {
    this.collectionData.Information = this.getFilterResult("");    
    this.rerender();
  }

  getFilterResult(section) {
    return this.informationMaster.filter(item => (
      (this.collectionData?.InformationTypes.every(f => (!f.Checked)) || this.collectionData.InformationTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked)) || section == 'types') &&
      (this.collectionData?.Jurisdictions.every(f => (!f.Checked)) || this.collectionData.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)) || section == 'jurisdictions') &&
      (this.collectionData?.Categories.every(f => (!f.Checked)) || this.collectionData.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked)) || section == 'categories') &&
      (this.collectionData?.Topics.every(f => (!f.Checked)) || this.collectionData.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics')

    )
        );
  }

  filterSelections(selector) {
    if (selector != 2) {
      this.collectionData?.InformationTypes?.forEach(obj => {
        this.collectionData.InformationTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('types').filter(f => f.InformationTypeId == obj.InformationTypeId).length;
      });
    }
    if (selector != 4) {
      this.collectionData?.Jurisdictions?.forEach(obj => {
        this.collectionData.Jurisdictions.filter(f => f.JurisdictionId == obj.JurisdictionId)[0].Count = this.getFilterResult('jurisdictions').filter(f => f.JurisdictionId == obj.JurisdictionId).length;
      });
    }
    if (selector != 5) {
      this.collectionData?.Categories?.forEach(obj => {
        this.collectionData.Categories.filter(f => f.CategoryId == obj.CategoryId)[0].Count = this.getFilterResult('categories').filter(f => f.CategoryId == obj.CategoryId).length;
      });
    }
    if (selector != 6) {
      this.collectionData?.Topics?.forEach(obj => {
        this.collectionData.Topics.filter(f => f.TopicId == obj.TopicId)[0].Count = this.getFilterResult('topics').filter(f => f.TopicId == obj.TopicId).length;
      });
    }
    this.saveFilters(this.storageKey);
  }


  saveFilters(key) {
    var filters = new CollectionInfo();
    filters.Topics = this.collectionData?.Topics?.filter(f => f.Checked);
    filters.InformationTypes = this.collectionData?.InformationTypes?.filter(f => f.Checked);
    filters.Jurisdictions = this.collectionData?.Jurisdictions?.filter(f => f.Checked);
    filters.Categories = this.collectionData?.Categories?.filter(f => f.Checked);
    localStorage.setItem(key, JSON.stringify(filters));
  }


  popSavedFilters(key) {
    let filters = JSON.parse(localStorage.getItem(key));
    localStorage.removeItem(key);
    return filters;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
    rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });  
      
    }  
    else if (this.dtElement)
    {      
         this.dtTrigger.next();     
    } 
  }
}
