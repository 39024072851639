import { Component, TemplateRef } from '@angular/core';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';


@Component({
  selector: 'safety-toolbox-trainer',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './safety-toolbox-trainer.html'
})

export class SafetyToolboxTrainerWidgetComponent {
  isLimitedAccess: boolean;
  modalRef: BsModalRef;
  constructor(private _mbService: MembershipDataService,
    public modalService: BsModalService) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }
  openModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
 }

  navigate() {
    
    window.open("https://safetytoolboxtrainer.blr.com/#/login", "_blank")

    //this.window.open = true;

    //this.window.close = true;
  }
}

