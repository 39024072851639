import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EhsHeroSvc } from '../shared/services/ehs-hero.service';

@Component({
  selector: 'hidden-reindex',
  templateUrl: './hidden-reindex.component.html',
  styleUrls: ['./hidden-reindex.component.css']
})

export class HiddenReindexComponent {

    timestrings: string[];
    intervals: number[];
    typeIds: number[] = [16,124,110,61,32,69,6,71,58,52,57,20,28,54,74,55,5,60,66,77,62,76,117,119,53,115,123]
    times: Date[];
    states: string[];

    constructor(
      public _route: ActivatedRoute,
      private _ehs: EhsHeroSvc
    ) { 
        this.timestrings = [];
        this.times = [];
        this.states = [];
        this.intervals = [];
        for(let i = 0; i < this.typeIds.length; i++){
            this.timestrings.push("0 seconds");
            this.times.push(new Date());
            this.states.push("STANDBY");
            this.intervals.push(0);
        }
        
    }
  
    ngOnInit() {
      
    }
    /*fakeIndexType(index){
        this.states[index] = "RUNNING";
        this.times[index] = new Date();
        let intervalIndex = index;
        let self = this;
        this.intervals[index] = window.setInterval(function(){
            self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
        },1000);
        window.setTimeout(function(){
            self.states[intervalIndex] = "ERROR";
            self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
            window.clearInterval(self.intervals[intervalIndex]);
        },15000);
    }*/
    indexType(index){
        this.states[index] = "RUNNING";
        this.times[index] = new Date();
        let intervalIndex = index;
        let self = this;
        this.intervals[index] = window.setInterval(function(){
            self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
        },1000);

        this._ehs.reindexType(this.typeIds[index]).then(result => {
            if(result == "success"){
                self.states[intervalIndex] = "DONE";
                self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
                window.clearInterval(self.intervals[intervalIndex]);
            }else{
                self.states[intervalIndex] = "ERROR";
                self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
                window.clearInterval(self.intervals[intervalIndex]);
            }
        })
    }
    cancelType(index){
        this.states[index] = "STOPPED";
        this.timestrings[index] = this.runtimeOf(index);
        window.clearInterval(this.intervals[index]);
    }
    typeFinished(index){
        
    }
    runtimeOf(index){
        let time = new Date();
        let milliseconds = time.getTime() - this.times[index].getTime();
        let seconds = Math.floor(milliseconds/1000);
        let minutes = Math.floor(milliseconds/60000);
        let adjustedSeconds = Math.floor( (milliseconds % 60000) / 1000);
        if(minutes > 0){
            return minutes + " minutes and " + adjustedSeconds + " seconds"
        }else{
            return seconds + " seconds";
        }
    }
    
  }