<tag-input [(ngModel)]='tags' theme="minimal" secondaryPlaceholder="Add search term(s)" placeholder="Add search term(s)"
           (onRemove)="itemUpdated($event)"
           (onAdd)="itemUpdated($event)" >
</tag-input>
<div style="margin-top: 10px;">
  <div class="radio-group">
    <input class="form-check-input" type="radio" name="searchAll" id="allKeywords" [(ngModel)]="allKeywords" (change)="itemUpdated($event)" [value]="true">
    <label class="form-check-label" for="allKeywords">
      Search All Keywords
    </label>


    <input class="form-check-input" type="radio" name="searchAll" id="anyKeyword" [(ngModel)]="allKeywords" (change)="itemUpdated($event)" [value]="false">
    <label class="form-check-label" for="anyKeyword">
      Search Any of the Keywords
    </label>
  </div>
</div>
<div *ngIf="loading"  class="row" style="text-align:center;">
  <img src="../../assets/images/loader.gif" />
</div>

<div *ngIf="searchItems == null && !loading && tags && tags.length && !spellingCorrection" style="margin-top:20px;" >
  <h2>No content found to match your criteria. Please try another search.</h2>
</div>

<div *ngIf="searchItems == null && !loading && tags && tags.length && spellingCorrection" style="margin-top:20px;">
  <h2>No content found to match your criteria. Did you mean <a [routerLink]="['/search/'+ spellingCorrection]" style="cursor:pointer;">{{spellingCorrection}}</a> </h2>
</div>

<div *ngIf="!loading && !tags.length && searchTermValue!='null'" style="margin-top:20px;">
  <h2>Please add search term(s) to try another search.</h2>
</div>

<div *ngIf="searchItems != null && !loading" style="margin-top:20px;" class="row">
 
  <div class="col-lg-3" style="margin-bottom:20px;">
    <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
    <div class="outer-filter-panel" #filters>
      <!-- <div class="filter-panel  body-content-label">
        &nbsp;<span  (click)="allCollapsed = !allCollapsed" data-target="#all" style="float: right;cursor: pointer;">{{ allCollapsed ? '+' : '&ndash;' }}</span>
      </div> -->
      <!-- <div id="all" [ngClass]="{'collapse in' : !allCollapsed , 'collapse': allCollapsed }" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tr>
            <td>
              <div class="radio">
                <label><input name="allItems" type="radio" (click)="filterNewItems('new', $event.target.checked)" [checked]="!searchItems.AllChecked">New</label><span style="float:right;">{{newRecordsCount}}</span> 
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="radio">
                <label><input name="newItmes" type="radio" (click)="filterNewItems('all', $event.target.checked)" [checked]="searchItems.AllChecked">All</label><span *ngIf="tags.length>0" style="float:right;"> {{allRecordsCount}}</span> 
              </div>
            </td>
          </tr>
        </table>
      </div> -->

      <div class="filter-panel  body-content-label">
        States<span data-toggle="collapse" (click)="stateCollapsed = !stateCollapsed" data-target="#state" style="float: right;cursor: pointer;">{{ stateCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="state" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterJurisdiction('all', $event.target.checked)" [checked]="!anyOptionChecked('Jurisdictions')">All</label><span style="float:right;">{{searchItems.Information.length}}</span>
                </div>
              </td>
            </tr>
            <tr *ngFor="let item of searchItems.Jurisdictions">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterJurisdiction(item.JurisdictionId, $event.target.checked)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="filter-panel  body-content-label">
        Languages<span data-toggle="collapse" (click)="languageCollapsed = !languageCollapsed" data-target="#language" style="float: right;cursor: pointer;">{{ languageCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="language" class="collapse in">
        <table class="table">
        <tbody>
          <tr>
            <td>
              <div class="checkbox">
                <label><input type="checkbox" (click)="filterLanguage('all', $event.target.checked)" [checked]="!anyOptionChecked('Languages')">All</label><span style="float:right;">{{searchItems.Information.length}}</span>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of searchItems.Languages">
            <td *ngIf="item.Count != 0">
              <div class="checkbox">
                <label><input type="checkbox" (click)="filterLanguage(item.Language, $event.target.checked)" [checked]="item.Checked">{{item.Language}}</label><span style="float:right;">{{item.Count}}</span>
              </div>
            </td>
          </tr>
        </tbody>
        </table>
      </div>

      <div class="filter-panel  body-content-label">
        Categories<span data-toggle="collapse" (click)="categoryCollapsed = !categoryCollapsed" data-target="#category" style="float: right;cursor: pointer;">{{ categoryCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="category" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterCategory('all', $event.target.checked)" [checked]="!anyOptionChecked('Categories')">All</label><span style="float:right;">{{searchItems.Information.length}}</span>
                </div>
              </td>
            </tr>
            <tr *ngFor="let item of searchItems.Categories">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterCategory(item.CategoryId, $event.target.checked)" [checked]="item.Checked">{{item.CategoryName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="filter-panel  body-content-label">
        Topics<span data-toggle="collapse" (click)="topicCollapsed = !topicCollapsed" data-target="#topic" style="float: right;cursor: pointer;">{{ topicCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic('all', $event.target.checked)" [checked]="!anyOptionChecked('Topics')">All</label><span style="float:right;">{{searchItems.Information.length}}</span>
                </div>
              </td>
            </tr>
            <tr *ngFor="let item of searchItems.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="filter-panel  body-content-label">
        Document Types<span data-toggle="collapse" (click)="docCollapsed = !docCollapsed" data-target="#doc" style="float: right;cursor: pointer;">{{ docCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
        <tbody>
          <tr>
            <td>
              <div class="checkbox">
                <label><input type="checkbox" (click)="filterDocument('all', $event.target.checked)" [checked]="!anyOptionChecked('InformationTypes')">All</label><span style="float:right;">{{searchItems.Information.length}}</span>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of searchItems.InformationTypes">
            <td *ngIf="item.Count != 0">
              <div class="checkbox">
                <label><input type="checkbox" (click)="filterDocument(item.InformationTypeId, $event.target.checked)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
              </div>
            </td>
          </tr>
        </tbody>
        </table>
      </div>

      <div class="filter-panel  body-content-label" *ngIf="hasEnviro&&hasSafety">
        Source<span data-toggle="collapse" (click)="sourceCollapsed = !sourceCollapsed" data-target="#source" style="float: right;cursor: pointer;">{{ sourceCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="source" class="collapse in" style="overflow-y:auto; max-height:186px;" *ngIf="hasEnviro&&hasSafety">
        <table class="table">
        <tbody>
          <tr *ngFor="let item of searchItems.Sources">
            <td *ngIf="item.Count != 0">
              <div class="checkbox">
                <label><input type="checkbox" (click)="filterSource(item.DatabaseId, $event.target.checked)" [checked]="item.Checked">{{item.Source}}</label><span style="float:right;">{{item.Count}}</span>
              </div>
            </td>
          </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-9">
    <div *ngIf="!loading && !tags.length && searchItems.AllChecked" style="margin-top:20px;">
      <h2>Your search returned too many results. Please enter a search term(s) above to narrow your results.</h2>
    </div>
    <div *ngIf="!loading && ((tags && tags.length>0)|| !searchItems.AllChecked )">  
      <div class="title-text">{{searchItems.Information.length}} results</div>
      <!-- <div *ngIf="searchItems.AllChecked" class="title-text">{{searchItems.Information.length}} results</div>
      <div *ngIf="!searchItems.AllChecked" class="title-text">{{searchItems.Information.length}} updated/added in the last 30 days</div> -->
      <div class="white-well" [ngStyle]="{'height.px': filtersHeight || filters.offsetHeight}">
        <div *ngFor="let item of searchItems.Information">

          <div style="font-weight:bold;  font-family: 'Open Sans', sans-serif !important;" *ngIf="(item.Url.startsWith('/training/') || item.Url.startsWith('/resources/') || item.Url.startsWith('/news/') || item.Url.startsWith('/checklists-forms/')|| item.Url.startsWith('/chart-builder/'))"><a [routerLink]="[ (item.Url.startsWith('/training/') || item.Url.startsWith('/resources/') || item.Url.startsWith('/news/') || item.Url.startsWith('/checklists-forms/')) ? (item.Url + '/' + item.InformationId) : item.Url]" class="link">{{item.Title}}</a></div>
          <!--<div style="font-weight:bold;  font-family: 'Open Sans', sans-serif !important;" *ngIf="(item.Url.startsWith('/training/'))"><a [routerLink]="[ '/training/' + item.InformationId ]" class="link">{{item.Title}}</a></div>
      <div style="font-weight:bold;  font-family: 'Open Sans', sans-serif !important;" *ngIf="!(item.Url.startsWith('/resources/') || item.Url.startsWith('/news/') || item.Url.startsWith('/checklists-forms/') || item.Url.startsWith('/training/'))"><a [routerLink]="[item.Url]" class="link">{{item.Title}}</a></div>-->
          <!--<div style="font-weight:bold; font-size:9pt;  font-family: 'Open Sans', sans-serif !important;">{{item.PubDate  | date: 'EEEE, MMMM d, y'}}</div>-->
          <div style="font-size:9pt;  font-family: 'Open Sans', sans-serif !important;">{{item.CategoryDesc}}/{{item.TopicDesc}}/{{item.InformationTypeName}}</div><br />
          <div class="body-content"><div [innerHTML]="item.Teaser"></div></div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
