import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({ name: "sortBy" })
@Injectable()
export class SortPipe {
  transform(array: Array<string>, args: string): Array<string> {
    array.sort((a: any, b: any) => {

      let argOne: string = (isNaN(a[args])) ? a[args].toLowerCase() : a[args];
      let argTwo: string = (isNaN(b[args])) ? b[args].toLowerCase() : b[args];

      if (argOne < argTwo) {
        return -1;
      } else if (argOne > argTwo) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
