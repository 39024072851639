<div *ngIf="widgets != null && available">
  <div class="row">
    <div [dragula]='currentRoute' [dragulaModel]='widgets'>

      <ng-container *ngIf="currentRoute != 'tools' && !useParent">

        <div *ngFor="let item of widgets | sortBy: 'PositionOnHome'; let i = index" [ngClass]="getToolClass(item)">
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'COVID' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <covid-19></covid-19>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'CHB' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <chart-builder-widget></chart-builder-widget>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'Training' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <all-training></all-training>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'RegActivity' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <regulatory-activity></regulatory-activity>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'STT' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <safety-toolbox-trainer></safety-toolbox-trainer>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'PB' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <plan-builder></plan-builder>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'SDS' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <sds-search></sds-search>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'ReportSpill' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <report-spill></report-spill>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'ComplianceCalendar' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <compliance-calendar-widget></compliance-calendar-widget>
          </div>          
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'HELP' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <help-widget></help-widget>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'ManagementTier' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <management-tier></management-tier>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'Safety101' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <safety101></safety101>
          </div>

          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'WHATSNEW' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <whats-new></whats-new>
          </div>

          <!--custom widgets-->
          <custom-widget *ngIf="item.IsCustom && checkVisibility(item) "
                         [widget]="item"
                         [useParent]="useParent"
                         class="bag-item">
          </custom-widget>

        </div>
      </ng-container>

      <ng-container *ngIf="currentRoute != 'tools' && useParent">
        <div *ngIf="useParent" class="no-drag-top">
          <div *ngFor="let item of parentWidgets | sortBy: 'PositionOnHome'; let i = index" [ngClass]="getToolClass(item)">
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'COVID' && checkVisibility(item)">
              <covid-19></covid-19>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'CHB' && checkVisibility(item)">
              <chart-builder-widget></chart-builder-widget>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'Training' && isAuthenticated && checkVisibility(item)">
              <all-training></all-training>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'RegActivity' && isAuthenticated && checkVisibility(item)">
              <regulatory-activity></regulatory-activity>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'STT' && isAuthenticated && checkVisibility(item)">
              <safety-toolbox-trainer></safety-toolbox-trainer>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'PB' && isAuthenticated && checkVisibility(item)">
              <plan-builder></plan-builder>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'SDS' && isAuthenticated && checkVisibility(item)">
              <sds-search></sds-search>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'ReportSpill' && isAuthenticated && checkVisibility(item)">
              <report-spill></report-spill>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="item.InclusionCode == 'ComplianceCalendar' && isAuthenticated && checkVisibility(item)">
              <compliance-calendar-widget></compliance-calendar-widget>
            </div>            
            <div class="white-well no-drag" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'HELP' && checkVisibility(item)">
              <help-widget></help-widget>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'ManagementTier' && checkVisibility(item)">
              <management-tier></management-tier>
            </div>
            <div class="white-well no-drag" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'Safety101' && checkVisibility(item)">
              <safety101></safety101>
            </div>            
            <div class="white-well no-drag" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'WHATSNEW' && checkVisibility(item)">
              <whats-new></whats-new>
            </div>
            <!--custom widgets-->
            <custom-widget *ngIf="item.IsCustom && checkVisibility(item) "
                           [widget]="item"
                           [useParent]="useParent"
                           class="no-drag">
            </custom-widget>

          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentRoute == 'tools'">

        <div *ngFor="let item of widgets | sortBy: 'PositionOnTools'; let i = index" [ngClass]="getToolClass(item)">
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'COVID' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <covid-19></covid-19>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'CHB' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <chart-builder-widget></chart-builder-widget>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'Training' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <all-training></all-training>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'RegActivity' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <regulatory-activity></regulatory-activity>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'STT' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <safety-toolbox-trainer></safety-toolbox-trainer>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'PB' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <plan-builder></plan-builder>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'SDS' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <sds-search></sds-search>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'ReportSpill' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <report-spill></report-spill>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="item.InclusionCode == 'ComplianceCalendar' && isAuthenticated && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <compliance-calendar-widget></compliance-calendar-widget>
          </div>          
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'HELP' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <help-widget></help-widget>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'ManagementTier' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <management-tier></management-tier>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'Safety101' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <safety101></safety101>
          </div>          
          <div class="white-well bag-item" [id]="item.Id" *ngIf="isAuthenticated && item.InclusionCode == 'WHATSNEW' && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item, 'tool')"><i class="fas fa-times"></i></span>
            <whats-new></whats-new>
          </div>
          <custom-widget *ngIf="item.IsCustom && checkVisibility(item) "
                         [widget]="item"
                         class="bag-item">
          </custom-widget>
        </div>
      </ng-container>

    </div>
  </div>
</div>

