import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { EhsHeroConfig } from '../../environments/environment'
import 'jquery';

@Component({
  selector: 'sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.css'],
})

export class SSOLoginComponent {
  loginResponse: string ;
  state: string;

    constructor(
        public _router: Router,
        public _route: ActivatedRoute,
        private _mbService: MembershipDataService
      ) { }
    
      ngOnInit() {
        var tokenValidated = this._route.snapshot.queryParams["tokenValidated"];
        let _this = this;
        if(tokenValidated)
        {
          if(tokenValidated.toLowerCase()=="true")
          {
            this.Login();
          }
          else
          {
            var error = this._route.snapshot.queryParams["error"];
            var errorDescription = this._route.snapshot.queryParams["error_description"];
            if(errorDescription)
            {
              _this.loginResponse = errorDescription;
            }
            $('.imgloader').css('visibility', 'hidden');
          }
        }
        else
        {
         this.Redirect();
          
        }
        
      }

      Login()
      {
        let _this = this;
        $.ajax({
          type: 'POST',
          url: EhsHeroConfig.endpoint + "/account/SsoLogin",
          xhrFields: {
            withCredentials: true
          },
          data: {
            ApplicationId: EhsHeroConfig.applicationId
          },
          crossDomain: true,
          success: function (data) {
            $('.imgloader').css('visibility', 'hidden');
            var account = data;
            //authentication failed
            if (account == null) {
              //_this.loginResponse = "Something didnt work out.";
              //$('.imgloader').css('visibility', 'hidden');
              this._router.navigate(['/error/500']);
            }  
            else
            {    
              console.log(account.Authorized);
              if (!account.Authorized || account.Authorized == "false") {

                if (account.ResponseMessage || (account.ResponseMessage?.length > 0)) {
                  _this.loginResponse = account.ResponseMessage;
      
                }
                else
                {
                  //_this.loginResponse = "Something didnt work out.";
                  this._router.navigate(['/error/500']);
                }
              }
              else
              {
                _this._mbService.setUpAccount(account);        
                _this.processLogin(_this, account);
              }
            }           
    
            
          }, error: function (err) {
            // _this.loginResponse = "Something didnt work out.";
            //   $('.imgloader').css('visibility', 'hidden');
            this._router.navigate(['/error/500']);
          }
        });
      }
      Redirect()
      {
        let _this = this;
        $.ajax({
          type: 'Get',
          url: EhsHeroConfig.endpoint + "/account/GetSsoLoginRedirectURL?appcode=ehs",
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: function (data) {
           
            console.log(data);
            if(data)
            {
              location.href=data;
            }
            else
            {
              // _this.loginResponse = "Something didnt work out.";
              // $('.imgloader').css('visibility', 'hidden');
              this._router.navigate(['/error/500']);
            }
            
          }, error: function (err) {
            // _this.loginResponse = "Something didnt work out.";
            // $('.imgloader').css('visibility', 'hidden');
            this._router.navigate(['/error/500']);
          }
        });
      }
      
    processLogin(_this, account) {

      if (!account.Authorized) {
        let navigateToTools: boolean = false;
  
        if (account.SubscriptionType == "EHS Chart Builder Safety Enviro") {
          navigateToTools = true;
          _this._router.navigate(['chart-builder']);
        }
        if (account.SubscriptionType == "EHS Chart Builder Enviro") {
          navigateToTools = true;
          _this._router.navigate(['chart-builder']);
        }
        if (account.SubscriptionType == "EHS Chart Builder Safety") {
          navigateToTools = true;
          _this._router.navigate(['chart-builder']);
        }
  
        if (!navigateToTools)
          _this.loginResponse = "This account is not authorized.";
      }
      else {
  
        _this._mbService.userIsEHSAuthenticated = true;
        _this._mbService.userIsHRAuthenticated = false;
        if (localStorage.getItem('ru')) {
          var url = localStorage.getItem('ru');
          localStorage.removeItem('ru');
          window.location.href = window.location.protocol + '//' + window.location.hostname + '/ehs'+url;
        } else {
        _this._router.navigate(['dashboard']);
        }
  
        switch (_this.route) {
          case 'home':
            _this._router.navigate(['dashboard']);
            break;
          case 'jdm':
            _this._router.navigate(['job-description-manager']);
            break;
          case 'hotline':
            _this._router.navigate(['hotline']);
            break;
          case 'slcb':
            _this._router.navigate(['state-law-chart-builder']);
            break;
          default:
            _this._router.navigate(['dashboard']);
            break;
        }
      }
    }
    }
