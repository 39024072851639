import { Component, ViewChild } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { UserProfile } from '../../shared/types/user-profile.type'
import { UserProfileService } from '../../shared/services/user-profile.service';
import { Subject} from 'rxjs';

import { DataTableDirective } from 'angular-datatables';

import  'jquery';
import { UserManagementService } from '../../shared/services/user-management.service';
import { Widget } from '../../shared/types/widget.type';

@Component({
  selector: 'preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})

export class PreferencesComponent {

  userProfile: UserProfile = new UserProfile;
  widgets: Widget[]=[];
  isChild: boolean = false;
  useParent: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(
    private _mbService: MembershipDataService,
    private _userProfileService: UserProfileService,
    private _userManagementService: UserManagementService
  ) {
    this.userProfile = this._mbService.userProfile;
  }

async ngOnInit() {

    $(".alert").hide();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: false,
      info: false,
      columnDefs: [
        { width: 'auto', targets:  [ 0, 1],}
    ],
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };

      this.widgets = this._mbService.user.Widgets.filter(f => this.isVisible(f))
      .sort(
        (a,b) => 
          b.IsCustom < a.IsCustom ? 
            1 : 
            (a.IsCustom < b.IsCustom ? 
              -1 :
              (a.Name.localeCompare(b.Name)))
      );
      let parentUserProfile = this._mbService.parentUserProfile;
      if(parentUserProfile != null && parentUserProfile != undefined && parentUserProfile.AdminPreferences != null && parentUserProfile.AdminPreferences != undefined){
        this.isChild = true;
        if(parentUserProfile.DashboardLock){
          this.useParent = true;   
        }
      }

     
  }

  widgetVisible(item: Widget, isCustom, page) {
    if (!isCustom || this.widgets.filter(f => f.IsCustom).length == 0)
      return true;

    if (page == 'tools')
        return this._mbService.customPermissions.GeneralPermissions.toolsWidgets.Visible && item.ActiveOnTools || (item.OwnerId == item.UserId && item.ActiveOnTools );
    else if (page == 'dashboard') {
        return this._mbService.customPermissions.GeneralPermissions.dashboardWidgets.Visible && item.ActiveOnHome || (item.OwnerId == item.UserId && item.ActiveOnHome) ;
    }

  }

  getType(isCustom) {
    if (isCustom)
      return "Custom-Widget";
    else
      return "Tool";
  }

  check(checked, item, type) {

    if (type == 'tools')
      item.VisibleOnTools = checked;

    if (type == 'dashboard')
      item.VisibleOnHome = checked;

    this._userManagementService.updateCustomWidget(item.Id, item.VisibleOnHome, item.VisibleOnTools, item.PositionOnHome, item.PositionOnTools).then(data => {
      $("#prefAlert").show();

      setTimeout(function () { $("#prefAlert").hide(500); }, 1000);
    });
  }

  isVisible(item: Widget) {

    let name = "";

    switch (item.Name) {
      case "Employee Handbook Builder":
        name = "Show Employee Handbook Builder";
        break;
      case "State Law Chart Builder":
        name = "Show State Law Chart Builder";
        break;
      case "FLSA Audit Tool":
        name = "Show FLSA Audit Tool";
        break;
      case "Job Description Manager":
        name = "Show Job Description Manager";
        break;
      default:
        name = item.Name;
        break;
    }

    if (item.IsCustom && !item.ActiveOnHome && !item.ActiveOnTools) {
      return false;
    }

    if (this.userProfile.AdminPreferences.filter(f => f.Name == name).length > 0)
      return (this.userProfile.AdminPreferences.filter(f => f.Name == name)[0].Visible && this._mbService.hasTool(item.InclusionCode));
    else
      if (!item.IsCustom) return this._mbService.hasTool(item.InclusionCode);
    
    if (item.IsCustom && item.OwnerId != item.UserId) {
      return (this._mbService.customPermissions.GeneralPermissions.toolsWidgets.Visible || this._mbService.customPermissions.GeneralPermissions.dashboardWidgets.Visible);
    }

    return true;
  }
  saveDashboardLock(checked){
    this.userProfile.DashboardLock = checked;
    this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);
    $("#lockAlert").show();
    setTimeout(function () { $("#lockAlert").hide(500); }, 1000);
  }

  displayName(widget) {
    switch (widget.Name) {
        case "ManagementTier": return "Management Tier";
        case "Safety101": return "Safety 101";
        default: return widget.Name;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
}

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });  
   
  }
}
