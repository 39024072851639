
import {take} from 'rxjs/operators';
import { Component, Provider, ElementRef, OnInit, Input, Compiler, Output, EventEmitter, ViewChild } from '@angular/core';

import { UserProfileService } from '../../services/user-profile.service';
import { MembershipDataService } from '../../services/membership.data.service'
import { PermissionTierApi } from '../../types/permission-tier-api.type';
import { ConfirmDeleteComponent } from '../confirm-delete.component';
import { NgModel } from '@angular/forms';
import { UserManagementService } from '../../services/user-management.service';
import { PermissionTier } from '../../types/permission-tier.type';

@Component({
  selector: "tier-selector",
  templateUrl: './tier-select.component.html'
})

export class TierSelectorComponent {

  @Input("assignedTierId") assignedTierId: number = 0;
  @Input("disablePermissions") disablePermissions: boolean = false;
  @Input("permissionTiers") permissionTiers: PermissionTierApi[];
  @Input("userProfile") userProfile: any;
  @Input("save") save: boolean = false;
  @Input("userId") userId: number;
  @Output() onSelectionComplete = new EventEmitter<number>();

  confirmationMessage: string;
  @ViewChild('selectModel') private selectModel: NgModel;
  @ViewChild('confirmModal')
  confirmModal: ConfirmDeleteComponent;
  

  constructor(
    private _mbService: MembershipDataService,
    private _userManagementService: UserManagementService, private _up: UserProfileService) { }

  ngOnInit() {

  }

  assignPermissionTier(value: number) {
    let tierId = value;
    if(value!=this.assignedTierId)
    {
      if (value != 0) {
        this.confirmationMessage = "Warning! This user permissions will change  according to the tier selection. Are you sure you want to continue?";
        this.confirmModal.open();
        this.confirmModal.confirmDelete.pipe(take(1)).subscribe(async data => {

          if (data) {
            this.assignedTierId = this.permissionTiers.find(f => f.PermissionTierId == value).PermissionTierId;
            if (this.save) {
              await this.saveTier(this.assignedTierId, this.userId);
              console.log("attempting to retrieve profile for userId " + this.userId);
              let profileapi = await this._up.getUserProfile(this.userId, this._mbService.userProfileAPIToken, 2);
              if(profileapi == null || profileapi == undefined){
                console.log("...but it came back null");
              }
              this.userProfile =  JSON.parse(profileapi.UserProfileJSON);
            }

            this._userManagementService.applyPermissionTier(JSON.parse(this.permissionTiers.find(f => f.PermissionTierId == value).PermissionTierJSON), this.userProfile);

            if (this.save) {
              await this._up.saveUserProfile(this.userId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, 2, this._mbService.user.UserName);
            }
            this.confirmModal.dismiss();
            
          }
          else 
          {
            this.selectModel.reset(this.assignedTierId);
          }
          this.onSelectionComplete.emit(this.assignedTierId);
        });
      }
      else {
        this.confirmationMessage = "Warning! User will be removed from the tier. Are you sure you want to continue?";
        this.confirmModal.open();
        this.confirmModal.confirmDelete.pipe(take(1)).subscribe(async data => {
          
          if (data) {
            this.assignedTierId = value;

            if (this.save) {
              await this.saveTier(this.assignedTierId, this.userId);
              console.log("attempting to retrieve profile for userId " + this.userId);
              let profileapi = await this._up.getUserProfile(this.userId, this._mbService.userProfileAPIToken, 2);
              if(profileapi == null || profileapi == undefined){
                console.log("...but it came back null");
              }
              this.userProfile = JSON.parse(profileapi.UserProfileJSON);
            }

            this._userManagementService.applyPermissionTier(new PermissionTier(), this.userProfile);
            if (this.save) {
              await this._up.saveUserProfile(this.userId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, 2, this._mbService.user.UserName);
            }
            this.confirmModal.dismiss();
          }
          else
          {
            this.selectModel.reset(this.assignedTierId);
          }
          this.onSelectionComplete.emit(this.assignedTierId);
        });
      }
    }
}


  async saveTier(tierId: number, userId: number) {
    if (tierId != 0)
      await this._userManagementService.assignTier(tierId, userId);
    else
      await this._userManagementService.deleteAssignment(userId);
  }
}
