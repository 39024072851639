
import {first, map, share, catchError, tap, shareReplay  } from 'rxjs/operators';
import { Injectable, Component } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse  } from '@angular/common/http'
import { Router, ActivatedRoute } from '@angular/router';
import { Category } from '../types/category.type'
import { Topic } from '../types/topic.type'
import { Jurisdition } from '../types/jurisdiction.type'
import { Information } from '../types/information.type'
import { Bookmark } from '../types/bookmark.type'
import { BookmarkFolder } from '../types/bookmark-folder.type'
import { RegUpdateSetting } from '../types/RegUpdate/regupdate-setting-type'
import { SearchModel } from '../types/search.type'
import { User } from '../types/membershipbase-user'
import { SDSSearch } from '../types/sds-search.type'
import { SDSNewSearch } from '../types/sds-new-search.type'
import { EhsHeroConfig } from '../../../environments/environment'
import { MembershipDataService } from '../services/membership.data.service'
import { CookieService } from 'ngx-cookie-service';
import { Account } from '../types/account.type';
import  'jquery';
import { IndustryCollectionDetails } from '../types/industry-collection-details.type';
import {CustomImage} from '../types/custom-image.type';
import { UrlLink } from '../types/urllink.type';
import { VodVideo } from '../types/vodVideo.type';
import { VodLibrary } from '../types/vodLibrary.type';
import { param } from 'jquery';

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})


export class EhsHeroSvc {

 
  hrHeroCon: any;

  constructor(private http: HttpClient, private mbDataService: MembershipDataService, private _cookieService: CookieService, private router: Router) {


  }

  getCategories(language, infoTypes, sourceDatabaseIds) {
    (language == 'Spanish') ? language = 1 : language = language;
    (language == 'English') ? language = 0 : language = language;


    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

     return this.http.get<Category[]>(EhsHeroConfig.endpoint + '/Category?spanish=' + language + '&infoTypes=' + infoTypes, options)
      .toPromise();
  }

  getTopics(language, categoryId, infoTypes, sourceDatabaseIds) {
    (language == 'Spanish') ? language = 1 : language = language;
    (language == 'English') ? language = 0 : language = language;
    (categoryId == 0) ? categoryId = null : categoryId = categoryId;

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    console.log(EhsHeroConfig.endpoint + '/Topic?spanish=' + language + '&categoryId=' + categoryId + '&infoTypes=' + infoTypes + '&sourceDatabaseIds=' + sourceDatabaseIds)

    return this.http.get<Topic[]>(EhsHeroConfig.endpoint + '/Topic?spanish=' + language + '&categoryId=' + categoryId + '&infoTypes=' + infoTypes + '&sourceDatabaseIds=' + sourceDatabaseIds, options)
      .toPromise();
  }

  getStates(list = null) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    return this.http.get<Jurisdition[]>(EhsHeroConfig.endpoint + '/States?list=' + list, options)
      .toPromise();
  }

  getAllStates() {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Jurisdition[]>(EhsHeroConfig.endpoint + '/States', options)
      .toPromise();
  }


  getStateOilSpillReportingAgency(abbr) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    console.log(EhsHeroConfig.endpoint + '/StateOilSpillReportingAgency?abbr=' + abbr)
    return this.http.get<any>(EhsHeroConfig.endpoint + '/StateOilSpillReportingAgency?abbr=' + abbr, options)
      .toPromise();
  }


  getStateOilSpillReportingAgencySates() {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<any>(EhsHeroConfig.endpoint + '/StateOilSpillReportingAgency', options)
      .toPromise();
  }

  getJurisdictions() {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Jurisdition>(EhsHeroConfig.endpoint + '/Jurisdiction', options)
      .toPromise();
  }

  getInformationByUrl(url) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information?url=' + url, options)
      .toPromise();
  }

  getInformationById(id, sourceDatabaseIds) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information?informationId=' + id + '&sourceInformationId=null&sourceDatabaseIds=' + sourceDatabaseIds, options)
      .toPromise();
  }


  getInformationByUrlParts(type, category, topic, page, sourceDatabaseIds) {

    let headers: any = {
      //'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information?informationType=' + type + '&category=' + category + '&topic=' + topic + '&pageName=' + page + "&sourceDatabaseIds=" + sourceDatabaseIds, options)
      .toPromise();
  }

  getAllInformation(infoTypeId, databaseId) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + "&top=500&ignoreExceptions=false&sourceDatabaseIds=" + databaseId, options)
      .toPromise();
  }

  getAllInformationDesc(infoTypeId, databaseId) {

    let headers: any = {
      //'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + "&top=500&ignoreExceptions=false&sourceDatabaseIds=" + databaseId, options)
      .toPromise();
  }

  getInformation(infoTypeId, categoryId, topicId, jurisdictionId, addDays = null, language = null, sourceDatabaseIds = null) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/?infoTypeId=' + infoTypeId + '&categoryId=' + categoryId + '&topicId=' + topicId + '&jurisId=' + jurisdictionId + '&addDays=' + addDays + '&language=' + language + '&sourceDatabaseIds=' + this.mbDataService.dataAccessLevels(), options)
      .toPromise();
  }

  getInformationByInfoIdList(infoTypeId, categoryId, topicId, jurisdictionId, addDays = null, language = null, sourceDatabaseIds = null) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/GetByInfoIdList?infoTypeId=' + infoTypeId + '&categoryId=' + categoryId + '&topicId=' + topicId + '&jurisId=' + jurisdictionId + '&addDays=' + addDays + '&language=' + language + '&sourceDatabaseIds=' + sourceDatabaseIds, options)
      .toPromise();
  }

  getTopTraining(infoTypeIdList, addDays = null, language = null, sourceDatabaseIds = null) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/TopTraining?infoTypeIdList=' + infoTypeIdList + '&addDays=' + addDays + '&sourceDatabaseIds=' + this.mbDataService.dataAccessLevels(), options)
      .toPromise();
  }

  getInformationTrainingNew(sourceDatabaseIds = null, pageType = null) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/GetNewTraining?sourceDatabaseIds=' + sourceDatabaseIds + '&pageType=' + pageType, options)
      .toPromise();
  }


  getTrainersToolbox() {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/GetTrainersToolbox', options)
      .toPromise();
  }

  getInformationByType(infoId, type) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information?id=' + infoId + '&type=' + type, options)
      .toPromise();
  }


  getRegulatoryActivity(sourceDatabaseIds) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<SearchModel>(EhsHeroConfig.endpoint + '/Information/RegulatoryActivity?databaseIds=' + sourceDatabaseIds + '&userName=' + this.mbDataService.user.UserName, options).toPromise();
  }

  getAllResouces(sourceDatabaseIds) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<SearchModel>(EhsHeroConfig.endpoint + '/Information/AllResources?databaseIds=' + sourceDatabaseIds, options).toPromise();
  }

  getRegulatoryActivityDetails(informationId) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get(EhsHeroConfig.endpoint + '/Information/RegulatoryActivity/' + informationId, options)
    .toPromise();
  }

  getResourceDetails(informationId) {
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get(EhsHeroConfig.endpoint + '/Information/ResourceDetails/' + informationId, options)
    .toPromise();
  }
  
  getInformationMostPopular() {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/MostPopular', options)
      .toPromise();
  }

  getTopInformation(infoTypeId, top) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + '&top=' + top + "&sourceDatabaseIds=" + this.mbDataService.dataAccessLevels(), options)
      .toPromise();
  }

  getNews(infoId) {
    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    var result = this.http.get<Information>(EhsHeroConfig.endpoint + '/Information?infoTypeId=1&infoId=' + infoId, options)     
      .toPromise();

    return result;
  }

  saveNewsArticle(info: Information) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.post(EhsHeroConfig.endpoint + '/Information', info, options)
      .toPromise();
  }

  getBookmarks(queryType, id) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    if (id == null) {
      id = this.mbDataService.user.UserId;
    }

    return this.http.get<Bookmark[]>(EhsHeroConfig.endpoint + '/Bookmark?id=' + id + '&queryType=' + queryType + "&applicationId=2", options);
  }

  getBookmarkFolders(databaseId) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<BookmarkFolder[]>(EhsHeroConfig.endpoint + '/BookmarkFolder?userId=' + this.mbDataService.user.UserId + "&applicationId=2", options)
     .toPromise();
  }

  updateMyBookmark(myBookmark: Bookmark) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.post(EhsHeroConfig.endpoint + '/Bookmark', myBookmark, options)
      .toPromise();
  }

  addBookmarkFolder(name: string, bookmarkFolderId: number) {

    let data: any;

    data = {
      "UserId": this.mbDataService.user.UserId,
      "Name": name,
      "BookmarkFolderId": bookmarkFolderId,
      "ApplicationId": 2
    };

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    let body = JSON.stringify(data);

    return this.http.post(EhsHeroConfig.endpoint + '/BookmarkFolder', body, options);
  }
  deleteBookmarkFolder(bookmarkFolderId: number) {


    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.delete(EhsHeroConfig.endpoint + '/BookmarkFolder?bookmarkFolderId=' + bookmarkFolderId, options);
  }

  deleteMyBookmark(bookmarkId: number) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.delete(EhsHeroConfig.endpoint + '/Bookmark?bookmarkId=' + bookmarkId, options);
  }

  saveBookmark(informationId: number,
    title: string,
    userId: number,
    bookmarkFolderId: number,
    informationTypeId: number,
    topicId: number,
    bookmarkId: number,
    categoryId: number = null,
    parentInformationTypeId: number = null,
  ) {

    let data: any;

    data = {
      "BookmarkId": bookmarkId,
      "Title": title,
      "InformationId": informationId,
      "BookmarkFolderId": bookmarkFolderId,
      "UserId": userId,
      "TopicId": topicId,
      "InformationTypeId": informationTypeId,
      "ApplicationId": 2,
      "stateName": "",
      "CategoryId": categoryId,
      "ParentInformationTypeId": parentInformationTypeId
    };

    let body = JSON.stringify(data);

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.post(EhsHeroConfig.endpoint + '/Bookmark', body, options);

  }


  saveCustomImage(customImage: FormData) {

    let headers: any = {
      //'Accept': 'application/json',
    //  'Token': this.token,
      //'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }
    customImage.append('ApplicationId', EhsHeroConfig.applicationId);
    customImage.append('UserId', this.mbDataService.user.UserId.toString());

    return this.http.post<UrlLink>(EhsHeroConfig.endpoint + '/SiteCustomization/SaveImage', customImage, options)
    .toPromise();
  }

  getCustomImages() {
    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }

    return this.http.get<CustomImage>(EhsHeroConfig.endpoint + '/SiteCustomization/GetImages?userId=' + this.mbDataService.user.UserId + '&applicationId=' + EhsHeroConfig.applicationId, options)
    .toPromise();
  }

  getMembershipbaseUsers() {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<User[]>(EhsHeroConfig.endpoint + '/MembershipBase?userId=' + this.mbDataService.user.UserId, options)
      .toPromise();

  }

  getFilteredVideos(customerId,includeLibrary) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }
    return this.http.get<VodVideo[]>(EhsHeroConfig.endpoint + '/VODVideo/GetFilteredVODVideos?includeLibrary=' + includeLibrary +"&customerId=" + customerId, options)
      .toPromise();

  }

  getVideos(includeLibrary) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }
    return this.http.get<VodVideo[]>(EhsHeroConfig.endpoint + '/VODVideo/GetVODVideos?includeLibrary=' + includeLibrary, options)
      .toPromise();

  }

  getVideoLibraries() {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }
    return this.http.get<VodLibrary[]>(EhsHeroConfig.endpoint + '/VODVideo/GetVODLibraries', options)
      .toPromise();

  }

  getIndustryCollectionDetails(category: string) {
    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<IndustryCollectionDetails>(EhsHeroConfig.endpoint + '/IndustryCollections/' + category, options)
      .toPromise();
  }

  getIndustryCollectionDetailsWithExclusions(category: string, exclusions: string) {
    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<IndustryCollectionDetails>(EhsHeroConfig.endpoint + '/IndustryCollections?collectionUrl=' + category + "&excludedDocTypes=" + exclusions, options)
      .toPromise();
  }

  getSDSSearch(product, manufacturer, cas) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<SDSSearch[]>(EhsHeroConfig.endpoint + '/SDSSearch?product=' + product + "&manufacturer=" + manufacturer + "&cas=" + cas + "&pageNumber=1", options)
      .toPromise();

  }
  getNewSDSSearch(product, manufacturer, cas){
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get<SDSNewSearch[]>(EhsHeroConfig.endpoint + '/SDSSearch/NewSearch?product=' + product + "&manufacturer=" + manufacturer + "&cas=" + cas + "&pageNumber=1", options)
     .toPromise();
  }
  getSdsPdf(name,libraryDocumentId){
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
   return this.http.get(EhsHeroConfig.endpoint + "/SDSSearch/RetrieveMSDSContent?name=" + encodeURIComponent(name) + "&libraryDocumentId=" + libraryDocumentId, { headers: headers, responseType: 'blob' }).pipe(map(
    res => {
        return new Blob([res], { type: 'application/pdf' })
    }));

  }

  saveLead(emailAddress, da) {

    let es = (da) ? "&emailSubscriptionTypeId=05,70" : "&emailSubscriptionTypeId=70";

    let qs =
      "?email=" + emailAddress +
      "&site=EHS" +
      "&Origin=Spill-Tool" +
      "&Source=WBZ" +
      "&Effort=11" +
      "&LeadSource=WN Web Newsletter" +
      es;

    $.ajax({

      url: "http://webservice.blr.com/Services/LeadService.svc/pox/CreateAndSaveLead" + qs,

      error: function (xhr, status, error) {

        console.log("lead error", xhr.responseText);
      }
      , success: function (xhr, status, error) {

        console.log("lead success", xhr);
      }
    });
  }

  getMemberships(customerId: number) {
      return this.http.get(EhsHeroConfig.endpoint + '/CustomerCentral/GetMemberships?customerId=' + customerId)
          .toPromise();
  }

  reindexType(sourceInfoTypeId) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.get(EhsHeroConfig.endpoint + '/Information/BuildIndex?site=ehs&databaseIds=3&informationTypeId=' + sourceInfoTypeId + '&destructive=true', options)
      .toPromise();
  }  

  downloadInformationFile(id,fileName,documentType)
  {
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }



    let params: any = {id: id};
    if (fileName)
    {
       params = {id: id, fileName: fileName};
    }

       let options =  {
      headers: headers,
      params: params,
      observe: "response" as 'body',
      responseType: 'blob' as 'json'
   }
      
          return this.http.get<any>(EhsHeroConfig.endpoint + '/RenderDocument', options).subscribe(respData => {
           
            this.processDownloadResponse(respData,documentType);
        }, error => {
      
        });
      
  }

  processDownloadResponse(respData,documentType)
  {
    var headers = respData.headers;
    var contentDisposition = headers.get('content-disposition');
    var downloadName="download." + documentType;
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) { 
        downloadName = matches[1].replace(/['"]/g, '');
      }
    }
    
    this.downLoadFile(respData.body, documentType, downloadName);
  }
  downLoadFile(data: any, type: string, downloadName: string) {
    var blob = new Blob([data], { type: type.toString() });
    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = downloadName;
    link.click();
    window.URL.revokeObjectURL(downloadURL);
    link.remove();
  }

  getRegUpdateSettings(userId?) {

    let headers: any = {
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

   if (userId == null)
   {
      userId = this.mbDataService.user.UserId;
   }
   var states = this.mbDataService.user.States;
   var market = this.GetUserMarket();

    return this.http.get<RegUpdateSetting>(EhsHeroConfig.endpoint + '/RegUpdate/GetRegUpdateSetting?userId=' + userId + '&states=' + states + '&market=' + market, options);
  }

  sendRegUpdateEmail(email) {

    let body = encodeURIComponent(email);
    let headers: any = {
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.post<RegUpdateSetting>(EhsHeroConfig.endpoint + '/RegUpdate/SendToEmail?email=' + body, options).toPromise();
  }

  

  GetUserMarket()
  {
    return this.mbDataService.dataAccessLevels(this.mbDataService.user.Market)
  }

  saveRegUpdateSettings(regUpdateSettings)
  {

    let body = JSON.stringify(regUpdateSettings);
    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.post(EhsHeroConfig.endpoint + '/RegUpdate/SaveRegUpdateSetting', body, options).toPromise();
  }

}
