import { Component, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'chart-builder-widget',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './chart-builder.html'
})

export class ChartBuilderWidgetComponent {
  isLimitedAccess: boolean;
  modalRef: BsModalRef;

  constructor(private _mbService: MembershipDataService,
    public modalService: BsModalService, private _router: Router) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {


       this._router.navigateByUrl("/chart-builder");


  }
  openModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
 }
}

