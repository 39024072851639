export class Widget {
  Id: number;
  Name: string;
  InclusionCode: string;
  PositionOnHome: number;
  PositionOnTools: number;
  VisibleOnTools: boolean;
  VisibleOnHome: boolean;
  Header: string;
  Body: string;
  Updated: boolean;
  IsCustom: boolean;
  OwnerId: number;
  UserId: number;
  WidthClass: string;
  Active: boolean;
  ActiveOnHome: boolean;
  ActiveOnTools: boolean;
}
