import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { EhsHeroConfig } from '../../environments/environment'
import 'jquery';
import { NgLocalization } from '@angular/common';

@Component({
  selector: 'snehs-login',
  templateUrl: './snehs-login.component.html',
  styleUrls: ['./login.component.css'],
})

export class SNEHSLoginComponent {

  userNameValue: string = null;
  passwordValue: string = null;
  loginResponse: string;
  authenticationSystem: string = "WA";
  route: string = "";
  copy: string = "EHS Hero";
  authToken: string;
  forgotLink: string;

  constructor(
    public _router: Router,
    public _route: ActivatedRoute,
    private _mbService: MembershipDataService
  ) { }

  ngOnInit() {
    this.forgotLink = EhsHeroConfig.ccBaseURL;
    if (this._route.snapshot.queryParams["auth_token"] != null) {

      this.authToken = this._route.snapshot.queryParams["auth_token"];

      //needec for autologin from freetrial, since authtoken forces page reload clearing value stored in shared service
      let storedSSOValue = localStorage.getItem("ssoNeeded");
      if (storedSSOValue) {
        localStorage.removeItem("ssoNeeded");
        this._mbService.ssoNeeded = true;
      }
      this.authenticate(null, this.authToken, this._mbService.ssoNeeded);

    } else {
      this._route.params.subscribe(params => {

        let path = 'dashboard';

        if (params["route"] != null) {
          this.route = params["route"];
        }

        if (this._mbService.ssoNeeded) {
          $('.imgloader').css('visibility', 'visible');
          $('#btnSubmit, #loginForm, #infoBox').hide();
          localStorage.setItem("ssoNeeded", "true");
          this.getSessionRedirectUrl("", "", this._mbService.ssoNeeded);
        }

        switch (this.route) {
          case 'ehb':
            this.copy = "Employee Handbook Builder";
            this.authenticationSystem = "WA";
            break;
          case 'jdm':
            this.copy = "Job Description Manager";
            path = 'job-description-manager';
            break;
          case 'hotline':
            this.copy = "HR Hotline";
            path = 'hotline';
            break;
          case 'slcb':
            this.copy = "State Law Chart Builder";
            path = 'state-law-chart-builder';
            break;
          case 'flsa':
            this.copy = "FLSA Audit Tool";
            this.authenticationSystem = "WA";
            break;
          default:
            this.copy = "EHS Hero";
            break;
        }

        if (this._mbService.userIsEHSAuthenticated) {

          switch (this.route) {
            case 'ehb':
              location.href = "http://handbookbuilder.blr.com/checkssologin.aspx";
              break;
            case 'flsa':
              location.href = "https://flsa.smarthrmanager.com/CheckSSOlogin.aspx";
              break;
          }

          this._router.navigate([path]);
        }

      });
    }

  }

  login(userName, password, authSystem = null) {

    if (userName == "" || password == "" || userName == null || password == null) {

      this.loginResponse = "Username or password incorrect";

      return;
    }

    if (authSystem != null)
      this.authenticationSystem = authSystem;

    this.getSessionRedirectUrl(userName, password)
  }

  getSessionRedirectUrl(userName: string, password: string, useId: boolean = false) {
    let _this = this;
    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit, #loginForm, #infoBox').hide();
    //localStorage.setItem("un", userName);
    //localStorage.setItem("pw", password);
    $.ajax({
      type: 'POST',
      url: EhsHeroConfig.endpoint + "/account/GetSessionRedirectUrl",
      xhrFields: {
        withCredentials: true
      },
      data: {
        UserName: userName,
        Password: password,
        AuthenticationSystem: this.authenticationSystem,
        ApplicationId: "SN_EHS"
      },
      crossDomain: true,
      success: function (src) {

        location.href = src;
      }, error: function (err) {
        console.log(err)
      }
    });
  }

  authenticate(authSystem = null, authToken = null, useId: boolean = false) {
    //this.userNameValue = localStorage.getItem('un');
    //this.passwordValue = localStorage.getItem('pw');
    let _this = this;
    let endpoint = (useId) ? "/account/SsoSessionIdLogin" : "/account/SsoSessionLogin"
    if (useId)
      this._mbService.ssoNeeded = false;
    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit, #loginForm, #infoBox').hide();

    $.ajax({
      type: 'POST',
      url: EhsHeroConfig.endpoint + endpoint,
      xhrFields: {
        withCredentials: true
      },
      data: {
        UserName: null,
        Password: null,
        AuthenticationSystem: this.authenticationSystem,
        ApplicationId: EhsHeroConfig.applicationId,
        AuthToken: authToken
      },
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        
        if (data == "Username or password incorrect") {

          _this.loginResponse = "Username or password incorrect";

          $('.imgloader').css('visibility', 'hidden');
          $('#btnSubmit, #loginForm, #infoBox').show();

          return;
        }

        var account = data;

        //authentication failed
        if (account == null) {
          _this._router.navigate(['login']);
          return false;
        }

        if (!account.Authorized || account.Authorized == "false") {

          if (account.ResponseMessage != null && account.ResponseMessage != "") {

            _this.loginResponse = account.ResponseMessage;
            if(_this.loginResponse.indexOf("expired") > -1){ //super clunky solution here but so's this whole page
              _this.loginResponse = "Your subscription is expired. Please contact your Safety National representative.";
            }else if(_this.loginResponse.indexOf("locked") > -1){
              _this.loginResponse = "The username you entered is locked. Please verify you entered the correct username and contact your Safety National representative if you need assistance.";
            }else if(_this.loginResponse.indexOf("Access denied") > -1){
              _this.loginResponse = "Invalid login. Please try again.";
            }
            
            $('.imgloader').css('visibility', 'hidden');
            $('#btnSubmit, #loginForm, #infoBox').show();
            return;
          }
          else {

            _this.loginResponse = "We're sorry, your credentials cannot be processed at this time. Please try again.";

          }

          if ((account.SubscriptionType == "EHS Plan Builder Safety Enviro" || account.SubscriptionType == "EHS Plan Builder Safety" || account.SubscriptionType == "EHS Plan Builder Enviro")) {

            _this.loginResponse = "Access denied. No subscription found. Please verify you entered the correct username and contact your Safety National representative if you need assistance.";

            $('.imgloader').css('visibility', 'hidden');
            $('#btnSubmit, #loginForm, #infoBox').show();

            return;

          }else{
            $('.imgloader').css('visibility', 'hidden');
            $('#btnSubmit, #loginForm, #infoBox').show();
            return;
          }

          //if (!(account.SubscriptionType == "EHS Chart Builder Safety Enviro" || account.SubscriptionType != "EHS Chart Builder Safety" || account.SubscriptionType != "EHS Chart Builder Enviro")) {

          //  $('.imgloader').css('visibility', 'hidden');
          //  $('#btnSubmit, #loginForm, #infoBox').show();

          //  return;

          //}

        }

        switch (_this.route) {
          case 'ehb':
            location.href = "http://handbookbuilder.blr.com/checkssologin.aspx";
            break;
          case 'flsa':
            location.href = "https://flsa.smarthrmanager.com/CheckSSOlogin.aspx";
            break;
        }

        _this._mbService.setUpAccount(account);
        _this.processLogin(_this, account);

      }
    });
  }

  processLogin(_this, account) {

    if (!account.Authorized) {
      let navigateToTools: boolean = false;

      if (account.SubscriptionType == "EHS Chart Builder Safety Enviro") {
        navigateToTools = true;
        _this._router.navigate(['chart-builder']);
      }
      if (account.SubscriptionType == "EHS Chart Builder Enviro") {
        navigateToTools = true;
        _this._router.navigate(['chart-builder']);
      }
      if (account.SubscriptionType == "EHS Chart Builder Safety") {
        navigateToTools = true;
        _this._router.navigate(['chart-builder']);
      }

      if (!navigateToTools)
        _this.loginResponse = "This account is not authorized.";
    }
    else {

      _this._mbService.userIsEHSAuthenticated = true;
      _this._mbService.userIsHRAuthenticated = false;

      _this._router.navigate(['dashboard']);

      switch (_this.route) {
        case 'home':
          _this._router.navigate(['dashboard']);
          break;
        case 'jdm':
          _this._router.navigate(['job-description-manager']);
          break;
        case 'hotline':
          _this._router.navigate(['hotline']);
          break;
        case 'slcb':
          _this._router.navigate(['state-law-chart-builder']);
          break;
        default:
          _this._router.navigate(['dashboard']);
          break;
      }
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
