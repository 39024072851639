export const environment = {
  production: true
};

export var EhsHeroConfig = {
  "endpoint": window.location.protocol + "//" + window.location.hostname + "/api",
  "marketingSite": "http://ehshero.blr.com",
  "trainingTypes": "2,3,4,5,6,7,8,9",
  "ehsSafetyTrainingTypes": "2,3,6,7,8,9,20,21,22,26",
  "ehsEnviroTrainingTypes": "2,3,6,7,8,9,21,22",
  "ehsSafetyEnviroTrainingTypes": "2,3,6,7,8,9,20,21,22,26",
  "applicationId": "AC408839-06B7-4ED4-8615-47BBE7E4894B",
  "origin": window.location.hostname,
  "isSecure": "false",
  "logOutURL": "https://accounts.blr.com/account/logout?return_url=",
  "ccBaseURL": "https://accounts.blr.com/",
  "planBuilderURL": "https://ehsplanbuilder.blr.com/#/login",
  "trainingTodayUrl": "https://gold.trainingtoday.com/account/login",
  "basicsafeURL":"https://basicsafe.blr.com",
  "usesInquiry":true,
  "newSDSSearch":true
}

export var authConfig = {
  "endpoint": "https://auth.blr.com/"
}

export var config = {
  "profileManagerService": "https://userprofileapi.blr.com",
  "applicationId": "5DFB4010-E889-4BA6-BED7-F69884981EEC"
}
