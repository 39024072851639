import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service';
import { Router } from '@angular/router';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';

@Component({
  selector: 'regulatory-activity',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './regulatory-activity.html'
})

export class RegulatoryActivityWidgetComponent {
  isLimitedAccess: boolean;
  modalRef: BsModalRef;

  constructor(private _mbService: MembershipDataService, private router: Router,
    public modalService: BsModalService) { }
  
    openModal(modal: TemplateRef<any>) {
      this.modalRef = this.modalService.show(modal);
   }
  

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {
    this.router.navigate(["/regulatory-activity"]);    
  }
}

