import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { UserProfileService } from '../shared/services/user-profile.service'
import {HttpClient} from '@angular/common/http'
import { EhsHeroConfig } from '../../environments/environment'

@Component({
  selector: 'autolink',
  templateUrl: './autolink.component.html',
  styleUrls: ['./login.component.css'],
})

export class AutoLinkComponent {
    authenticationSystem: string = "WA";
    authToken: string;
    message: string = "Logging you in..."

    constructor(
        public _router: Router,
        public _route: ActivatedRoute,
        private _mbService: MembershipDataService,
        private http: HttpClient,
        private _ups: UserProfileService
      ) { }
    
      ngOnInit() {
        $('.imgloader').css('visibility', 'visible');
        if (this._route.snapshot.queryParams["auth_token"] != null) {
          this.authToken = this._route.snapshot.queryParams["auth_token"];
          this.authenticate();
        }else{
            this.message = "Something went wrong. No auth token provided by CustomerCentral."
            $('.imgloader').css('visibility', 'hidden');
        }
      }

      authenticate() {
        let _this = this;
    
        $.ajax({
          type: 'POST',
          url: EhsHeroConfig.endpoint + "/account/AutoLinkLogin",
          xhrFields: {
            withCredentials: true
          },
          data: {
            UserName: null,
            Password: null,
            AuthenticationSystem: this.authenticationSystem,
            ApplicationId: EhsHeroConfig.applicationId,
            AuthToken: this.authToken
          },
          crossDomain: true,
          success: function (data, textStatus, jqXHR) {
            var account = data;
    
            //authentication failed
            if (account == null) {
              this._router.navigate(['login']);
              return false;
            }
    
            if (account.Authorized == false || account.Authorized == "false") {
    
              if (account.ResponseMessage != "") {
                this.message = account.ResponseMessage;
              } else {
                this.message = "Something went wrong. No error response provided. "
              }
              $('.imgloader').css('visibility', 'hidden');
              return false;
            }
    
            _this._mbService.setUpAccount(account);
            _this.processLogin(_this);
          }
        });
      }
    
      processLogin(_this) {
          _this._mbService.userIsHRAuthenticated = false;
          _this._mbService.userIsEHSAuthenticated = true;
          if (localStorage.getItem('ru')) {
            var url = localStorage.getItem('ru');
            localStorage.removeItem('ru');
            window.location.href = window.location.protocol + '//' + window.location.hostname + '/hr' + url;
          } else {
            _this._router.navigate(['dashboard']);
          }
      }

}
