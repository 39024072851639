
export class RegUpdateSetting {
    RegUpdateSettingId: number;
    UserId: number;
    Active: boolean;
    AllStates: boolean;
    States: RegUpdateSelectedState[];
    AllCategories: boolean;
    Categories: RegUpdateCategory[];
  }
export class RegUpdateSelectedState{
    StateId: number;
    StateDescription: string;
    Selected: boolean;
}

export class RegUpdateCategory{
  CategoryId: number;
  CategoryDescription: string;
  Selected: boolean;
  RegUpdateMarketId: string;
}