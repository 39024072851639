
<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/helpwidget.png" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Help Resources</div>

  <div class="body-content-italic">User Guide, Tutorial Videos, Feedback Survey, Contact Numbers, and more...</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" href="https://interactive.blr.com/ehs-hero-help-page" rel="noopener noreferrer" target="_blank">Get Started</a>
</div>


<!--<p style="text-align: center;"><span style="font-family: Arial, Helvetica, sans-serif;"><br></span></p>

<p style="text-align: center;">
  <span style="font-family: Arial,Helvetica,sans-serif;"><span style="font-size: 24px;"><strong>Help Resources</strong></span></span>
</p>

<p style="text-align: center;"><span style="font-family: Arial,Helvetica,sans-serif;"><br></span></p>

<p style="text-align: center;"><span style="font-family: Arial,Helvetica,sans-serif;"><a href="https://interactive.blr.com/ehs-hero-help-page" rel="noopener noreferrer" target="_blank"><img class="fr-dib" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/helpwidget.png" style="width: 80px;"></a></span></p>

<p style="text-align: center;"><span style="font-family: Arial,Helvetica,sans-serif;"><br></span></p>

<p style="text-align: center;"><a href="https://interactive.blr.com/ehs-hero-help-page" rel="noopener noreferrer" target="_blank"><span style="color: rgb(0, 0, 0);"><span style="font-family: Arial, Helvetica, sans-serif;"><em>User Guide, Tutorial Videos, Feedback Survey, Contact Numbers, and&nbsp;</em></span></span><span style="color: rgb(0, 0, 0);"><span style="font-family: Arial, Helvetica, sans-serif;"><em>more...</em></span></span></a></p>-->
