<div *ngIf="myAccount != null">

  <h2>My Account Summary</h2>

  <div>
    <label>Subscription:</label> {{myAccount.SubscriptionType}}
  </div>
  <div>
    <label>Expiration Date:</label> {{myAccount.ExpirationDate}}
  </div>
  <div>
    <label>Username:</label> {{myAccount.UserName}}
  </div>
  <div>
    <label>Email:</label> {{myAccount.EmailAddress}}
  </div>
  <div>
    <label>First Name:</label> {{myAccount.FirstName}}
  </div>
  <div>
    <label>Last Name:</label> {{myAccount.LastName}}
  </div>
  <div>
    <label>Company:</label> {{myAccount.Company}}
  </div>

  <div *ngIf="states != null && allStates == ''">
    <label>States:</label>
    {{ states }}
  </div>
  <div *ngIf="states != null && allStates == 'All'">
    <label>States:</label> All States
  </div>
  <br />

  <a *ngIf="showForgotLink" class="btn btn-default" [href]="forgotLink" target="_blank">Change Password</a>

</div>
