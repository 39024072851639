import { Component } from '@angular/core';
import { Router} from '@angular/router';


import { MembershipDataService } from '../shared/services/membership.data.service'

@Component({
  selector: 'compliance-calendar',
  templateUrl: './compliance-calendar.component.html',
  styleUrls: ['./compliance-calendar.component.css']
})

export class ComplianceCalendarComponent {

  constructor(private _mbService: MembershipDataService, private router: Router) { }

  ngOnInit() {

    if (!this._mbService.hasTool("ComplianceCalendar") || !this._mbService.customPermissions.GeneralPermissions.ComplianceCalendar.Visible)
      this.router.navigate(["error/403"]);

  }
}
