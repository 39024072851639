import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'
import { Router } from '@angular/router';

@Component({
  selector: 'all-training',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './all-training.html'
})

export class AllTrainingWidgetComponent {
  isLimitedAccess: boolean;

  constructor(private _mbService: MembershipDataService, private router: Router,) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {
    this.router.navigate(["/training"]);    
  }
}

