<div *ngIf="!details && loading" class="row" style="text-align:center;">
  <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
</div>


<div *ngIf="!details && newsArticles != null">
  <h1>News</h1>
  <div class="white-well">
    <div *ngFor="let article of newsArticles">

      <a (click)="viewArticle(article)" class="link" style="font-weight:bold;">{{article.Title}}</a>
      <div>{{article.PubDate  | date: 'EEEE, MMMM d, y'}}</div><br />
      <div [innerHTML]="article.Teaser"></div>

      <div style="margin-top:10px; margin-bottom:15px;">
        <a (click)="viewArticle(article)" class="link" style="font-weight:bold;">Read More</a>
      </div>

      <hr />
    </div>
  </div>
</div>

<div class="row" *ngIf="details && selectedArticle != null">
  <div class="col-lg-12">
    <h1>{{selectedArticle.Title}}</h1>
    <div>{{selectedArticle.PubDate  | date: 'EEEE, MMMM d, y'}}</div><br />
    <div style="font-weight:bold;" *ngIf="selectedArticle.Author != null">By {{selectedArticle.Author}}<br /><br /></div>
  </div>
</div>

<div class="row" *ngIf="details && selectedArticle != null">
  <div class="col-lg-8">
    <div [innerHTML]="selectedArticle.Body | safeHtmlNews"></div>

    <div style="margin-bottom:10px;padding-top:20px" *ngIf="newsArticles!=null">
      <a (click)="previous()" class="btn-white" style="margin-right:10px;">Previous</a>
      <a (click)="next()" class="btn-white">Next</a>
    </div>

  </div>
  <div class="col-lg-4">

    <div id="articleScroll">
      <span *ngFor="let item of newsArticles" [id]="item.InformationId" class="list-item">
        <a (click)="viewArticle(item)" class="link">
          <table>
            <tbody>
              <tr>
                <td *ngIf="selectedArticle != null && selectedArticle.InformationId == item.InformationId" ><div style="background-color:#588a7e; height:90px;"><img style="height:inherit;" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/arrow-left-transparent.png" /></div></td>
                <td style="width:30px; padding-left:4px; padding-right:6px;" *ngIf="selectedArticle != null && selectedArticle.InformationId != item.InformationId"><div style="height:90px;"><img style="height:inherit;" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/arrow-left-gray-transparent.png" /></div></td>
                <td style="width:30px; padding-left:4px; padding-right:6px;" *ngIf="selectedArticle == null"><div style="height:90px;"><img style="height:inherit;" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/arrow-left-gray-transparent.png" /></div></td>
                <td style="width:230px;" class="body-content link-no-color">{{item.Title}}</td>
              </tr>
            </tbody>
          </table>
        </a>
      </span>
    </div>

  </div>
</div>

<div *ngIf="selectedArticle != null" style="display:none;">Unified ID: {{selectedArticle.SourceInfoId}}</div>