
import { CCMembership } from '../types/cc-membership'
import { CCUser } from '../types/cc-user'
import { CCAddress } from '../types/cc-address'

export class CCCustomer {
  CustomerId: number; //not sure why these types are different in EHS vs HR
  FirstName: string;
  LastName: string;
  UserName: string;
  Company: string;
  MembershipIds: Array<number>;
  MembershipId: number;
  ProfileAddressId: number;
  UserId: number;

  id: number;
  parent_customer_id: number;
  created_in_application_id: number;
  type: string;
  timezone: string;
  users: CCUser[];
  addresses: CCAddress[];
  profileAddress: CCAddress;
  IsSelected: boolean = false;
  addOns: CCMembership[];  
  Email: string;  
  Markets: string;  
  PermissionTierId: number;
  Inclusions: Array<string>;
  Memberships: any;
}
