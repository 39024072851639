import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

//types
import { KeyValue } from '../../shared/types/key-value.type'
import { UserProfile } from '../../shared/types/user-profile.type'

//services
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service';
import { UserProfileService } from '../../shared/services/user-profile.service';
import { MembershipDataService } from '../../shared/services/membership.data.service'

import { UserManagementService } from '../../shared/services/user-management.service';
import { Widget } from '../../shared/types/widget.type';
import { EhsHeroConfig } from '../../../environments/environment';

@Component({
  selector: 'edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['../../../../node_modules/dragula/dist/dragula.css', './edit-widget.component.css']
})

export class AddWidgetComponent {

  customWidgets: Widget[]=[];
  selectedCustomWidget: Widget;
  form: FormGroup;
  isEdit: boolean = false;
  cwbag: Array<string> = new Array<string>();
  nameErrorMessage: string;
  bodyErrorMessage: string;

  userProfile: UserProfile = null;
  useParent: boolean = false;
  hasAccess: boolean = false;

  public options: Object = null;

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: EhsHeroSvc,
    private fb: FormBuilder,
    private _mb: MembershipDataService,
    private _up: UserProfileService,
    private _userManagementService: UserManagementService
  ) {

    this.form = this.fb.group({
      Active: true,
      Header: null,
      Name: null,
      WidthClass: 'col-lg-3',
      ActiveOnHome: true,
      ActiveOnTools: true,
      Body: null,
      Id: 0,
      UserId: this._mb.user.UserId
    });

    this.hasAccess = this._mb.getVisibility('Custom Widgets');

    this._route.params.subscribe(params => {

      if (params["id"] != null) {

        this.getCustomWidgetById(params["id"]);

        this.isEdit = true;

      }

    });

  }

  ngOnInit() {

    this.userProfile = this._mb.userProfile;
    let parentUserProfile = this._mb.parentUserProfile;
    if(parentUserProfile != null && parentUserProfile != undefined && parentUserProfile.AdminPreferences != null && parentUserProfile.AdminPreferences != undefined){
      if(parentUserProfile.DashboardLock){
        this.useParent = true;
      }
    }
    this.options = {
      imageUploadURL: EhsHeroConfig.endpoint + '/Upload',
      imageUploadMethod: 'POST',
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      key: '1wzeB3zuv==',
      fileUploadURL: EhsHeroConfig.endpoint + '/Upload/PostFile',
      fileUploadMethod: 'POST',
      fileAllowedTypes: ['*']
    };
  }

  getCss(colClass) {
    if (colClass == 1) {
      colClass = 'col-lg-3';
    }
    return "bag-item " + colClass;
  }

  async getCustomWidgetById(id) {
    var customWidgets = (await this._userManagementService.getWidgets()).filter(f => f.IsCustom);
    if (customWidgets.length > 0) {
      if (customWidgets.filter(f => f.Id == id).length > 0) {

        this.selectedCustomWidget = customWidgets.filter(f => f.Id == id)[0];

        this.form = this.fb.group({
          Active: this.selectedCustomWidget.Active,
          Header: this.selectedCustomWidget.Header,
          Name: this.selectedCustomWidget.Name,
          WidthClass: this.selectedCustomWidget.WidthClass,
          ActiveOnHome: this.selectedCustomWidget.ActiveOnHome,
          ActiveOnTools: this.selectedCustomWidget.ActiveOnTools,
          Body: this.selectedCustomWidget.Body,
          Id: this.selectedCustomWidget.Id,
          UserId: this._mb.user.UserId
        });
      }
    }
  }

  async saveCustomWidget() {

    this.nameErrorMessage = "";
    this.bodyErrorMessage = "";
    let valid = true;

    let userId = this._mb.user.UserId;
    var customWidgets = (await this._userManagementService.getWidgets()).filter(f => f.IsCustom);

    this.form.value['UserId'] = userId;
    this.form.value['OwnerId'] = userId;

    if (this.form.value['Name'] == null || this.form.value['Name'] == "" || this.form.value['Name']  == undefined) {
      this.nameErrorMessage = "Name is required."
      valid = false;
    }
    if (this.form.value['Body'] == null || this.form.value['Body'] == "" || this.form.value['Body'] == undefined) {
      this.bodyErrorMessage = "Widget content is required."
      valid = false;
    }

    if (customWidgets.filter(f => f.Name == this.form.value['Name']).length > 0 && this.form.value['Id'] == 0) {
      this.bodyErrorMessage = "Widget Name already in use. Please choose a different name."
      valid = false;
    }

    if (!valid) {
      return;
    }

    let data = {
      Model: this.form.value,
      ApplicationId: 2,
      IsParent:this._mb.userProfileTypeId == 1
    }

    this._userManagementService.saveCustomWidget(data).subscribe((data) => {
      
      let widget = data;

      if (this._mb.user.Widgets.find(f => f.Id == widget.Id)) {
        let index = this._mb.user.Widgets.findIndex(f => f.Id == widget.Id);
        this._mb.user.Widgets[index] = widget;
      }
      else {
        this._mb.user.Widgets.push(widget);
      }
      this._router.navigate(['/custom-settings']);
    });

  }

  deleteCustomWidget() {
    if (confirm("Are you sure you want to delete this widget?")) {
      this._userManagementService.deleteCustomWidget(this.selectedCustomWidget.Id).subscribe((data) => {
        if (this._mb.user.Widgets.find(f => f.Id == this.selectedCustomWidget.Id)) {
          let index = this._mb.user.Widgets.findIndex(f => f.Id == this.selectedCustomWidget.Id);
          this._mb.user.Widgets.splice(index, 1);
        }
        this._router.navigate(['/custom-settings']);
      });
    }
  }
}
