<div *ngIf="regulations != null && !loading">
  <h1 *ngIf="hasEnviro && hasSafety">Federal Environmental and Safety Regulations</h1>
    <h1 *ngIf="hasSafety && !hasEnviro">Federal Safety Regulations</h1>
    <h1 *ngIf="hasEnviro && !hasSafety">Federal Environmental Regulations</h1>
</div>

<!--<div *ngIf="regulations == null && !loading != null" style="margin-top:20px;">
  <h2>No Results</h2>
</div>-->
<!--<div class="search col-md-3" style="margin-bottom:15px; padding-right: 0;">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Keyword" (keyup.enter)="filterKeyword(keyword.value)" [value]="keywordValue" #keyword>
    <span class="input-group-btn" (click)="filterKeyword(keyword.value)">
      <button class="btn btn-default" type="submit">
        <i class="fa fa-search"></i>
      </button>
    </span>
  </div>
</div>-->

<div [hidden]="regulations == null && loading" style="margin-top:20px;clear: both;" class="row">


  <div class="col-lg-3" style="margin-bottom:20px;">
    <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
    <div class="outer-filter-panel">

      <collapse-header dataTargetId="#cfr" headerTitle="Federal"></collapse-header>
      <div id="cfr" class="collapse in" style="overflow-y:auto;">
        <table class="table" *ngIf="CFRs != null">
          <tbody>
            <tr *ngFor="let item of CFRs">
              <td>
                <div class="checkbox">
                  <label [tooltip]="item.Description" placement="right" container="body" containerClass="customTooltip"><input type="checkbox" (click)="filterCFR(item.CFR, $event.target.checked)" [checked]="item.Checked">{{item.CFR}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="hasEnviro">
        <collapse-header dataTargetId="#stateRegulations" headerTitle="State Regulations"></collapse-header>
        <div id="stateRegulations"  class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table" *ngIf="StateRegulations != null">
            <tbody>
              <tr *ngFor="let item of StateRegulations">
                <td>
                  <a [href]="item.Link" rel="noopener noreferrer" target="_blank" (click)="StateRegulationClicked(item.State)">{{item.State}}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-9">
    <div class="fr-tab-container">
      <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag.Name)">
        <div class="inline-text">{{tag.Name}}</div>
        <div class="delete-button" style="height: 100%">
          <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
            <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="white-well">
      <!-- <table class="table table-striped federal-regulations-table" [mfData]="regulations" [mfSortBy]="getNaturalSort" mfSortOrder="asc" #mf="mfDataTable" [mfRowsOnPage]="15"> -->
       <table datatable class="table table-striped federal-regulations-table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="thead">
          <tr>
            <th style="min-width:65px">
              <a class="text-nowrap" style="cursor:pointer">Title <span></span> </a>
              
            </th>
            <th>
              <a class="text-nowrap" style="cursor:pointer">Part <span></span> </a>
              
            </th>
            <th>
              <a class="text-nowrap" style="cursor:pointer">Description <span></span> </a>
             
            </th>
            <th *ngIf="hasSafety && hasEnviro">
              <a class="text-nowrap" style="cursor:pointer">Source <span></span> </a>
             
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of regulations">
            <td>{{item.Title}}</td>
            <td><a [href]="item.Link" *ngIf="!isLimitedAccess" rel="noopener noreferrer" target="_blank">{{item.Section}}</a><a (click)="challengeModal.open()" *ngIf="isLimitedAccess" style="cursor:pointer;">{{item.Section}}</a></td>
            <td>{{item.Description}}</td>
            <td *ngIf="hasSafety && hasEnviro">{{getSource(item.DatabaseId)}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

</div>
<challenge-modal #challengeModal></challenge-modal>
