
  <div>
    <ng-container>
      <div class="body-content-margin-override">
        <div class="row">
          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Contractor Safety</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Contractor-Safety.png" alt="Contractor Safety" />
                <div class="tool-content">

                  <div class="body-content-italic">Training materials, policies, plans, and other essential information specific to contractors and multiemployer worksites.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Contractor-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Healthcare Safety</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Healthcare-Safety.png" alt="Healthcare Safety" />

                <div class="tool-content">

                  <div class="body-content-italic">Easy access to training and compliance information for safety and health professionals working in the healthcare industry.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Healthcare-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Hospitality and Food Service</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Hospitality-and-Food-Service.png" alt="Hospitality and Food Service" />

                <div class="tool-content">

                  <div class="body-content-italic">A collection of documents to help employers in the food service industry identify and control common hazards and train their employees.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Food-Service-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Municipal Worker Safety</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Municipal-Worker-Safety.png" alt="Municipal Worker Safety" />

                <div class="tool-content">

                  <div class="body-content-italic">Safety and health laws that apply to municipal workers and a collection of documents to help municipalities comply with regulations.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Municipal-Worker-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Retail Safety</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Retail-Safety.png" alt="Retail Safety" />

                <div class="tool-content">

                  <div class="body-content-italic">Use this resource center to identify and control common safety and health hazards in the retail industry.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Retail-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Schools</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Schools.png" alt="Schools" />

                <div class="tool-content">

                  <div class="body-content-italic">This resource center will help schools of all types identify common workplace safety and health hazards.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['School-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Tech Industry</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Tech-Industry.png" alt="Tech Industry" />

                <div class="tool-content">

                  <div class="body-content-italic">Use this resource center to identify and control common tech industry hazards.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Technology']">Read More</a>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Transportation Safety</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Transportation-Safety.png" alt="Tech Industry" />

                <div class="tool-content">

                  <div class="body-content-italic">This collection includes information on hazardous materials transportation and the motor carrier safety regulations.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Transportation-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="white-well bag-item">
              <div class="widget-header">Warehouse Safety</div>
              <div style="text-align:center">
                <img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ResourceCenters/Warehouse-Safety.png" alt="Warehouse Safety" />

                <div class="tool-content">

                  <div class="body-content-italic">A collection of documents to help you implement safe practices in your warehouse and protect your workers.</div>
                </div>
                <div style="padding-top:25px;">
                  <a class="btn-orange" [routerLink]="['Warehouse-Safety']">Read More</a>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-container>

  </div>

