<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/whatsnew.png" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Here's the Latest</div>

  <div class="body-content-italic">Access the most recent content that's been added or updated within the last month.</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" [routerLink]="['/search/null/new']"  rel="noopener noreferrer">Get Started</a>
</div>