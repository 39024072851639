<div *ngIf="hasAccess && userProfile != null">
  <div class="panel panel-default">
    <div class="panel-heading  panel-heading">Manage Custom Widgets</div>
    <div class="panel" style="padding:10px;">

      <form novalidate [formGroup]="form">
        <input type="hidden" formControlName="Id" />
        <!--ngNativeValidate-->
        <div class="row top20">
          <!--<div class="col-sm-2" style="padding-top:6px;">
    <label>Active:</label>
  </div>-->
          <!--<div class="col-md-1" style="padding-top:6px;">
    <input formControlName="Active" type="checkbox" />
  </div>-->
          <div class="col-sm-1" style="padding-top:6px;">
            <label>Name:</label>
          </div>
          <div class="col-md-3">
            <input formControlName="Name" type="text" class="form-control" />
            <div style="margin-top:10px; color:red;">{{nameErrorMessage}}</div>
          </div>
          <div [ngStyle]="useParent ? {'visibility':'hidden'}: {'visibility':'visible'}" class="col-sm-2" style="padding-top:6px;">
            <label>Active On Dashboard:</label>
          </div>
          <div [ngStyle]="useParent ? {'visibility':'hidden'}: {'visibility':'visible'}" class="col-md-1" style="padding-top:6px;">
            <input formControlName="ActiveOnHome" type="checkbox" />
          </div>
        </div>

        <div class="row top20">
          <!--<div class="col-sm-2" style="padding-top:6px;">
    <label>Visible On Tools:</label>
  </div>
  <div class="col-md-1" style="padding-top:6px;">
    <input formControlName="VisibleOnTools" type="checkbox" />
  </div>-->
          <div class="col-sm-1" style="padding-top:6px;">
            <label>Header:</label>
          </div>
          <div class="col-md-3">
            <input formControlName="Header" type="text" class="form-control" />
          </div>
          <div class="col-sm-2" style="padding-top:6px;">
            <label>Active On Tools:</label>
          </div>
          <div class="col-md-1" style="padding-top:6px;">
            <input formControlName="ActiveOnTools" type="checkbox" />
          </div>
        </div>

        <div class="row top20">
          <div class="col-sm-1" style="padding-top:6px;">
            <label>Width:</label>
          </div>
          <div class="col-md-3">
            <select formControlName="WidthClass" class="form-control">
              <option value="col-lg-3">Single Width</option>
              <option value="col-lg-6">Double Width</option>
              <option value="col-lg-12">Full Width</option>
            </select>
          </div>
        </div>

        <div class="row top20">
          <div class="col-md-12 pull-left" *ngIf="options != null">
            <div [froalaEditor]="options" [(froalaModel)]="Body" name="Body" formControlName="Body"></div>
            <div style="margin-top:10px; color:red;">{{bodyErrorMessage}}</div>
          </div>
        </div>

        <br />
      <div class="btn-toolbar">
        <input type="button" class="btn btn-danger" (click)="deleteCustomWidget()" value="Delete" />
        <input type="button" class="btn btn-success" (click)="saveCustomWidget()" value="Save" />
        <input type="button" class="btn btn-default" [routerLink]="['/custom-settings']" value="Cancel" />
      </div>
      </form>

    </div>
  </div>

  <br />

  <div style="font-style:italic;" class="seperator">Custom Widget View<!--<label style="color:green; font-size:10px; font-style:normal;"> UI Options: Drag, Resize</label>--></div>

  <div class="row">
    <div [ngClass]="getCss(form.value['WidthClass'])">
      <div *ngIf="form.value['Header'] != null && form.value['Header'] != ''" class='widget-header' [innerHtml]="form.value['Header']"></div>
      <div class="white-well-radius-bottom-left-align" >
        <!--<div class='widget-header-hidden' [innerHtml]="&nbsp;">
          &nbsp;
        </div>-->
        <div [innerHtml]="form.value['Body'] | safeHtml">
          
        </div>
      </div>
    </div>
  </div>


  <!--<div class='row' [dragula]='"cwbagbb"' [dragulaModel]='cwbag' style="padding-top:20px;">

    <div [ngClass]="form.value['WidthClass']" [innerHtml]="form.value['body']"></div>

    <div [id]="0" [ngClass]="getCss(form.value['WidthClass'])" *ngIf="!isEdit"><div class='widget-header' [innerHtml]="form.value['Header']"></div><div class="red-well-radius-bottom-left-align" [innerHtml]="form.value['Body']"></div></div>
    <div *ngFor='let item of customWidgets' [id]="item.CustomWidgetId" [innerHtml]="item.CustomWidgetId == form.value['CustomWidgetId'] ? '<div class=widget-header >' + form.value['Header'] + '</div>' + '<div class=red-well-radius-bottom-left-align >' + form.value['Body'] + '</div>' :  getHtml(item)" [ngClass]="(item.CustomWidgetId == form.value['CustomWidgetId'] ? form.value['WidthClass'] : item.WidthClass) + ' bag-item'"></div>
  </div>-->
</div>

<div *ngIf="!hasAccess">
  <h2>You do not have access to this view.</h2>
</div>
