<h1>New Training Materials</h1>
<div *ngIf="information != null" class="outer-panel">
  <div *ngFor="let item of information | groupBy:'InformationTypeName';  let parentIndex = index; let parentRefIndex = index;">
    <!--<div class="new-panel body-content-label">
      {{item.key}}
    </div>-->
    <table class="table">
      <thead class="new-panel body-content-label">
        <tr>
          <td>{{item.key}}</td>
          <td *ngIf="databaseIds == '3,4'" style="text-align:right; padding-right:20px;">Source</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let subItem of item.value; let i = index">
          <td *ngIf="i < 5"><a style="cursor:pointer;" (click)="selectInformation(subItem)" class="link">{{subItem.Title}}</a></td>
          <td *ngIf="i < 5 && databaseIds =='3,4'" style="text-align:right; padding-right:25px;">{{subItem.Source}}</td>
        </tr>
      </tbody>
    </table>
    <div [class]="'collapse ' + parentIndex">
      <table class="table">
        <tbody>
          <tr *ngFor="let subItem of item.value; let i = index">
            <td *ngIf="i >= 5"><a style="cursor:pointer;" (click)="selectInformation(subItem)" class="link">{{subItem.Title}}</a></td>
            <td *ngIf="i >= 5 && databaseIds =='3,4'" style="text-align:right; padding-right:25px;">{{subItem.Source}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div #parentRefIndex *ngIf="item.value.length > 5" class="toggle" [attr.data-target]="'.' + parentIndex" data-toggle="collapse" aria-expanded="false" [attr.aria-controls]="parentIndex" (click)="changeText(parentRefIndex, parentIndex)">

      See all materials

    </div>
  </div>
</div>
