<div>
    <div class="body-content-margin-override">
      <tools></tools>
    </div>
    <h2  *ngIf="customPermissions.GeneralPermissions.news.Visible && hasNews">Latest News</h2>
      <div class="white-well"  *ngIf="customPermissions.GeneralPermissions.news.Visible && hasNews">
        <div>
          <latest-news></latest-news>
        </div>
      </div>
          
      <h2 *ngIf="customPermissions.GeneralPermissions.training.Visible && hasTraining">New Training Materials</h2>
      <div *ngIf="customPermissions.GeneralPermissions.training.Visible && hasTraining" class="white-well">
        <div >
          <training-new></training-new>
        </div>
      </div> 
</div>
