<div class="tool-image-container">
  <img style="width:110px" class="tool-image" alt="Report a Spill" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ComplianceCalendar.jpg">
</div>

  
<div class="tool-content">
  <div class="nav-sub-link">Compliance Calendar</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">Avoid costly fines and penalties resulting from late or missed submissions by using the Compliance Calendar to track federal and state reporting deadlines.</div>
</div>

<div style="padding-top:20px;">
  <a class="btn-blue-sm" (click)="navigate();" >Get Started</a>
</div>
