<div class="footer">
  <div class="container text-center footer-content">

    <div class="footer-image"><img [src]="footerImage" alt="" width="79" height="49"></div>
    <div class="footer-text">
      © {{year | date:'yyyy'}} BLR®, a division of <a href="http://www.simplifycompliance.com" target="_blank">Simplify Compliance LLC</a>. All rights reserved<br>{{footerAddress}} | {{footerPhone}}
      <p class="footer-version">Hero 3.21.0/EHS 1.22.0</p>
      </div>
    
  </div>
</div>
