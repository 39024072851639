export class InheritableProperties {

  constructor() {
    this.HeaderLogo.Value = "../assets/images/ehs-hero-logo.png";
    this.FooterLogo.Value = "../assets/images/BLR_footerLogo.png";
    this.EHSHeaderLogo.Value = "../assets/images/ehs-hero-logo.png";
    this.EHSFooterLogo.Value = "../assets/images/BLR_footerLogo.png";
    this.EHSFooterAddress.Value = "101 Creekside Crossing, Suite 1700-735, Brentwood, Tennessee 37027";
    this.EHSFooterPhone.Value = "800-727-5257";
  }

  Tabs: Tabs = new Tabs;
  TopNavBar: Setting = new Setting();
  HeaderLogo: Setting = new Setting();
  FooterLogo: Setting = new Setting();
  EHSHeaderLogo: Setting = new Setting();
  EHSFooterLogo: Setting = new Setting();
  EHSFooterAddress: Setting = new Setting();
  EHSFooterPhone: Setting = new Setting();
}

export class Tabs {
  Hotline: Setting = new Setting();
  Tools: Setting = new Setting();
  SLCB: Setting = new Setting();
  JDM: Setting = new Setting();
  FLSA: Setting = new Setting();
  EHB: Setting = new Setting();
  Training: Setting = new Setting();
  Resources: Setting = new Setting();
  ChecklistsForms: Setting = new Setting();
  Calculators: Setting = new Setting();
}

export class Setting {
  Value: string = "true";
  TakeChild: string = "false";
}

