import { Component, Provider, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { Information } from '../../shared/types/information.type'
import { MembershipDataService } from '../../shared/services/membership.data.service'
@Component({
  selector: 'training-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})

export class TrainingNewDashboardComponent {

  information: Information[];

  constructor(
    private _heroService: EhsHeroSvc,
    public router: Router,
    private _membershipService: MembershipDataService,
  ) { }

  ngOnInit() {
    this._heroService.getInformationTrainingNew(this._membershipService.dataAccessLevels(), 'dash').then(information => {

      if (information != null) {
        
        this.information = information;
      }

    });

  }

  selectInformation(informationItem: Information) {
    this.router.navigate([informationItem.Url]);
  }

}
