<h1>My Preferences</h1>

<div id="prefAlert" class="alert alert-success">
  <strong>Preferences have been saved.</strong>
</div>

<div class="panel panel-default" *ngIf="!isChild">
  <div class="panel-heading">Dashboard Lock</div>
  <div class="panel-body">
    <input type="checkbox" [checked]='userProfile.DashboardLock' (click)="saveDashboardLock($event.target.checked)"> Prevent child accounts from rearranging their dashboard widgets. Child dashboards will match your widget order.
  </div>
</div>
<div id="lockAlert" class="alert alert-success">
  <strong>Preferences have been saved.</strong>
</div>

  <div class="panel panel-default">

    <table datatable class="table table-striped regulatory-activity-table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead class="thead">
        <tr>
          <th> Widget Name </th>
          <th style="min-width: 110px;"> Type </th>
          <th *ngIf="!useParent" style="max-width: 150px;"> Visible On Dashboard </th>
          <th style="max-width: 110px;"> Visible On Tools </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of widgets">
         
            <td>{{displayName(item)}}</td>
            <td>{{getType(item.IsCustom)}}</td>
            <td *ngIf="!useParent"><input *ngIf="item.Type == 'Tool' || widgetVisible(item, item.IsCustom, 'dashboard')" type="checkbox" [checked]="item.VisibleOnHome" [ngModel]="item.VisibleOnHome" (ngModelChange)="check($event, item, 'dashboard')" /></td>
            <td><input *ngIf="item.Type == 'Tool' || widgetVisible(item, item.IsCustom, 'tools')" type="checkbox" [checked]="item.VisibleOnTools" [ngModel]="item.VisibleOnTools" (ngModelChange)="check($event, item, 'tools')" /></td>
          
        </tr>
      </tbody>      
    </table>
  </div>
