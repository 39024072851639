
export class PermissionSetting {
  DisplayName: string;
  Visible: boolean = true;
}

export class GeneralPermissionSet {
  hotline: PermissionSetting = { DisplayName: 'Hotline', Visible: true };
  training: PermissionSetting = { DisplayName: 'Training Materials', Visible: true };
  allresources: PermissionSetting = { DisplayName: 'All Resources', Visible: true };
  regActivity: PermissionSetting = { DisplayName: 'Regulatory Activity', Visible: true };
  regulations: PermissionSetting = { DisplayName: 'Regulations', Visible: true };
  news: PermissionSetting = { DisplayName: 'News', Visible: true };
  covid: PermissionSetting = { DisplayName: 'Coronavirus Resources', Visible: true };
  help: PermissionSetting = { DisplayName: 'Help Resources', Visible: true };
  ManagementTier: PermissionSetting = { DisplayName: 'Management Tier', Visible: true };
  Safety101: PermissionSetting = { DisplayName: 'Safety 101', Visible: true };
  chartBuilderTool: PermissionSetting = { DisplayName: 'Regulatory Analysis Chart Builder', Visible: true };
  planBuilterTool: PermissionSetting = { DisplayName: 'Plan Builder', Visible: true };
  sdsSearch: PermissionSetting = { DisplayName: 'SDS Search', Visible: true };
  dashboardWidgets: PermissionSetting = { DisplayName: 'Custom widgets on Home page', Visible: true };
  toolsWidgets: PermissionSetting = { DisplayName: 'Custom widgets on Tools page', Visible: true };
  ComplianceCalendar: PermissionSetting = { DisplayName: 'Compliance Calendar', Visible: true };
}

export class SafetyPermissionSet {
  safetyToolboxtrainer: PermissionSetting = { DisplayName: 'Safety Toolbox Trainer', Visible: true };
}

export class EnviroPermissionSet {
  spillReporting: PermissionSetting = { DisplayName: 'Report a Spill', Visible: true };

}

export class PermissionTier {
  Name: string;
  Market: string;
  GeneralPermissions: GeneralPermissionSet = new GeneralPermissionSet();
  SafetyPermissions: SafetyPermissionSet = new SafetyPermissionSet();
  EnviroPermissions: EnviroPermissionSet = new EnviroPermissionSet();
}
