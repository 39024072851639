import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';

import { Widget } from '../../shared/types/widget.type'
import { UserManagementService } from '../services/user-management.service';
@Component({
  selector:"custom-widget",
  templateUrl: './custom-widget.component.html'
})

export class CustomWidgetComponent {

  @Input() widget: Widget;
  @Input() bagClass: string;
  @Input() useParent: boolean;

  visible: boolean = false;
  available: boolean = false;

  constructor(private _router: Router, private _userManagementService: UserManagementService) { }
  ngOnInit() {

    if (this.widget != null) {
      if (this.widget.Header == '' || this.widget.Header == null) {
        this.bagClass = 'white-well';
      } else {
        this.bagClass = 'white-well-radius-bottom-left-align';
      }
      if(this.useParent){
        this.bagClass = this.bagClass + " no-drag";
      }else{
        this.bagClass = this.bagClass + " bag-item";
      }

      this.available = true;
    }

  }
  hideComponent(id: number) {
    let currentUrl = this._router.url;
    
      if (currentUrl.indexOf("/tools") > -1) {
        this.widget.VisibleOnTools = false;
      } else if (currentUrl.indexOf("/dashboard") > -1) {
        this.widget.VisibleOnHome = false;
      }
    
    this._userManagementService.updateCustomWidget(id, this.widget.VisibleOnHome, this.widget.VisibleOnTools, this.widget.PositionOnHome, this.widget.PositionOnTools);
 }

  
}
