<ng-template #template>
  <div class="modal-header modal-header-green">
    Limited Access
  </div>
  <div class="modal-body">
    <h1>
      {{title}}
    </h1>

    <div class="body-content">
      <a href="http://interactive.blr.com/hrhero-demo-request">Request a demo</a> to access time saving resources, workflow tools and much more.
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="hide()">Close</button>
  </div>
</ng-template>
