import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

import { SafeHtmlPipe } from "../../shared/pipes/safe-html";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TrainingAllModel } from "../../shared/types/training/training-all";
import { TrainingInformation } from "../../shared/types/Training/training-information";
import { EhsTrainingService } from '../../shared/services/ehs-training.service';
import { Information } from '../../shared/types/information.type';
import { TrainingAllFilterOptions } from '../../shared/types/Training/training-all-filter-options-type';

import { Subject, Subscription} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'all-training-component',
  templateUrl: './all-training.component.html',
  styleUrls: ['./all-training.component.css'],
  providers: [SafeHtmlPipe]
})

export class TrainingAllComponent {

  resourceItems: TrainingAllModel;
  trainingItems: TrainingInformation[];
  resourceMaster: TrainingInformation[];
  supportingDocuments: TrainingInformation[];
  loading: boolean = false;

  draw : number;
  pageNumber : number =1;
  defaultPageSize:number = 15;

  tags: any = [];
  showBody: boolean = true;
  ttCollapsed: boolean = false;
  dtCollapsed: boolean = false;
  langCollapsed: boolean = false;
  topicsCollapsed: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  savedFilters: TrainingAllModel;

  filterParameters: TrainingAllFilterOptions = {
    DatabaseIds: "",
    PageNumber: 1,
    PageSize:   this.defaultPageSize,
    SelectedTrainingTypes: "",
    SelectedLanguages: "",
    SelectedSources: "",
    SelectedTopics: "",
    SelectedDocTypes: "",
    SortBy: "title",
    Acsending: true,
    UserId: this._mbService.user.UserId,
    HasEnviro: false,
    HasSafety: false
  };

  sourcesParam:string;
  languagesParam:string;
  trainingTypesParam:string;
  docTypesParam:string;
  topicsParam:string;


  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroTrainingService: EhsTrainingService,
    private _mbService: MembershipDataService,


  ) { }

  ngOnInit() {

    if (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible)
    this.router.navigate(["error/403"]);
    this.filterParameters.DatabaseIds = this._mbService.dataAccessLevels();
    this.filterParameters.HasSafety = this.filterParameters.DatabaseIds.includes("3");
    this.filterParameters.HasEnviro = this.filterParameters.DatabaseIds.includes("4");
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      searching: false,
      ordering: true,
      order:[[2, 'asc']],
      info: false,
      language : {
        emptyTable: "No training found to match your search criteria. Please adjust your filters and try again.",  
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
        columns: this.filterParameters.HasSafety && this.filterParameters.HasEnviro ? [{ data: 'type' }, { data: 'topic' }, { data: 'title' },{data:'doctype'} ,{ data: 'language' }, { data: 'source' }] :[{ data: 'type' }, { data: 'topic' }, { data: 'title' },{data:'doctype'} ,{ data: 'language' }],
        lengthMenu : [15,25, 50],
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>',
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {

          console.log('Loading Data');
          this.loading = true;

          this.savedFilters = this._heroTrainingService.popSavedFilters("all-training-filters-paged");
          if (!this.HasFilterQueryParams() && this.savedFilters != null)
          {
            console.log('loading Saved filters');
            this.filterParameters = this.savedFilters.FilterParameters;
            this.filterParameters.DatabaseIds = this._mbService.dataAccessLevels();
            this.filterParameters.HasSafety = this.filterParameters.DatabaseIds.includes("3");
            this.filterParameters.HasEnviro = this.filterParameters.DatabaseIds.includes("4");
          }else if(this.HasFilterQueryParams)
          { 
            if(this.sourcesParam)
            {
              this.filterParameters.SelectedSources = this.sourcesParam;
            }
            if(this.trainingTypesParam)
            {
              this.filterParameters.SelectedTrainingTypes = this.trainingTypesParam;
            }
            if(this.topicsParam)
            {
              this.filterParameters.SelectedTopics = this.topicsParam;
            }
            if(this.docTypesParam)
            {
              this.filterParameters.SelectedDocTypes = this.docTypesParam;
            }
            if(this.languagesParam)
            {
              this.filterParameters.SelectedLanguages = this.languagesParam;
            }
          }

          this.draw = dataTablesParameters.draw;
          var take = this.filterParameters.PageSize = dataTablesParameters.length;
          var skip = dataTablesParameters.start;
          this.filterParameters.PageNumber = skip == 0 ? 1 : skip / take + 1;

          if (dataTablesParameters.order != null)
          {
            this.filterParameters.SortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
            this.filterParameters.Acsending = dataTablesParameters.order[0].dir.toLowerCase() == "asc";
          }

          this._heroTrainingService.getAllPagedTraining(this.filterParameters).then(result => {
          if (result != null) {
  
            //We only load the masters at the start or when all filters are cleared which is used for counts and then take filtered information from the query because it is paged
            if ((this.resourceMaster === undefined) || (this.resourceMaster === null))
            {
                this.resourceItems = result;
                this.resourceMaster = result.InformationMaster;
            }

            
            this.trainingItems = result.Information;

            
            if (!this.HasFilterQueryParams() && this.savedFilters != null) {              

              this.sourcesParam = this.savedFilters.Sources.reduce((a, b) => { if (b.Checked) return a + b.DatabaseId + ","; else return a; }, "").slice(0, -1);
              this.trainingTypesParam = this.savedFilters.TrainingTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);
              this.languagesParam = this.savedFilters.Languages.reduce((a, b) => { if (b.Checked) return a + b.Language + ","; else return a; }, "").slice(0, -1);
              this.docTypesParam   = this.savedFilters.DocumentTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);
              this.topicsParam = this.savedFilters.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1);


              this.setAllUrl(this.sourcesParam,this.languagesParam,this.trainingTypesParam,this.docTypesParam,this.topicsParam);
  
            } 
            

            this.tags = [];

            if(this.topicsParam)
            { 
              this.resourceItems.Topics.forEach(f => f.Checked = this.topicsParam.split(',').some(s => s == f.TopicId.toString()));
              this.resourceItems.Topics.forEach(f => {
                if (this.topicsParam && this.topicsParam.split(',').some(s => s == f.TopicId.toString())) {
                  this.tags.push({ Id: f.TopicId, Filter: "Topic", Name: f.TopicName })
                }
              });
            }
            if(this.docTypesParam)
            {
              this.resourceItems.DocumentTypes.forEach(f => f.Checked = this.docTypesParam.split(',').some(s => s == f.InformationTypeId.toString()));
              this.resourceItems.DocumentTypes.forEach(f => {
                if (this.docTypesParam.split(',').some(s => s == f.InformationTypeId.toString())) {
                  this.tags.push({ Id: f.InformationTypeId, Filter: "DocumentType", Name: f.InformationTypeName })
                }
              });
            }
            if(this.trainingTypesParam)
            {
              this.resourceItems.TrainingTypes.forEach(f => f.Checked = this.trainingTypesParam.split(',').some(s => s == f.InformationTypeId.toString()));
              this.resourceItems.TrainingTypes.forEach(f => {
                if (this.trainingTypesParam.split(',').some(s => s == f.InformationTypeId.toString())) {
                  this.tags.push({ Id: f.InformationTypeId, Filter: "TrainingType", Name: f.InformationTypeName })
                }
              });
            }
            if(this.languagesParam)
            {
              this.resourceItems.Languages.forEach(f => f.Checked = this.languagesParam?.split(',').some(s => s == f.Language));
              this.resourceItems.Languages.forEach(f => {
                if (this.languagesParam.split(',').some(s => s == f.Language)) {
                  this.tags.push({ Id: f.Language, Filter: "Language", Name: f.Language })
                }
              });
            }
            if(this.sourcesParam)
            {
              this.resourceItems.Sources.forEach(f => f.Checked = this.sourcesParam?.split(',').some(s => s == f.DatabaseId.toString()));
              this.resourceItems.Sources.forEach(f => {
                if (this.sourcesParam.split(',').some(s => s == f.DatabaseId.toString())) {
                  this.tags.push({ Id: f.DatabaseId, Filter: "Source", Name: f.Source })
                }
              });

            }
            
            this.filterSelections(8);
            this.loading = false;
          }
          callback({
            recordsTotal: result.TotalCount,               
            recordsFiltered: result.TotalCount,
            data: []
          });
      });
    },
    drawCallback : (settings:any)=>{
      if (this.dtElement && this.trainingItems?.length == 0) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          if ($('.dataTables_paginate').length > 0) {
            $('.dataTables_paginate').hide(); 
          }
          if ($('.dataTables_length').length > 0) {
            $('.dataTables_length').hide(); 
          }
          
        });
      }
      else if (this.dtElement && this.trainingItems?.length > 0)
      {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          if ($('.dataTables_paginate').length > 0) {
            $('.dataTables_paginate').show(); 
          }
          if ($('.dataTables_length').length > 0) {
            $('.dataTables_length').show(); 
          }
          if ($('.dataTables_empty').length > 0) {
            $('.dataTables_empty').hide(); 
          }
        });
      }
    }
      
    };
  }
  HasFilterQueryParams()
  {
    this.sourcesParam = this.route.snapshot.queryParams['sources'];
    this.languagesParam = this.route.snapshot.queryParams['languages'];
    this.trainingTypesParam = this.route.snapshot.queryParams['trainingTypes'];
    this.docTypesParam = this.route.snapshot.queryParams['docTypes'];
    this.topicsParam = this.route.snapshot.queryParams['topics'];

    return this.languagesParam!=null || this.sourcesParam!=null || this.trainingTypesParam!=null || this.docTypesParam!=null || this.topicsParam!=null;
  }

  anyTrainingTypeChecked() {
    return (this.resourceItems.TrainingTypes.length > this.resourceItems.TrainingTypes.filter(a => a.Checked).length);
  }

  anyDocumentTypeChecked() {
    return (this.resourceItems.DocumentTypes.length > this.resourceItems.DocumentTypes.filter(a => a.Checked).length);
  }

  anyTopicChecked() {
    return (this.resourceItems.Topics.length > this.resourceItems.Topics.filter(a => a.Checked).length);
  }

  anyLanguageChecked() {
    return (this.resourceItems.Languages.length > this.resourceItems.Languages.filter(a => a.Checked).length);
  }
  anySourceChecked() {
    return (this.resourceItems.Sources.length > this.resourceItems.Sources.filter(a => a.Checked).length);
  }

  clearAll() {
    this.tags = [];

    this.resourceItems.Topics.forEach((item) => {
      item.Checked = false;
    });
    this.resourceItems.DocumentTypes.forEach((item) => {
      item.Checked = false;
    });
    this.resourceItems.TrainingTypes.forEach((item) => {
      item.Checked = false;
    });
    this.resourceItems.Languages.forEach((item) => {
      item.Checked = false;
    });
    this.resourceItems.Sources.forEach((item) => {
      item.Checked = false;
    });

    this.filterParameters.SelectedTrainingTypes = "";
    this.filterParameters.SelectedLanguages = "";
    this.filterParameters.SelectedTopics = "";
    this.filterParameters.SelectedDocTypes = "";
    this.filterParameters.SelectedSources = "";
    this.resourceMaster = null;
    this._heroTrainingService.removedSaveFilter("all-training-filters-paged");
    this.setAllUrl(null,null,null,null,null);
    this.reload();
  }


  getPage(page: number) {

    this.pageNumber = page;

  }

  filterTrainingTypes(informationTypeId, checked, name) {

    this.resourceItems.TrainingTypes.filter(f => f.InformationTypeId == informationTypeId || informationTypeId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    this.filterParameters.SelectedTrainingTypes = this.resourceItems.TrainingTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);   
    this.setTrainingTypeUrl(this.filterParameters.SelectedTrainingTypes);    
    this.filter(3);
  }

  filterTopic(topicId, checked, name) {

    this.resourceItems.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => {
      item.Checked = checked;
    });

      this.filterParameters.SelectedTopics = this.resourceItems.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1);  
      this.setTopicsUrl(this.filterParameters.SelectedTopics);    
      this.filter(4);
  }


  filterDocumentTypes(documentTypeId, checked, name) {
    this.resourceItems.DocumentTypes.filter(f => f.InformationTypeId == documentTypeId || documentTypeId == 'all').forEach((item) => {
      item.Checked = checked;
    });
      this.filterParameters.SelectedDocTypes = this.resourceItems.DocumentTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1);      
      this.setDocTypesUrl(this.filterParameters.SelectedDocTypes);
    this.filter(5);
  }

  filterLanguages(language, checked, name) {
    this.resourceItems.Languages.filter(f => f.Language == language || language == 'all').forEach((item) => {
      item.Checked = checked;
    });

      this.filterParameters.SelectedLanguages = this.resourceItems.Languages.reduce((a, b) => { if (b.Checked) return a + b.Language + ","; else return a; }, "").slice(0, -1);
      this.setLanguagesUrl(this.filterParameters.SelectedLanguages);
      this.filter(6);
      
  }

  filterSources(databaseId, checked, name) {
    this.resourceItems.Sources.filter(f => f.DatabaseId == databaseId || databaseId == 'all').forEach((item) => {
      item.Checked = checked;
    });
    
      this.filterParameters.SelectedSources = this.resourceItems.Sources.reduce((a, b) => { if (b.Checked) return a + b.Source + ","; else return a; }, "").slice(0, -1);
      this.setSourcesUrl(this.filterParameters.SelectedSources);
      this.filter(7);

  }

  filter(selector) {
    this.loading = true;
    this.saveFilters();
    this.reload();    
    this.filterSelections(selector);
  }

  clearTag(tag) {
    switch (tag.Filter) {
      case "TrainingType": this.filterTrainingTypes(tag.Id, false, tag.Name); break;
      case "Topic": this.filterTopic(tag.Id, false, tag.Name); break;
      case "DocumentType": this.filterDocumentTypes(tag.Id, false, tag.Name); break;
      case "Language": this.filterLanguages(tag.Id, false, tag.Name); break;
      case "Source": this.filterSources(tag.Id, false, tag.Name); break;
    }
  }

  linkType(informationSelected) {

    if (informationSelected.FilePath == null || informationSelected.FilePath == '') {
      return 1;
    }
    else if (informationSelected.FilePath.includes(':')) {
      return 2;
    }
    else {
      return 3;
    }

  }

  openDetails(information) {
    this.saveFilters();
    return information.Url + '/' + information.InformationChildId;
  }

  navigateToDetailsByUrl(information: Information) {
    this.saveFilters();
    this.router.navigate([information.Url + '/' + information.InformationChildId], { relativeTo: this.route });
  }

  saveFilters() {
    var filters = new TrainingAllModel();
    filters.FilterParameters = this.filterParameters;
    filters.Topics = this.resourceItems.Topics.filter(f => f.Checked);
    filters.DocumentTypes = this.resourceItems.DocumentTypes.filter(f => f.Checked);
    filters.TrainingTypes = this.resourceItems.TrainingTypes.filter(f => f.Checked);
    filters.Languages = this.resourceItems.Languages.filter(f => f.Checked);
    filters.Sources = this.resourceItems.Sources.filter(f => f.Checked);
    this._heroTrainingService.saveResouceFilters("all-training-filters-paged", filters);
  }


  getFilterResult(section) {
    return this.resourceMaster.filter(item => (
      (this.resourceItems.Topics.every(f => (!f.Checked)) || this.resourceItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics') &&
      (this.resourceItems.DocumentTypes.every(f => (!f.Checked)) || this.resourceItems.DocumentTypes.some(f => (f.InformationTypeId == item.DocumentTypeId && f.Checked)) || section == 'documentTypes') &&
      (this.resourceItems.TrainingTypes.every(f => (!f.Checked)) || this.resourceItems.TrainingTypes.some(f => (f.InformationTypeId == item.TrainingTypeId && f.Checked)) || section == 'trainingTypes') &&
      (this.resourceItems.Sources.every(f => (!f.Checked)) || this.resourceItems.Sources.some(f => (f.DatabaseId == item.DatabaseId && f.Checked)) || section == 'sources') &&
      (this.resourceItems.Languages.every(f => (!f.Checked)) || this.resourceItems.Languages.some(f => (f.Language == item.Language && f.Checked)) || section == 'languages'))
    );
  }

  filterSelections(selector) {

    if (selector != 3) {
      this.resourceItems.TrainingTypes.forEach(obj => {
        this.resourceItems.TrainingTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('trainingTypes').filter(f => f.TrainingTypeId == obj.InformationTypeId).length;
      });
    }
    if (selector != 4) {
      this.resourceItems.Topics.forEach(obj => {
        this.resourceItems.Topics.filter(f => f.TopicId == obj.TopicId)[0].Count = this.getFilterResult('topics').filter(f => f.TopicId == obj.TopicId).length;
      });
    }
    if (selector != 5) {
      this.resourceItems.DocumentTypes.forEach(obj => {
        this.resourceItems.DocumentTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('documentTypes').filter(f => f.DocumentTypeId == obj.InformationTypeId).length;
      });
    }
    if (selector != 6) {
      this.resourceItems.Languages.forEach(obj => {
        this.resourceItems.Languages.filter(f => f.Language == obj.Language)[0].Count = this.getFilterResult('languages').filter(f => f.Language == obj.Language).length;
      });
    }
    if (selector != 7) {
      this.resourceItems.Sources.forEach(obj => {
        this.resourceItems.Sources.filter(f => f.DatabaseId == obj.DatabaseId)[0].Count = this.getFilterResult('sources').filter(f => f.DatabaseId == obj.DatabaseId).length;
      });
    }
  }



  reload()
  {
    console.log('reloading');
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  setAllUrl(sources,languages,trainingTypes,docTypes,topics)
{
   
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: { sources: sources?sources:null ,languages: languages?languages:null, trainingTypes:trainingTypes?trainingTypes:null, docTypes:docTypes?docTypes:null, topics:topics?topics:null},
      queryParamsHandling: 'merge'
    });
}

setSourcesUrl(sources)
{ 
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {sources: sources?sources:null},
      queryParamsHandling: 'merge'
    });
}
setTopicsUrl(topics)
{
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {topics: topics?topics:null},
      queryParamsHandling: 'merge'
    });
}
setDocTypesUrl(docTypes)
{    
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {docTypes: docTypes?docTypes:null},
      queryParamsHandling: 'merge'
    });
}

setTrainingTypeUrl(trainingTypes)
{
 
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {trainingTypes: trainingTypes?trainingTypes:null},
      queryParamsHandling: 'merge'
    });
}

setLanguagesUrl(languages)
{
    
  this.router.navigate(
    [], 
    {
      relativeTo: this.route,
      queryParams: {languages: languages?languages:null},
      queryParamsHandling: 'merge'
    });
}

}
