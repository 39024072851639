<div><h1><a class="default-link" routerLink="/user-management">Back to the User Management</a></h1></div>
<div class="panel panel-default">
  <div class="panel-heading  panel-heading"><h1>Manage Permission Tiers  <a style="margin-left:10px" class="default-link" (click)="addNewTier()">add new tier</a></h1> </div>
  <div class="panel" style="padding:10px;">
    <table *ngIf="tiersList && tiersList.length > 0 && !loading" class="table table-striped">
      <thead class="thead">
        <tr>
          <th>
            Tier Name
          </th>
          <th>
            Excludes
          </th>
          <th>
            Market
          </th>
          <th>
            Manage
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tier of tiersList">
          <td>{{tier.Name}}</td>
          <td>{{getExcluded(tier)}}</td>
          <td>{{tier.Market}}</td>
          <td><a style="margin-right:10px" (click)="editTier(tier.PermissionTierId)">Edit</a><a style="margin-left:10px" (click)="deleteTier(tier.PermissionTierId)">Delete</a></td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!loading &&( !tiersList || tiersList.length==0)">No permission tiers have been added yet.</div>
    <div *ngIf="loading"><img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" /></div>
  </div>
</div>

<div class="container-fluid" id="newTier" *ngIf="editTierVisible">
  <form [formGroup]="form">
    <input type="hidden" formControlName="PermissionTierId" />
    <div class="row align-items-center top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label>Name:</label>
      </div>
      <div class="col-md-3">
        <input formControlName="Name" class="form-control" required type="text" />
        <p *ngIf="form.get('Name').hasError('required') && form.get('Name').touched" [ngClass]="'error'"> Name is required. </p>
      </div>
    </div>
    <div class="row top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label>Market:</label>
      </div>
      <div class="col-md-3">
        <select formControlName="MembershipId" class="form-control"  (change)="getOptions($event.target.value)">
          <option [value]="sub.MembershipId" *ngFor="let sub of markets">{{sub.Market}}</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label>Tier options:</label>
        <p><i>Uncheck to exclude</i></p>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div *ngFor="let item of generalPermissions | sortBy: 'DisplayName'">
            <div class="checkbox">
              <label><input [disabled]="!isIncluded(item, form.get('MembershipId').value)" type="checkbox" [checked]="item.Visible" (change)="item.Visible = !item.Visible">{{item.DisplayName}}</label>
            </div>
          </div>
          <div *ngFor="let item of widgetPermissions | sortBy: 'DisplayName'">
            <div class="checkbox">
              <label><input [disabled]="!isIncluded(item, form.get('MembershipId').value)" type="checkbox" [checked]="item.Visible" (change)="item.Visible = !item.Visible">{{item.DisplayName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-toolbar">
      <button type="submit" [disabled]="form.invalid" class="btn btn-success" (click)="saveTier()">Save</button>
      <input type="button" class="btn btn-default" (click)="editTierVisible=false" value="Cancel" />
    </div>
  </form>
</div>


<confirm-delete #confirmModal [confirmationMessage]="deleteConfirmationMessage"></confirm-delete>
