<div *ngIf="hasAccess">
  <h1>My Custom Widgets <a [routerLink]="['/edit-widget']" class="default-link" style="font-size:14px;">add new</a></h1>

  

    <div class="panel panel-default">

      <table  datatable class="table table-striped ehs-table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="thead">
          <tr>
            <th>
              Name
            </th>
            <th>
              Header
            </th>
            <th *ngIf="!useParent" style="max-width: 150px;">
              Active on Dashboard
            </th>
            <th style="max-width: 110px;">
              Active on Tools
            </th>
            <th style="max-width: 70px;">Manage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of customWidgets">
            <td>{{item.Name}}</td>
            <td>{{(item.Header == "&nbsp;") ? "" : item.Header}}</td>
            <td *ngIf="!useParent">{{(item.ActiveOnHome) ? "Yes" : "No"}}</td>
            <td>{{(item.ActiveOnTools) ? "Yes" : "No"}}</td>
            <td><a [routerLink]="['/edit-widget', item.Id]">manage</a></td>
          </tr>
        </tbody>
      </table>
    </div>
 
</div>

<div *ngIf="!hasAccess">
  <h2>You do not have access to this view.</h2>
</div>
