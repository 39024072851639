import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler } from '@angular/core';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { Router } from "@angular/router";
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hotline',
  templateUrl: './hotline.component.html',
  styleUrls: ['./hotline.component.css']
})

export class HotlineComponent {
  isLimitedAccess: boolean;
  modalRef: BsModalRef;
  constructor(private _mbService: MembershipDataService, private router: Router,
    public modalService: BsModalService) { }

  ngOnInit() {


    this.isLimitedAccess = this._mbService.isLimitedAccess();
    if (!this._mbService.hasTool("Hotline") || !this._mbService.customPermissions.GeneralPermissions.hotline.Visible)
      this.router.navigate(["error/403"]);

    var file = "../assets/Scripts/zenDesk.js";
    // DOM: Create the script element
    var jsElm = document.createElement("script");
    // set the type attribute
    jsElm.type = "application/javascript";
    // make the script element load file
    jsElm.src = file;
    // finally insert the element to the body element in order to load the script
    document.body.appendChild(jsElm);
  }

  openEmail() {
    window.location.href = "mailto:EHSHotline@blr.com?Body=Please enter the following information to help our team better assist you with your question:%0D%0A%0D%0ATopic:%0D%0A%0D%0AJurisdiction:%0D%0A%0D%0AQuestion:";
  }
  openModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
 }
}
