import { Component, Input } from '@angular/core';
import { Jurisdition } from '../shared/types/jurisdiction.type'
import { Router, ActivatedRoute } from '@angular/router';

//services
import { EhsHeroSvc } from '../shared/services/ehs-hero.service';

import { MembershipDataService } from '../shared/services/membership.data.service'

@Component({
  selector: 'spill-reporting',
  templateUrl: './spill-reporting.component.html',
  styleUrls: ['./spill-reporting.component.css']
})

export class SpillReportingComponent {

  @Input() currentStateId: number = 0;
  states: Jurisdition[];
  agency: any;
  ehsDailyAdvisorValue: boolean = false;
  emailValue: string;

  constructor(private _heroService: EhsHeroSvc, private _mbService: MembershipDataService, private router: Router) { }

  ngOnInit() {

    if (!this._mbService.hasTool("ReportSpill") || !this._mbService.customPermissions.EnviroPermissions.spillReporting.Visible)
      this.router.navigate(["error/403"]);

    this._heroService.getStateOilSpillReportingAgencySates().then(states => {
      this.states = states;
    });

  }

  onStateChange(abbr) {
    this._heroService.getStateOilSpillReportingAgency(abbr).then(agency => {
      this.agency = agency;
    });
  }

}
