import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';


import { SafeHtmlPipe } from "../../shared/pipes/safe-html";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EhsResourcesService } from '../../shared/services/ehs-resources.service';
import { ChallengeModalComponent } from '../../shared/components/challenge-modal.component';
import {  AdminCode, CFR , StateRegulation} from '../../shared/types/regulation.type';
import { IFilterTag } from '../../shared/types/filter-tag.type';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'federal-regulations',
  templateUrl: './federal-regulations.component.html',
  styleUrls: ['./federal-regulations.component.css'],
  providers: [ SafeHtmlPipe]
})

export class FederalRegulations {

  regulations: AdminCode[]=[];
  regulationsMaster: AdminCode[]=[];
  loading: boolean = false;
  hasEnviro: boolean = false;
  hasSafety: boolean = false;
  CFRs: CFR[];
  StateRegulations: StateRegulation[];
  tags: Array<IFilterTag> = [];
  keywordValue: string = '';
  isLimitedAccess: boolean = true;
  @ViewChild('challengeModal')
  challengeModal: ChallengeModalComponent;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: EhsResourcesService,
    private _mbService: MembershipDataService
  ) { }

  ngOnInit() {
    //Add a sort for Title column in table
    $.fn['dataTable'].ext.oSort['TitleSort-pre']  = function(a) {
      return Number(a.split(' ')[0]);
    };
    $.fn['dataTable'].ext.oSort['SectionSort-pre']  = function(a) {
      var span = document.createElement('div');
      span.innerHTML = a;  
      var section = span.textContent || span.innerText;
      return Number(section.replace(/\D/g, ''));
    };
    
  
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      searching: false,
      ordering: true,
      info: false,
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
        columnDefs: [
          {targets: [0], type: 'TitleSort',orderData: [0, 1]},
          {targets: [1], type: 'SectionSort'}, 
        ],
        lengthMenu : [15,25, 50],
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };
    
    if (!this._mbService.hasTool("Regulations") || !this._mbService.customPermissions.GeneralPermissions.regulations.Visible)
      this.router.navigate(["error/403"]);

    let accessLevels = this._mbService.dataAccessLevels();
    this.hasSafety = accessLevels.includes("3");
    this.hasEnviro = accessLevels.includes("4");
    this.isLimitedAccess = this._mbService.isLimitedAccess();
    this.loading = true;
    this._heroService.getFederalRegulations(accessLevels).then(data => {
      if (data != null) {
        this.regulations = data.AdministrativeCodeList;
        this.regulationsMaster = data.AdministrativeCodeList;
        var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        this.CFRs = data.CFRList.sort((a, b)=> collator.compare(a.CFR, b.CFR));
        this.StateRegulations = data.StateRegulationList;
        this.filterSelections();
        this.loading = false;
        this.rerender();
      }
    });
  }  
  ngAfterViewInit(): void {
    //this.dtTrigger.next();
}

  clearTag(title: string) {
    this.filterCFR(title, false);
  }

  filterKeyword(keywordValue: string) {
    this.keywordValue = keywordValue.toLowerCase();
    this.regulations = this.getFilterResult();
    this.rerender();
  }

  filterCFR(title: string, checked: boolean) {
    this.CFRs.filter(f => f.CFR == title || title == 'all').forEach((item) => {
      item.Checked = checked;
    });
    if (title !== "all")
      if (checked) this.tags.push({ Name: title, Filter: "CFR" });  else this.tags = this.tags.filter(item => item.Name !== title);
    else {
      if (checked) this.tags = this.CFRs.map(item => { return { Name: item.CFR, Filter: "CFR" }; }); else this.tags = [];
    }
    this.regulations = this.getFilterResult();
    this.rerender();
  }

  clearAll() {
    this.tags = [];
    this.CFRs.forEach((item) => {
      item.Checked = false;
    });
    this.regulations = this.getFilterResult();
    this.rerender();
  }

  getSource(databaseId: number) {
    switch (databaseId) {
      case 3: return "Safety";
      case 4: return "Enviro"
    }
  }

  getFilterResult() {
    return this.regulationsMaster.filter(item => (
      (this.keywordValue == '' || item.Description.toLowerCase().includes(this.keywordValue)) &&
      (this.CFRs.every(f => (!f.Checked)) || this.CFRs.some(f => (f.CFR == item.Title && f.Checked)))    
    )
    );
  }

  filterSelections() {
    this.CFRs.forEach(obj => {
      this.CFRs.filter(f => f.CFR == obj.CFR)[0].Count = this.getFilterResult().filter(f => f.Title == obj.CFR).length;
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  notifyInquiry(state){
    this._mbService.specialInquiryRequest("StateRegulationsSelected");
  }
  StateRegulationClicked(state)
  {
    this.notifyInquiry(state);
  }
    rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });  
      
    }  
    else if (this.dtElement)
    {      
         this.dtTrigger.next();     
    } 
  }
}
