
<div *ngIf="information != null">

  <div *ngFor="let article of information | orderBy: 'PubDate': 'desc'">

    <div style="font-weight:bold;"><a (click)="selectInformation(article)" class="link">{{article.Title}}</a></div>
    <div>{{article.InformationTypeName}}</div>
    <div>{{article.PubDate  | date: 'EEEE, MMMM d, y'}}</div><br />
    <div [innerHTML]="article.Teaser"></div>

    <div style="margin-top:10px; margin-bottom:15px;">
      <a [routerLink]="[article.Url]" class="link-bold">Read More</a>
    </div>

    <hr />
  </div>
</div>
