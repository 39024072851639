
 
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { Injectable, Provider, Component } from "@angular/core";
import { HttpClient, HttpHeaders  } from '@angular/common/http'
import { EhsHeroSvc } from "./ehs-hero.service";
import { Router } from "@angular/router";
import { EhsHeroConfig } from '../../../environments/environment'
import { MembershipDataService } from "./membership.data.service";
import { SearchModel } from "../types/search.type";
import { RegulatoryActivityModel } from "../types/reg-activity.type";
import { AllResourcesFilterOptions } from "../types/allresources-filter-options.type";
import { PagedResourcesModel } from "../types/resources-paged";
import { RegActivityFilterOptions } from "../types/reg-activity-filter-options.type";
import { Information } from "../types/information.type";
import { FederalRegulationModel } from "../types/regulation.type";


@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

export class EhsResourcesService {


  constructor(private http: HttpClient, private ehsService: EhsHeroSvc, private router: Router, private mbDataService: MembershipDataService) {
    
  }

  getAllNews(sourceDatabaseIds: string) {
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }

    return this.http.get<Information[]>(EhsHeroConfig.endpoint + '/Information/GetLatestNews?top=500&sourceDatabaseIds=' + sourceDatabaseIds, options)
      .toPromise();
  }

  getNewsArticle(informationId: number) {
    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }

    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information/' + informationId, options)
      .toPromise();
  }

  getFederalRegulations(sourceDatabaseIds: string) {

    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<FederalRegulationModel>(EhsHeroConfig.endpoint + '/AdministrativeCodes?databaseIds=' + sourceDatabaseIds, options)
    .toPromise();
  }

  getRegulatoryActivity(sourceDatabaseIds: string) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<RegulatoryActivityModel>(EhsHeroConfig.endpoint + '/Information/RegulatoryActivity?databaseIds=' + sourceDatabaseIds + '&userName=' + this.mbDataService.user.UserName, options)
    .toPromise();
  }

  getRegulatoryActivityPagedPost(filters: RegActivityFilterOptions) {
    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }
    filters.UserId = this.mbDataService.user.UserId;
       let options =  {
      headers: headers
   }
    return this.http.post<RegulatoryActivityModel>(EhsHeroConfig.endpoint + '/Information/RegulatoryActivityPaged', filters, options).pipe(
    map(mappedresponse => {
      mappedresponse.Information.forEach(i => i.Url = i.Url.replace("regulatory-activity/",""));
      return mappedresponse as RegulatoryActivityModel;
    }));

  }

  getAllResources(sourceDatabaseIds: string) {

    let headers: any = {
  //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<SearchModel>(EhsHeroConfig.endpoint + '/Information/AllResources?databaseIds=' + sourceDatabaseIds + '&userName=' + this.mbDataService.user.UserName, options)
    .toPromise();
  }

  getAllResourcesPagedPost(filters: AllResourcesFilterOptions) {
    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.post<PagedResourcesModel>(EhsHeroConfig.endpoint + '/Information/AllResourcesPaged', filters, options);

  }

  getRegulatoryActivityDetails(informationId: number) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information/RegulatoryActivity/' + informationId, options)
    .toPromise();
  }

  getResourceDetails(informationId: number) {
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }
       let options =  {
      headers: headers
   }
    return this.http.get<Information>(EhsHeroConfig.endpoint + '/Information/ResourceDetails/' + informationId, options)
    .toPromise();
  }

  saveResouceFilters(key: string, filters: any) {
    sessionStorage.removeItem(key);
    sessionStorage.setItem(key, JSON.stringify(filters));
  }

  popSavedFilters(key: string) {
    let filters = JSON.parse(sessionStorage.getItem(key));
    return filters;
  }

  clearSavedFilters(key: string) {
    sessionStorage.removeItem(key);
  }
}
