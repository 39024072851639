import { Injectable, Component, Provider } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { UserProfileAPI } from '../types/user-profile-api.type'
import { Property } from '../types/property.type'
import { config, EhsHeroConfig } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

/*Component({

})*/

export class UserProfileService {

  constructor(
    private http: HttpClient
  ) {

  }

  saveUserProfile(userId: number, json: string, token: string, profileType = null, userName = null) {

    let data: any;
    if (profileType == null) {
      profileType = 1
    }

    data = {
      "LoggedInUserName": userName,
      "UserId": userId,
      "ApplicationId": EhsHeroConfig.applicationId,
      "UserProfileJSON": json,
      "UserProfileTypeId": profileType
    };

    let body = JSON.stringify(data);
    let headers = new HttpHeaders({
      'Token': token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.post(config.profileManagerService + '/api/UserProfiles', body, options)
      .toPromise()
      .catch(this.handleErrorPromise.bind(this));
  }

  getUserProfile(userId: number, token: string, profileType = null) {

    let headers = new HttpHeaders({
      'Token': token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    if (profileType == null) {
      profileType = 1
    }

    return this.http.get<UserProfileAPI>(config.profileManagerService + '/api/UserProfiles?id=' + userId + '&applicationId=' + EhsHeroConfig.applicationId + '&userProfileTypeId=' + profileType, options)
      .toPromise().catch(this.handleErrorPromise.bind(this));
  }

  getUserProfiles(userId: number, token: string) {

    let headers = new HttpHeaders({
      'Token': token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }


    return this.http.get<UserProfileAPI[]>(config.profileManagerService + '/api/UserProfiles?id=' + userId, options)
      .toPromise()
      .catch(this.handleErrorPromise.bind(this));
  }

  getProperties(userId: string, applicationId: string, userProfileTypeId: string, token: string) {

    let headers = new HttpHeaders({
      'Token': token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Property[]>(config.profileManagerService + '/api/Property?applicationId=' + applicationId, options)
      .toPromise()
      .catch(this.handleErrorPromise.bind(this));

  }

  private handleErrorPromise(error: Response | any) {

    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

}