import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserProfileService } from '../services/user-profile.service'
import { Router } from '@angular/router';

import { Account } from '../types/account.type';
import { UserProfile } from '../types/user-profile.type';
import { KeyValueString } from '../types/key-value-string.type';

import { Injectable } from '@angular/core';
import { EhsHeroConfig } from '../../../environments/environment'
import  'jquery';
import { PermissionTier } from '../types/permission-tier.type';
import { UserManagementService } from './user-management.service';
import { CCCustomer } from "../types/cc-customer";
import { Subject,AsyncSubject, Observable } from "rxjs";
import { CCMembership } from '../types/cc-membership';

declare let pendo: any;

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

export class MembershipDataService {
  user: Account = null;
  parentUserProfile: UserProfile = new UserProfile;
  userProfile: UserProfile = new UserProfile;
  defaultUserProfile: UserProfile = new UserProfile;
  userIsEHSAuthenticated: boolean = false;
  userIsHRAuthenticated: boolean = false;
  userProfileAPIToken: string;
  slcbAPIToken: string;
  jdmAPIToken: string;
  cbAPIToken: string;
  userProfileTypeId: number;
  parentUserId: number;
  libraries: string;
  hasJDM: boolean = false;
  hasSLCB: boolean = false;
  selectedState: any;
  profileSet: boolean = false;
  isMembershipLimited: boolean = false;
  data: any;
  ssoNeeded: boolean = false;
  memberships: CCMembership[];
  private userLoggedIn = new Subject<boolean>();
  AutherizationType:number;

  hasCB: boolean = false;

  showLitmosLogin: boolean = false;
  litmosLink: string = "";

  allUsers: CCCustomer[];
  private userSubject = new AsyncSubject<any>();

  customPermissions: PermissionTier = new PermissionTier();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private _ups: UserProfileService,
  ) { 
    this.userLoggedIn.next(false);
  }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }


  setUpAccount(account:Account){
    this.setApiTokens(account.APITokens);

    this.setUserProfileTypeId(account.UserProfileTypeId);

    this.userIsEHSAuthenticated = true;
    this.userIsHRAuthenticated = false;

    this.setUser(account);
    this.parentUserId = account.ParentUserId;
    if (account.Profile != null)
    {
      this.setUserProfile(JSON.parse(account.Profile), JSON.parse(account.ParentProfile), account.ParentUserId);
    }

    this.setUserLoggedIn(true);
  }
  authenticate(authSystem = null, authToken = null, redirect = false) {

    let _this = this;

    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit, #loginForm, #infoBox').hide();

    $.ajax({
      type: 'POST',
      url: EhsHeroConfig.endpoint + "/account/SsoSessionLogin",
      xhrFields: {
        withCredentials: true
      },
      data: {
        UserName: null,
        Password: null,
        ApplicationId: EhsHeroConfig.applicationId,
        AuthToken: authToken
      },
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {

        _this.data = data;

        //authentication failed
        if (_this.data == null) {
          location.href = "/ehs/login";
        }

        if (!_this.data.Authorized || _this.data.Authorized == "false") {

          location.href = "/ehs/login";

        }

        _this.setUpAccount(_this.data);

        if (redirect) {          
          _this.router.navigate(['dashboard']);
        } else {
          return true;
        }

      }

    });

    return false;
  }

  getSsoSession(returnUrl: string = '') {

    var _this = this;

    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit').hide();

    $.ajax({
      type: 'POST',
      url: EhsHeroConfig.endpoint + "/account/GetSessionRedirectUrl",
      data: {
        UserName: null,
        Password: null,
        ApplicationId: EhsHeroConfig.applicationId,
        AutoLogin: true,
        ReturnUrl: returnUrl
      },
      success: function (data, textStatus, jqXHR) {
        if (data.split('&return_url=').length > 1) {
          localStorage.setItem('ru', data.split('&return_url=')[1]);
        }
        var element = document.createElement("iframe");
        element.setAttribute('style', "width:0;height:0;border:0; border:none;");
        element.setAttribute('src', data);
        document.body.appendChild(element);

        //wait for auth token
        setTimeout(function () {

          if (element.contentWindow.location.href.includes("hero.blr.com")) {

            let authToken = _this.getParameterByName("auth_token", element.contentWindow.location.href);

            _this.authenticate(null, authToken, true);
          }

        }, 500);
      }
    });

    return null;
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  autoLogin() {

    let headers: any = {
      'Content-Type': 'application/json'
    }

    let options = {
      withCredentials: true,
      headers: new HttpHeaders(headers)
    };

    let data: any;

    data = {
      "AuthenticationSystem": 'WA',
      "ApplicationId": EhsHeroConfig.applicationId
    };

    return this.httpClient.post(EhsHeroConfig.endpoint + "/account/AutoLogin?origin=" + EhsHeroConfig.origin + "&isSecure=" + EhsHeroConfig.isSecure, data, options);

  }

  logout() {
    this.setUserLoggedIn(false);
    $.ajax({
      url: EhsHeroConfig.endpoint + "/account/logout?applicationId=2",
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: function (data) {
        localStorage.removeItem('ru');
        location.href = EhsHeroConfig.logOutURL + EhsHeroConfig.endpoint.replace("/api", "") + "/ehs/Login";
      }
    });

  }

  setUser(account: Account) {
    this.user = account;
    this.AutherizationType = account.AutherizationType;
    this.userIsEHSAuthenticated = account.Authorized;
    this.user.LastLoginDate = account.LastLoginDate;

    if (this.userIsEHSAuthenticated) {
      this.userIsHRAuthenticated = false;
    }

    this.user.States = account.States;

    this.libraries = account.States;

    if (account.Roles != null) {
      if (account.Roles.filter(f => f == "Limited").length > 0) {
        this.isMembershipLimited = true;
      }

      if (account.Roles.filter(f => f == "EHS Chart Builder Safety Enviro").length > 0) {
        this.hasCB = true;
      }

      if (account.Roles.filter(f => f == "EHS Chart Builder Safety").length > 0) {
        this.hasCB = true;
      }

      if (account.Roles.filter(f => f == "EHS Chart Builder Enviro").length > 0) {
        this.hasCB = true;
      }
    }

    if(this.user.EmailAddress != null && this.user.MembershipIds != null && this.user.MembershipIds != undefined && this.user.MembershipIds.length > 0){
      this.checkLitmos();
    }

    // just need any API URL so we can get a response...doesn't need to be anything specific
    var apiUrl = 'https://api.github.com/users/godfathr';

      console.log('Pendo Initialization');
      this.httpClient.get(apiUrl, {}).subscribe(r => {
        pendo.initialize({
            visitor: {
                id: this.user.CustomerId,
                email: this.user.EmailAddress,
                full_name: this.user.FirstName + ' ' + this.user.LastName
            },
            account: {
                id: account.CustomerId,
                name: this.user.Company 
            }
        });
      });
        console.log('After pendo');

  }

  setUserProfile(userProfile: UserProfile, parentUserProfile: UserProfile, parentUserId) {

    let save = false;

    if (userProfile.InheritableProperties.EHSHeaderLogo == undefined)
      userProfile.InheritableProperties.EHSHeaderLogo = this.defaultUserProfile.InheritableProperties.EHSHeaderLogo;

    if (userProfile.InheritableProperties.EHSFooterLogo == undefined)
      userProfile.InheritableProperties.EHSFooterLogo = this.defaultUserProfile.InheritableProperties.EHSFooterLogo;

    if (userProfile.InheritableProperties.EHSFooterPhone == undefined)
      userProfile.InheritableProperties.EHSFooterPhone = this.defaultUserProfile.InheritableProperties.EHSFooterPhone;

    if (userProfile.InheritableProperties.EHSFooterAddress == undefined)
      userProfile.InheritableProperties.EHSFooterAddress = this.defaultUserProfile.InheritableProperties.EHSFooterAddress;

    if (parentUserId != null) {

      this.parentUserProfile = parentUserProfile;
      this.getPermissionsFromProfile(this.customPermissions, userProfile);

      this.userProfile = userProfile;


      if (save) {
        this._ups.saveUserProfile(this.user.CustomerId, JSON.stringify(this.userProfile), this.userProfileAPIToken, 2, this.user.UserName);
      }

      this.profileSet = true;

      console.log("loading all users");      
      this.getAllRelatedUsers();
      


    } else {
      this.userProfile = userProfile;
      this.getAllRelatedUsers();
      this.profileSet = true;
    }
  }

  async getAllRelatedUsers() {
    if(this.userProfileTypeId === 1 || (this.userProfile.AdminPreferences != null && this.getVisibility('User Management')))
    {    
      let headers: any = {
   //     'Token': this.userProfileAPIToken,
        'Content-Type': 'application/json'
      }

      let options =  {
        headers: headers
    }

      let userId = (this.userProfileTypeId == 1) ? this.user.CustomerId : this.user.ParentUserId;
      let request = EhsHeroConfig.endpoint + '/CustomerCentral/GetAllChildren?customerId=' + userId + '&forceRefresh=true'; 
      if (this.userProfileTypeId == 2)
      {
        request += "&childAdminId=" + this.user.CustomerId;
      }
      else
      {
        request += "&childAdminId=0" 
      }

      return this.httpClient.get<CCCustomer[]>(request, options).subscribe(data => {
        this.allUsers = data;
        this.userSubject.next(data);
        this.userSubject.complete();
      });
    }
  }


  public getAllUsers(): Observable<any> {
    return this.userSubject.asObservable();
  }


  setApiTokens(tokens: Array<KeyValueString>) {

    if (tokens != null) {

      if (tokens.filter(w => w.Key == "blr").length > 0) {
        this.userProfileAPIToken = tokens[0].Value;
        localStorage.setItem('token', this.userProfileAPIToken);
      }
      if (tokens.filter(w => w.Key == "cb").length > 0) {
        this.cbAPIToken = tokens[1].Value;
      }

    }

  }

  setUserProfileTypeId(id) {
    this.userProfileTypeId = id;
  }

  isLimitedAccess() {
    return this.isMembershipLimited;
  }

  dataAccessLevels(market = null) {
    if (market == null)
      market = this.user.Market;
    switch (market) {
      case "ComboMarket":
        return "3,4";
      case "SafetyMarket":
        return "3";
      case "EnviroMarket":
        return "4";
    }

  }

  ehsCbAccessLevel() {

    switch (this.user.SubscriptionType) {
      case "EHS Safety Enviro":
        return 3;
      case "EHS Safety":
        return 1;
      case "EHS Enviro":
        return 2;
    }

  }

  getTrainingTypesBySubscription() {

    switch (this.user.SubscriptionType) {
      case "EHS Safety Enviro":
        return EhsHeroConfig.ehsSafetyEnviroTrainingTypes;
      case "EHS Safety":
        return EhsHeroConfig.ehsSafetyTrainingTypes;
      case "EHS Enviro":
        return EhsHeroConfig.ehsEnviroTrainingTypes;
      case "EHS Free Trial":
        return EhsHeroConfig.ehsSafetyEnviroTrainingTypes;
    }

  }

  hasTool(name: string, inclusions = null, dataAccessLevels = null) {

    if (this.user.Inclusions == null) {
      return true;
    }

    if (inclusions == null)
      inclusions = this.user.Inclusions;
    if (dataAccessLevels == null)
      dataAccessLevels = this.dataAccessLevels();
    
      switch (name) {
      case "ReportSpill":
        return (inclusions.filter(f => f == "ReportSpill").length > 0) && !(dataAccessLevels == "3");
      case "CHB":
        return (inclusions.filter(f => f == "CHB").length > 0);
      case "PB":
        return (inclusions.filter(f => f == "PB").length > 0);
      case "RegActivity":
        return (inclusions.filter(f => f == "RegActivity").length > 0);
      case "SDS":
        return (inclusions.filter(f => f == "SDS").length > 0);
      case "STT":
        return (inclusions.filter(f => f == "STT").length > 0) && !(dataAccessLevels == "4");
      case "Training":
        return (inclusions.filter(f => f == "Training").length > 0);
      case "AllResources":
        return (inclusions.filter(f => f == "AllResources").length > 0);
      case "Hotline":
        return (inclusions.filter(f => f == "Hotline").length > 0);
      case "News":
        return (inclusions.filter(f => f == "News").length > 0);
      case "Regulations":
        return (inclusions.filter(f => f == "Regulations").length > 0);
      case "Graybar":
        return (inclusions.filter(f => f == "Graybar").length > 0);
      case "Industries":
        return (inclusions.filter(f => f == "Industries").length > 0);
      case "VIDEO":
          return (inclusions.filter(f => f == "VIDEO").length > 0);
      case "Safety101":
          return (inclusions.filter(f => f == "Safety101").length > 0);
      case "ComplianceCalendar":
          return (inclusions.filter(f => f == "ComplianceCalendar").length > 0) && !(dataAccessLevels == "3");
      case "COVID": case "HELP": case "ManagementTier": case "WHATSNEW":
        return true;
    }

    return false;
  }

  hasSubscription(subscriptionType) {
    return this.user.SubscriptionType.includes(subscriptionType);
  }


  isAuthenticated() {
    return this.userIsEHSAuthenticated;
  }

  getVisibility(name) {
    if (this.userProfileTypeId == 1) {
      return true;
    } else {
      if (this.userProfile.AdminPreferences.filter(f => f.Name == name).length > 0) {
        return this.userProfile.AdminPreferences.filter(f => f.Name == name)[0].Visible;
      }
    }

    return false;
  }
  getCustomerId() {
    return this.user.CustomerId;
  }
  getLegacyId() {
    return this.user.LegacyUserId;
  }
  getParentId() {
    return this.user.ParentUserId;
  }

  getAllHeroMembershipIds() {
    return this.user.MembershipIds;
  }

  getAllPlans() {
    return this.user.AllMarkets;
  }

  getPermissionsFromProfile(tier: PermissionTier, userProfile: UserProfile) {
    tier.EnviroPermissions.spillReporting.Visible = false;
    tier.GeneralPermissions.ComplianceCalendar.Visible = false;
    tier.SafetyPermissions.safetyToolboxtrainer.Visible = false;
    userProfile.AdminPreferences.forEach(preference => {
      switch (preference.Name) {
        case "Safety Toolbox Trainer": tier.SafetyPermissions.safetyToolboxtrainer.Visible = preference.Visible; break;
        case "Regulatory Activity": tier.GeneralPermissions.regActivity.Visible = preference.Visible; break;
        case "Training Materials": tier.GeneralPermissions.training.Visible = preference.Visible; break;
        case "Chart Builder": tier.GeneralPermissions.chartBuilderTool.Visible = preference.Visible; break;
        case "Plan Builder": tier.GeneralPermissions.planBuilterTool.Visible = preference.Visible; break;
        case "SDS Search": tier.GeneralPermissions.sdsSearch.Visible = preference.Visible; break;
        case "Report a Spill": tier.EnviroPermissions.spillReporting.Visible = preference.Visible; break;
        case "Compliance Calendar": tier.GeneralPermissions.ComplianceCalendar.Visible = preference.Visible; break;
        case "Show Custom widgets on Home page": tier.GeneralPermissions.dashboardWidgets.Visible = preference.Visible; break;
        case "Show Custom widgets on Tools page": tier.GeneralPermissions.toolsWidgets.Visible = preference.Visible; break;
        case "Hotline": tier.GeneralPermissions.hotline.Visible = preference.Visible; break;
        case "Regulations": tier.GeneralPermissions.regulations.Visible = preference.Visible; break;
        case "News": tier.GeneralPermissions.news.Visible = preference.Visible; break;
        case "Management Tier": tier.GeneralPermissions.ManagementTier.Visible = preference.Visible; break;
        case "Safety 101": tier.GeneralPermissions.Safety101.Visible = preference.Visible; break;
        case "All Resources": tier.GeneralPermissions.allresources.Visible = preference.Visible; break;
      }
    });
  }

  specialInquiryRequest(urlExtension: string, secondField: string = ""){
    if(!(EhsHeroConfig.usesInquiry)){
      return; //don't send requests from QA/STG/local
    }
    let ActiveXObject: (type: string) => void;
    var xmlhttp; var send;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    } else {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }
    xmlhttp.open("POST", ("https://d1v834xrsg9kw8.cloudfront.net/api/request.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&uf="
      + this.user.FirstName + "&ul=" + this.user.LastName + "&ue="
      + this.user.EmailAddress + "&uc=" + this.user.Company + "&us="
      + this.user.SubscriptionType + "&r1=" + EhsHeroConfig.origin +
      "&r2=" + secondField + "&r3=" +
      this.user.LastLoginDate + "&uldap=" + this.user.UserName +
      "&uccid=" + this.user.CustomerId + "&uccpid=" +
      this.user.ParentUserId + "&xu=" + window.location.href + "/" + urlExtension + "&xw="
      + screen.width + "&xh=" + screen.height + "&xn="
      + navigator.userAgent + "&xc=" +
      navigator.cookieEnabled + "&xl=" + navigator.language +
      "&xp=" + navigator.platform + "&xx=15");
  }

  getLitmosSsoLink(email,membershipId){
    let headers = new HttpHeaders({
  //    'Token': this.userProfileAPIToken,
      'Content-Type': 'application/json'
    });
    
    let options =  {
      headers: headers
    }
    let url = EhsHeroConfig.endpoint + '/UserProfile/GetLitmosInformationForUser?commonUsername=' + encodeURIComponent(email) + '&membershipId=' + membershipId;
    return this.httpClient.get<string>(url, options)
    .toPromise();
  }
  checkLitmos(){
    let self = this;
    this.getLitmosSsoLink(this.user.EmailAddress,this.user.MembershipIds[0]).then(link => {
      if(link != null && link != "N/A"){
        self.showLitmosLogin = true;
        self.litmosLink = link;
      }
    });
  }

  
}

