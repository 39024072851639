<div class="tool-image-container"><img class="tool-image" style="width:107px;" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/Safety101.png" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Safety 101</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">Explore the key elements of a successful safety program and access helpful tools for implementation in your organization.</div>
</div>

<div style="padding-top:3px;">
  <a class="btn-blue-sm" (click)="navigate();">Get Started</a>
</div>
