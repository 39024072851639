

<div *ngIf="!details && loading" class="row" style="text-align:center;">
  <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
</div>

<div *ngIf="details">

  <div id="reg-document">
    <span style="font-size: 14px;">{{details.PubDate | date:'MMM dd, yyyy'}}</span>

    <div class="doc-title">{{details.Title}}</div>
    <div class="subHead" (click)="showSummary = !showSummary"><span><img *ngIf="!showSummary" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/plus.gif" /><img *ngIf="showSummary" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/minus.gif" /> Summary</span></div>
    <div class="divCollapse" *ngIf="showSummary">{{details.Summary}}</div>
    <div *ngIf=" details.ImportantDates!=null">
      <div class="subHead" (click)="showDates = !showDates"><span><img *ngIf="!showDates" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/plus.gif" /><img *ngIf="showDates" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/minus.gif" /> Important Dates</span></div>
      <div class="divCollapse" *ngIf="showDates">
        <div style="margin-bottom: 4px;">
          <label for="RegisterDate" style="width:110px;display: block;font-weight: bold;float:left;">Register Date:</label>
          <span id="RegisterDate" style="float:left;">{{details.ImportantDates.RegisterDate| date:'MM/dd/yyyy'}}</span>
          <div style="clear:both;"></div>
        </div>
        <div style="margin-bottom: 4px;">
          <label for="AdoptedDate" style="width:110px;display: block;font-weight: bold;float:left;">Adopted Date:</label>
          <span id="AdoptedDate" style="float:left;">{{details.ImportantDates.AdoptedDate| date:'MM/dd/yyyy'}}</span>
          <div style="clear:both;"></div>
        </div>
        <div style="margin-bottom: 4px;">
          <label for="EffectiveDate" style="width:110px;display: block;font-weight: bold;float:left;">Effective Date:</label>
          <span id="EffectiveDate" style="float:left;">{{details.ImportantDates.EffectiveDate| date:'MM/dd/yyyy'}}</span>
          <div style="clear:both;"></div>
        </div>
        <div style="margin-bottom: 4px;">
          <label for="CommentsDate" style="width:110px;display: block;font-weight: bold;float:left;">Comments Date:</label>
          <span id="CommentsDate" style="float:left;">{{details.ImportantDates.CommentsDate| date:'MM/dd/yyyy'}}</span>
          <div style="clear:both;"></div>
        </div>
      </div>
    </div>
    <div *ngIf="details.RelatedRegulations.length > 0">
      <div class="subHead" (click)="showRelated = !showRelated"><img *ngIf="!showRelated" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/plus.gif" /><img *ngIf="showRelated" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/minus.gif" /> Related Regulations</div>
      <div class="divCollapse" *ngIf="showRelated">
        <div *ngFor="let item of details.RelatedRegulations">
          <div *ngIf="item.InformationId != details.InformationId">
            <span class="instructions">({{item.PubDate | date:'M/dd/yyyy hh:mm:ss'}}) {{item.JurisdictionName}} - {{item.InformationTypeName}} - {{item.TopicDesc}} </span>
            <div> <a [routerLink]="'/' + item.Url">{{item.Title}}</a></div>
          </div>
        </div>
      </div>
    </div>

    <div class="subHead" (click)="showBody = !showBody"><img *ngIf="!showBody" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/plus.gif" /><img *ngIf="showBody" src="../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/RegActivity/minus.gif" /> Body of Entry</div>
    <div class="divCollapse" *ngIf="showBody" [innerHTML]="details.Body | safeHtml"></div>
  </div>
  <div *ngIf="details != null" style="display:none;">Unified ID: {{details.SourceInfoId}}</div>
</div>


<div [hidden]="details">
  <div *ngIf="activityItems != null && !loading">
    <h1>Regulatory Activity</h1>
  </div>

  <div *ngIf="!loading"> Stay up to date on federal and state environmental regulations with personalized email notifications from <a [routerLink]="['/regupdate']" rel="noopener noreferrer">RegUpdate</a>.</div>


  <div [hidden]="loading==true" style="margin-top:20px;" class="row">
    <div class="col-lg-3" style="margin-bottom:20px;">
      <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
      <div class="outer-filter-panel">
        <collapse-header dataTargetId="#new" headerTitle="&nbsp;"></collapse-header>
        <div id="new" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <div class="radio">
                    <label><input type="radio" (click)="filterNew('new', $event.target.checked)" [checked]="itemNew.Checked">New</label><span style="float:right;">{{activityItems?.NewItems || 0}}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="radio">
                    <label><input type="radio" (click)="filterNew('all', $event.target.checked)" [checked]="!itemNew.Checked">All</label><span style="float:right;">{{(itemNew.Checked)? activityItems?.AllTimeTotal: (activityItems?.Total || 0)}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#state" headerTitle="States"></collapse-header>
        <div id="state" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
              <tr *ngFor="let item of activityItems.Jurisdictions">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterJurisdiction(item.JurisdictionId, $event.target.checked, item.JurisdictionName)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <collapse-header dataTargetId="#category" headerTitle="Categories"></collapse-header>
        <div id="category" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
              <tr>
              <tr *ngFor="let item of activityItems.Categories">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterCategory(item.CategoryId, $event.target.checked, item.CategoryName)" [checked]="item.Checked">{{item.CategoryName}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <collapse-header dataTargetId="#topic" headerTitle="Topics"></collapse-header>
        <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
            <tr *ngFor="let item of activityItems.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#doc" headerTitle="Document Types"></collapse-header>
        <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
            <tr *ngFor="let item of activityItems.InformationTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterDocument(item.InformationTypeId, $event.target.checked, item.InformationTypeName)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#date" headerTitle="Publication Date Range"></collapse-header>
        <div id="date" class="collapse in" style="max-height:186px; padding:8px">
          <label>
            From:
            <input type="text"
                   placeholder="From"
                   class="form-control regulatory-activity-datepicker"
                   bsDatepicker
                   [bsConfig]="{ adaptivePosition: true, showWeekNumbers:false }"
                   [(ngModel)]="fromDate"
                   [isDisabled]="itemNew.Checked"
                   [maxDate]="toDate">
          </label>

          <label>
            To:
            <input type="text"
                   placeholder="To"
                   class="form-control regulatory-activity-datepicker"
                   bsDatepicker
                   [bsConfig]="{ adaptivePosition: true, showWeekNumbers:false }"
                   [(ngModel)]="toDate"
                   [isDisabled]="itemNew.Checked"
                   [minDate]="fromDate">

          </label>
          <div><button style="float:right" [disabled]="itemNew.Checked" class="btn btn-default" (click)="filterByDate(fromDate, toDate)">Go</button><div style="clear: both;"></div></div>
        </div>

        <collapse-header dataTargetId="#source" headerTitle="Source" *ngIf="hasEnviro&&hasSafety"></collapse-header>
        <div id="source" class="collapse in" style="overflow-y:auto; max-height:186px;" *ngIf="hasEnviro&&hasSafety">
          <table class="table">
            <tbody>
              <tr *ngFor="let item of activityItems.Sources">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterSources(item.DatabaseId, $event.target.checked)" [checked]="item.Checked">{{getSource(item.DatabaseId)}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="ra-tab-container">
        <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
          <div class="inline-text">{{tag.Name}}</div>
          <div class="delete-button" style="height: 100%">
            <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
              <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="white-well">
        <div *ngIf="(activityItems.Information?.length || 0) == 0">No regulations found to match your search criteria. Please adjust your filters and try again.</div>
        <table [hidden]="(activityItems.Information?.length || 0) == 0" datatable class="table table-striped regulatory-activity-table" [dtOptions]="dtOptions" style="width: 100%;">
          <thead class="thead">
            <tr>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Type <span></span> </a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Topic <span></span> </a>
              </th>
              <th style="min-width: 350px;">
                <a class="text-nowrap" style="cursor:pointer">Title <span></span> </a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Date <span></span> </a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">State <span></span> </a>
              </th>
              <th *ngIf="hasSafety && hasEnviro">
                <a class="text-nowrap" style="cursor:pointer">Source <span></span> </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of activityItems?.Information">
              <td>{{getType(item.InformationTypeId).InformationTypeName}}</td>
              <td>{{getTopic(item.TopicId).TopicName}}</td>
              <td><a [routerLink]="[item.Url]" *ngIf="!isLimitedAccess" style="cursor:pointer;">{{item.Title}}</a> <a (click)="challengeModal.open()" *ngIf="isLimitedAccess" style="cursor:pointer;">{{item.Title}}</a></td>
              <td>{{item.PubDate | date:'MM/dd/yyyy'}}</td>
              <td>{{getState(item.JurisdictionId).JurisdictionName}}</td>
              <td *ngIf="hasSafety && hasEnviro">{{getSource(item.DatabaseId)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<challenge-modal #challengeModal></challenge-modal>
