import { Component, Provider, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { Information } from '../../shared/types/information.type'
import { MembershipDataService } from '../../shared/services/membership.data.service'
@Component({
  selector: 'trainers-toolbox',
  templateUrl: './trainers-toolbox.html',
  styleUrls: ['./trainers-toolbox.css']
})

export class TrainingTrainersToolboxComponent {
  information: Information[];
  isLimitedAccess: boolean;
  downloadChallengeTitle: string = "Downloads are reserved for EHS Hero subscribers.";

  constructor(private _heroService: EhsHeroSvc, public router: Router, 
    private _mbService: MembershipDataService,) { }

  ngOnInit() {
    if (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible)
      this.router.navigate(["error/403"]);

    this.isLimitedAccess = this._mbService.isLimitedAccess();

    this._heroService.getTrainersToolbox().then(information => {

      if (information != null) {
        this.information = information;
      }

    });

  }

  selectInformation(informationItem: Information) {
    this.router.navigate([informationItem.Url]);
  }
  notifyInquiry(docid){
    this._mbService.specialInquiryRequest("DOWNLOAD/" + docid);
  }

  download(id,documentType, title)
  {
    this.notifyInquiry(id);
    var fileName = title?.replace(/[^\x00-\x7F]/g, "").replace(/[|&;$%@"<>()+,]/g, "").replace(/\s/g, '_');
    this._heroService.downloadInformationFile(id,fileName, documentType);
}


}
