<div class="tool-image-container"><img class="tool-image" style="width:107px;" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/ManagementTier.png" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Management Tier</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">Access all of your easy-to-use workflow tools in one place. Manage plans, store documents, to stay ahead of compliance requirements.</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" [routerLink]="['/managementtier']" href="" target="_blank">Log In</a>
</div>

