import { Component } from '@angular/core';
import { DragulaService } from 'ng2-dragula'
import { Router } from '@angular/router';

//services
import { MembershipDataService } from '../shared/services/membership.data.service'
import { UserManagementService } from '../shared/services/user-management.service';

//types
import { Widget } from '../shared/types/widget.type'
import { UserProfile } from '../shared/types/user-profile.type'

import 'jquery';


@Component({
  selector: 'tools',
  templateUrl: './tools.html',
  styleUrls: ['../../../node_modules/dragula/dist/dragula.css', './tools.css'],
  providers: [ DragulaService]
})

export class ToolsComponent {

  userProfile: UserProfile = new UserProfile;
  parentUserProfile: UserProfile = null;

  widgets: Widget[] = null;
  parentWidgets: Widget[] = null;
  useParent: boolean = false;

  index: number = 0;
  available: boolean = false;

  raVisible: boolean = false;
  sfttVisible: boolean = false;
  scbVisible: boolean = false;
  pbVisible: boolean = false;
  atVisible: boolean = false;
  sdsSearchVisible: boolean = false;
  clVisible: boolean = false;
  reportSpillVisible: boolean = false;
  complianceCalendarVisible: boolean = false;
  recordableVisible: boolean = false;
  isAuthenticated: boolean = false;

  parentCustomWidgetsVisible: boolean = false;

  toolCount: number = 0;
  toolClass: string = 'col-lg-3';

  currentRoute: string;

  constructor(
    private _dragulaService: DragulaService,
    private _mbService: MembershipDataService,
    private _router: Router,
    private _userManagementService: UserManagementService
  ) {

    this._dragulaService.dropModel.subscribe((value) => {
      this.onDropModel(value.slice(1));
    });
    let drake = this._dragulaService.find('dashboard');
    if(drake == null){
      this._dragulaService.setOptions('dashboard',{
        moves: (el,source,handle,sibling) => !el.classList.contains('no-drag')
      })
    }else{
      drake.moves = (el,source,handle,sibling) => !el.classList.contains('no-drag');
    }

  }

  ngOnDestroy() {
    try {
      this._dragulaService.destroy(this.currentRoute);
    } catch (ex) { }
  }

  ngOnInit() {
    this.userProfile = this._mbService.userProfile;
    this.parentUserProfile = this._mbService.parentUserProfile;
    if(this.parentUserProfile != null && this.parentUserProfile != undefined){
      if (this.parentUserProfile.DashboardLock && this._router.url.indexOf("/dashboard") > -1) {
        this.useParent = true;
      }
    }
    let currentUrl = this._router.url;

    if (currentUrl.indexOf("/tools") > -1) {
      this.currentRoute = "tools";
    }
    if (currentUrl.indexOf("/dashboard") > -1) {
      this.currentRoute = "dashboard";
    }

    this.parentWidgets = this._mbService.user.ParentWidgets;
    if ((this.currentRoute == "dashboard" && !this._mbService.customPermissions.GeneralPermissions.dashboardWidgets.Visible) ||
        (this.currentRoute == "tools" && !this._mbService.customPermissions.GeneralPermissions.toolsWidgets.Visible)) {
        this.widgets = this._mbService.user.Widgets.filter(f => !f.IsCustom || ( f.IsCustom && f.OwnerId == f.UserId));  //filter out only the parent's custom widgets leaving child's cust widgets
    }

    else
        this.widgets = this._mbService.user.Widgets;

    this.init();
  }

  private init() {

    this.toolCount = 0;

    if (((this.widgets.filter(f => f.IsCustom).length == 0) || !this._mbService.customPermissions.GeneralPermissions.toolsWidgets.Visible)
      && !(this._mbService.customPermissions.GeneralPermissions.training.Visible
        || this._mbService.customPermissions.GeneralPermissions.regActivity.Visible
        || this._mbService.customPermissions.GeneralPermissions.planBuilterTool.Visible
        || this._mbService.customPermissions.GeneralPermissions.chartBuilderTool.Visible
        || this._mbService.customPermissions.SafetyPermissions.safetyToolboxtrainer.Visible
        || this._mbService.customPermissions.EnviroPermissions.spillReporting.Visible
        || this._mbService.customPermissions.GeneralPermissions.ComplianceCalendar.Visible
        || this._mbService.customPermissions.GeneralPermissions.sdsSearch.Visible)) {
      if (this.currentRoute == "tools")
        this._router.navigate(["error/403"]);
      else
        if (!this._mbService.customPermissions.GeneralPermissions.dashboardWidgets.Visible || (this.widgets.filter(f => f.IsCustom).length == 0)) {
        this.available = false;
          return;
        }
    }

    if (this._mbService.user.Roles.filter(f => f == "EHS Chart Builder Safety Enviro").length > 0 ||
      this._mbService.user.Roles.filter(f => f == "EHS Chart Builder Enviro").length > 0 ||
      this._mbService.user.Roles.filter(f => f == "EHS Chart Builder Safety").length > 0) {
      this._router.navigate(['chart-builder']);
    }

    // this.sortBy = this.getSortBy();

    this.isAuthenticated = this._mbService.userIsEHSAuthenticated;

    if (this._mbService.userProfileTypeId == 1)
      //parent settings
        this.parentCustomWidgetsVisible = true;

    else {
      //child settings
      let currentUrl = this._router.url;

      if (currentUrl.indexOf("/tools") > -1) {
        this.currentRoute = "tools";
        if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Custom widgets on Tools page").length > 0)
          this.parentCustomWidgetsVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Custom widgets on Tools page")[0].Visible;
      }

      if (currentUrl.indexOf("/dashboard") > -1) {
        this.currentRoute = "dashboard";
        if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Custom widgets on Home page").length > 0)
          this.parentCustomWidgetsVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Custom widgets on Home page")[0].Visible;
      }
    }

    //get tool count for inclusions
    if (this._mbService.user.Inclusions != null) {
      this._mbService.user.Inclusions.forEach(obj => {
        if (this._mbService.hasTool(obj) && this.isWidget(obj) && this.isChildAdminPrefernceVisible(obj) && this.isVisible(obj))
          this.toolCount++;
      });
    }
    this.available = true;
  }


  private onDropModel(args) {
    this.orderItems();
  }

  orderItems() {

    let _ths = this;
    let index = 0;
    let processed = [];
    let currentUrl = this._router.url;
    let widgetClass = this.useParent ? ".no-drag" : ".bag-item";

    if (currentUrl.indexOf("/tools") > -1) {
      $(widgetClass).each(function (ind) {
        let widget = _ths.widgets.filter(f => f.Id == parseInt(this.id));
        if (widget.length > 0 && processed.filter(p => p == this.id).length == 0) {
          processed.push(this.id);
          widget[0].PositionOnTools = index++;

          if(!_ths.useParent){
            _ths._userManagementService.updateCustomWidget(widget[0].Id, widget[0].VisibleOnHome, widget[0].VisibleOnTools, widget[0].PositionOnHome, widget[0].PositionOnTools);
          }
        }
      });
    }

    if (currentUrl.indexOf("/dashboard") > -1) {
      $(widgetClass).each(function (ind) {
        let widget = _ths.widgets.filter(f => f.Id == parseInt(this.id));
        if (widget.length > 0 && processed.filter(p => p == this.id).length == 0) {
          processed.push(this.id);
          widget[0].PositionOnHome = index++;

          if(!_ths.useParent){
            _ths._userManagementService.updateCustomWidget(widget[0].Id, widget[0].VisibleOnHome, widget[0].VisibleOnTools, widget[0].PositionOnHome, widget[0].PositionOnTools);
          }
        }
      });
    }

  }

  checkVisibility(item: Widget): boolean {

    if (!this.isAuthenticated)
      if (this._mbService.hasCB)
        this._router.navigate(['chart-builder']);


    if (item.InclusionCode == "CHB" && (this._mbService.user.SubscriptionType == "EHS Chart Builder Enviro Safety"
      || this._mbService.user.SubscriptionType == "EHS Chart Builder Enviro"
      || this._mbService.user.SubscriptionType == "EHS Chart Builder Safety"))
      return true;

    if (this._mbService.user.SubscriptionType == "EHS Free Trial") {
      if (item.InclusionCode == "ReportSpill" && this._mbService.dataAccessLevels() == "3")
        return false;
      else if (item.InclusionCode == "STT" && this._mbService.dataAccessLevels() == "4")
        return false;
      return true;
    }

    //if item is a tool and user does not have inclusion
    if (!item.IsCustom && !this._mbService.hasTool(item.InclusionCode))
      return false;    

    //check custom widget settings

    if (item.IsCustom && this.widgets.filter(f => f.IsCustom).length > 0) {
      if (this.currentRoute == 'tools' && (!item.VisibleOnTools || !item.ActiveOnTools))
        return false;

      if (this.currentRoute == 'dashboard' && (!item.VisibleOnHome || !item.ActiveOnHome))
        return false;
    }

    //check user management admin settings (child only)
    if (this._mbService.userProfileTypeId == 2 && !item.IsCustom) 
      if (this.userProfile.AdminPreferences.filter(f => f.Name == item.Name).length > 0 && !this.userProfile.AdminPreferences.filter(f => f.Name == item.Name)[0].Visible)
        return false;  

    //check preference settings
    if (this.currentRoute == 'tools')
      return item.VisibleOnTools;
    else if (this.currentRoute == 'dashboard')
      return item.VisibleOnHome;

    return false;
  }

  hideComponent(widget: Widget) {

    let currentUrl = this._router.url;

    if (currentUrl.indexOf("/tools") > -1)
      widget.VisibleOnTools = false;
    else if (currentUrl.indexOf("/dashboard") > -1)
      widget.VisibleOnHome = false;

    this.widgets.filter(f => f.Id == widget.Id)[0] = widget;

    this._userManagementService.updateCustomWidget(widget.Id, widget.VisibleOnHome, widget.VisibleOnTools, widget.PositionOnHome, widget.PositionOnTools);

    this.init();
  }

  getToolClass(item: Widget) {

    let noDragAppend = this.useParent ? " no-drag" : "";
    if (!this._mbService.hasTool(item.InclusionCode) && !item.IsCustom)
      return '' + noDragAppend;

    let currentUrl = this._router.url;

    if (this.currentRoute == 'tools' && !item.VisibleOnTools)
      return '' + noDragAppend;
    
    else if (this.currentRoute == 'dashboard' && !item.VisibleOnHome)
      return '' + noDragAppend;

    if (!item.IsCustom && !this.isChildAdminPrefernceVisible(item.InclusionCode))
      return '' + noDragAppend;

    if (item.IsCustom) {
        if (this.currentRoute == 'tools' && (!item.VisibleOnTools || !item.ActiveOnTools)) 
        return '' + noDragAppend;
        if (this.currentRoute == 'dashboard' && (!item.VisibleOnHome || !item.ActiveOnHome)) 
        return '' + noDragAppend;
      return item.WidthClass + noDragAppend;
    }

    //switch (this.toolCount) {
    //  case 1:
    //    this.toolClass = 'col-lg-12';
    //    break;
    //  case 2:
    //    this.toolClass = 'col-lg-6';
    //    break;
    //  case 3:
    //    this.toolClass = 'col-lg-4';
    //    break;
    //  case 4:
    //    this.toolClass = 'col-lg-3';
    //    break;
    //  default:
    //    this.toolClass = 'col-lg-3';
    //    break;
    //}

    return this.toolClass + noDragAppend;
  }

  isWidget(name) {

    switch (name) {
      case "ReportSpill":
      case "CHB":
      case "PB":
      case "RegActivity":
      case "SDS":
      case "STT":
      case "Training":
      case "COVID":
      case "HELP":
      case "ManagementTier":
      case "Safety101":
      case "ComplianceCalendar":
      case "WHATSNEW":
        return true;
    }

    return false;
  }

  isChildAdminPrefernceVisible(name) {

    let adminPreferenceName: string;
    switch (name) {
      case "CHB":
        adminPreferenceName = "Regulatory Analysis Chart Builder";
        break;
      case "STT":
        adminPreferenceName = "Safety Toolbox Trainer";
        break;
      case "RegActivity":
        adminPreferenceName = "Regulatory Activity";
        break;
      case "Training":
        adminPreferenceName = "Training Materials";
        break;
      case "PB":
        adminPreferenceName = "Plan Builder";
        break;
      case "SDS":
        adminPreferenceName = "SDS Search";
        break;
      case "ReportSpill":
        adminPreferenceName = "Report a Spill";
        break;
      case "Resources":
        adminPreferenceName = "All Resources";
        break;
      case "HELP":
       adminPreferenceName = "Help Resources";
       break;
      case "COVID":
        adminPreferenceName = "Coronavirus Resources";
        break;
      case "ManagementTier":
        adminPreferenceName = "Management Tier";
        break;
      case "Safety101":
        adminPreferenceName = "Safety 101";
      break;        
      case "WHATSNEW":
        adminPreferenceName = "What's New";
        break;
      case "ComplianceCalendar":
          adminPreferenceName = "Compliance Calendar";
          break;        
      default:
        adminPreferenceName = name;
    }

    if (this._mbService.userProfileTypeId == 2) {
      if (this.userProfile.AdminPreferences.filter(f => f.Name == adminPreferenceName).length > 0)
        return this.userProfile.AdminPreferences.find(f => f.Name == adminPreferenceName).Visible;
    } else
        return true;
  }

  isVisible(ic) {
    if (this.widgets.filter(f => f.InclusionCode == ic).length > 0) {
      let item = this.widgets.find(f => f.InclusionCode == ic);
      if (this.currentRoute == 'tools' && !item.VisibleOnTools)
        return false;   
      else if (this.currentRoute == 'dashboard' && !item.VisibleOnHome)
        return false; 
    }
    return true;
  }
}
