
import {first, map} from 'rxjs/operators';
import { CanActivate, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MembershipDataService } from '../services/membership.data.service';
import { Account } from '../types/account.type';
import { UserProfile } from '../types/user-profile.type';

@Injectable()

export class ToolsAuthGuard implements CanActivate {

  userProfile: UserProfile = null;
  debug: boolean = true;

  constructor(
    private _mbDataService: MembershipDataService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  canActivate(): Observable<boolean> | boolean {

    //already authenticated
    if (this._mbDataService.isAuthenticated()) {
      return true;
    }

    let _ths = this;

    //auto login
    return this._mbDataService.autoLogin().pipe(map((account: Account) => {

      let standalone = false;

      //authentication failed
      if (account == null || account.Authorized == false) {

        if (account != null && account.Roles != null && account.Authorized == false) {
          if (account.Roles.filter(f => f == "EHS Chart Builder Safety Enviro").length > 0) {
            return false;
          }

          if (account.Roles.filter(f => f == "EHS Chart Builder Enviro").length > 0) {
            return false;
          }

          if (account.Roles.filter(f => f == "EHS Chart Builder Safety").length > 0) {
            return false;
          }
        }

        if (!standalone) {

          if (!(this.document.location.href.toLowerCase().indexOf("/login") > -1)) {

            this._mbDataService.getSsoSession();

          }

          this.router.navigate(['login']);

          return false;

        }
      }

      _ths._mbDataService.setUpAccount(account);     
      return true;
    }),first(),);
  }
}
