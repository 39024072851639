<!--Mobile top nav-->
<div  *ngIf="topNavVisible" class="row grayTopNav-mobile" [hidden]="snBranded">
  <div class="container">
    <div class="col-xs-12">
      <ul>
        <li><a href="https://www.blr.com" target="_blank">BLR</a></li>
        <li><a class="active" href="http://hero.blr.com">EHS Hero</a></li>
        <li><a href="/hr/" target="_blank">HR Hero</a></li>
        <li *ngIf="!(_mbService.showLitmosLogin)"><a [href]="trainingTodayUrl" target="_blank">TrainingToday</a></li>
        <li><a href="http://store.blr.com" target="_blank">Store</a></li>
        <li *ngIf="_mbService.showLitmosLogin" style="cursor:pointer;"><a (click)=litmosLogin()>TrainingToday LMS Login</a></li>
        <li style="float:right">
          <a data-toggle="collapse" data-target="#mobile-dropdown" class="hamburger-black-btn" #mobileClick>
            <i class="glyphicon glyphicon-menu-hamburger hamburger-black" data-toggle="collapse" data-target="#date"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!--Mobile top nav dropdown-->
<div id="mobile-dropdown" class="collapse out navigation-menu-mobile" *ngIf="currentRoute.indexOf('login') == -1">
  <a *ngIf="industriesVisibility()" [routerLink]="['industry-collections']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'industry-collections' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">Industries</a>
  <a *ngIf="showWidgets" [routerLink]="['tools']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'tools' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">Tools</a>
  <a *ngIf="customPermissions.GeneralPermissions.hotline.Visible && hotlineInclusion" [routerLink]="['hotline']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'hotline' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">EHS Hotline</a>
  <a *ngIf="trainingVisibility()" [routerLink]="['training']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'training' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">Training</a>
  <a *ngIf="resourcesVisibility()"
     [routerLink]="[(customPermissions.GeneralPermissions.news.Visible && newsInclusion)?'news':(customPermissions.GeneralPermissions.regActivity.Visible && regActivityInclusion)? 'regulatory-activity' : (customPermissions.GeneralPermissions.regulations.Visible && regulationInclusion)? 'regulations': (customPermissions.GeneralPermissions.allresources.Visible && allResourcesInclusion)? 'all-resources': 'sds-search']"
     (click)="closeMobileDropDown()"
     [ngClass]="currentRoute == 'resources' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">Resources</a>
  <a [routerLink]="['myBookmarks']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'myBookmarks' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">My Bookmarks</a>
  <a (click)="navigate('account-settings', 'close')" style="cursor:pointer;" [ngClass]="currentRoute == 'hotline' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">Settings</a>
  <a *ngIf="isAdmin" (click)="navigate('administration', 'close')" style="cursor:pointer;" [ngClass]="currentRoute == 'regupdate-admin' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">EHS Administration</a>
  <a (click)="closeMobileDropDownAndLogOut()" [ngClass]="currentRoute == 'hotline' ? 'nav-item-selected  navigation-menu-mobile' : 'nav-item  navigation-menu-mobile'">Log Out</a>
</div>

<!--Standard top nav dropdown-->
<div *ngIf="topNavVisible" class="row grayTopNav" style="margin-right: 0px !important;" [hidden]="snBranded">
  <div class="container">
    <div class="col-xs-12">
      <ul>
        <li><a href="https://www.blr.com" target="_blank">BLR</a></li>
        <li><a class="active" href="/ehs/">EHS Hero</a></li>
        <li><a href="/hr/" target="_blank">HR Hero</a></li>
        <li *ngIf="!(_mbService.showLitmosLogin)"><a [href]="trainingTodayUrl" target="_blank">TrainingToday</a></li>
        <li style="float:right"><a href="http://store.blr.com" target="_blank">Store</a></li>
        <li *ngIf="_mbService.showLitmosLogin" style="float:right; cursor:pointer;"><a (click)=litmosLogin()>TrainingToday LMS Login</a></li>
      </ul>
    </div>
  </div>
</div>

<div id="main-wrapper">
  <div class="navbar navbar-inverse">
    <div id="app" [ngClass]="(currentSubRoute == 'external' && currentRoute == 'tools') ? 'container collapse in showhead' : 'container'" style="margin-bottom:10px;" aria-expanded="true">
      <div class="navigation-menu navbar-header" style="text-align:center">
        <div class="center logo" *ngIf="currentRoute.indexOf('login') > -1">
          <img (click)="navigate('login', null)" [src]="headerImage" height="80" width="140" class="ehs-hero-logo"  onerror="this.src= '../assets/images/ehs-hero-logo.png';" *ngIf="!snBranded"/>
          <img src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/safetynational_ehs.png" class="hr-hero-logo sn-logo" *ngIf="snBranded"/>
        </div>
        <div class="center logo" *ngIf="currentRoute.indexOf('login') == -1">
          <img [routerLink]="['dashboard']" [src]="headerImage" height="80" width="140" class="ehs-hero-logo" onerror="this.src= '../assets/images/ehs-hero-logo.png';"/>
        </div>
      </div>
      <div class="navigation-menu-mobile-search" style="text-align:center" *ngIf="currentRoute.indexOf('login') == -1">
        <div class="center">
          <img [routerLink]="['dashboard']" [src]="headerImage" height="80" width="140" style="display: inline-block;" onerror="this.src= '../assets/images/ehs-hero-logo.png';" *ngIf="!snBranded"/>
          <img src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/safetynational_ehs.png" class="sn-logo" *ngIf="snBranded"/>
        </div>
        <div [ngClass]="currentRoute == 'search' ? 'navigation-menu-mobile-search-hide' : 'navigation-menu-mobile-search'">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTermMobile.value)" [value]="searchTermValue" #searchTermMobile>
            <span class="input-group-btn" (click)="search(searchTermMobile.value)">
              <button class="btn btn-default" type="submit">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="navigation-menu" *ngIf="currentRoute.indexOf('login') == -1">
        <div class="collapse navbar-collapse col-md-9">
          <div class="row navigation-menu" style="margin-right:0px; padding-top:56px;">

            <div style="margin-left:50px;">
              <a *ngIf="industriesVisibility()" [routerLink]="['industry-collections']" [ngClass]="currentRoute == 'industry-collections' ? 'nav-item-selected' : 'nav-item'">Industries</a>
              <a *ngIf="showWidgets" [routerLink]="['tools']" [ngClass]="currentRoute == 'tools' ? 'nav-item-selected' : 'nav-item'">Tools</a>
              <a *ngIf="customPermissions.GeneralPermissions.hotline.Visible && hotlineInclusion" [routerLink]="['hotline']" [ngClass]="currentRoute == 'hotline' ? 'nav-item-selected' : 'nav-item'">EHS Hotline</a>
              <a *ngIf="trainingVisibility()" [routerLink]="['training']" [ngClass]="currentRoute == 'training' ? 'nav-item-selected' : 'nav-item'">Training</a>
              <a *ngIf="resourcesVisibility()"
                 [routerLink]="[(customPermissions.GeneralPermissions.news.Visible && newsInclusion)?'news':(customPermissions.GeneralPermissions.regActivity.Visible && regActivityInclusion)? 'regulatory-activity' : (customPermissions.GeneralPermissions.regulations.Visible && regulationInclusion)? 'regulations': (customPermissions.GeneralPermissions.allresources.Visible && allResourcesInclusion)? 'all-resources': 'sds-search']"
                 [ngClass]="currentRoute == 'resources' ? 'nav-item-selected' : 'nav-item'">Resources</a>
            </div>
          </div>
        </div>
        <div class="search col-md-3">
          Welcome, {{userName}} |
          <div class="dropdown" style="margin-bottom:5px;">
            <a class="droplink nav-sub-link">My Account</a>

            <div class="dropdown-content">
              <a *ngIf="isAdmin" (click)="navigate('administration', null)" style="cursor:pointer;">EHS Administration</a>
              <a (click)="navigate('account-settings', null)" style="cursor:pointer;">Settings</a>
              <a [routerLink]="'myBookmarks'">My Bookmarks</a>

              <a (click)="logout()" class="link">Log Out</a>
            </div>
          </div>

          <div class="input-group">
            <!--<input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTerm)" [(ngModel)]="searchTerm" [typeahead]="suggestions" (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" typeaheadMinLength="3"/>-->
            <input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTerm)" [(ngModel)]="searchTerm" />
            <span class="input-group-btn" (click)="search(searchTerm)">
              <button class="btn btn-default" type="submit">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
          <div style="margin-top: 10px; text-align: right;" *ngIf="helpVisibility()">
            <a style="color:#333;" href="https://interactive.blr.com/ehs-hero-help-page" rel="noopener noreferrer" target="_blank"><i class="fa fa-question-circle"></i> Help Center</a>            
          </div>
        </div>
      </div>
    </div>
    <div class="navbar navbar-inverse in" style="min-height:40px !important;background-color:#588a7e; border:none;" *ngIf="!snBranded">
      <div *ngIf="currentSubRoute == 'external' && currentRoute == 'tools'">
        <ul style="list-style:none;">
          <li style="float:left">
            <a data-toggle="collapse" data-target="#app" id="appTrigger" style="cursor:pointer;" class="white-link headeroption" aria-expanded="true" (click)="toggleToolHeader(headerVisible);" #appClick>
              <i class="glyphicon glyphicon-resize-full white" data-toggle="expand" style="color:white;"></i> <span class="showHead" aria-expanded="false">Show</span><span class="hideHead" aria-expanded="true">Hide</span> Header
            </a>
          </li>
        </ul>
      </div>
      <div class="container" style="background-color:#588a7e; margin-top:8px;" *ngIf="currentRoute.indexOf('login') == -1">
        <div class="navbar-header" style="margin-left:10px;">
          <div *ngIf="currentRoute == 'dashboard'">
            <a [routerLink]="['dashboard']" class="white-link">My Dashboard</a>
            <!-- <a href='/ehs/search/null/new' class="white-link">What’s New</a> -->
          </div>
          <div *ngIf="currentSubRoute == 'spill-reporting' && reportSpillInclusion">
            <a [routerLink]="['spill-reporting']" [ngClass]="currentSubRoute == 'spill-reporting' ? 'white-link-selected' : 'white-link'">Report a Spill</a>
          </div>
          <div *ngIf="currentSubRoute == 'compliance-calendar' && complianceCalendarInclusion">
            <a [routerLink]="['compliance-calendar']" [ngClass]="currentSubRoute == 'compliance-calendar' ? 'white-link-selected' : 'white-link'">Compliance Calendar</a>
          </div>          
          <div *ngIf="customPermissions.GeneralPermissions.training.Visible && currentRoute == 'training'">
            <a [routerLink]="['training']" [ngClass]="currentSubRoute == 'all' ? 'white-link-selected' : 'white-link'" >Training Materials</a>
            <a [routerLink]="['training/new']" [ngClass]="currentSubRoute == 'new' ? 'white-link-selected' : 'white-link'">New Training Materials</a>
            <a [routerLink]="['training/trainers-toolbox']" [ngClass]="currentSubRoute == 'trainers-toolbox' ? 'white-link-selected' : 'white-link'">Trainer's Toolbox</a>
            <a *ngIf="videoInclusion" [routerLink]="['training/safety-video']" [ngClass]="currentSubRoute == 'safety-video' ? 'white-link-selected' : 'white-link'">Videos On Demand</a>      
          </div>
          <div *ngIf="currentRoute == 'resources'">
            <a *ngIf="customPermissions.GeneralPermissions.news.Visible && newsInclusion" [routerLink]="['news']" [ngClass]="currentSubRoute == 'news' ? 'white-link-selected' : 'white-link'">News</a>
            <a *ngIf="customPermissions.GeneralPermissions.regActivity.Visible && regActivityInclusion" [routerLink]="['regulatory-activity']" [ngClass]="currentSubRoute == 'regulatory-activity' ? 'white-link-selected' : 'white-link'" id="regActivity">Regulatory Activity</a>
            <a *ngIf="customPermissions.GeneralPermissions.regulations.Visible && regulationInclusion" [routerLink]="['regulations']" [ngClass]="currentSubRoute == 'regulations' ? 'white-link-selected' : 'white-link'">Regulations</a>
            <a *ngIf="customPermissions.GeneralPermissions.allresources.Visible && allResourcesInclusion" [routerLink]="['all-resources']" [ngClass]="currentSubRoute == 'all-resources' ? 'white-link-selected' : 'white-link'" id="allResources">All Resources </a>
            <a *ngIf="customPermissions.GeneralPermissions.sdsSearch.Visible && sdsInclusion" [routerLink]="['sds-search']" [ngClass]="currentSubRoute == 'sds-search' ? 'white-link-selected' : 'white-link'">SDS Search</a>
          </div>
          <div *ngIf="currentRoute == 'preferences' || currentRoute == 'account-settings' || currentRoute == 'custom-settings' || currentRoute == 'edit-widget' || currentRoute == 'user-management'|| currentRoute == 'regupdate' || currentRoute == 'custom-site-settings'">
            <a [routerLink]="['account-settings']" [ngClass]="currentSubRoute == 'account-settings' ? 'white-link-selected' : 'white-link'">My Account Summary</a>
            <a  *ngIf="customWidgetsVisible" [routerLink]="['custom-settings']" [ngClass]="currentSubRoute == 'custom-settings' ? 'white-link-selected' : 'white-link'">Custom Widgets</a>
            <a [routerLink]="['preferences']" [ngClass]="currentSubRoute == 'preferences' ? 'white-link-selected' : 'white-link'">My Preferences</a>
            <a *ngIf="regActivityInclusion" [routerLink]="['regupdate']" [ngClass]="currentSubRoute == 'regupdate' ? 'white-link-selected' : 'white-link'">Regupdate</a>
            <a *ngIf="userManagementVisible" [routerLink]="['user-management']" [ngClass]="currentSubRoute == 'user-management' ? 'white-link-selected' : 'white-link'">User Management</a>
            <a *ngIf="customSettingsVisible" [routerLink]="['custom-site-settings']" [ngClass]="currentSubRoute == 'custom-site-settings' ? 'white-link-selected' : 'white-link'">Custom Site Settings</a>
          </div>
          <div *ngIf="currentRoute == 'administration' || currentRoute == 'regupdate-admin'">
            <a *ngIf="regActivityInclusion" [routerLink]="['regupdate-admin']" [ngClass]="currentSubRoute == 'regupdate-admin' ? 'white-link-selected' : 'white-link'">Regupdate Admin</a>
          </div>          
          <div *ngIf="currentRoute == 'myBookmarks'">
            <a [routerLink]="'myBookmarks'" class="white-link">My Bookmarks</a>
          </div>
        </div>
      </div>
    </div>
    <hotline-header *ngIf="currentRoute == 'hotline' && customPermissions.GeneralPermissions.hotline.Visible"></hotline-header>

    <div [ngClass]="(currentSubRoute == 'external' && currentRoute == 'tools') ? '' : 'container'">
      <div style="margin:10px; margin-bottom:10px;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <br /><br />
  <app-footer [footerImage]="footerImage" [footerAddress]="footerAddress" [footerPhone]="footerPhone" class="footer" *ngIf="!snBranded"></app-footer>

</div>
<!-- <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-child-name" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-child-name" class="modal-title pull-left">The website is about to logout</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mrgbtm">
          <div class="col-md-6">
              <button type="button" (click)="logout()" class="btn btn-default">Logout</button>
          </div>
          <div class="col-md-6">
              
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div id="notificationBanner" *ngIf="showNotificationBanner" style="padding: 15px;">
  <p><b>Important notice: Your account will be logged out in {{warningTimeRemaining* 1000 | date: 'mm:ss'}} minutes due to our security policies.</b></p>
  <p>For the safety and security of your personal information, we periodically log out accounts. Please be sure to save any work or data you have open before the logout takes effect.</p>
  <p>You can extend your session by clicking 'Stay', or log back in at any time to continue using our services. Account logouts occur regularly to help prevent unauthorized access to your information.</p>
  <p> Thank you for your cooperation.</p>
  <button type="button" (click)="logout()" class="btn btn-default">Logout</button>  <button type="button" (click)="stay()" class="btn btn-default">Stay</button>
</div>
<back-to-top *ngIf="userLoggedIn"></back-to-top>