

<div *ngIf="!informationSelected && loading" class="row" style="text-align:center;">
  <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
</div>


 <div *ngIf="informationSelected">
    <h1 *ngIf="!loading">Resource Details</h1>
    <div id="resource-details">
      <h2 class="seperator">{{informationSelected.Title}}</h2>
      <div class="body-content body-content-padding"><span class="body-content-label">Document Type: </span>{{informationSelected.InformationTypeName}}</div>
      <div class="body-content body-content-padding"><span class="body-content-label">Topic: </span>{{informationSelected.TopicDesc}}</div>
      <div class="body-content body-content-padding"><span class="body-content-label">Jurisdiction: </span>{{informationSelected.Jurisdiction}}</div>
      <div *ngIf="informationSelected.Teaser" class="body-content body-content-padding"><span class="body-content-label">Summary: </span><div [innerHtml]="informationSelected.Teaser"></div></div>
      <h2 class="seperator"></h2>
  
      <div *ngIf="!isLimitedAccess" class="body-content body-content-padding"><div dynamicAnchor [innerHtml]="informationSelected.Body | safeHtml"></div></div>
      <div *ngIf="isLimitedAccess && informationSelected.Body">
        <div class="limited-access-row" [innerHtml]="informationSelected.Body"></div>
        <div class="modal-header modal-header-green">
          Limited Access
        </div>
        <div class="modal-body">
          <h1>
            The resource you are trying to access is reserved for EHS subscribers
          </h1>
  
          <div class="body-content">
            <a href="http://interactive.blr.com/hrhero-demo-request">Request a demo</a> to access time saving resources, workflow tools and much more.
          </div>
        </div>
      </div>
      <div class="document-btns" style="padding-top:15px;">
  
        <download-information *ngIf="linkType(informationSelected.FilePath) == 3" [informationId]="informationSelected.InformationId" [documentType]="informationSelected.DocumentType" [fileName]="informationSelected.Title"></download-information>
        <a *ngIf="linkType(informationSelected.FilePath) == 2 && !isLimitedAccess" [href]="informationSelected.FilePath" target="_blank" class="btn-orange">Go</a>
        <a *ngIf="linkType(informationSelected.FilePath) == 2 && isLimitedAccess" (click)="challengeModal.open()" class="btn-orange">Go</a>
        <bookmarks [bookmarkTitle]="informationSelected.Title" [informationId]="informationSelected.InformationId" [informationTypeId]="informationSelected.InformationTypeId" [topicId]="informationSelected.TopicId" [categoryId]="informationSelected.CategoryId" [parentInformationTypeId]="informationSelected.ParentInformationTypeId"></bookmarks>
      </div>
  
    </div>
    <div *ngIf="informationSelected != null" style="display:none;">Unified ID: {{informationSelected.SourceInfoId}}</div>
</div>



<div *ngIf="!informationSelected">
  <h1 *ngIf="!loading">All Resources</h1>
  <div [hidden]="loading" style="margin-top:20px;" class="row">


    <div class="col-lg-3" style="margin-bottom:20px;">
      <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
      <div class="outer-filter-panel">

        <collapse-header dataTargetId="#state" headerTitle="States"></collapse-header>
        <div id="state" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
            <tr *ngFor="let item of resourceItems?.Jurisdictions">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterJurisdiction(item.JurisdictionId, $event.target.checked, item.JurisdictionName)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#category" headerTitle="Categories"></collapse-header>
        <div id="category" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
            <tr *ngFor="let item of resourceItems?.Categories">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterCategory(item.CategoryId, $event.target.checked, item.CategoryName)" [checked]="item.Checked">{{item.CategoryName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#topic" headerTitle="Topics"></collapse-header>
        <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
            <tr *ngFor="let item of resourceItems?.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#doc" headerTitle="Document Types"></collapse-header>
        <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table">
            <tbody>
            <tr *ngFor="let item of resourceItems?.InformationTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterDocument(item.InformationTypeId, $event.target.checked, item.InformationTypeName)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#source" headerTitle="Source" *ngIf="hasEnviro&&hasSafety"></collapse-header>
        <div id="source" class="collapse in" style="overflow-y:auto; max-height:186px;" *ngIf="hasEnviro&&hasSafety">
          <table class="table">
            <tbody>
              <tr *ngFor="let item of resourceItems?.Sources">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterSources(item.DatabaseId, $event.target.checked)" [checked]="item.Checked">{{getSource(item.DatabaseId)}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-lg-9">

      <div class="ar-tab-container">
        <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
          <div class="inline-text">{{tag.Name}}</div>
          <div class="delete-button" style="height: 100%" (click)="clearTag(tag)">
            <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
              <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
            </svg>
          </div>
        </div>
      </div>

      <div class="white-well">
        <div [hidden]="(resourceItems?.Information?.length  || 0) != 0">No resources found to match your search criteria. Please adjust your filters and try again.</div>
        <table  [hidden]="(resourceItems?.Information?.length  || 0) == 0" datatable [dtOptions]="dtOptions" class="table table-striped all-resources-table" style="width: 100%;">
          <thead class="thead">
            <tr>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Type <span></span> </a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Topic <span></span> </a>
              </th>
              <th style="min-width: 350px;">
                <a class="text-nowrap" style="cursor:pointer">Title <span></span> </a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">State <span></span> </a>
              </th>
              <th *ngIf="hasSafety && hasEnviro">
                <a class="text-nowrap" style="cursor:pointer">Source <span></span> </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of resourceItems.Information">
              <td>{{getType(item.InformationTypeId).InformationTypeName}}</td>
              <td>{{getTopic(item.TopicId).TopicName}}</td>
              <td><a [routerLink]="['/' + item.Url]" style="cursor:pointer;">{{item.Title}}</a></td>
              <td>{{getState(item.JurisdictionId).JurisdictionName}}</td>
              <td *ngIf="hasSafety && hasEnviro">{{getSource(item.DatabaseId)}}</td>
            </tr>
          </tbody>        
        </table>

      </div>
    </div>
  </div>
</div>

<challenge-modal #challengeModal></challenge-modal>
