import { Component, Provider, ElementRef, OnInit } from '@angular/core';

import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { Information } from '../../shared/types/information.type'

@Component({
  selector: 'latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.css'],
  providers: [EhsHeroSvc]
})

export class LatestNewsComponent {

  newsArticles: Information[];

  constructor(
    private _heroService: EhsHeroSvc
  ) { }

  ngOnInit() {

    this._heroService.getTopInformation(1, 5).then(information => {

      if (information != null) {
        this.newsArticles = information;
      }

    });

  }
}
