
import {take} from 'rxjs/operators';
import { Component, ViewChild } from "@angular/core";
import { EhsHeroSvc } from "../../shared/services/ehs-hero.service";
import { UserProfileService } from "../../shared/services/user-profile.service";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { PermissionTier, PermissionSetting } from "../../shared/types/permission-tier.type";
import { PermissionTierApi } from "../../shared/types/permission-tier-api.type";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { UserManagementService } from "../../shared/services/user-management.service";
import { ConfirmDeleteComponent } from "../../shared/components/confirm-delete.component";
import { InclusionsMarket } from "../../shared/types/inclusions-market.type";

@Component({
  selector: 'permission-tiers',
  templateUrl: './permission-tiers.component.html',
  styleUrls: ['./permission-tiers.component.css']
})

export class PermissionTiersComponent {
  @ViewChild('confirmModal')
  confirmModal: ConfirmDeleteComponent;
  form: FormGroup;
  editTierId: number;
  editTierPermissions: PermissionTier = new PermissionTier();
  editTierName: string;
  editTierVisible: boolean = false;
  tiersList: PermissionTierApi[]=[];
  hasEnviro: boolean = false;
  hasSafety: boolean = false;
  loading: boolean;
  markets: Array<InclusionsMarket>;
  deleteConfirmationMessage: string;
  generalPermissions: Array<PermissionSetting>;
  widgetPermissions: Array<PermissionSetting>;

  constructor(private _up: UserProfileService, private _heroService: EhsHeroSvc
    , private _mb: MembershipDataService, private fb: FormBuilder
    , private _userManagementService: UserManagementService) {
  }
  ngOnInit() {
    let accessLevels = this._mb.dataAccessLevels();
    this.hasSafety = accessLevels.includes("3");
    this.hasEnviro = accessLevels.includes("4");
    this.getTiers();
    this.markets = this._mb.getAllPlans().filter(f => !((f.Inclusions.length == 2 && this._mb.hasTool("CHB", f.Inclusions) && this._mb.hasTool("PB", f.Inclusions)) || (f.Inclusions.length == 1 && (this._mb.hasTool("CHB", f.Inclusions) || this._mb.hasTool("PB", f.Inclusions)))));
    this.form = this.fb.group({
      PermissionTierId: 0,
      Name: ['', Validators.required],
      MembershipId: this.markets[0].MembershipId
    });
  }

  getTiers() {
    this.loading = true;
    this._userManagementService.getPermissionTiers().then(data => {
      if (data) {
        this.tiersList = data;
        this.loading = false;
      }
    });
  }

  generateArray(obj) {
    return Object.keys(obj).map((key) => { return obj[key] });
  }

  arrangePermissionsIntoArray(membershipId) {
    let market = this.markets.find(f => f.MembershipId == this.form.get('MembershipId').value).Market;
    var dispayPermissions = this.generateArray(this.editTierPermissions.GeneralPermissions).filter(f => !f.DisplayName.includes('Custom widgets'));
    if (this.hasSafety && (market.includes('Safety') || market.includes('Combo'))) {
      dispayPermissions = dispayPermissions.concat(this.generateArray(this.editTierPermissions.SafetyPermissions));
    }
      
    
    if (this.hasEnviro && (market.includes('Enviro') || market.includes('Combo'))) {
      dispayPermissions = dispayPermissions.concat(this.generateArray(this.editTierPermissions.EnviroPermissions));

    }
    return dispayPermissions;
  }

  applyExclusions(membershipId, permissions) {
    permissions.forEach(f => {
      f.Visible = this.isIncluded(f, membershipId);
    })
  }

  isIncluded(setting: PermissionSetting, membershipId: number) {
    let inclusions = this.markets.find(f => f.MembershipId == membershipId).Inclusions;
    var isIncluded = this._userManagementService.checkTierInclusions(setting, inclusions);
    return isIncluded;
  }

  getWidgetPermissions(membershipId: number) {
    let array = this.generateArray(this.editTierPermissions.GeneralPermissions).filter(f => f.DisplayName.includes('Custom widgets'));
    return array;
  }

  getOptions(membershipId) {
    this.generalPermissions = this.arrangePermissionsIntoArray(membershipId);
    this.applyExclusions(membershipId, this.generalPermissions);
    this.widgetPermissions = this.getWidgetPermissions(membershipId);
    this.applyExclusions(membershipId, this.widgetPermissions);
  }

  getExcluded(tier: PermissionTierApi) {
    var permissions: PermissionTier = JSON.parse(tier.PermissionTierJSON);
    let inclusions = this.markets.find(f => f.MembershipId == tier.MembershipId).Inclusions;
    var result: string[] = [];
    this.generateArray(permissions.GeneralPermissions).filter(f => !f.Visible && this.isIncluded(f, tier.MembershipId)).forEach(p => {
      result.push(p.DisplayName);
    });

    if (permissions.EnviroPermissions)
      this.generateArray(permissions.EnviroPermissions).filter(f => !f.Visible && this.isIncluded(f, tier.MembershipId)).forEach(p => {
        result.push(p.DisplayName);
      });
    if (permissions.SafetyPermissions)
      this.generateArray(permissions.SafetyPermissions).filter(f => !f.Visible && this.isIncluded(f, tier.MembershipId)).forEach(p => {
        result.push(p.DisplayName);
      });
    return result.join(', ');
  }

  addNewTier() {
    this.editTierPermissions = new PermissionTier();
    this.editTierVisible = true;
    this.form.setValue({
      PermissionTierId: 0,
      Name: null,
      MembershipId: this.markets[0].MembershipId
    });

    this.getOptions(this.markets[0].MembershipId);
    setTimeout(() => {
      let el = document.getElementById("newTier");
      el.scrollIntoView();
    }, 20);
  }

  editTier(tierId: number) {
    var tier = this.tiersList.find(f => f.PermissionTierId == tierId);
    this.editTierPermissions = JSON.parse(tier.PermissionTierJSON);
    this.editTierVisible = true;

    this.form.setValue({
      PermissionTierId: tierId,
      Name: tier.Name,
      MembershipId: tier.MembershipId
    });
    this.generalPermissions = this.arrangePermissionsIntoArray(tier.MembershipId);
    this.widgetPermissions = this.getWidgetPermissions(tier.MembershipId);
    setTimeout(() => {
      let el = document.getElementById("newTier");
      el.scrollIntoView();
    }, 20);
    
  }

  saveTier() {
    
    var tier = this.form.value as PermissionTierApi;
    tier.Market = this.markets.find(f => f.MembershipId == tier.MembershipId).Market;
    tier.PermissionTierJSON = JSON.stringify(this.editTierPermissions);

    this._userManagementService.setPermissionTier(tier).then(data => {
      this.getTiers();
      this.editTierVisible = false;
    });
  }

  deleteTier(tierId: number) {
    let users = this.tiersList.find(f => f.PermissionTierId == tierId).UsersAssigned;
    if (users == 0) {
      this.deleteConfirmationMessage = "Are you sure you want to delete this tier?";
    } else {
      this.deleteConfirmationMessage = `Are you sure you want to delete this tier? there are ${users} users assigned to it. Deleting the tier will give assigned users access to all currently closed sections.`
    }
    this.confirmModal.open();
    this.confirmModal.confirmDelete.pipe(take(1)).subscribe(data => {
      if (data) {
        this._userManagementService.deletePermissionTier(tierId).then(data => {
          this.getTiers();
          if (this._mb.allUsers != null)
              this._mb.allUsers.filter(f => f.PermissionTierId == tierId).forEach(f => {
              f.PermissionTierId = 0;
            });
        });
        
        this.confirmModal.dismiss();
      }
    });
  }
}
