import { Component, Input, Output, ViewChild, TemplateRef, EventEmitter } from '@angular/core';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.component.html'
})
export class ConfirmDeleteComponent {
  @Input("confirmationMessage") confirmationMessage: string;
  @Output() confirmDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
  modal: BsModalRef;
  @ViewChild('template') private templateRef: TemplateRef<any>;

  constructor(public modalService: BsModalService
  ) {  }
  ngOnInit() {
    
  }

 open()
 {
    this.modal = this.modalService.show(this.templateRef);
 }

  dismiss() {
   this.modal.hide();
   this.confirmDelete.emit(false);
  }

  onModalHide() {
   this.confirmDelete.emit(false);
  }

  
}