import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { EhsHeroSvc } from '../shared/services/ehs-hero.service'
import { Information } from '../shared/types/information.type' 
import { SearchModel } from '../shared/types/search.type'
import { SearchTerms } from '../shared/types/search-terms'
import { MembershipDataService } from '../shared/services/membership.data.service'
import { EhsSearchService } from '../shared/services/ehs-search.service';

@Component({
  selector: 'news',
  templateUrl: './search.html',
  styleUrls: ['./search.css', './tag.scss']
})

export class Search {

  searchItems: SearchModel;
  allItemsMaster: SearchModel;
  informationMaster: Information[]=[];
  searchTermValue: string = null;
  tags: SearchTerms[] = new Array<SearchTerms>();
  loading: boolean = false;
  hasEnviro: boolean = false;
  hasSafety: boolean = false;
  allKeywords: boolean = true;
  spellingCorrection: string = "";

  allCollapsed: boolean = false;
  stateCollapsed: boolean = false;
  languageCollapsed: boolean = false;
  categoryCollapsed: boolean = false;
  topicCollapsed: boolean = false;
  docCollapsed: boolean = false;
  allRecordsCount: number;
  newRecordsCount: number;
  //pastNrDays: number = 30;
  //fromDate: any;
  @ViewChild('filters') elementView: ElementRef;
  filtersHeight: number;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private _router: Router,
    private _heroService: EhsSearchService,
    private mbDataService: MembershipDataService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      if (params['searchTerm']) {
        this.searchTermValue = params['searchTerm']
        let accessLevels = this.mbDataService.dataAccessLevels();
        this.hasSafety = accessLevels.includes("3");
        this.hasEnviro = accessLevels.includes("4");
        //let today = new Date();
        //this.fromDate = today.setDate(new Date().getDate() - this.pastNrDays);
        this.search(params['searchTerm'], true);
        // this.search(params['searchTerm'], true, this.pastNrDays).then(obj => {
        //     if (this.searchItems != null) {
               
        //         if (params['type'] && params['type'] == "new") {
        //             this.searchItems.AllChecked = false;
        //             this.allCollapsed = true;
        //             //this.filterNewItems("new", true);
        //         }
        //         else {
        //             this.searchItems.AllChecked = true;
        //             this.allCollapsed = false;
        //         }
        //     }
        // });

        this.tags = new Array<SearchTerms>();
        let tags = new Array<string>();
        tags = params['searchTerm'].split(',');

        if (tags.length == 1) {
            if (tags[0]!="null") {
                let term: SearchTerms = new SearchTerms;

                term.value = tags[0];
                term.display = tags[0];
                this.tags.push(term);
            } 
        }
        else {
          for (var i = 0; i < tags.length; i++) {
            let term: SearchTerms = new SearchTerms;
            term.value = tags[i];
            term.display = tags[i];
            this.tags.push(term);
          }
        }
      }
     
      else
        this.searchItems = null;
    });
  }

  async search(searchTerm: string, pageLoad: boolean = false, pastDays:number=0) {
    let savedFilters = JSON.parse(sessionStorage.getItem("search-filters"));
    let allCheck = savedFilters ? savedFilters.AllChecked : true;

    this.loading = true;
    this.expandAllFilters();
    this.spellingCorrection = "";
    let searchItems = await this._heroService.getInformationBySearchTerm(searchTerm, this.mbDataService.dataAccessLevels(), this.allKeywords, null, pastDays);

    if (searchItems != null && searchItems.Information.length > 0) {
    
      this.searchItems = searchItems ;
      this.allItemsMaster = JSON.parse(JSON.stringify(this.searchItems)); 
      this.allRecordsCount = this.searchItems.Information.length;
      //this.searchItems.AllChecked = true;
      this.informationMaster = searchItems.Information;
      this.restoreFilters(searchTerm);

      // if (savedFilters && (savedFilters.Categories.length > 0 || savedFilters.InformationTypes.length > 0 || savedFilters.Jurisdictions.length > 0 || savedFilters.Languages.length > 0 || savedFilters.Sources.length > 0 ||
      //     savedFilters.Topics.length > 0) && !savedFilters.AllChecked) {
          //this.filterNewItems("new", true);
      // }
      // else {
      //   if(this.searchItems.NewItems)
      //   {
      //     this.newRecordsCount = this.searchItems.NewItems.length;
      //   }
      // }  
      
    } else {
      this.searchItems = null;
      this.spellingCorrection = await this._heroService.getSpellcheck(searchTerm, this.mbDataService.dataAccessLevels());
    }

    this.loading = false;
    setTimeout(() => { if (this.elementView) { this.filtersHeight = this.elementView.nativeElement.offsetHeight; } }, 0);

    if (!pageLoad) {
      if (searchTerm == 'null') {
        searchTerm = '';
        this._router.navigateByUrl("/search");
      } else {
        this._router.navigateByUrl("/search/" + searchTerm);
      }
    }
  }

  restoreFilters(searchTerm) {
      let savedFilters = this._heroService.popSavedFilters("search-filters");
      if (savedFilters != null && savedFilters.SearchTermValue == searchTerm) {
          this.searchItems.Jurisdictions.forEach(f => f.Checked = savedFilters.Jurisdictions.some(s => s.JurisdictionId == f.JurisdictionId));
          this.searchItems.Topics.forEach(f => f.Checked = savedFilters.Topics.some(s => s.TopicId == f.TopicId));
          this.searchItems.InformationTypes.forEach(f => f.Checked = savedFilters.InformationTypes.some(s => s.InformationTypeId == f.InformationTypeId));
          this.searchItems.Categories.forEach(f => f.Checked = savedFilters.Categories.some(s => s.CategoryId == f.CategoryId));
          this.searchItems.Languages.forEach(f => f.Checked = savedFilters.Languages.some(s => s.Language == f.Language));
          this.searchItems.Sources.forEach(f => f.Checked = savedFilters.Sources.some(s => s.DatabaseId == f.DatabaseId));
          //this.searchItems.AllChecked = savedFilters.AllChecked;

          this.filter(7);
          this.filterSelections(7);
      }
  }

  anyOptionChecked(property: string) {
    return (this.searchItems[property].length > this.searchItems[property].filter(a => a.Checked).length);
  }

  clearFilters() {
      this.searchItems.Sources.forEach((item) => {
          item.Checked = false;
      });
      this.searchItems.Languages.forEach((item) => {
          item.Checked = false;
      });
      this.searchItems.Jurisdictions.forEach((item) => {
          item.Checked = false;
      });
      this.searchItems.Categories.forEach((item) => {
          item.Checked = false;
      });
      this.searchItems.Topics.forEach((item) => {
          item.Checked = false;
      });
      this.searchItems.InformationTypes.forEach((item) => {
          item.Checked = false;
      });
  }

  clearAll() {
      this.clearFilters();
      this.searchItems = JSON.parse(JSON.stringify(this.allItemsMaster));
      this.informationMaster = this.searchItems.Information;
      this.filter(7);

      // if (!this.searchItems.AllChecked) {
      //     this.searchItems.AllChecked = true;
      // }

      if (this.searchTermValue=='null') {
          //this.filterNewItems("new", true);
      }
      else {         
          this._heroService.clearSavedFilters("search-filters");
          this.filterSelections(7);
      }  
  }

  filterSource(databaseId, checked) {
    this.searchItems.Sources.filter(f => f.DatabaseId == databaseId).forEach((item) => {
      item.Checked = checked;
    });
    this.saveFilters();
    this.filter(1);
    this.filterSelections(1);
  }

  filterDocument(informationTypeId, checked) {

    this.searchItems.InformationTypes.filter(f => f.InformationTypeId == informationTypeId || informationTypeId == 'all').forEach((item) => { item.Checked = checked; });

    this.saveFilters();
    this.filter(2);
    this.filterSelections(2);
  }

  filterLanguage(language, checked) {

    this.searchItems.Languages.filter(f => f.Language == language || language == 'all').forEach((item) => {
      item.Checked = checked;
    });
    this.saveFilters();
    this.filter(3);
    this.filterSelections(3);
  }

  filterJurisdiction(jurisdictionId, checked) {
    this.searchItems.Jurisdictions.filter(f => f.JurisdictionId == jurisdictionId || jurisdictionId == 'all').forEach((item) => { item.Checked = checked; });

    this.saveFilters();
    this.filter(4);
    this.filterSelections(4);
  }

  filterCategory(categoryId, checked) {

    this.searchItems.Categories.filter(f => f.CategoryId == categoryId || categoryId == 'all').forEach((item) => {
      item.Checked = checked;
    });
    this.saveFilters();
    this.filter(5);
    this.filterSelections(5);
  }

  filterTopic(topicId, checked) {

    this.searchItems.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => { item.Checked = checked; });
    this.saveFilters();
    this.filter(6);
    this.filterSelections(6);
  }

  // filterNewItems(type: string, checked) {

  //     if (type === "new") {
  //         if (this.searchItems.AllChecked == true) {
  //             this.saveFilters();
  //         }
  //         this.searchItems.AllChecked = false;

  //         this.informationMaster = this.searchItems.NewItems.filter(item => (
  //             (this.searchItems.Sources.every(f => (!f.Checked)) || this.searchItems.Sources.some(f => (f.DatabaseId == item.DatabaseId && f.Checked))) &&
  //             (this.searchItems.InformationTypes.every(f => (!f.Checked)) || this.searchItems.InformationTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked))) &&
  //             (this.searchItems.Languages.every(f => (!f.Checked)) || this.searchItems.Languages.some(f => (f.Language == item.Language && f.Checked))) &&
  //             (this.searchItems.Jurisdictions.every(f => (!f.Checked)) || this.searchItems.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked))) &&
  //             (this.searchItems.Categories.every(f => (!f.Checked)) || this.searchItems.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked))) &&
  //             (this.searchItems.Topics.every(f => (!f.Checked)) || this.searchItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)))));  

  //         this.saveFilters();
  //         this.searchItems.Information = this.informationMaster;
  //         this.filterSelections(7);        
  //     }
  //     else {
  //         this.searchItems = JSON.parse(JSON.stringify(this.allItemsMaster));
  //         this.informationMaster = this.searchItems.Information;

  //         let savedFilters = JSON.parse(sessionStorage.getItem("search-filters"));
  //         if (savedFilters != null && savedFilters.SearchTermValue == this.searchTermValue && (savedFilters.Categories.length > 0 || savedFilters.InformationTypes.length > 0 || savedFilters.Jurisdictions.length > 0 || 
  //             savedFilters.Languages.length > 0 || savedFilters.Sources.length > 0 || savedFilters.Topics.length > 0)) {
  //             this.restoreFilters(this.searchTermValue);
  //         }

  //         this.saveFilters();
  //         this.searchItems.AllChecked = true;
  //     }   
  // }

  filter(selector) {
      this.searchItems.Information = this.getFilterResult("");
  }

  getFilterResult(section) {

      var information = this.informationMaster.filter(item => (
          (this.searchItems.Sources.every(f => (!f.Checked)) || this.searchItems.Sources.some(f => (f.DatabaseId == item.DatabaseId && f.Checked)) || section == 'sources') &&
          (this.searchItems.InformationTypes.every(f => (!f.Checked)) || this.searchItems.InformationTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked)) || section == 'types') &&
          (this.searchItems.Languages.every(f => (!f.Checked)) || this.searchItems.Languages.some(f => (f.Language == item.Language && f.Checked)) || section == 'languages') &&
          (this.searchItems.Jurisdictions.every(f => (!f.Checked)) || this.searchItems.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)) || section == 'jurisdictions') &&
          (this.searchItems.Categories.every(f => (!f.Checked)) || this.searchItems.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked)) || section == 'categories') &&
          (this.searchItems.Topics.every(f => (!f.Checked)) || this.searchItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics')));  

      // if (this.searchItems.AllChecked) {
          // let newInfoMaster = Object.assign([], this.allItemsMaster.NewItems);

          // var newInfoFiltered = newInfoMaster.filter(item => (
          //     (this.searchItems.Sources.every(f => (!f.Checked)) || this.searchItems.Sources.some(f => (f.DatabaseId == item.DatabaseId && f.Checked)) || section == 'sources') &&
          //     (this.searchItems.InformationTypes.every(f => (!f.Checked)) || this.searchItems.InformationTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked)) || section == 'types') &&
          //     (this.searchItems.Languages.every(f => (!f.Checked)) || this.searchItems.Languages.some(f => (f.Language == item.Language && f.Checked)) || section == 'languages') &&
          //     (this.searchItems.Jurisdictions.every(f => (!f.Checked)) || this.searchItems.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)) || section == 'jurisdictions') &&
          //     (this.searchItems.Categories.every(f => (!f.Checked)) || this.searchItems.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked)) || section == 'categories') &&
          //     (this.searchItems.Topics.every(f => (!f.Checked)) || this.searchItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics')));  
              
          // this.newRecordsCount = newInfoFiltered.length;
          // this.allRecordsCount = information.length;
      // }
      // else {
      //     let allInfoMaster = Object.assign([], this.allItemsMaster.Information);

      //     var allInfo = allInfoMaster.filter(item => (
      //         (this.searchItems.Sources.every(f => (!f.Checked)) || this.searchItems.Sources.some(f => (f.DatabaseId == item.DatabaseId && f.Checked)) || section == 'sources') &&
      //         (this.searchItems.InformationTypes.every(f => (!f.Checked)) || this.searchItems.InformationTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked)) || section == 'types') &&
      //         (this.searchItems.Languages.every(f => (!f.Checked)) || this.searchItems.Languages.some(f => (f.Language == item.Language && f.Checked)) || section == 'languages') &&
      //         (this.searchItems.Jurisdictions.every(f => (!f.Checked)) || this.searchItems.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)) || section == 'jurisdictions') &&
      //         (this.searchItems.Categories.every(f => (!f.Checked)) || this.searchItems.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked)) || section == 'categories') &&
      //         (this.searchItems.Topics.every(f => (!f.Checked)) || this.searchItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics')));  

      //     this.newRecordsCount = information.length;
      //     this.allRecordsCount = allInfo.length;
      // }
      return information;
  }


  filterSelections(selector) {

    if (selector != 1) {
      this.searchItems.Sources.forEach(obj => {
        this.searchItems.Sources.filter(f => f.DatabaseId == obj.DatabaseId)[0].Count = this.getFilterResult('sources').filter(f => f.DatabaseId == obj.DatabaseId).length;
        });
    }
    if (selector != 2) {
      this.searchItems.InformationTypes.forEach(obj => {
        this.searchItems.InformationTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('types').filter(f => f.InformationTypeId == obj.InformationTypeId).length;
      });
    }
    if (selector != 3) {
      this.searchItems.Languages.forEach(obj => {
        this.searchItems.Languages.filter(f => f.Language == obj.Language)[0].Count = this.getFilterResult('languages').filter(f => f.Language == obj.Language).length;
      });
    }
    if (selector != 4) {
      this.searchItems.Jurisdictions.forEach(obj => {
        this.searchItems.Jurisdictions.filter(f => f.JurisdictionId == obj.JurisdictionId)[0].Count = this.getFilterResult('jurisdictions').filter(f => f.JurisdictionId == obj.JurisdictionId).length;
        });
    }
    if (selector != 5) {
      this.searchItems.Categories.forEach(obj => {
        this.searchItems.Categories.filter(f => f.CategoryId == obj.CategoryId)[0].Count = this.getFilterResult('categories').filter(f => f.CategoryId == obj.CategoryId).length;
      });
    }
    if (selector != 6) {
      this.searchItems.Topics.forEach(obj => {
        this.searchItems.Topics.filter(f => f.TopicId == obj.TopicId)[0].Count = this.getFilterResult('topics').filter(f => f.TopicId == obj.TopicId).length;
      });
    }

    setTimeout(() => { if (this.elementView) { this.filtersHeight = this.elementView.nativeElement.offsetHeight; } }, 0);
  }

  itemUpdated(event) {
    let searchText = '';
    searchText = this.tags.map(function (e) {
      return e.value;
    }).join(', ');
    if (searchText == '') this._router.navigateByUrl("/search/");

    else
      this.search(searchText, false);
  }

  saveFilters() {

    var filters = {
        Jurisdictions: this.searchItems.Jurisdictions.filter(f => f.Checked),
        Categories: this.searchItems.Categories.filter(f => f.Checked),
        Topics: this.searchItems.Topics.filter(f => f.Checked),
        InformationTypes: this.searchItems.InformationTypes.filter(f => f.Checked),
        Sources: this.searchItems.Sources.filter(f => f.Checked),
        Languages: this.searchItems.Languages.filter(f => f.Checked),
        SearchTermValue: this.searchTermValue,
        //AllChecked: this.searchItems.AllChecked
    };
    this._heroService.saveSearchFilters("search-filters", filters);
  }

  expandAllFilters() {
    this.allCollapsed = false;
    this.stateCollapsed = false;
    this.languageCollapsed = false;
    this.categoryCollapsed = false;
    this.topicCollapsed = false;
    this.docCollapsed = false;
  }

  collapse(stateCollapsed) {
      return !stateCollapsed;
  }
}