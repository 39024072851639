import { Injectable, Provider, Component } from "@angular/core";
import { HttpClient, HttpHeaders, HttpSentEvent  } from '@angular/common/http'
import { EhsHeroSvc } from "./ehs-hero.service";
import { Router } from "@angular/router";
import { EhsHeroConfig } from '../../../environments/environment'
import { MembershipDataService } from "./membership.data.service";
import { SearchModel } from "../types/search.type";


@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

export class EhsSearchService {


  constructor(private http: HttpClient, private ehsService: EhsHeroSvc, private router: Router, private mbDataService: MembershipDataService) {
    
  }

  getInformationBySearchTerm(searchtext: string, sourceDatabaseIds, allKeywords: boolean, infoTypes = null, pastDays: number=0) {

    let headers: any = {
      //'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    let exclusions = "";
    if (!this.mbDataService.customPermissions.GeneralPermissions.news.Visible || !this.mbDataService.hasTool("News"))
      exclusions += "news,";
    if (!this.mbDataService.customPermissions.GeneralPermissions.regActivity.Visible || !this.mbDataService.hasTool("RegActivity"))
      exclusions += "regulatory activity,";
    if (!this.mbDataService.customPermissions.GeneralPermissions.allresources.Visible || !this.mbDataService.hasTool("AllResources"))
      exclusions += "all resources,";
    if (!this.mbDataService.customPermissions.GeneralPermissions.training.Visible || !this.mbDataService.hasTool("Training"))
      exclusions += "training";

    return this.http.get<SearchModel>(EhsHeroConfig.endpoint + '/Search?searchtext=' + searchtext.replace("'", "") + '&userId=' + this.mbDataService.user.CustomerId + '&searchTerms=' + null + '&sourceDatabaseIds=' + sourceDatabaseIds + '&exclusions=' + exclusions + '&allKeywords=' + allKeywords + '&pastDays=' + pastDays, options)
      .toPromise();
  }

  getInformationBySearchTermAndModel(searchtext, model) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.get<SearchModel>(EhsHeroConfig.endpoint + '/Search?searchtext=' + searchtext + '&searchModel=' + JSON.stringify(model), options)
      .toPromise();
  }

  getSpellcheck(searchTerm: string, sourceDatabaseIds:string) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    return this.http.get<string>(EhsHeroConfig.endpoint + '/Search/Spell?searchTerm=' + searchTerm.replace("'", "")  + '&sourceDatabaseIds=' + sourceDatabaseIds, options)
      .toPromise();
  }

  getSuggestions(searchTerm: string, sourceDatabaseIds: string) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    return this.http.get<Array<string>>(EhsHeroConfig.endpoint + '/Search/Suggest?searchTerm=' + searchTerm.replace("'", "") + '&sourceDatabaseIds=' + sourceDatabaseIds, options)
      
  }

  saveSearchFilters(key: string, filters: any) {
    sessionStorage.removeItem(key);
    sessionStorage.setItem(key, JSON.stringify(filters));
  }

  popSavedFilters(key: string) {
    let filters = JSON.parse(sessionStorage.getItem(key));
    sessionStorage.removeItem(key);
    return filters;
  }

  clearSavedFilters(key: string) {
    sessionStorage.removeItem(key);
  }
}
