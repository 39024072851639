import { Injectable } from "@angular/core";
import { HttpClient  } from '@angular/common/http'
import { MembershipDataService } from "./membership.data.service";
import { EhsHeroConfig } from "../../../environments/environment";
import { PermissionTierApi } from "../types/permission-tier-api.type";
import { CCCustomer } from "../types/cc-customer";
import { PermissionTier } from "../types/permission-tier.type";
import { UserProfile } from "../types/user-profile.type";
import { Widget } from "../types/widget.type";
import { CCMembership } from '../../shared/types/cc-membership';
import { CCUser } from '../types/cc-user'
import { map } from 'rxjs/operators';
import { CustomTag } from "../types/CustomTag";
import { AsyncSubject, Observable, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'  
})

export class UserManagementService {

  private _token: string;
  private userSubject = new AsyncSubject<any>();

 
  constructor(private http: HttpClient, private mbDataService: MembershipDataService) {
    
  }

  getCustomerCentralUser(userId: number) {
    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }
    let options =  {
      headers: headers
   }

   
    return this.http.get<CCCustomer>(EhsHeroConfig.endpoint + '/CustomerCentral/GetUser/' + userId + "/false", options)
      .toPromise();
  }

  getUserProfile(userId:number, profileTypeId:number, market: string) {
    let headers: any = {
 //     'Token': this.token,
      'Content-Type': 'application/json'
    }
    let options =  {
      headers: headers
   }
   return this.http.get(EhsHeroConfig.endpoint + '/UserProfile/GetUserProfile?userId=' + userId + "&profileType=" + profileTypeId + "&market=" + market, options)
   .pipe( map(result =>  JSON.parse(result.toString()) as UserProfile)).toPromise();
    
    
  }

  getDefaultUserProfileForMarket(market: string) {
      let headers: any = {
      //    'Token': this.token,
          'Content-Type': 'application/json'
      }
      let options =  {
        headers: headers
     }
     return this.http.get(EhsHeroConfig.endpoint + '/UserProfile/GetDefaultUserProfileForMarket?market=' + market, options)
     .pipe( map(result =>  JSON.parse(result.toString()) as UserProfile)).toPromise();
  }

  getPermissionTiers() {
    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }
    let userId = (this.mbDataService.userProfileTypeId == 1) ? this.mbDataService.user.CustomerId : this.mbDataService.user.ParentUserId;
    let options =  {
      headers: headers
   }
    return this.http.get<PermissionTierApi[]>(EhsHeroConfig.endpoint + '/UserManagement/PermissionTiers/' + userId, options)
      .toPromise();
  }

  setPermissionTier(tier: PermissionTierApi) {
    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }
    let options =  {
      headers: headers
   }
    tier.UserId = this.mbDataService.user.UserId;
    tier.ApplicationId = EhsHeroConfig.applicationId;
    return this.http.post(EhsHeroConfig.endpoint + '/UserManagement/PermissionTier', tier, options)
    .toPromise();
  }

  deletePermissionTier(tierId: number) {
    let headers: any = {
//      'Token': this.token,
      'Content-Type': 'application/json'
    }
    let options =  {
      headers: headers
   }
    return this.http.delete(EhsHeroConfig.endpoint + '/UserManagement/PermissionTier/' + tierId, options).toPromise();
  }

  getAssignedTier(userId: number) {
    let headers: any = {
 //     'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    return this.http.get<PermissionTierApi>(EhsHeroConfig.endpoint + '/UserManagement/AssignedTier/' + userId, options)
      .toPromise();
  }

  assignTier(tierId: number, userId:number) {
    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    let assign = {
      PermissionTierId: tierId,
      UserId: userId
    };

    return this.http.put(EhsHeroConfig.endpoint + '/UserManagement/TierAssignment', assign, options).toPromise();
  }

  deleteAssignment(userId: number) {
    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

    return this.http.delete(EhsHeroConfig.endpoint + '/UserManagement/TierAssignment/' + userId, options).toPromise();
  }

  applyPermissionTier(tier: PermissionTier, userProfile: UserProfile) {
    userProfile.AdminPreferences.forEach(preference => {
      switch (preference.Name) {
        case "Safety Toolbox Trainer": preference.Visible = tier.SafetyPermissions.safetyToolboxtrainer.Visible; break;
        case "Regulatory Activity": preference.Visible = tier.GeneralPermissions.regActivity.Visible; break;
        case "Training Materials": preference.Visible = tier.GeneralPermissions.training.Visible; break;
        case "Regulatory Analysis Chart Builder": preference.Visible = tier.GeneralPermissions.chartBuilderTool.Visible; break;
        case "Plan Builder": preference.Visible = tier.GeneralPermissions.planBuilterTool.Visible; break;
        case "SDS Search": preference.Visible = tier.GeneralPermissions.sdsSearch.Visible; break;
        case "Report a Spill": preference.Visible = tier.EnviroPermissions.spillReporting.Visible; break;
        case "Compliance Calendar": preference.Visible = tier.GeneralPermissions.ComplianceCalendar.Visible; break;
        case "Show Custom widgets on Home page": preference.Visible = tier.GeneralPermissions.dashboardWidgets.Visible; break;
        case "Show Custom widgets on Tools page": preference.Visible = tier.GeneralPermissions.toolsWidgets.Visible; break;
        case "Hotline": preference.Visible = tier.GeneralPermissions.hotline.Visible; break;
        case "Regulations": preference.Visible = tier.GeneralPermissions.regulations.Visible; break;
        case "News": preference.Visible = tier.GeneralPermissions.news.Visible; break;
        case "All Resources": preference.Visible = tier.GeneralPermissions.allresources.Visible; break;
        case "Help Resources": preference.Visible = tier.GeneralPermissions.help.Visible; break;
        case "Coronavirus Resources": preference.Visible = tier.GeneralPermissions.covid.Visible; break;
        case "Management Tier": preference.Visible = tier.GeneralPermissions.ManagementTier.Visible; break;
        case "Safety 101": preference.Visible = tier.GeneralPermissions.Safety101.Visible; break;
      }
    });
  }

  checkTierInclusions(setting, inclusions=null, dataAccessLevels = null) {
    let name;
    if (setting.DisplayName != undefined)
      name = setting.DisplayName;
    else if (setting.Name != undefined)
      name = setting.Name;
    switch (name) {
      case "Hotline": {
        if (!this.mbDataService.hasTool("Hotline", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Training Materials": {
        if (!this.mbDataService.hasTool("Training", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "All Resources": {
        if (!this.mbDataService.hasTool("AllResources", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Regulatory Activity": {
        if (!this.mbDataService.hasTool("RegActivity", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Regulations": {
        if (!this.mbDataService.hasTool("Regulations", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "News": {
        if (!this.mbDataService.hasTool("News", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Regulatory Analysis Chart Builder": {
        if (!this.mbDataService.hasTool("CHB", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Plan Builder": {
        if (!this.mbDataService.hasTool("PB", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "SDS Search": {
        if (!this.mbDataService.hasTool("SDS", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Safety Toolbox Trainer": {
        if (!this.mbDataService.hasTool("STT", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Compliance Calendar": {
        if (!this.mbDataService.hasTool("ComplianceCalendar", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
      case "Report a Spill": {
        if (!this.mbDataService.hasTool("ReportSpill", inclusions, dataAccessLevels)) {
          return false;
        }; break;
      };
    }
    return true;
  }

  async getWidgets() {

    let userId = this.mbDataService.user.UserId;
    let parentUserId = null;
    if (this.mbDataService.parentUserId != null && this.mbDataService.parentUserId > 0) {
      parentUserId = this.mbDataService.parentUserId;
    }

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    let widgets = await this.http.get<Array<Widget>>(EhsHeroConfig.endpoint + '/Widget?userId=' + userId + "&applicationId=2", options)
      .toPromise().catch(()=>
      {
        return null;
      });
      return widgets;
  }
  async getParentWidgets(){
    let parentUserId = null;
    if (this.mbDataService.parentUserId != null && this.mbDataService.parentUserId > 0) {
      parentUserId = this.mbDataService.parentUserId;
    }
    
    if(parentUserId != null){
      let headers: any = {
      // 'Token': this.token,
        'Content-Type': 'application/json'
      }
  
         let options =  {
      headers: headers
   }
      
      let widgets = await this.http.get<Array<Widget>>(EhsHeroConfig.endpoint + '/Widget?userId=' + parentUserId + "&applicationId=2", options)
      .toPromise().catch(()=>
      {
        return null;
      });
      return widgets;
    }
    else
    {
      return null;
    }
  }

  saveCustomWidget(customWidget) {

    let headers: any = {
      'Accept': 'application/json',
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.post<Widget>(EhsHeroConfig.endpoint + '/Widget', customWidget, options);
  }


  deleteCustomWidget(customWidgetId: number) {

    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }

       let options =  {
      headers: headers
   }

    return this.http.delete(EhsHeroConfig.endpoint + '/Widget?customWidgetId=' + customWidgetId, options);
  }


  updateCustomWidget(customWidgetId: number, visibleOnHome: boolean, visibleOnTools: boolean, positionOnHome, positionOnTools) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

    this.mbDataService.user.Widgets.filter(f => f.Id == customWidgetId)[0].VisibleOnHome = visibleOnHome;
    this.mbDataService.user.Widgets.filter(f => f.Id == customWidgetId)[0].VisibleOnTools = visibleOnTools;
    this.mbDataService.user.Widgets.filter(f => f.Id == customWidgetId)[0].PositionOnHome = positionOnHome;
    this.mbDataService.user.Widgets.filter(f => f.Id == customWidgetId)[0].PositionOnTools = positionOnTools;

       let options =  {
      headers: headers
   }
    let body = {
      UserId: this.mbDataService.user.UserId,
      WidgetId: customWidgetId,
      VisibleOnHome: visibleOnHome,
      VisibleOnTools: visibleOnTools,
      PositionOnHome: positionOnHome,
      PositionOnTools: positionOnTools
    };
    return this.http.patch(EhsHeroConfig.endpoint + '/Widget', body, options).toPromise();
  }

  removeChildAcct(parentMembershipId: number, childCustomerId: number) {

    let headers: any = {
   //   'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

      return this.http.get(EhsHeroConfig.endpoint + '/CustomerCentral/RemoveChildAcct?parentMembershipId=' + parentMembershipId +
          '&childCustomerId=' + childCustomerId, options).toPromise();
  }

  getMemberships(customerId: number) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

      return this.http.get<CCMembership[]>(EhsHeroConfig.endpoint + '/CustomerCentral/GetMemberships?customerId=' + customerId + '&pubCode=ehshero', options)
          .toPromise();
  }

  validateEmail(email: string) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }

      return this.http.get(EhsHeroConfig.endpoint + '/CustomerCentral/isEmailAvailable?email=' + email + '&pubCode=ehshero', options)
          .toPromise();
  }

  isUsernameAvailable(username: string) {

    let headers: any = {
    //  'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
      return this.http.get<boolean>(EhsHeroConfig.endpoint + '/CustomerCentral/isUsernameAvailable?username=' + username, options)
          .toPromise();
  }

  createChildAccount(user: CCUser) {

    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
      var parentId = this.mbDataService.user.CustomerId
      var membershipId = user.MembershipId ? user.MembershipId : this.mbDataService.user.MembershipIds[0];
      var enc_password = encodeURIComponent(user.Password);

      return this.http.get<CCCustomer>(EhsHeroConfig.endpoint + '/CustomerCentral/CreateChildAcount?username=' + user.username + '&password=' + enc_password +
          '&email=' + user.Email + '&firstName=' + user.FirstName + '&lastName=' + user.LastName + '&company=' + user.Company +
          '&parentMembershipId=' + membershipId, options)
          .toPromise();
  }
  copyWidgetsToChild(childId: number){
    let headers: any = {
  //    'Token': this.token,
      'Content-Type': 'application/json'
    }

    let options =  {
      headers: headers
   }
    var parentId = this.mbDataService.user.CustomerId;
    return this.http.get(EhsHeroConfig.endpoint + '/Widget/CopyWidgetsFromUserToUser?sourceUserId=' + parentId + '&targetUserId=' + childId, options)
          .toPromise();
  }

  linkCustomerToMembershipByEmail(email: string, membershipId?: number){
      let headers: any = { 
        'Accept': 'application/json', 
       // 'Token': this.token, 
        'Content-Type': 'application/json' };

         let options =  {
      headers: headers
   }

      membershipId = membershipId ? membershipId : this.mbDataService.user.MembershipIds[0];
      return this.http.post<number>(EhsHeroConfig.endpoint + '/CustomerCentral/LinkAccountToMembership?email=' + email 
          + '&membershipId=' + membershipId,null,options)
          .toPromise();
  }

  updateUser(customerId: number, profileAddress) {
      let headers: any = { 
        'Accept': 'application/json',
       //  'Token': this.token, 
         'Content-Type': 'application/json' };

         let options =  {
      headers: headers
   }
      var body = JSON.stringify(profileAddress);

      return this.http.post(EhsHeroConfig.endpoint + '/CustomerCentral/UpdateCustomerAddress?customerId=' + customerId, body, options)
          .toPromise();
  }

  updateCustomerEmail(customerId: number, email: string) {
      let headers: any = { 
        'Accept': 'application/json', 
        //'Token': this.token, 
        'Content-Type': 'application/json' };
         let options =  {
      headers: headers
   }

      return this.http.post(EhsHeroConfig.endpoint + '/CustomerCentral/UpdateCustomerEmail?customerId=' + customerId + '&email=' + email, null, options)
          .toPromise();
  }

  updateUsername(userId: number, newUsername: string) {
    
      let headers: any = { 
        'Accept': 'application/json', 
        //'Token': this.token, 
        'Content-Type': 'application/json' };
         let options =  {
      headers: headers
   }

      return this.http.post(EhsHeroConfig.endpoint + '/CustomerCentral/UpdateUsername?userId=' + userId + '&newUsername=' + newUsername, null, options)
          .toPromise();
  }

  updateMembership(customerId: number, currentMembershipId: number, newMembershipId: number) {
    
      let headers: any = { 
        'Accept': 'application/json',
         //'Token': this.token, 
         'Content-Type': 'application/json' };
         let options =  {
      headers: headers
   }

      return this.http.post(EhsHeroConfig.endpoint + '/CustomerCentral/UpdateMembership?customerId=' + customerId +
          '&currentMembershipId=' + currentMembershipId + '&newMembershipId=' + newMembershipId, null, options)
          .toPromise();
  }
  getCustomTags(membershipId:number,userId?:number) {
    let headers: any = {
       'Accept': 'application/json', 
       //'Token': this.token, 
       'Content-Type': 'application/json' };
    let options =  {
 headers: headers
}

  if(userId && userId>0)
  {
    return this.http.get<CustomTag[]>(EhsHeroConfig.endpoint + '/CustomTag?membershipid='+ membershipId + '&applicationId=2&userId=' + userId,options)
    .toPromise();
  }
  else
  {
    return this.http.get<CustomTag[]>(EhsHeroConfig.endpoint + '/CustomTag?membershipid='+ membershipId + '&applicationId=2' ,options)
    .toPromise();
  }
    
  }
  saveCustomTags(customTags) {
    let headers: any = { 
      'Accept': 'application/json', 
      //'Token': this.token, 
      'Content-Type': 'application/json' };
    let options =  {
 headers: headers
}

    return this.http.post<any>(EhsHeroConfig.endpoint + '/CustomTag', customTags, options).toPromise();
  }
  

}
