import { Category } from '../types/category.type'
import { Topic } from '../types/topic.type'
import { Jurisdition } from '../types/jurisdiction.type'
import { InformationType } from '../types/information-type.type'
import { Language } from '../types/language.type'
import { Information } from '../types/information.type'
import { Source } from '../types/market.type'

export class PagedResourcesModel {
  Categories: Category[]=[];
  Topics: Topic[]=[];
  Jurisdictions: Jurisdition[]=[];
  InformationTypes: InformationType[]=[];
  Languages: Language[]=[];
  Information: Information[]=[];
  Sources: Source[]=[];
  PageNumber: number;
  PageSize: number;
  Total: number;  
}
