import { Component } from '@angular/core';

@Component({
  selector: 'help-widget',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './help.html'
})

export class HelpWidgetComponent {

  constructor( ) { }

  ngOnInit() {
  }

}
