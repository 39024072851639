<div class="container">
  <div class="row">
    <div class="col-md-8 col-md-offset-2" style="text-align:center;">
      <div class="error" style="color:red;">{{loginResponse}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <div style="text-align:center;" class="imgloader">

          <h1>Loading</h1>
          
          <span><img style="margin-left:5px; height:60px;width:60px;" src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loading-dots.gif" /></span>

        </div>
    </div>
  </div>
</div>


