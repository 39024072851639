<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/covidwidget.png" style="width: 144px;" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Coronavirus (COVID-19)</div>

  <div class="body-content-italic">Access EHS Hero resources that relate to Coronavirus (COVID-19)</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" (click)="navigate();" target="_blank">Get Started</a>
</div>
