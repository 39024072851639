<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/plan-builder.png" alt="Plan Builder" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Plan Builder</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">Create custom plans that meet the needs of your organization</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" (click)="navigate();" >Get Started</a>
  <!-- <a class="btn-blue-sm" (click)="openModal(modal)" *ngIf="isLimitedAccess"><i class="fas fa-lock"></i>&nbsp;&nbsp;Get Started</a> -->
</div>



<ng-template cssClass="modal-xl" #modal>
  <div class="modal-header modal-header-green">
    Limited Access
  </div>
  <div class="modal-body">
    <div class="body-content">
      <h1>
        Full access to the Plan Builder is reserved for EHS Hero subscribers.
      </h1>

      <div>
        <a href="http://store.blr.com/hrhero" target="_blank">Upgrade now</a> or <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">request a demo</a> for immediate access.
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>
