import { Component, Provider, ElementRef, OnInit, Input, Compiler } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service';
import { EhsHeroConfig } from '../../../environments/environment';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html'
})

export class AccountSettingsComponent {

  myAccount: any = null;
  allStates: string = "hidden";
  states: any;
  forgotLink: string;
  showForgotLink:boolean = false;

  constructor(private _mbService: MembershipDataService, private fb: FormBuilder, private _heroService: EhsHeroSvc,) { }

  ngOnInit() {

    this.forgotLink = EhsHeroConfig.ccBaseURL + "account/forgot"
    this.showForgotLink = this._mbService.AutherizationType==1;

    if (this._mbService.dataAccessLevels() == "3") {
      this.allStates = "hidden";
    }
    else if ((this._mbService.user.States && this._mbService.user.States.filter(f => f != "All").length == 52) || this._mbService.isLimitedAccess()) {
      this.allStates = "All";
    } else {
      this.allStates = "";
    }
     
    this._heroService.getStates(this._mbService.user.States).then(states => {

      if (states != null) {
        this.states = states.map(function (elem) {
          return elem.JurisdictionName;
        }).join(", ");
      }

    });

    this.myAccount = this._mbService.user;

  }

}
