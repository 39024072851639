<div>

  <img src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/SpillReporting/li-background.PNG" />

  <div style="background-color: #e9e9e9; padding: 30px; margin-top: 2px; ">

    <div style="text-align:center">
      <div class="text" style="margin-top: 10px; margin-bottom: 3px;">State-specific reporting information</div>

      <select class="form-control" (change)="onStateChange($event.target.value)">
        <option value="" disabled selected>Select a state</option>
        <option *ngFor="let state of states" class="option" value="{{state.StateAbbr}}">{{state.State}}</option>
      </select>

    </div>
    <div id="divAgencyInfo" runat="server" visible="False" *ngIf="agency != null">
      <br />
      <h1>
        {{agency.State}}
      </h1>

      <h3>Agency Name:</h3>
      <div class="text">
        {{agency.Name}}
      </div>

      <h3>Website:</h3>
      <div class="text">
        <a href="{{agency.Website}}" target="_blank">{{agency.Website}}</a>
      </div>

      <h3>Emergency Phone Number:</h3>
      <h3>
        {{agency.Phone}}
      </h3>

      <h3>Reporting Timeframe:</h3>
      <div [innerHtml]="agency.Timeframe"></div>

      <h3 class="textSubHead">Quantity:</h3>
      <div class="text" [innerHtml]="agency.Quantity">
      </div>

    </div>
  </div>

</div>
