import { Component, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';

//types
import { UserProfile } from '../../shared/types/user-profile.type'

//services
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { UserManagementService } from '../../shared/services/user-management.service';
import { Widget } from '../../shared/types/widget.type';
import { Subject} from 'rxjs';

@Component({
  selector: 'custom-settings',
  templateUrl: './custom-settings.component.html',
  styleUrls: ['./custom-settings.component.css']
})

export class CustomSettingsComponent {

  customWidgets: Widget[] = [];
  userProfile: UserProfile = new UserProfile;
  hasAccess: boolean = false;
  useParent: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(private _userManagementService: UserManagementService, private _mbService: MembershipDataService) {
  }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: false,
      info: false,
      columnDefs: [
        { width: 'auto', targets:  [ 0, 1],}
    ],
      language : {
        emptyTable: "No data to display",
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };

    this.hasAccess = this._mbService.getVisibility('Custom Widgets');
    this.userProfile = this._mbService.userProfile;
    let parentUserProfile = this._mbService.parentUserProfile;
    if(parentUserProfile != null && parentUserProfile != undefined && parentUserProfile.AdminPreferences != null && parentUserProfile.AdminPreferences != undefined){
      if(parentUserProfile.DashboardLock){
        this.useParent = true;
      }
    }
    await this.getCustomWidgets();
    this.rerender();
  }

  async getCustomWidgets() {
    this.customWidgets = (await this._userManagementService.getWidgets())?.filter(f => f.IsCustom && f.OwnerId == this._mbService.user.CustomerId);
    if (this.customWidgets?.length == 0)
    {
      this.customWidgets = null;
    }
    
      
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
}

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });    
  }
}
