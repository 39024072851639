
import {first, map} from 'rxjs/operators';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { MembershipDataService } from '../services/membership.data.service';
import { UserProfileService } from '../services/user-profile.service'
import { Account } from '../types/account.type';
import { UserProfile } from '../types/user-profile.type';

@Injectable()

export class AuthGuard implements CanActivate {

  userProfile: UserProfile = null;
  debug: boolean = true;
  currentRoute: string;

  constructor(
    private _mbDataService: MembershipDataService,
    private router: Router,
    private _ups: UserProfileService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    //already authenticated
    if (this._mbDataService.isAuthenticated()) {
      return true;
    }

    let _ths = this;
    let processed = false;

    //auto login
    return this._mbDataService.autoLogin().pipe(map((account: Account) => {
      if (state.url == "/error/404") {
        state.url = "";
      }
      if (account && account.ResponseMessage == "sso session inactive" && !(this.document.location.href.toLowerCase().indexOf("/login") > -1)) {
        this._mbDataService.ssoNeeded = true;
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
      //authentication failed
      if ((account == null || account.Authorized == false) && (account != null && (account.SubscriptionType != "EHS Free Trial"))) {

        if (account != null && account.Roles != null && account.Authorized == false) {
          if (account.Roles.filter(f => f == "EHS Chart Builder Safety Enviro").length > 0) {
            return false;
          }

          if (account.Roles.filter(f => f == "EHS Chart Builder Enviro").length > 0) {
            return false;
          }

          if (account.Roles.filter(f => f == "EHS Chart Builder Safety").length > 0) {
            return false;
          }

        }

        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });


        return false;

      } 
      else
      {
        processed = true;
      }

      if (processed) {

        _ths._mbDataService.setUpAccount(account);
        return true;
      }


    }),first(),);
  }
}
