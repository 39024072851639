
<div style="padding-top: 20px;">
    <div class="clearfix">
        <!-- column -->
        <div class="clearfix colelem" id="pu419"> 
            <div class="clearfix grpelem" id="vod-header">
                <!-- content -->
                <h1>Create Your Own Library of EHS Training Videos</h1>
                <p>BLR's Streaming Video-on-Demand EHS training is a valuable component in an effective blended learning training program. These high-resolution videos provide comprehensive and engaging EHS training on a large selection of topics. Not only does the video format make it easy to train anywhere, anytime, you can create a training library specific to your facility’s needs.</p>
            </div>
        </div>
        <div class="rounded-corners clearfix colelem" id="vod-container">
            <div id="vod-video-container">
                <div *ngIf="vidoes==null" class="row" style="text-align:center;">
                    <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
                  </div>
                <div *ngIf="isDemo">
                
                    <div *ngIf="selectedVideo!=null">
                        <div class="row">
                            <div  style="vertical-align: top;" class="col-lg-5">
                                <!-- <div class="row">
                                    <div class="col-lg-12"> -->
                                        <div *ngIf="selectedVideoUrl!=null">
                                            <iframe allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" id="iFrameVideo"
                                                allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="360" 
                                                [src]="selectedVideoUrl | safe"></iframe>
                                            <script src="//fast.wistia.net/assets/external/E-v1.js" async></script>
                                        </div>
                                <!-- </div>
                                </div> -->
                                <!-- <div class="row">
                                    <div class="col-lg-12">
                                      
                                    </div>
                                </div> -->
                                
                            </div>
                            <div style="vertical-align: top; padding-left: 10px;" class="col-lg-7">
                                <!-- <div class="row">
                                    <div class="col-lg-12">

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        
                                    </div>
                                </div> -->
                                <div id="divOverview" class="vod-copy" [innerHtml]="selectedVideo.Description">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                                <Label class="vod-title" id="lblTitle">{{selectedVideo.Title}}</Label><br />
                                <Label id="lblDetails" class="vod-copy"><span class='bold'>Duration: </span>{{selectedVideo.Duration}} <span class='bold'> Topic: </span>{{selectedVideo.TopicName}}</Label>
                    
                                       </div>
                            <div class="col-lg-4 text-right">
                                <!-- <div >                                    
                                    <div class="btn-group" btnRadioGroup [(ngModel)]="selectedlanguage">
                                        <label class="btn btn-blue-sm mb-0" btnRadio="English">English</label>
                                        <label class="btn btn-blue-sm mb-0" btnRadio="Spanish" [disabled]="true">Spanish</label>
                                    </div>                                                                
                                </div> -->
                            </div>
                        </div>
                    </div>
                <br />
                <p>Click on a title below to preview the Video On Demand (VOD) program. Call us at <b>800-727-5257</b> or <b>contact your Account Manager</b> to subscribe to any of these EHS training videos today!</p>
                <br />
                <accordion *ngIf="videoLibraries!=null" [isAnimated]="true" [closeOthers]="true">
                        <accordion-group *ngFor="let library of videoLibraries; let i = index" #groupval  [isOpen]="i==0">
                            <div accordion-heading style="width:100%;color: #006869;">
                               {{library.LibraryName}}
                                <i class="pull-right float-xs-right glyphicon"
                                   [ngClass]="{'glyphicon-chevron-down': groupval?.isOpen, 'glyphicon-chevron-up': !groupval?.isOpen}"></i>
                              </div>
                              <div  style="max-height: 200px;overflow-y: scroll;">
                                <table class="table table-striped" *ngIf="vidoes!=null" style="width: 100%;">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Topic</th>
                                            <th>Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let video of getVideosForLibrary(library.LibraryId)">
                                            <td><a [routerLink]="" (click)="LoadVideo(video.VideoId)">{{video.Title}}</a></td>
                                            <td><a [routerLink]="" (click)="LoadVideo(video.VideoId)">{{video.TopicName}}</a></td>
                                            <td><a [routerLink]="" (click)="LoadVideo(video.VideoId)">{{video.Duration}}</a></td>
                                          </tr>
                                    </tbody>
                                </table>
                              </div>
                            
                          </accordion-group>               
                  </accordion>
                </div>   


                <div *ngIf="!isDemo">
                    <div id="video" *ngIf="selectedVideo!=null">
                        <iframe allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" id="iFrameVideo"
                        allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="500px" [src]="selectedVideoUrl | safe"></iframe>
                        <script src="//fast.wistia.net/assets/external/E-v1.js" async></script>
                    </div>
                    <br />
                    <div *ngIf="selectedVideo!=null">
                        <div class="row">
                            <div class="col-lg-8">
                                <label  class="vod-title" ID="lblTitle">{{selectedVideo.Title}}</label><br />
                                <label  ID="lblDetails" class="vod-copy">
                                    <span class='bold'>Duration: </span>{{selectedVideo.Duration}}<span style='padding-left:20px;' class='bold'>Topic: </span>{{selectedVideo.TopicName}}
                                </label>
                            </div>
                            <div class="col-lg-4 text-right">
                                <div *ngIf="this.selectedVideo.SpanishFullVideo!=null && this.selectedVideo.SpanishFullVideo.trim()!=''">
                                    <div class="btn-group" btnRadioGroup [(ngModel)]="selectedlanguage">
                                        <label class="btn btn-blue-sm mb-0" btnRadio="English"  (click)="LoadVideo(this.selectedVideo.VideoId)">English</label>
                                        <label class="btn btn-blue-sm mb-0" btnRadio="Spanish"  (click)="LoadVideo(this.selectedVideo.VideoId)">Spanish</label>
                                    </div>     
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line-spacer"></div>
                    <div class="bold" style="margin-bottom: 10px; padding-left: 3px; font-size: 14px;">Overview</div>
                    <div  id="divOverview" class="vod-copy" style="padding-left: 3px;" [innerHtml]="selectedVideo.Description"  *ngIf="selectedVideo!=null">
                        
                    </div>
                    <br />
                    <div class="panel card panel-default" style="width: 100%;">
                        <div class="panel-heading card-header" style="color: #006869;">
                            <div class="panel-title">
                                My Videos
                            </div>
                        </div>
                        <div class="panel-body" style="width: 100%;">
                            <table class="table table-striped" *ngIf="vidoes!=null" style="width: 100%;" datatable [dtOptions]="dtOptions">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Topic</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let video of vidoes">
                                        <td><a [routerLink]="" (click)="LoadVideo(video.VideoId)">{{video.Title}}</a></td>
                                        <td><a [routerLink]="" (click)="LoadVideo(video.VideoId)">{{video.TopicName}}</a></td>
                                        <td><a [routerLink]="" (click)="LoadVideo(video.VideoId)">{{video.Duration}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>                    
                    <br />                             
                </div>                                
            </div>
        </div> 
    </div>  
</div>
