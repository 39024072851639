<h1>Training Details</h1>

<div *ngIf="information != null">

  <h3 class="seperator">{{information.Title}}</h3>

  <div class="body-content body-content-padding"><span class="body-content-label">Topic: </span>{{information.TopicDesc}}</div>
  <div class="body-content body-content-padding"><span class="body-content-label">Document Type: </span>{{information.InformationTypeName}}</div>
  <div class="body-content body-content-padding"><span class="body-content-label">Summary: </span><div [innerHtml]="information.Teaser"></div></div>
  <div class="body-content body-content-padding" *ngIf="!isLimitedAccess && information.Body != null && information.Body != ''"><div class="seperator"></div><div [innerHtml]="information.Body | safeHtml"></div></div>

  <div class="body-content body-content-padding" *ngIf="isLimitedAccess && information.Body">
    <div class="seperator"></div>
    <div class="limited-access-row" [innerHtml]="information.Body"></div>
    <div class="modal-header modal-header-green">
      Limited Access
    </div>
    <div class="modal-body">
      <h1>
        The resource you are trying to access is reserved for EHS subscribers
      </h1>

      <div class="body-content">
        <a href="http://interactive.blr.com/hrhero-demo-request">Request a demo</a> to access time saving resources, workflow tools and much more.
      </div>
    </div>
  </div>

  <div class="document-btns">
    <a *ngIf="((information.InformationTypeId == 11 && information.FilePath && information.FilePath !== '') || linkType(information) == 2) && !isLimitedAccess" [routerLink]="['/audio-presentations',information.FilePath]" target="_blank" class="btn-orange">Go</a>
    <download-information *ngIf="information.InformationTypeId != 11 && linkType(information) == 3" [informationId]="information.InformationId" [documentType]="information.DocumentType" [fileName]="information.Title"></download-information>
    <a *ngIf="((linkType(information) == 2) || (information.InformationTypeId == 11 && information.FilePath)) && isLimitedAccess" (click)="challengeModal.open()" class="btn-orange">Go</a>
    <bookmarks [bookmarkTitle]="information.Title" [informationId]="information.InformationId" [informationTypeId]="information.InformationTypeId" [topicId]="information.TopicId"></bookmarks>
  </div>

  <div *ngIf="supportingDocuments != null && !isCollection">
    <!--Kit Supporting Documents:-->
    <span style="margin-top:0px !important;" class="body-content-label" *ngIf="!isCollection">Training Type:</span> {{(information.ParentInformationTypeName == null) ? information.InformationTypeName : information.ParentInformationTypeName}} <br />

    <span style="margin-top:0px !important;" class="body-content-label" *ngIf="!isCollection && information.InformationTypeName == information.ParentInformationTypeName">{{information.InformationTypeName}} Kit Supporting Documents: <br /><br /></span>
    <span style="margin-top:0px !important;" class="body-content-label" *ngIf="!isCollection && information.InformationTypeName != information.ParentInformationTypeName">This document is one part of a collection of related documents. Click below to see other components. <br /><br /></span>

    <div *ngFor="let ts of supportingDocuments">
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'pdf'"><i class="far fa-file-pdf fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'ppt'"><i class="far fa-file-powerpoint fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'pptx'"><i class="far fa-file-powerpoint fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && (ts.DocumentType == 'doc' || ts.DocumentType == 'docx')"><i class="far fa-file-word fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && (ts.DocumentType == 'rtf' || ts.DocumentType == 'RTF')"><i class="far fa-file-word fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.FilePath != null && ts.InformationTypeId == 11"><i class="far fa-file-audio fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
      <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.FilePath != null && ts.DocumentType == null && ts.InformationTypeId != 11"><i class="far fa-file-alt fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>

    </div>
  </div>

  <div *ngIf="supportingDocuments != null && isCollection">

    <div *ngFor="let item of supportingDocuments | groupBy:'ParentInformationTypeName'">

      <br />

      <span style="margin-top:0px !important;" class="body-content-label" *ngIf="isCollection">Training Type:</span> {{item.key}} <br />

      <span style="margin-top:0px !important;" class="body-content-label" *ngIf="isCollection && information.InformationTypeName == item.key">{{item.key}} Kit Supporting Documents: <br /><br /></span>
      <span style="margin-top:0px !important;" class="body-content-label" *ngIf="isCollection && information.InformationTypeName != item.key">This document is one part of a collection of related documents. Click below to see other components. <br /><br /></span>

      <div *ngFor="let ts of item.value">

        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'pdf'"><i class="far fa-file-pdf fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'ppt'"><i class="far fa-file-powerpoint fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'pptx'"><i class="far fa-file-powerpoint fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && (ts.DocumentType == 'doc' || ts.DocumentType == 'docx')"><i class="far fa-file-word fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.DocumentType != null && ts.DocumentType == 'rtf'"><i class="far fa-file-word fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.FilePath != null && ts.InformationTypeId == 11"><i class="far fa-file-audio fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>
        <a style="cursor:pointer;" class="link" (click)="openDetails(ts)" *ngIf="ts != null && ts.Url != null && ts.FilePath != null && ts.DocumentType == null && ts.InformationTypeId != 11"><i class="far fa-file-alt fa-w-12 fa-lg"></i>&nbsp;{{ts.Title}} ({{ts.InformationTypeName}})</a>

      </div>

    </div>

  </div>

</div>
<div *ngIf="information != null" style="display:none;">Unified ID: {{information.SourceInfoId}}</div>
<challenge-modal #challengeModal></challenge-modal>
