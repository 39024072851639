import { Component, Provider, OnInit, ViewChild, IterableDiffers } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { VodLibrary } from '../../shared/types/vodLibrary.type'
import { VodVideo } from '../../shared/types/vodVideo.type';
import { UserManagementService } from '../../shared/services/user-management.service';
import { Console } from 'console';
import { CCMembership } from '../../shared/types/cc-membership';

@Component({
  selector: 'safety-video',
  templateUrl: './safety-video.html',
  styleUrls: ['./safety-video.css']
})

export class SafetyVideoComponent {

  dtOptions: DataTables.Settings = {};

    vidoes: VodVideo[];
    videoLibraries : VodLibrary[];
    addedVideos : number[];
    selectedVideoUrl : string=null;
    selectedVideo : VodVideo;
    selectedlanguage :string="English";
    isDemo:boolean=true;
    planIds: number[] = [];

  constructor(private _heroService: EhsHeroSvc, public router: Router, 
    private _mbService: MembershipDataService,
    private _userManagementService: UserManagementService,) { 
        
    }

  ngOnInit() {

    if(!this._mbService.hasTool("VIDEO"))
    { 
      this.router.navigate(["error/403"]);
    }
    
          this.isDemo= !this._mbService.user.HasFullVideoAccess;   

          this._heroService.getVideoLibraries().then(data => {

                if (data != null) {
                
                this.videoLibraries = data;
                this._heroService.getVideos(true).then(data => {
        
                  if (data != null) {          
                    this.vidoes = data.filter((obj, index) => {
                      return index === data.findIndex(o => obj.Title === o.Title);
                    });
                    
                    
                    //this.selectedVideo = this.vidoes.find(i => i.VideoId ===  this.getVideosForLibrary(this.videoLibraries[0].LibraryId)[0].VideoId);    
                    //this.selectedVideoUrl = "//fast.wistia.net/embed/iframe/" + this.selectedVideo.DemoVideo;          

                    let vid = this.getVideosForLibrary(this.videoLibraries[0].LibraryId)[0].VideoId;
                    this.LoadVideo(vid);
                           
                  }
              });
        
                }
        
            });    
   
  }
  

  getVideosForLibrary(libraryId:number)
  {
        var videos = this.vidoes.filter(i => i.LibraryId === libraryId).sort((a, b) => (a.Title > b.Title) ? 1 : -1);        
        return videos;
  }
  
  LoadVideo(videoId:number)
  {
    if(this.isDemo)
    {
      this.LoadDemoVideo(videoId);
    }
    else
    {
      this.LoadFullVideo(videoId)
    }
  }

  private LoadDemoVideo(videoId:number)
  {   
    this.selectedVideo = this.vidoes.find(i => i.VideoId === videoId);   
    this.selectedVideoUrl = "//fast.wistia.net/embed/iframe/" + this.selectedVideo.DemoVideo;
    console.log(this.selectedlanguage);
  }
  private LoadFullVideo(videoId:number)
  {
    this.selectedVideo = this.vidoes.find(i => i.VideoId === videoId);
    if(this.selectedlanguage=="Spanish" && this.selectedVideo.SpanishFullVideo!=null && this.selectedVideo.SpanishFullVideo!="")
      {
        this.selectedVideoUrl = "//fast.wistia.net/embed/iframe/" + this.selectedVideo.SpanishFullVideo;
      }
     else
      {
        this.selectedlanguage="English";
        this.selectedVideoUrl = "//fast.wistia.net/embed/iframe/" + this.selectedVideo.FullVideo;
      } 
    
  }



}
