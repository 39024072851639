import { Component,TemplateRef } from '@angular/core';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'sds-search',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './sds-search.html'
})

export class SDSSearchWidgetComponent {
  isLimitedAccess: boolean;
  modalRef: BsModalRef;
  constructor(private _mbService: MembershipDataService,
    public modalService: BsModalService,private _router: Router) { }


  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }
  openModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
 }
  navigate() {
    
      this._router.navigateByUrl("/sds-search");
    
  }
}

