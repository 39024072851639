import { Component, Provider, ElementRef, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { EhsHeroSvc } from '../services/ehs-hero.service';

@Component({
  selector:"download-information",
  templateUrl: './download.component.html'
})

export class DownloadComponent {

  isLimitedAccess: boolean;
  downloadChallengeTitle: string = "Downloads are reserved for EHS Hero subscribers.";

  @Input() informationId: number;
  @Input() documentType: string;
  @Input() fileName: string;

  constructor(private _mbService: MembershipDataService, private _heroService: EhsHeroSvc ) { 
    
  }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }
  notifyInquiry(){
    if(!this.isLimitedAccess){
      this._mbService.specialInquiryRequest("DOWNLOAD");
    }
  }
  download()
  {
    console.log("File Downloading...");
    this.notifyInquiry();
    var fileName = this.fileName?.replace(/[^\x00-\x7F]/g, "").replace(/[|&;$%@"<>()+,]/g, "").replace(/\s/g, '_');
    this._heroService.downloadInformationFile(this.informationId,fileName, this.documentType);
}



}


