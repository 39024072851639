import {Component, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
//services
import { MembershipDataService } from '../../shared/services/membership.data.service';
//types
import { CCCustomer } from '../../shared/types/cc-customer';
import { UserManagementService } from '../../shared/services/user-management.service';
import { CCMembership } from '../../shared/types/cc-membership';

import { DataTableDirective } from 'angular-datatables';
import { PermissionTierApi } from '../../shared/types/permission-tier-api.type';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styles: ['.thead { background-color: #588a7e; color: white;}']
})

export class UserManagementComponent  {
  permissionTiers: PermissionTierApi[];
  ccCustomers: CCCustomer[] = [];
  ccCustomersMaster: CCCustomer[] = [];
  hasAccess: boolean = false;
  dataAvailable: number = 0;
  keywordValue: string = '';
  isOwner: boolean;
  parentMemberships: CCMembership[]=[];
  selectedCount: number = 0;
  parentMarkets: any;
  currentlyFiltering: boolean = false;
  subscription: Subscription;
  
  
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(private _mb: MembershipDataService, private _userManagementService: UserManagementService) {

  }

  ngOnInit() {
    this.hasAccess = this._mb.getVisibility('User Management') && this._mb.AutherizationType==1;

    this.dtOptions = {
     
        pagingType: 'full_numbers',
        pageLength: 10,
        searching: true,
        ordering: true,
        order:[[1, 'asc']],
        info: false,
        autoWidth: true,
        language : {
          search: "",
          emptyTable: "No additional users. To add a user, click ADD NEW USER.",
          zeroRecords:"No users found matching your search criteria. Please try another search.",
          searchPlaceholder: "Search",
          lengthMenu: '_MENU_',
          paginate: {
                first: '<<',
                last: '>>',
                next: '>',
                previous: '<'
            }
          },
          columnDefs: [            
            {targets: [5],width:"8%"},
            {targets: [6], searchable: !1, orderable: !1},
            {targets: [7], searchable: !1, orderable: !1, width:"5%"},
            {targets: [8], searchable: !1, orderable: !1, width:"4%"},
           
         ],
         lengthMenu : [5, 10,25],
         dom:  '<"input-group col-md-3"<"col pull-left" f>><"panel panel-default" t<"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
      };

      
      this.isOwner = this._mb.userProfileTypeId == 1;
      this._userManagementService.getPermissionTiers().then(result => {
        this.permissionTiers = result;
      });;
      this.getCCUsers();
      this.parentMarkets = this._mb.user.Markets;

  
    }
  
    getCCUsers() {
      if (this._mb.allUsers != null || this._mb.allUsers != undefined) {
        console.log('Retrieving cached Users.')
        this.ccCustomersMaster = this.ccCustomers = this._mb.allUsers;
        this.getMemberships(this._mb.user.CustomerId);
        this.dataAvailable = 1;
        this.rerender();  
      }
      else {
               this._mb.getAllUsers().subscribe((result)=> {
                console.log(`Received loaded Users`);
                this.ccCustomersMaster  = this.ccCustomers = result;
                  this.getMemberships(this._mb.user.CustomerId);
                this.dataAvailable = 1;
                this.rerender();  
          });
      }
    }
  ngAfterViewInit(): void {
   this.dtTrigger.next();
  }

  filterKeyword(keywordValue: string) {
      if (keywordValue && keywordValue.trim().length > 0) {
          this.currentlyFiltering = true;
          this.ccCustomers = this.ccCustomersMaster.filter(f => (keywordValue.toLowerCase() == '' ||
              ((f.FirstName != null) && f.FirstName.toLowerCase().includes(keywordValue.toLowerCase()) ) ||
              ((f.LastName != null) && f.LastName.toLowerCase().includes(keywordValue.toLowerCase()) ) ||
              ((f.Company != null) && f.Company.toLowerCase().includes(keywordValue.toLowerCase()) ) ||
              ((f.Email != null) && f.Email.toLowerCase().includes(keywordValue.toLowerCase()) ) ||
              ((f.UserName != null) && f.UserName.toLowerCase().includes(keywordValue.toLowerCase())) ));              
          
      }
      else {
          this.ccCustomers = this.ccCustomersMaster;
          this.currentlyFiltering = false;
      }
      
  }

  getApplicableTiers(user: CCCustomer) {
    if(this.permissionTiers)
    {
          return this.permissionTiers.filter(f => user.MembershipIds.includes(f.MembershipId)); 
    }
    else
    {
      return null;
    }  
  }

  getMemberships(customerId: number) {
    
      this._userManagementService.getMemberships(customerId).then(result => {
        if (result?.length > 0) {
          this.parentMemberships = result;         
              
        }
      });
  }

  getExpirationDate(membership: CCMembership) {
    return membership.effectiveDates.sort((a, b) => { if (a.stop_date < b.stop_date) return 1; else if (a.stop_date > b.stop_date) return -1; else return 0 })[0].stop_date;
  }

  getMarket(membershipId: number) {
      var market = this._mb.user.AllMarkets.filter(f => f.MembershipId == membershipId).map(x => x.Market);
      return market;
  }

   removeUsers() {
      if (confirm("Are you sure you want to remove this user?")) {
          let selectedUsers = this.ccCustomers.filter(user => user.IsSelected);

          if (this._mb.user.MembershipIds?.length > 0) {

              for (let user of selectedUsers) {
                  this._userManagementService.removeChildAcct(user.MembershipIds[0], user.CustomerId).then(result => {
                      this.selectedCount--;
                      let index = this._mb.allUsers.indexOf(user);
                      this.ccCustomersMaster.splice(index, 1);
                      let filteredIndex = this.ccCustomers.indexOf(user);
                      this.ccCustomers.splice(filteredIndex,1);

                      if (this.selectedCount == 0) {
                          this._mb.allUsers = this.ccCustomersMaster;
                          this.getMemberships(this._mb.user.CustomerId);
                      }
                  });
              }
          }
          else {
              return;
          }
      }
  }

  sortByMembership = (item: any) => {
      var membership = item.memberships ? item.memberships[0].plan.name : '' + item.addOns ? ',' : ''
      var addOn = item.addOns ? item.addOns[0].plan.name : '';
      var result = membership + (addOn ? (',' + addOn) : '');

      return result;
  }

  setSelectedFlag(e) {
      if (e.target.checked == true) {
          this.selectedCount++;
      }
      else {
          this.selectedCount--;
      }
  }
  ngOnDestroy(): void {
    if(this.subscription)
    {
      this.subscription.unsubscribe();
    }
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    if(this.dtElement)
    {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }
}
