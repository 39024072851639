
<div [hidden]="collectionData == null && loading" style="margin-top:20px;clear: both;" class="row">
  <h1>{{title}}</h1>
  <div *ngIf="collectionData!=null && !loading" style="margin-top: 4px;" [innerHTML]="body | safeHtmlNews"></div>
  <div style="clear: both;margin-top: 20px;" class="row">
    <div class="col-lg-3" style="margin-bottom:20px;">
      <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
      <div class="outer-filter-panel">

        <collapse-header dataTargetId="#state" headerTitle="States"></collapse-header>
        <div id="state" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table" *ngIf="collectionData!=null">
            <tbody>
            <tr *ngFor="let item of collectionData.Jurisdictions">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterJurisdiction(item.JurisdictionId, $event.target.checked, item.JurisdictionName)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#category" headerTitle="Categories"></collapse-header>
        <div id="category" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table" *ngIf="collectionData!=null">
            <tbody>
              <tr *ngFor="let item of collectionData.Categories">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterCategory(item.CategoryId, $event.target.checked, item.CategoryName)" [checked]="item.Checked">{{item.CategoryName}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#topic" headerTitle="Topics"></collapse-header>
        <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table" *ngIf="collectionData!=null">
            <tbody>
              <tr *ngFor="let item of collectionData.Topics">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <collapse-header dataTargetId="#doc" headerTitle="Document Types"></collapse-header>
        <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
          <table class="table" *ngIf="collectionData!=null">
            <tbody>
              <tr *ngFor="let item of collectionData.InformationTypes">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterDocument(item.InformationTypeId, $event.target.checked, item.InformationTypeName)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <div class="col-lg-9">
      <div class="ic-tab-container">
        <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
          <div class="inline-text">{{tag.Name}}</div>
          <div class="delete-button" style="height: 100%">
            <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
              <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="white-well">
        <table class="table table-striped collection-data-table"  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead class="thead">
            <tr>
              <th style="min-width:65px">
                <a class="text-nowrap" style="cursor:pointer"> Topic &nbsp; <span></span> </a>  
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer"> Title &nbsp; <span></span> </a>                    
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer"> Document Type &nbsp; <span></span> </a>                    
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer"> State &nbsp; <span></span> </a>                    
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of collectionData?.Information">
              <td>{{item.TopicDesc}}</td>
              <td><a [routerLink]="[item.Url]">{{item.Title}}</a></td>
              <td>{{item.InformationTypeName}}</td>
              <td>{{item.JurisdictionName}}</td>
            </tr>
          </tbody>          
        </table>

      </div>
    </div>
  </div>
</div>
