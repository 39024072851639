import { Component, Provider, TemplateRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';

import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { Information } from '../../shared/types/information.type'

import { GroupByPipe } from '../../shared/pipes/group-by'

import { MembershipDataService } from '../../shared/services/membership.data.service'

@Component({
  selector: 'calculators',
  templateUrl: './calculators.html',
  styleUrls: ['./calculators.css']
})

export class CalculatorsComponent {

  information: Information[];
  informationSelected: Information;
  informationGrouped: Information[];

  bookmarkFormOpen: boolean = false;
  selectedArticle: Information;

  @ViewChild('calculatorModal')
  private template: TemplateRef<any>;
  calculatorModal: BsModalRef;

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: EhsHeroSvc,
    private mbDataService: MembershipDataService,
    public modalService: BsModalService
  ) { }

  ngAfterViewInit() {

    this.getCalculators();

    this._route.params.subscribe(params => {
      if (params["category"] != null && params["topic"] != null && params["title"] != null) {

        this.selectInformationByURL('calculators', params["category"], params["topic"], params["title"]);

        this.calculatorModal = this.modalService.show(this.template); 
      } else {

      }
    });
  }

  getCalculatorByUrl(url: string) {

    let parts = url.split('/');

    this.getCalculator(parts[1], parts[2], parts[3], parts[4]);
  }

  getCalculatorById(informationId) {

    this._heroService.getInformationById(informationId, this.mbDataService.dataAccessLevels()).then(information => {
      console.log(information);
          if (information != null) {

              this.informationSelected = information;
               this.calculatorModal = this.modalService.show(this.template); 
              this._router.navigate([this.informationSelected.Url]);
          }
          else {
              this._router.navigate(['/error/404']);
          }
      });
  }

  getCalculator(type, category, topic, page) {

    this._heroService.getInformationByUrlParts('calculators', category, topic, page, this.mbDataService.dataAccessLevels()).then(information => {

      if (information != null) {

        this.informationSelected = information;
        this.calculatorModal = this.modalService.show(this.template); 
        this._router.navigate([this.informationSelected.Url]);

      }
      else {
        this._router.navigate(['/error/404']);
      }

    });
  }

  selectInformationByURL(type, category, topic, page) {

    this._heroService.getInformationByUrlParts(type, category, topic, page, this.mbDataService.dataAccessLevels()).then(information => {

      if (information != null) {

        this.informationSelected = information;
        
      }
      else {
        this._router.navigate(['/error/404']);
      }

    });

  }

  getCalculators() {

    this._heroService.getAllInformation(15, 4).then(information => {
      if (information != null) {
        this.information = information;

        this.informationGrouped = GroupByPipe.prototype.transform(information, 'TopicDesc');
      }
    });
  }

  filterCalculatorsByTopic(topicDesc) {

    return this.information.filter(f => f.TopicDesc == topicDesc);
  }

  openBookmarkForm() {

    this.bookmarkFormOpen = true;
  }
  
  bookmarkUpdated(event) {

    switch (event) {
      case 'open':
         this.calculatorModal.hide();
        break;
      case 'close':
        this.calculatorModal = this.modalService.show(this.template); 
        break;
    }

    this.bookmarkFormOpen = false;
  }
}
