import { Component } from '@angular/core';

//services
import { MembershipDataService } from '../shared/services/membership.data.service'

//types
import { Widget } from '../shared/types/widget.type'
import { UserProfile } from '../shared/types/user-profile.type'
import { PermissionTier } from '../shared/types/permission-tier.type';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../../node_modules/dragula/dist/dragula.css', './dashboard.component.css']
})

export class DashBoardComponent {

  customPermissions: PermissionTier;
  userProfile: UserProfile;
  left: Widget[];
  right: Widget[];
  selectedComponent: Widget;
  customWidgetsVisible: boolean = false;
  hasTraining: boolean = false;
  hasNews: boolean = false;

  constructor(   
    private _mbService: MembershipDataService
  ) 
  {
    this.selectedComponent = new Widget;
  }
 
  ngOnInit() {

    if (this._mbService.userProfileTypeId == 1) {
      this.customWidgetsVisible = true;
    }
    this.customPermissions = this._mbService.customPermissions;

    this.hasTraining = this._mbService.hasTool("Training");
    this.hasNews = this._mbService.hasTool("News");
  }

  hideWidget(item){
    item.Visible = false;
  }

  removeItem(item: Widget, placement) {
    item.VisibleOnHome = false;
  }

}
