<div class="hotline-container-outer">
  <div class="container hotline-container">
    <div class="head-image">
      <div class="head-textblock">
        <h1 class="head-h1content">We are here to help</h1>
        <div class="head-regcontent">Submit your questions to our safety and environmental law experts to receive the answers you are looking for; with the urgency you need!
        </div>
      </div>
    </div>
    <div class="interstitial-h2 row">
      <span class="interstitial-span col-md-4" style="font-size:18pt;"><i class="fa fa-check fa-1x" aria-hidden="true"></i>&nbsp;Reliable</span>
      <span class="interstitial-span col-md-4" style="font-size:18pt;"><i class="fa fa-check fa-1x" aria-hidden="true"></i>&nbsp;Fast</span>
      <span class="interstitial-span col-md-4" style="font-size:18pt;"><i class="fa fa-check fa-1x" aria-hidden="true"></i>&nbsp;Invaluable</span>
    </div>
  </div>

</div>
