<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/safety-toolbox-trainer.png" alt="Safety Toolbox Trainer" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Safety Toolbox Trainer</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">Schedule OSHA safety meetings, manage your notes, and streamline your compliance obligations</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" (click)="navigate();" *ngIf="!isLimitedAccess">Get Started</a>
  <a class="btn-blue-sm" (click)="openModal(modal)" *ngIf="isLimitedAccess"><i class="fas fa-lock"></i>&nbsp;&nbsp;Get Started</a>
</div>


<!--bookmark modal-->
<ng-template cssClass="modal-xl" #modal>
  <div class="modal-header modal-header-green">
    Limited Access
  </div>
  <div class="modal-body">
    <div class="body-content">
      
          <h1>
            Full access to the Safety Toolbox Trainer is reserved for EHS Hero subscribers.
          </h1>

          <div>
            <a href="http://store.blr.com/hrhero" target="_blank">Upgrade now</a> or <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">request a demo</a> for immediate access.
          </div>
      </div>
      </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>
