
<div *ngIf="informationGrouped != null">

  <div class="page-head">
      <h1>Calculate cost per hire, revenue per employee, and much more to identify problems and solutions, and improve your organizations ability to compete.</h1>
  </div>

  <div *ngFor="let item of informationGrouped | sortBy : 'key'; let i = index" style="text-align:left; margin-left:10px;">
    <div *ngIf="i % 3 == 0" class="row">
      <div class="col-lg-4">
        <div class="panel panel-default">
          <div class="panel-heading panel-heading">{{informationGrouped[i].key}}</div>
          <div class="calculator-panel">

            <table class="table table-striped">
              <tbody>
                <tr *ngFor="let subItem of filterCalculatorsByTopic(informationGrouped[i].key)" >
                  <td ><a (click)="getCalculatorById(subItem.InformationId)" class="link">{{subItem.Title}}</a></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div *ngIf="informationGrouped.length > i + 1" class="col-lg-4">
        <div class="panel panel-default">
          <div class="panel-heading panel-heading">{{informationGrouped[i+1].key}}</div>
          <div class="calculator-panel">

            <table class="table table-striped">
              <tbody>
                <tr *ngFor="let subItem of filterCalculatorsByTopic(informationGrouped[i+1].key)">
                  <td><a (click)="getCalculatorById(subItem.InformationId)" class="link">{{subItem.Title}}</a></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div *ngIf="informationGrouped.length > i + 2" class="col-lg-4">
        <div class="panel panel-default">
          <div class="panel-heading panel-heading">{{informationGrouped[i+2].key}}</div>
          <div class="calculator-panel">

            <table class="table table-striped">
              <tbody>
                <tr *ngFor="let subItem of filterCalculatorsByTopic(informationGrouped[i+2].key)" >
                  <td><a (click)="getCalculatorById(subItem.InformationId)" class="link">{{subItem.Title}}</a></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #calculatorModal>
    <div class="calculator-modal-header">
      <button type="button" class="close" aria-label="Close" (click)="calculatorModal.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div *ngIf="informationSelected != null" class="calculator-modal">

      <div class="calculator-modal-image"><img src="../../../assets/images/calculator.PNG" /></div>

      <h1 class="seperator">{{informationSelected.Title}}</h1>

      <div class="calculator-modal-teaser">{{informationSelected.Teaser}}</div>

      <div class="seperator"></div>

    </div>
 
  <div class="calculator-modal-footer" *ngIf="informationSelected != null && !bookmarkFormOpen">
    <download-information [informationId]="informationSelected.InformationId" [documentType]="informationSelected.DocumentType"></download-information>
    <bookmarks (bookmarkSaved)="bookmarkUpdated($event)" [informationId]="informationSelected.InformationId" [informationTypeId]="informationSelected.InformationTypeId" [topicId]="informationSelected.TopicId"  [bookmarkTitle]="informationSelected.Title"></bookmarks>

  </div>
</ng-template>


