<h1>Regupdate</h1>

<div [class.is-hidden]="!this.loading" class="imgloader">
  <span>Loading <img style="margin-left:5px;width:60px; height:60px;" src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loading-dots.gif" /></span>
</div>

<div id="lockAlert" class="alert alert-success lockAlert">
  <strong>RegUpdate Settings have been saved.</strong>
</div>

<div [class.is-hidden]="this.loading">
  <div class="panel panel-default">
    <div class="panel-heading">
      <mat-slide-toggle [(ngModel)]="regupdateSetting.Active">Receive RegUpdate emails?
      </mat-slide-toggle>
    </div>
</div>
  <div [class.is-hidden]="this.regupdateSetting.Active">
    <br/>
    <span class="text-danger">
     You have opted out of receiving Regupdate emails!
    </span>
  </div>

  <div [class.is-hidden]="!this.regupdateSetting.Active">
    <div class="panel panel-default">
      <div style="margin-left:25px;">
        <h2>Which states would you like to receive email updates regarding?</h2>
        <div class="checkbox">
          <label><input type="checkbox" (click)="setAllStates($event.target.checked)" [checked]="regupdateSetting.AllStates">All of my subscribed states</label>
        </div>
        <div [class.is-hidden]="regupdateSetting.AllStates">
          <div   class="checkbox">
            <ul class="states-group regupdate-checklist regupdate-columns">
              <li *ngFor="let state of regupdateSetting.States">
                <label><input class="regupdate-check" type="checkbox" (click)="setState(state.StateId,$event.target.checked)" [checked]="state.Selected"><span>{{state.StateDescription}}</span></label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-default">
      <div style="margin-left:25px;">
        <h2>Which categories would you like to receive email updates regarding?</h2>
        <div class="checkbox">
          <label><input type="checkbox" (click)="setAllCategories($event.target.checked)" [checked]="regupdateSetting.AllCategories">All of my subscribed categories</label>
        </div>
        <div [class.is-hidden]="regupdateSetting.AllCategories">
          <div *ngIf="showEnviro">
            <h2>Environmental Categories</h2>
            <div  class="checkbox">
              <ul class="states-group regupdate-checklist">
                <li *ngFor="let category of enviroCategories">
                  <label><input class="regupdate-check" type="checkbox" (click)="setCategory(category.CategoryId, $event.target.checked)" [checked]="category.Selected"><span>{{category.CategoryDescription}}</span></label>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="showSafety">
          <h2>Safety Categories</h2>
            <div  class="checkbox">
              <ul class="states-group regupdate-checklist">
                <li *ngFor="let category of safetyCategories">
                  <label><input class="regupdate-check" type="checkbox" (click)="setCategory(category.CategoryId, $event.target.checked)" [checked]="category.Selected"><span>{{category.CategoryDescription}}</span></label>
                </li>
              </ul>
            </div>          
          </div>
        </div>
      </div>
    </div>  
  </div>

  <div class="row" style="padding-top:10px;padding-bottom:20px;">
    <div class="col-sm-12">
      <a class="btn-orange" style="float:right;" (click)="saveRegUpdateSettings()">Save</a>
    </div>
  </div>
</div>

<div id="lockAlert2" class="alert alert-success lockAlert">
  <strong>RegUpdate Settings have been saved.</strong>
</div>