<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/all-training.png" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Training Materials</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">Access Instructor-Led Training Materials, including Customizable Powerpoints, Audiovisual Presentations, 7-Minute Safety Training, Toolbox Talks and Safety Meetings</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" (click)="navigate();" >Get Started</a>
</div>
