<a *ngIf="!isLimitedAccess && (documentType == 'doc' || documentType == 'docx')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file-word fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'xls' || documentType == 'xlsx' || documentType == 'xlsm')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file-excel fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'pdf')" target="_blank" class="btn-orange" (click)="download()"><i style="color:white;" class="far fa-file-pdf fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'ppt' || documentType == 'ppsx' || documentType == 'pptx')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file-powerpoint fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'html' || documentType == 'htm')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file-code fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'jpg' || documentType == 'jpeg' || documentType == 'png')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file-image fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'rtf' ||documentType == 'RTF')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file fa-w-12 fa-lg"></i>&nbsp;Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'mp3')" target="_blank" class="btn-orange" (click)="download()" role="button"><i style="color:white;" class="far fa-file-audio fa-w-12 fa-lg"></i>&nbsp;Download Now</a>

<a *ngIf="isLimitedAccess" (click)="challengeModal.open()" class="btn-orange"><i style="color:white;" class="far fa-file-pdf fa-w-12 fa-lg"></i>&nbsp;Download Now</a>


<challenge-modal #challengeModal [title]='downloadChallengeTitle'></challenge-modal>
