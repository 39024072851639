<h1>SDS Search</h1>

<h3><b>We're thrilled to announce exciting enhancements to our platform!</b></h3>
<p>
  With these updates, users can effortlessly access Safety Data Sheets (SDSs) in both Spanish and English.
  Our extensive SDS database remains intact, ensuring comprehensive information is always at your fingertips.
  Plus, we've introduced additional search options, making it easier than ever to find the information you need quickly.
</p>

<p>
  We're excited about these changes and confident they'll greatly enhance your experience with us!
</p>

<div class="row" style="padding-top:30px; padding-bottom:20px;">
  <div class="col-sm-12">
    <a href="https://www.3eonline.com/EeeOnlinePortal/DesktopDefault.aspx?id=9dra1OtLrcdkUxlRB%2BT%2FFI%2FEssypDtbHJD4R9QFtrIJUzp4HIHRVnK0ph3RBEFrIzTPwBKD4W6JdwAFrSROU%2Bw%3D%3D"
       class="btn-orange"
       style="float:right; margin-right:38%" target="_blank">Access SDS Search</a>
  </div>
</div>

<!--<div class="row" style="padding-top:5px;">
  <div class="col-sm-6">

    <div class="row">

      <div class="col-sm-12" >
        <label>Product Identifiers:</label>
        <input #product type="text" class="form-control" id="txtProduct" />
      </div>

    </div>

    <div class="row" style="padding-top:10px;">

      <div class="col-sm-12" >
        <label>Manufacturer or Supplier:</label>
        <input #manufacturer type="text" class="form-control" id="txtManufacturer" />
      </div>

    </div>

    <div class="row" style="padding-top:10px;">

      <div class="col-sm-12" >
        <label>CAS #:</label>
        <input #cas type="text" class="form-control" id="txtCAS" />
      </div>

    </div>

    <div class="row" style="padding-top:10px;">
      <div class="col-sm-12">
        <a class="btn-orange" style="float:right;" (click)="search(product.value, manufacturer.value, cas.value)">Search</a>
      </div>
    </div>

  </div>
  <div class="col-sm-6">
    <label>Note:</label> As of June 1, 2016, facilities must be in compliance with
    the Globally Harmonized System of Classification and Labeling
    of Chemicals (GHS) requirements.
  </div>

</div>

<div style="text-align:center; visibility:hidden;" class="imgloader">
  <span><img style="margin-left:5px;width:60px; height:60px;" src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loading-dots.gif" /></span>
</div>

<div style="padding-top:10px;" [hidden]="sdsSearchItems == null">
  <table  datatable class="table table-striped regulatory-activity-table"   [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
    <thead class="thead">
      <tr>
        <th>
          <a class="text-nowrap" style="cursor:pointer">Product <span></span> </a>          
        </th>
        <th>
          <a class="text-nowrap" style="cursor:pointer">Cas # <span></span> </a>          
        </th>
        <th>
          <a class="text-nowrap" style="cursor:pointer">Company Name <span></span> </a>         
        </th>
        <th>
          <a class="text-nowrap" style="cursor:pointer">Date <span></span> </a>          
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of sdsSearchItems">
        <td *ngIf="!updatedAPI"><a href="{{item.MSDSLink}}" target="_blank">{{item.name}}</a></td>
        <td *ngIf="updatedAPI"><a class="pointercursor" (click)="openPDF(item.name,item.libraryDocumentId);">{{item.name}}</a></td>
        <td>{{item.CAS}}</td>
        <td>{{item.manufacturerName}}</td>
        <td>{{item.revisionDate | date: 'MM/dd/yyyy'}}</td>
      </tr>
    </tbody>    
  </table>
</div>-->
