import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef,BsModalService  } from 'ngx-bootstrap/modal';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'report-spill',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './report-spill.html'
})

export class ReportSpillWidgetComponent {
  isLimitedAccess: boolean;
  modalRef: BsModalRef;
  
  constructor(private _mbService: MembershipDataService, public route: ActivatedRoute, private router: Router,
    public modalService: BsModalService) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }
  openModal(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
 }
  navigate() {
    this.router.navigate(['spill-reporting']);
  }
}

