import { Component, Provider, ElementRef, OnInit, Input, Compiler } from '@angular/core';

//services
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service';
import { UserProfileService } from '../../shared/services/user-profile.service';
import { MembershipDataService } from '../../shared/services/membership.data.service';

//types
import { UserProfile } from '../../shared/types/user-profile.type';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Setting } from '../../shared/types/application-settings';

@Component({
  selector: 'site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.css']
})

export class SiteSettingsComponent {
  showError: boolean = false;
  showHeaderError: boolean = false;
  showFooterError: boolean = false;
  userProfile: UserProfile = null;
  userId: number;
  headerImage: Setting = new Setting();
  footerImage: Setting = new Setting();
  footerAddress: Setting = new Setting();
  footerPhone: Setting = new Setting();
  showSuccess: boolean = false;
  headerError: any;
  footerError: any;
  error: any;
  fileSizeLimit: number = 49152;

  headerUploadInProgress: boolean = false;
  footerUploadInProgress: boolean = false;
  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: EhsHeroSvc,
    private fb: FormBuilder,
    private _mb: MembershipDataService,
    private _up: UserProfileService
  ) {
    
  }

  async ngOnInit() {
    this.userProfile = this._mb.userProfile;

    this.userId = this._mb.user.UserId;
    var customImages = await this._heroService.getCustomImages();

    if (this._mb.userProfileTypeId == 2) {
      if (this.userProfile.InheritableProperties.EHSHeaderLogo == undefined)
        this.userProfile.InheritableProperties.EHSHeaderLogo = this._mb.parentUserProfile.InheritableProperties.EHSHeaderLogo;
      if (this.userProfile.InheritableProperties.EHSFooterLogo == undefined)
        this.userProfile.InheritableProperties.EHSFooterLogo = this._mb.parentUserProfile.InheritableProperties.EHSFooterLogo;
      if (this.userProfile.InheritableProperties.EHSFooterAddress == undefined)
        this.userProfile.InheritableProperties.EHSFooterAddress = this._mb.parentUserProfile.InheritableProperties.EHSFooterAddress;
      if (this.userProfile.InheritableProperties.EHSFooterPhone == undefined)
        this.userProfile.InheritableProperties.EHSFooterPhone = this._mb.parentUserProfile.InheritableProperties.EHSFooterPhone;
    }

    this.headerImage.Value = customImages.header || this.userProfile.InheritableProperties.EHSHeaderLogo.Value;
    this.headerImage.TakeChild = this.userProfile.InheritableProperties.EHSHeaderLogo.TakeChild;
    this.footerImage.Value = customImages.footer || this.userProfile.InheritableProperties.EHSFooterLogo.Value;
    this.footerImage.TakeChild = this.userProfile.InheritableProperties.EHSFooterLogo.TakeChild;
    this.footerAddress.Value = this.userProfile.InheritableProperties.EHSFooterAddress.Value;
    this.footerAddress.TakeChild = this.userProfile.InheritableProperties.EHSFooterAddress.TakeChild;
    this.footerPhone.Value = this.userProfile.InheritableProperties.EHSFooterPhone.Value;
    this.footerPhone.TakeChild = this.userProfile.InheritableProperties.EHSFooterPhone.TakeChild;
  }

  uploadHeader(image) {
    const formData: FormData = new FormData();
    formData.append('Image', image[0], image[0].name);
    formData.append('Type', 'header');
    this.headerUploadInProgress = true;
    if (image[0].size >= this.fileSizeLimit) {
      this.showHeaderError = true;
      this.headerUploadInProgress = false;
      this.headerError = { Message: "File size limit exceeded" };
    }
    else {
      this._heroService.saveCustomImage(formData).then(data => {
        this.headerImage.Value = data.link;
        this.headerUploadInProgress = false;
      }, error => {
        this.showHeaderError = true;
        this.headerUploadInProgress = false;
        if (error.status == 500 || error.status == 400)
          this.headerError = error.error;
        else if (error.status == 413)
          this.headerError = { Message: error.statusText };
        else
          this.headerError = { Message: "Unknown error has occurred" };
      });
    }
  }

  uploadFooter(image) {
    const formData: FormData = new FormData();
    formData.append('Image', image[0], image[0].name);
    formData.append('Type', 'footer');
    this.footerUploadInProgress = true;
    if (image[0].size >= this.fileSizeLimit) {
      this.showFooterError = true;
      this.footerUploadInProgress = false;
      this.footerError = { Message: "File size limit exceeded" };
    }
    else {
      this._heroService.saveCustomImage(formData).then(data => {
        this.footerImage.Value = data.link;
        this.footerUploadInProgress = false;
      }, error => {
        this.showFooterError = true;
        this.footerUploadInProgress = false;

        if (error.status == 500 || error.status == 400)
          this.footerError = error.error;
        else if (error.status == 413)
          this.footerError = { Message: error.statusText };
        else
          this.footerError = { Message: "Unknown error has occurred" };
      });
    }
  }


  applyFooter(apply) {
    this.footerImage.TakeChild = (!apply).toString();
  }

  applyHeader(apply) {
    this.headerImage.TakeChild = (!apply).toString();
  }

  applyContacts(apply) {
    this.footerAddress.TakeChild = (!apply).toString();
    this.footerPhone.TakeChild = (!apply).toString();
  }

  isActive(setting) {
    return setting.TakeChild == 'false';
  }

  saveSettings() {
    this.userProfile.InheritableProperties.EHSFooterAddress.Value = this.footerAddress.Value;
    this.userProfile.InheritableProperties.EHSFooterAddress.TakeChild = this.footerAddress.TakeChild;
    this.userProfile.InheritableProperties.EHSFooterPhone.Value = this.footerPhone.Value;
    this.userProfile.InheritableProperties.EHSFooterPhone.TakeChild = this.footerPhone.TakeChild;
    this.userProfile.InheritableProperties.EHSHeaderLogo.TakeChild = this.headerImage.TakeChild;
    this.userProfile.InheritableProperties.EHSHeaderLogo.Value = this.headerImage.Value;
    this.userProfile.InheritableProperties.EHSFooterLogo.TakeChild = this.footerImage.TakeChild;
    this.userProfile.InheritableProperties.EHSFooterLogo.Value = this.footerImage.Value;

    this._up.saveUserProfile(this.userId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, this._mb.user.UserProfileTypeId, this._mb.user.UserName).then(data => {
      this.showSuccess = true;
    }, error => {
      this.showError = true;
      if (error.status == 500 || error.status == 400)
        this.error = error.error;
      else
        this.error = { Message: "Unknown error has occurred" };
    });
  }
}
