import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'compliance-calendar-widget',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './compliance-calendar.html'
})

export class ComplianceCalendarWidgetComponent {
  
  constructor(private _mbService: MembershipDataService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
  
  }
  
  navigate() {
    this.router.navigate(['compliance-calendar']);
  }
}

