import { Component } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { UserProfile } from '../../shared/types/user-profile.type'
import { UserProfileService } from '../../shared/services/user-profile.service';
import { Router } from '@angular/router';

//services
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service';

import  'jquery';
import { UserManagementService } from '../../shared/services/user-management.service';
import { RegUpdateSetting, RegUpdateCategory } from '../../shared/types/RegUpdate/regupdate-setting-type';

@Component({
  selector: 'regupdate',
  templateUrl: './regupdate.component.html',
  styleUrls: ['./regupdate.component.css']
})

export class RegUpdateComponent {

  userProfile: UserProfile = new UserProfile;
  regupdateSetting: RegUpdateSetting = new RegUpdateSetting;
  RegUpdateSelectedCategoryModel = true;
  loading = false;
  showSafety = true;
  showEnviro = true;
  safetyCategories: RegUpdateCategory[];
  enviroCategories: RegUpdateCategory[];


  constructor(
    private _mbService: MembershipDataService,
    private _router: Router,
    private _userProfileService: UserProfileService,
    private _userManagementService: UserManagementService,
    private _heroService: EhsHeroSvc
  ) {
    this.userProfile = this._mbService.userProfile;
  }

async ngOnInit() {
    this.loading = true;
    $(".lockAlert").hide();

    if (!this._mbService.hasTool("RegActivity"))
    {
      this._router.navigate(["error/404"]);
    }
    this._heroService.getRegUpdateSettings().subscribe((result) => {
      
      this.regupdateSetting = result;
      this.loading = false;

      var userMarket = this._heroService.GetUserMarket();
      if (userMarket == '3')
      {
        this.showEnviro = false;
      }
      else if (userMarket == '4')
      {
        this.showSafety = false;
      }
      
      this.enviroCategories = this.regupdateSetting.Categories.filter(x=>x.RegUpdateMarketId == '4' || x.RegUpdateMarketId == '3,4')
      this.safetyCategories = this.regupdateSetting.Categories.filter(x=>x.RegUpdateMarketId == '3' || x.RegUpdateMarketId == '3,4')
    });
  }

  
  setAllStates(checked)
  {
    this.regupdateSetting.AllStates = checked;
  }

  setAllCategories(checked)
  {
    this.regupdateSetting.AllCategories = checked;
  }

  setState(stateId, checked)
  {
    this.regupdateSetting.States.find(x=>x.StateId === stateId).Selected = checked;
  }

  setCategory(categoryId, checked)
  {
    this.regupdateSetting.Categories.find(x=>x.CategoryId === categoryId).Selected = checked;
  }

  saveRegUpdateSettings()
  {
     this._heroService.saveRegUpdateSettings(this.regupdateSetting);
     $(".lockAlert").show();
     setTimeout(function () { $(".lockAlert").hide(500); }, 1000);
  }
}
