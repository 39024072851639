<ng-template (onHide)="onModalHide()" onabort="onModalHide()" #template>
    <div class="modal-header modal-header-green">
         <h1>
           Confirm action
         </h1>
       </div>
       <div class="modal-body">
         <div class="body-content">
           {{confirmationMessage}}
         </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-default" (click)="confirmDelete.emit(true)">Yes</button>
         <button type="button" class="btn btn-default" (click)="dismiss()">No</button>
       </div>
    </ng-template>
