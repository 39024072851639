import { InformationType } from "./information-type.type";
import { Information } from "./information.type";
import { Topic } from "./topic.type";
import { Category } from "./category.type";
import { Jurisdition } from "./jurisdiction.type";

export class CollectionInfo {
  Categories: Category[];
  Topics: Topic[];
  Jurisdictions: Jurisdition[];
  InformationTypes: InformationType[];
  Information: Information[];

}

export class IndustryCollectionDetails {
  Title: string;
  Body: string;
  CollectionInfo: CollectionInfo;
  DocLink: string;
}
