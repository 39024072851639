<div class="tool-image-container"><img class="tool-image" alt="Report a Spill" src="/ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/SpillReporting/report-a-spill-icon.png"/></div>

<div class="tool-content">
  <div class="nav-sub-link">Report a Spill</div>
  <div class="tool-line-container">
    <div class="tool-line"></div>
  </div>
  <div class="body-content-italic">In the event of a spill quickly find your states specific spill reporting information</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" (click)="navigate();" >Get Started</a>
</div>


<ng-template cssClass="modal-xl" #modal>
  <div class="modal-header modal-header-blue">
    Limited Access
  </div>
  <div class="modal-body">
    <div class="body-content">
      Coming soon
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>
