<h1>Trainer's Toolbox</h1>
<div class="body-content seperator body-content-margin-override">Download these helpful documents to use them with all of EHS Hero training presentations</div>

<div *ngIf="information != null" class="panel panel-default">
    <table class="table table-striped">
      <thead class="thead">
        <tr>
          <th>
            Title
          </th>
          <th>
            Language
          </th>
          <th  style="text-align:right; padding-right:20px;">
            Document Type
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of information">
          <td><a style="cursor:pointer;" (click)="selectInformation(item)" class="link" [innerHtml]="item.Title"></a></td>
          <td>{{item.Language}}</td>
          <td *ngIf="!isLimitedAccess && item.DocumentType == 'pdf'" style="text-align:right; padding-right:80px;"><a target="_blank" role="button" class="link" (click)="download(item.InformationId,item.DocumentType,item.Title)"><i class="far fa-file-pdf fa-w-12 fa-lg"></i> PDF</a></td>
          <td *ngIf="!isLimitedAccess && item.DocumentType == 'doc'" style="text-align:right; padding-right:70px;"><a target="_blank" role="button" class="link" (click)="download(item.InformationId,item.DocumentType,item.Title)"><i class="far fa-file-word fa-w-12 fa-lg"></i> Word</a></td>

          <td *ngIf="isLimitedAccess && item.DocumentType == 'pdf'" style="text-align:right; padding-right:80px;"><a class="link" (click)="challengeModal.open()"><i class="far fa-file-pdf fa-w-12 fa-lg"></i> PDF</a></td>
          <td *ngIf="isLimitedAccess && item.DocumentType == 'doc'" style="text-align:right; padding-right:70px;"><a class="link" (click)="challengeModal.open()"><i class="far fa-file-word fa-w-12 fa-lg"></i> Word</a></td>
        </tr>
      </tbody>
    </table>

  <challenge-modal #challengeModal [title]='downloadChallengeTitle'></challenge-modal>
</div>
