<div style="margin-bottom:20px;" class="body-content-label" >
  <i class="fas fa-folder-open" aria-hidden="true"></i> My Bookmarks > {{bookmarkFolderLabel}}
</div>
<div class="row">
  <div class="col-lg-3">
    <div class="outer-filter-panel">
      <div class="filter-panel" data-toggle="collapse" data-target="#date">
        Folders
      </div>
      <div id="date" class="collapse in" style="overflow-y:auto;">

        <table class="table table-striped" *ngIf="bookmarkFolders != null">
          <tbody>
            <tr *ngFor="let item of bookmarkFolders" style="cursor:pointer;">
              <td (click)="getBookmarksByBookmarkFolder(item.BookmarkFolderId, item.Name);">{{item.Name}} <a (click)="editBookmarkFolder(item)" style="cursor:pointer;">edit</a></td>
              <td (click)="getBookmarksByBookmarkFolder(item.BookmarkFolderId, item.Name);">{{item.BookmarkCount}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div style="margin-top:20px;">
      <a class="btn-white-grey btn-stretch" (click)="openBookmarkFolderModal()" style="width:100%;">
        New Folder <i class="fas fa-plus-circle"></i>
      </a>
    </div>
  </div>
  <div *ngIf="loading" class="row" style="text-align:center;">
    <img src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
  </div>
  <div class="col-lg-9" [hidden]="loading">
    <div class="panel panel-default" [hidden]= "bookmarks==null || bookmarks.length==0">
      <table datatable class="table table-striped" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="thead">
          <tr>
            <th width="12.5%">
              Type
            </th>
            <th width="22.5%">
              Topic
            </th>
            <th width="37.5%">
              Title
            </th>
            <th width="15%">
              Last Saved
            </th>
            <th style="width: 10%">Manage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of bookmarks">
            <td [routerLink]="[(item.URL.startsWith('/resources/') || item.URL.startsWith('/training/') || item.URL.startsWith('/checklists-forms/')) ? item.URL + '/' + item.InformationId : item.URL]" style="cursor:pointer;">{{item.InformationTypeName}}</td>
            <td [routerLink]="[(item.URL.startsWith('/resources/') || item.URL.startsWith('/training/') || item.URL.startsWith('/checklists-forms/')) ? item.URL + '/' + item.InformationId : item.URL]" style="cursor:pointer;">{{item.TopicName}}</td>
            <td [routerLink]="[(item.URL.startsWith('/resources/') || item.URL.startsWith('/training/') || item.URL.startsWith('/checklists-forms/')) ? item.URL + '/' + item.InformationId : item.URL]" style="cursor:pointer;">{{item.Title}}</td>
            <td [routerLink]="[(item.URL.startsWith('/resources/') || item.URL.startsWith('/training/') || item.URL.startsWith('/checklists-forms/')) ? item.URL + '/' + item.InformationId : item.URL]" style="cursor:pointer;">{{item.DateTimeBookmarked | date:'shortDate'}}</td>
            <td><bookmarks (bookmarkSaved)="bookmarkSaved($event)" [bookmarkId]="item.BookmarkId" [bookmarkTitle]="item.Title" [bookmarkFolderId]="item.BookmarkFolderId" [informationId]="item.InformationId" [informationTypeId]="item.InformationTypeId" [topicId]="item.TopicId"></bookmarks></td>
          </tr>
        </tbody>
      </table>
    </div>    
    <div *ngIf="bookmarks == null || bookmarks.length==0"><h2>No bookmarks exist in this folder.</h2></div>
  </div>
</div>

<!--MY Bookmark modal-->
<!-- <ng-template #MyBookmarkModal>
  <div *ngIf="selectedBookmark != null">
    <div class="modal-header"> MANAGE BOOKMARKS </div>
    <div class="modal-body">
      Title
      <input type="text" class="form-control" [(ngModel)]="selectedBookmark.Title" placeholder="Edit bookmark title" />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="saveBookmark(selectedBookmark)">Save</button>
      <button type="button" class="btn btn-default" (click)="deleteBookmark(selectedBookmark.BookmarkId)">Delete</button>
      <button type="button" class="btn btn-default" (click)="MyBookmarkModal.dismiss()">Cancel</button>
    </div>
  </div>
</ng-template> -->



<!--bookmark folder modal-->
<ng-template #bookmarkFolderModal>
  <div class="modal-header modal-header-blue" *ngIf="bookmarkFolderName != '' && bookmarkFolderName != null">
    Manage Folder
  </div>
  <div class="modal-header modal-header-blue" *ngIf="!(bookmarkFolderName != '' && bookmarkFolderName != null)">
    New Folder
  </div>
  <div class="modal-body">
    <div class="body-content-label">Name</div>
    <input type="text" class="form-control" placeholder="Enter folder name" [(ngModel)]="bookmarkFolderName" [value]="bookmarkFolderName" />
    <div style="margin-top:10px; color:red;">{{errorMessage}}</div>
  </div>
  <div class="modal-footer">
    <button *ngIf="bookmarkFolderName != '' && bookmarkFolderName != null" type="button" class="btn btn-danger" (click)="deleteBookmarkFolder()">Delete</button>
    <button type="button" class="btn btn-success" (click)="addBookmarkFolder()">Save</button>
    <button type="button" class="btn btn-default" (click)="closeBookmarkFolderModal()">Cancel</button>
  </div>
</ng-template>