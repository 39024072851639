import { Component, Provider, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { Information } from '../../shared/types/information.type'
import { MembershipDataService } from '../../shared/services/membership.data.service'

@Component({
  selector: 'new',
  templateUrl: './new.html',
  styleUrls: ['./new.css']
})

export class TrainingNewComponent {

  information: Information[];
  databaseIds: string;

  constructor(
    private _heroService: EhsHeroSvc,
    public router: Router,
    private mbDataService: MembershipDataService
  ) { }

  ngOnInit() {

    if (!this.mbDataService.hasTool("Training") || !this.mbDataService.customPermissions.GeneralPermissions.training.Visible)
      this.router.navigate(["error/403"]);

    this.databaseIds = this.mbDataService.dataAccessLevels();

    //6 months
    this._heroService.getInformationTrainingNew(this.mbDataService.dataAccessLevels(), 'page').then(information => {
      if (information != null) {
        this.information = information;
      }
    });

  }

  selectInformation(informationItem: Information) {
    this.router.navigate([informationItem.Url]);
  }

  changeText(el, parentIndex) 
  {
    if (el.innerHTML.includes("See all materials")) el.innerHTML = "See less materials";
    else el.innerHTML = "See all materials";
  }


}
