import { Component, Input, ViewEncapsulation } from '@angular/core';


@Component({
  selector: "collapse-header",
  templateUrl: './collapse-header.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class CollapseHeaderComponent {

  isCollapsed: boolean = false;
  

  @Input() dataTargetId: string;
  @Input() headerTitle: string;

}
