import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EhsHeroSvc } from '../../shared/services/ehs-hero.service'
import { EhsTrainingService } from '../../shared/services/ehs-training.service'
import { Information } from '../../shared/types/information.type'
import { MembershipDataService } from '../../shared/services/membership.data.service';
import { ChallengeModalComponent } from '../../shared/components/challenge-modal.component';

@Component({
  templateUrl: './details.html',
  styleUrls: ['./details.css']
})

export class TrainingDetailsComponent {

  isLimitedAccess: any;
  information: Information = null;
  supportingDocuments: Information[]=[];
  @ViewChild('challengeModal')
  modal: ChallengeModalComponent;
  pageName: string;
  isCollection: boolean = false;

  constructor(
    public _route: ActivatedRoute,
    public _router: Router,
    private _heroService: EhsHeroSvc,
    private _heroTrainingService: EhsTrainingService,
    private _mbService: MembershipDataService
  ) { }

  ngOnInit() {
    if (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible)
    { 
      this._router.navigate(["error/403"]);
    }
    this._route.params.subscribe(params => {
      this.isLimitedAccess = this._mbService.isLimitedAccess();
      if (params["id"] != null) {

        this.getTrainingDetails(params["id"]);
        this.getSupportingDcouments(params["id"]);

      }

    });

  }

  getTrainingDetails(infoId) {

    this._heroTrainingService.getTrainingDetails(infoId).then(result => {

      if (result != null) {

        this.information = result;

        this.pageName = this.information.FilePath;

        if (this.information != null && this.information != undefined) {

          let source = (this.information.DatabaseId == 3) ? "safety" : "enviro";
          if ((this.information.InformationTypeId == 11) && (this.information.FilePath) && (this.information.FilePath !== ''))

            this.information.FilePath = "/uploads-virtual/Audio/EHS/" + source + "/" + this.information.FilePath;
        }
      }
      else
        this._router.navigate(["error/404"]);
    });

  }

  getSupportingDcouments(infoId) {

    this._heroTrainingService.getTrainingSupportingDocuments(infoId).then(result => {

      if (result != null && result.length > 0) {
        this.supportingDocuments = result;
        let parentId = null;

        result.forEach(item => {

          if (item.InformationId != parentId && parentId != null) {
            this.isCollection = true;
          }

          parentId = item.InformationId;

        });

      }

    });

  }


  getSupportingDocuments(infoId) {
    this._heroService.getInformationByType(infoId, 'child-docs').then(ts => {

      this.supportingDocuments = ts;

    });
  }

  openDetails(information) {
    this._router.navigate([information.Url], { relativeTo: this._route });
  }
  linkType(informationSelected) {

    if (informationSelected.FilePath == null || informationSelected.FilePath == '') {
      return 1;
    }
    else if (informationSelected.FilePath.includes(':')) {
      return 2;
    }
    else {
      return 3;
    }

  }
}
