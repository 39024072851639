import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { MembershipDataService } from '../shared/services/membership.data.service'

import 'jquery';
import { loadavg } from 'os';

@Component({
    selector: 'chart-builder',
    templateUrl: './chart-builder.component.html'
})

export class ChartBuilderComponent {
    url: string = null;
    loading: boolean = false;
    constructor(
        private mbDataService: MembershipDataService,
        public _router: Router,
      public sanitizer: DomSanitizer,
      private route: ActivatedRoute

    ) { }
    onMessage(e) {
        console.log(e)
    }
  ngOnInit() {
        if (!this.mbDataService.hasTool("CHB") || !this.mbDataService.customPermissions.GeneralPermissions.chartBuilderTool.Visible)
            this._router.navigate(["error/403"]);

      this.url = window.location.protocol + "//" + window.location.hostname.replace("hero.blr.com", "ehscbapi.blr.com")
        + "/client?userId="+ this.mbDataService.user.CustomerId
        + "&typeId=" + this.mbDataService.ehsCbAccessLevel();
    //this.url = "http://localhost:55634/client?userId=203709&typeId=3"; url for run it in local
    if (this.route.snapshot?.queryParams?.chartId) {
      this.url += "&chartId=" + this.route.snapshot.queryParams.chartId;
    }
    else {
      if (this.route.snapshot.params.topicId)
        this.url += "&topicId=" + this.route.snapshot.params.topicId
          + "&subtopicId=" + this.route.snapshot.params.subtopicId
          + "&stateId=" + this.route.snapshot.params.stateId;
    }
        //this.url = this.url.replace("local.ehsehs","stg-ehs");
  
        let _this = this;
        $(function ($) {
            var lastHeight = 0, curHeight = 0, $frame = $('#chbIFrame');
            setInterval(function () {
                curHeight = parseInt(_this.getCookie("_ehsCbHeight"));

                if (curHeight != lastHeight) {
                    lastHeight = curHeight;
                    $frame.css('height', (lastHeight) + 50 + 'px');
                }
            }, 500);
        });
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
}
