import { Category } from '../types/category.type'
import { Topic } from '../types/topic.type'
import { Jurisdition } from '../types/jurisdiction.type'
import { InformationType } from '../types/information-type.type'
import { Language } from '../types/language.type'
import { Information } from '../types/information.type'
import { DateRange } from '../types/date-range.type'
import { Source } from '../types/market.type'

export class SearchModel {
  Categories: Category[];
  Topics: Topic[];
  Jurisdictions: Jurisdition[];
  InformationTypes: InformationType[];
  Languages: Language[];
  Information: Information[];
  DateRanges: DateRange[];
  Sources: Source[];
  //NewItems: Information[];
  //AllChecked: boolean;
}
