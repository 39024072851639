<div *ngIf="url != null">

  <iframe id="chbIFrame"
          allow="clipboard-read; clipboard-write"
          frameborder="0"
          scrolling="no"
          style=" position: relative;
          height: 100%;
          width: 100%;
          overflow: hidden;"
          [src]="url | safe">
  </iframe>

</div>
