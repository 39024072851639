import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component, ErrorHandler} from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule,HTTP_INTERCEPTORS } from '@angular/common/http';

import { CanActivate, Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormWizardModule } from 'angular2-wizard';

import { AppComponent } from './app.component';
//components

//import { AdminNewsComponent } from '../app/news/news.component'
//import { AdminEditNewsComponent } from '../app/news/editInfo.component'
//pages
import { DashBoardComponent } from '../app/dashboard/dashboard.component'
import { ToolsComponent } from '../app/tools/tools'
import { LoginComponent } from '../app/login/login.component'
import { SNEHSLoginComponent } from '../app/login/snehs-login.component'
import { AutoLinkComponent } from './login/autolink.component';
import { LatestNewsComponent } from '../app/resources/news/latest-news.component'
import { TrainingNewComponent } from '../app/training/new/new'
import { TrainingNewDashboardComponent } from '../app/training/shared/new.component'
import { TrainingAllComponent } from '../app/training/all-training/all-training.component'
import { TrainingDetailsComponent } from '../app/training/details/details'
import { TrainingTrainersToolboxComponent } from '../app/training/trainers-toolbox/trainers-toolbox'
import { SafetyVideoComponent } from '../app/training/safety-video/safety-video'
import { PreferencesComponent } from '../app/settings/preferences/preferences.component'
import { RegUpdateComponent } from './settings/regupdate/regupdate.component'
import { RegUpdateAdminComponent } from './admin/regupdateadmin/regupdate-admin.component'

import { AccountSettingsComponent } from '../app/settings/account-settings/account-settings.component'
import { CustomSettingsComponent } from '../app/settings/custom-settings/custom-settings.component'
import { AddWidgetComponent } from '../app/settings/custom-settings/edit-widget.component'
import { UserManagementComponent } from '../app/settings/user-management/user-management.component'
import { EditUserComponent } from '../app/settings/user-management/edit-user.component'
import { MyBookmarksComponent } from '../app/myBookmarks/myBookmarks.component'
import { ChecklistsFormsComponent } from '../app/resources/checklists-forms/checklists-forms'
import { CalculatorsComponent } from '../app/resources/calculators/calculators'
import { SDSSearchComponent } from '../app/resources/sds-search/sds-search.component'
import { ReportableIncidentComponent } from '../app/reportableIncident/reportable-incident.component'
import { SpillReportingComponent } from '../app/spill-reporting/spill-reporting.component'
import { ComplianceCalendarComponent } from '../app/compliance-calendar/compliance-calendar.component'
import { News } from '../app/resources/news/news'
import { Search } from '../app/search/search'
import { Error } from '../app/error/error'
import { BackToTopComponent } from '../app/shared/components/back-to-top.component'
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

//components
import { CategoryTopicLanguageComponent } from '../app/shared/components/selectors/category-topic-language.component'
import { CategoryTopicTypeComponent } from '../app/shared/components/selectors/category-topic-type.component'
import { StateSelectorComponent } from '../app/shared/components/selectors/state.component'
import { DownloadComponent } from '../app/shared/components/download.component'
import { HotlineComponent } from '../app/hotline/hotline.component'
//import { AdminComponent } from '../app/admin/admin.component'
import { BookmarkComponent } from '../app/shared/components/bookmarks.component'
import { AllTrainingWidgetComponent } from '../assets/widgets/tools/all-training/all-training'
import { ChartBuilderWidgetComponent } from '../assets/widgets/tools/chart-builder/chart-builder'
import { PlanBuilderWidgetComponent } from '../assets/widgets/tools/plan-builder/plan-builder'
import { RegulatoryActivityWidgetComponent } from '../assets/widgets/tools/regulatory-activity/regulatory-activity'
import { SafetyToolboxTrainerWidgetComponent } from '../assets/widgets/tools/safety-toolbox-trainer/safety-toolbox-trainer'
import { SDSSearchWidgetComponent } from '../assets/widgets/tools/sds-search/sds-search'
import { RecordableWidgetComponent } from '../assets/widgets/tools/recordable/recordable'
import { ReportSpillWidgetComponent } from '../assets/widgets/tools/report-spill/report-spill'
import { ComplianceCalendarWidgetComponent } from '../assets/widgets/tools/compliance-calendar/compliance-calendar'
import { ChemiclListsWidgetComponent } from '../assets/widgets/tools/chemical-lists/chemical-lists'
import { ManagementTierWidgetComponent } from '../assets/widgets/tools/management-tier/management-tier'
import { WhatsNewWidgetComponent } from '../assets/widgets/tools/whats-new/whats-new'
import { CustomWidgetComponent } from '../app/shared/components/custom-widget.component'
import { HotlineHeaderComponent } from '../app/hotline/hotline-header.component'
import { HiddenReindexComponent } from '../app/dev/hidden-reindex.component'
import { Safety101WidgetComponent } from '../assets/widgets/tools/safety101/safety101'

//external components
import { ChartBuilderComponent } from '../app/external-components/chart-builder.component'
//import { JDMComponent } from '../app/external-components/jdm.component'

//modules
import { DragulaModule } from 'ng2-dragula'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables'
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { TagInputModule } from 'ngx-chips';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule} from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

// TagInputModule.withDefaults({
//   tagInput: {
//     inputClass: 'tag-class',
//   }
// });

//pipes
import { SafeHtmlPipe } from '../app/shared/pipes/safe-html'
import { SafeHtmlNewsPipe } from '../app/shared/pipes/safe-html-news.pipe'
import { GroupByPipe } from '../app/shared/pipes/group-by'
import { SortPipe } from '../app/shared/pipes/sort.pipe'
import { OrderModule } from 'ngx-order-pipe';
import { FooterComponent } from '../app/footer/footer.component'
import { SafePipe } from '../app/shared/pipes/safe-url.pipe'
import { SanitizeHtmlPipe } from '../app/shared/pipes/sanitizeHtml.pipe'
import { DataFilterPipe } from '../app/shared/pipes/data-filter.pipe'
import { FilterPipe } from '../app/shared/pipes/filter.pipe'

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

//services

import { AuthGuard } from '../app/shared/guards/auth-guard'
import { ToolsAuthGuard } from '../app/shared/guards/tools-auth-guard'
import { MBToolAuthGuard } from '../app/shared/guards/mb-tool-auth-guard'
import { CookieService } from 'ngx-cookie-service'
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { RegulatoryActivity } from './resources/regulatory-activity/regulatory-activity.component';
import { FederalRegulations } from './resources/regulations/federal-regulations.component';
import { AllResources } from './resources/all-resources/all-resources.component';
import { ChallengeModalComponent } from './shared/components/challenge-modal.component';
import { CollapseHeaderComponent } from './shared/components/collapse-header.component';
import { SiteSettingsComponent } from './settings/site-settings/site-settings.component';
import { PermissionTiersComponent } from './settings/permission-tiers/permission-tiers.component';
import { ConfirmDeleteComponent } from './shared/components/confirm-delete.component';
import { TierSelectorComponent } from './shared/components/selectors/tier-select.component';
import { HeroErrorHandler } from './error/error-handler';
import { Covid19WidgetComponent } from '../assets/widgets/tools/covid-19/covid-19';
import { IndustryCollectionsComponent } from './industry-collections/industry-collections.component';
import { IndustryCollectionsDetailsComponent } from './industry-collections/industry-collections-details.component';
import { HelpWidgetComponent } from '../assets/widgets/tools/help/help';
import { ManagementTierSplashComponent } from './managementtier/managementtier-splash.component';
import {SSOLoginComponent} from './sso-login/sso-login.component';
import { AudioPresentationsComponent } from './training/audio-presentations/audio-presentations';

//directives
import { DynamicAnchorDirective } from './shared/directives/dynamicAnchor';

//helpers
import { AuthInterceptor } from '../app/shared/helpers/AuthInterceptor';

@NgModule({
  declarations: [
    BackToTopComponent,
    //AdminEditNewsComponent,
    //AdminNewsComponent,
    DashBoardComponent,
    ToolsComponent,
    AppComponent,
    AllTrainingWidgetComponent,
    ChartBuilderWidgetComponent,
    PlanBuilderWidgetComponent,
    RecordableWidgetComponent,
    ChemiclListsWidgetComponent,
    ReportSpillWidgetComponent,
    ComplianceCalendarWidgetComponent,
    SpillReportingComponent,
    RegulatoryActivityWidgetComponent,
    SafetyToolboxTrainerWidgetComponent,
    SDSSearchWidgetComponent,
    CustomWidgetComponent,
    Covid19WidgetComponent,
    HelpWidgetComponent,
    LoginComponent,
    SNEHSLoginComponent,
    AutoLinkComponent,
    ChartBuilderComponent,
    SDSSearchComponent,
    ManagementTierWidgetComponent,
    Safety101WidgetComponent,
    WhatsNewWidgetComponent,
    //JDMComponent,
    HotlineComponent,
    PreferencesComponent,
    RegUpdateComponent,
    RegUpdateAdminComponent,
    CustomSettingsComponent,
    EditUserComponent,
    AddWidgetComponent,
    UserManagementComponent,
    AccountSettingsComponent,
    MyBookmarksComponent,
    HotlineHeaderComponent,
    //AdminComponent,
    CategoryTopicLanguageComponent,
    ReportableIncidentComponent,
    CategoryTopicTypeComponent,
    StateSelectorComponent,
    DownloadComponent,
    TrainingNewComponent,
    ChecklistsFormsComponent,
    CalculatorsComponent,
    TrainingNewDashboardComponent,
    TrainingAllComponent,
    TrainingTrainersToolboxComponent,
    TrainingDetailsComponent,
    BookmarkComponent,
    FooterComponent,
    SafeHtmlPipe,
    GroupByPipe,
    SortPipe,
    SafePipe,
    SanitizeHtmlPipe,
    SafeHtmlNewsPipe,
    DataFilterPipe,
    FilterPipe,
    News,
    LatestNewsComponent,
    Search,
    Error,
    RegulatoryActivity,
    FederalRegulations,
    AllResources,
    ChallengeModalComponent,
    CollapseHeaderComponent,
    SiteSettingsComponent,
    PermissionTiersComponent,
    ConfirmDeleteComponent,
    TierSelectorComponent,
    IndustryCollectionsComponent,
    IndustryCollectionsDetailsComponent,
    ManagementTierSplashComponent,
    HiddenReindexComponent   ,
    DynamicAnchorDirective,
    SafetyVideoComponent,
    SSOLoginComponent,
    AudioPresentationsComponent

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    DragulaModule,
    //ResizableModule,
    FormWizardModule,
    DataTablesModule,
    MatSlideToggleModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    VirtualScrollModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    //SimpleNotificationsModule.forRoot(),
    TagInputModule,
    HttpClientModule,
    HttpClientJsonpModule,
    OrderModule,
    TooltipModule.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard', component: DashBoardComponent, canActivate: [AuthGuard]  },
      { path: 'tools', component: ToolsComponent, canActivate: [ToolsAuthGuard]   },
      { path: 'hotline', component: HotlineComponent, canActivate: [AuthGuard]   },
      { path: 'myBookmarks', component: MyBookmarksComponent, canActivate: [AuthGuard]   },
      { path: 'preferences', component: PreferencesComponent, canActivate: [AuthGuard] },
      { path: 'regupdate', component: RegUpdateComponent, canActivate: [AuthGuard] },
      { path: 'regupdate-admin', component: RegUpdateAdminComponent, canActivate: [AuthGuard] },
      { path: 'custom-settings', component: CustomSettingsComponent, canActivate: [AuthGuard] },
      { path: 'edit-widget', component: AddWidgetComponent, canActivate: [AuthGuard] },
      { path: 'edit-widget/:id', component: AddWidgetComponent, canActivate: [AuthGuard] },
      { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard] },
      { path: 'user-management/edit-user/:id', component: EditUserComponent, canActivate: [AuthGuard] },
      { path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuard] },
      { path: 'search', component: Search, canActivate: [AuthGuard]   },
      { path: 'search/:searchTerm', component: Search, canActivate: [AuthGuard] },
      { path: 'search/:searchTerm/:type', component: Search, canActivate: [AuthGuard] },
      { path: 'training/new', component: TrainingNewComponent, canActivate: [AuthGuard]   },
      { path: 'training/trainers-toolbox', component: TrainingTrainersToolboxComponent, canActivate: [AuthGuard] },      
      { path: 'training/safety-video', component: SafetyVideoComponent, canActivate: [AuthGuard] },      
      { path: 'training/access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'training', component: TrainingAllComponent, canActivate: [AuthGuard]   },
      { path: 'training/:id', component: TrainingDetailsComponent, canActivate: [AuthGuard] },
      { path: 'training/:type/:category/:topic/:title/:id', component: TrainingDetailsComponent, canActivate: [AuthGuard] },
      { path: 'workplace-safety-trainers-outlines/:category/:topic/:title', component: TrainingDetailsComponent, canActivate: [AuthGuard] },
      { path: 'checklists-forms/:type/:category/:topic/:title/:id', component: AllResources, canActivate: [AuthGuard] },
      { path: 'resources/final-regulations/:category/:topic/:title/:id', component: RegulatoryActivity, canActivate: [AuthGuard] },
      { path: 'resources/notices/:category/:topic/:title/:id', component: RegulatoryActivity, canActivate: [AuthGuard] },
      { path: 'resources/proposed-regulations/:category/:topic/:title/:id', component: RegulatoryActivity, canActivate: [AuthGuard] },
      { path: 'resources/:type/:category/:topic/:title/:id', component: AllResources, canActivate: [AuthGuard] },
      { path: 'all-resources', component: AllResources, canActivate: [AuthGuard] },
      { path: 'all-resources/access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'sds-search', component: SDSSearchComponent, canActivate: [AuthGuard] },
      { path: 'sds-search/access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'calculators', component: CalculatorsComponent, canActivate: [AuthGuard] },
      { path: 'calculators/:category/:topic/:title', component: CalculatorsComponent, canActivate: [AuthGuard] },
      { path: 'sso-login/:route', component: SSOLoginComponent },
      { path: 'sso-login', component: SSOLoginComponent },
      { path: 'login/:route', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'snehs-login', component: SNEHSLoginComponent },
      { path: 'autolink', component: AutoLinkComponent },
      { path: 'news', component: News, canActivate: [AuthGuard] },
      { path: 'news/access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'news/:category/:topic', component: News, canActivate: [AuthGuard] },
      { path: 'news/:category/:topic/:title', component: News, canActivate: [AuthGuard] },
      { path: 'news/:category/:topic/:title/:id', component: News, canActivate: [AuthGuard] },
      { path: 'regulatory-activity/resources/:type/:category/:topic/:title/:id', component: RegulatoryActivity, canActivate: [AuthGuard] },
      { path: 'regulatory-activity', component: RegulatoryActivity, canActivate: [AuthGuard] },
      { path: 'regulatory-activity/access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'regulations', component: FederalRegulations, canActivate: [AuthGuard] },
      { path: 'regulations/access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'user-management/permission-tiers', component: PermissionTiersComponent, canActivate: [AuthGuard] },
      { path: 'recordable-incident', component: ReportableIncidentComponent, canActivate: [AuthGuard] },
      { path: 'spill-reporting/:type', component: SpillReportingComponent, canActivate: [AuthGuard] },
      { path: 'spill-reporting', component: SpillReportingComponent, canActivate: [AuthGuard] },
      { path: 'compliance-calendar', component: ComplianceCalendarComponent, canActivate: [AuthGuard] },
      { path: 'chart-builder', component: ChartBuilderComponent, canActivate: [MBToolAuthGuard] },
      { path: 'chart-builder/:topicId/:subtopicId/:stateId', component: ChartBuilderComponent, canActivate: [MBToolAuthGuard] },
      { path: 'custom-site-settings', component: SiteSettingsComponent, canActivate: [AuthGuard] },
      { path: 'industry-collections', component: IndustryCollectionsComponent, canActivate: [AuthGuard] },
      { path: 'industry-collections/:category', component: IndustryCollectionsDetailsComponent, canActivate: [AuthGuard] },
      { path: 'managementtier', component: ManagementTierSplashComponent, canActivate: [AuthGuard] },
      { path: 'error/:type', component: Error, canActivate: [AuthGuard] },
      { path: 'error/:type/:page', component: Error, canActivate: [AuthGuard] },
      { path: 'access-denied', component: Error, canActivate: [AuthGuard] },
      { path: 'hidden-reindex', component: HiddenReindexComponent, canActivate: [AuthGuard] },
      { path: 'audio-presentations/:url', component: AudioPresentationsComponent, canActivate: [AuthGuard] },
      { path: '**', redirectTo: 'error/404' }//,
    ]),
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent],
  providers: [AuthGuard, ToolsAuthGuard, MBToolAuthGuard, CookieService, BsModalService,BsModalRef,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },] 
})

export class AppModule { }
