import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { VirtualScrollComponent } from 'angular2-virtual-scroll';

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Information } from '../../shared/types/information.type'

import { SafeHtmlPipe } from '../../shared/pipes/safe-html'
import { MembershipDataService } from '../../shared/services/membership.data.service';
import { EhsResourcesService } from '../../shared/services/ehs-resources.service';

@Component({
  selector: 'news',
  templateUrl: './news.html',
  styleUrls:['./news.css'],
  providers: [ SafeHtmlPipe]
})

export class News {

  newsArticles: Information[];

  selectedArticle: Information;

  details: boolean = false;
  category: string;
  topic: string;
  title: string;
  loading: boolean = false;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: EhsResourcesService,
    private _mbService: MembershipDataService,
  ) { }

  ngOnInit() {

    this.route.params.subscribe(async params => {
      let url = '/news/';
      if (!this._mbService.hasTool("News") || !this._mbService.customPermissions.GeneralPermissions.news.Visible)
        this.router.navigate(["error/403"]);

      if (params['category'] != null) {
        url = url + params['category'];
      }
      if (params['topic'] != null) {
        url = url + "/" + params['topic'];
      }
      if (params['title'] != null) {
        url = url + "/" + params['title'];
      }
      this.loading = true;

      if (params['id'] != null) {
        let id = params['id'];

        this.loading = true;
        this.selectedArticle = await this._heroService.getNewsArticle(id);
        if (this.selectedArticle == null)
          this.router.navigate(["error/404"]);
        this.details = true;
        if (!this.newsArticles)
          this.newsArticles = await this._heroService.getAllNews(this._mbService.dataAccessLevels());
        if (this.newsArticles.find(w => w.InformationId == id) != null) {
          window.scrollTo(0, 0);
        }
        this.loading = false;

      }
      else {
      this._heroService.getAllNews(this._mbService.dataAccessLevels()).then(information => {

        if (information != null) {
          this.newsArticles = information;
          this.loading = false;
          if (this.newsArticles.find(w => w.Url == url || w.Url == url + '/') != null) {
            this.viewArticle(this.newsArticles.find(w => w.Url == url || w.Url == url + '/'));

            window.scrollTo(0, 0);
          }
        }

      });
    }
    });

  }

  viewArticle(info: Information) {

    this.router.navigate([info.Url]);
    this.selectedArticle = info;
    this.details = true;

  }

  next() {

    let index = this.newsArticles.findIndex(item => item.InformationId == this.selectedArticle.InformationId)

    if (this.newsArticles.length > index) {
      let next = index + 1;
      var currentTarget = document.getElementById(this.newsArticles[index].InformationId.toString());
      var nextTarget = document.getElementById(this.newsArticles[next].InformationId.toString());
      this.animate(document.getElementById('articleScroll'), "scrollTop", "", currentTarget.offsetTop, nextTarget.offsetTop, 250, true);

      this.viewArticle(this.newsArticles[next]);
      
      window.scrollTo(0, 0);
    }
  }

  previous() {

    let index = this.newsArticles.findIndex(item => item.InformationId == this.selectedArticle.InformationId)

    if (index != 0) {
      let next = index - 1;
      var currentTarget = document.getElementById(this.newsArticles[index].InformationId.toString());
      var nextTarget = document.getElementById(this.newsArticles[next].InformationId.toString());
      this.animate(document.getElementById('articleScroll'), "scrollTop", "", currentTarget.offsetTop, nextTarget.offsetTop, 250, true);

      this.viewArticle(this.newsArticles[next]);

      window.scrollTo(0, 0);
    }
  }

  animate(elem, style, unit, from, to, time, prop) {

    if (!elem) {
      return;
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = (from + step * (to - from)) + unit;
        } else {
          elem.style[style] = (from + step * (to - from)) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 25);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }
}
