import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EhsHeroSvc } from '../shared/services/ehs-hero.service'
import { ChallengeModalComponent } from '../shared/components/challenge-modal.component';
import { MembershipDataService } from '../shared/services/membership.data.service';


@Component({
  templateUrl: './industry-collections.component.html',
  styleUrls: ['./industry-collections.component.css']
})

export class IndustryCollectionsComponent {

  isLimitedAccess: any;

  @ViewChild('challengeModal')
  modal: ChallengeModalComponent;
  pageName: string;
  isCollection: boolean = false;

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: EhsHeroSvc,
    private _mbService: MembershipDataService
  ) { }

  ngOnInit() {
    let accessLevels = this._mbService.dataAccessLevels();
    var hasSafety = accessLevels.includes("3");
   if ((!this._mbService.hasTool("Industries")) || !hasSafety  || ((!this._mbService.hasTool("AllResources") || !this._mbService.customPermissions.GeneralPermissions.allresources.Visible) && (!this._mbService.hasTool("Training") || !this._mbService.customPermissions.GeneralPermissions.training.Visible)))
    { 
      this._router.navigate(["error/403"]);
    }
    this._route.params.subscribe(params => {
      this.isLimitedAccess = this._mbService.isLimitedAccess();

    }
    );

  }

}
